import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Tooltip,
} from '@mui/material';
import Chart from 'react-apexcharts';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router';
import moment from 'moment';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import BtnExport from '../../assets/img/BtnExport.svg';
import BtnEdit from '../../assets/img/BtnEdit.svg';
import Site from '../../assets/img/Site.svg';
import Facebook from '../../assets/img/Facebook.svg';
import PlusBlue from '../../assets/img/PlusBlue.svg';
import Twitter from '../../assets/img/Twitter.svg';
import LinkedIn from '../../assets/img/LinkedIn.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import MenuSocial from '../../components/Menu/MenuSocial';
import { lightTheme } from '../../styles/theme';
import ScrollToTop from '../../utils/ScrollToTop';
import MenuSummary from '../../components/Menu/MenuSummary';
import MenuExperience from '../../components/Menu/MenuExperience';
import MenuFormation from '../../components/Menu/MenuFormation';
import MenuHardskill from '../../components/Menu/MenuHardskill';
import MenuAbout from '../../components/Menu/MenuAbout';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import DeleteModal from '../../components/DeleteModal';
import api from '../../api';
import CopyModal from '../../components/CopyModal';

import {
  Container,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  Group,
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  SubTitleLink,
  ContainerBlue,
  GroupFilter,
  Circle,
  Line,
  Wrap,
} from './styles';
import { userXpLevels } from '../../utils/xpLevels';
import { ProgressCard } from '../../components/ProgressCard';
import { AvatarEditor } from '../../components/AvatarEditor';
import { ImageCrop } from '../../components/ImageCrop';

function Profile() {
  const history = useHistory();
  const { token } = useContext(StoreContext);
  if (token === null) {
    history.push({
      pathname: '/login',
    });
  }
  const location = useLocation();
  const [width, setWidth] = useState(window.screen.width);
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);
  const [card6, setCard6] = useState(false);
  const [link, setLink] = useState(false);
  const [reportCulture, setReportCulture] = useState([]);
  const [reportBehavioral, setReportBehavioral] = useState([]);
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [hardskill, setHardskill] = useState([]);
  const [radar, setRadar] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);
  const [del, setDel] = useState(false);
  const [selectedHS, setSelectedHS] = useState('');
  const [active, setActive] = useState({});
  const [idFoto, setIdFoto] = useState('');
  const [urlFoto, setUrlFoto] = useState('');
  const [croppedImage, setCroppedImage] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [fileToCrop, setFileToCrop] = useState(null);

  const getData = useCallback(
    async id => {
      await api
        .get(`/dados-candidatos/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          setData(response.data);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token],
  );

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const u = response.data.users.filter(item => {
          return (
            item.email ===
            (location.search.length > 0
              ? location.search.substring(
                  location.search.indexOf('=') + 1,
                  +location.search.length,
                )
              : token.email)
          );
        });
        setUser(u);
        getData(u[0].id);
      })
      .catch(() => {
        setError(true);
      });
  }, [getData, location.search, token.email, token.refreshToken]);

  const onSaveHS = async id => {
    setSelectedHS(id);
    setDel(true);
  };

  const onCard1 = () => {
    setCard1(true);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  };

  const onCard2 = () => {
    setCard1(false);
    setCard2(true);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  };

  const onCard3 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(true);
    setCard4(false);
    setCard5(false);
    setCard6(false);
  };

  const onCard4 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(true);
    setCard5(false);
    setCard6(false);
  };

  const onCard5 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(true);
    setCard6(false);
  };

  const onCard6 = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(true);
  };

  const onClose = () => {
    setCard1(false);
    setCard2(false);
    setCard3(false);
    setCard4(false);
    setCard5(false);
    setCard6(false);
    getUsers();
  };

  const editExperience = async arr => {
    setActive(arr);
    onCard4();
  };

  const newExperience = async () => {
    setActive([]);
    onCard4();
  };

  const editCollege = async arr => {
    setActive(arr);
    onCard5();
  };

  const newCollege = async () => {
    setActive([]);
    onCard5();
  };

  const onCulture = e => {
    e.preventDefault();
    history.push({
      pathname: '/relatoriodeCultura',
      state: {
        activeUser:
          location.search.length > 0
            ? location.search.substring(
                location.search.indexOf('=') + 1,
                +location.search.length,
              )
            : token.email,
      },
    });
  };

  const onBehavioral = e => {
    e.preventDefault();
    history.push({
      pathname: '/relatorioComportamental',
      state: {
        activeUser:
          location.search.length > 0
            ? location.search.substring(
                location.search.indexOf('=') + 1,
                +location.search.length,
              )
            : token.email,
      },
    });
  };

  const onCloseError = () => {
    setError(false);
  };

  const getTest = useCallback(async () => {
    const body = {
      email:
        location.search.length > 0
          ? location.search.substring(
              location.search.indexOf('=') + 1,
              +location.search.length,
            )
          : token.email,
    };
    await api
      .post('/tests', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const dataCulture = response.data.cultural;
        const dataBehavioral = response.data.comportamental;
        if (dataCulture) {
          await setReportCulture(dataCulture);
          await setRadar({
            series: [
              {
                name: 'Cultura',
                data: [
                  dataCulture.ACOLHIMENTO,
                  dataCulture.PROPOSITO,
                  dataCulture.APRENDIZADO,
                  dataCulture.PRAZER,
                  dataCulture.RESULTADOS,
                  dataCulture.AUTORIDADE,
                  dataCulture.SEGURANÇA,
                  dataCulture.ORDEM,
                ],
              },
            ],
            options: {
              colors: ['#95D45A'],
              chart: {
                height: 350,
                type: 'radar',
                fontFamily: 'Poppins',
              },
              title: {
                text: '',
              },
              xaxis: {
                color: [colors.darkBlue],
                categories: [
                  'Acolhimento',
                  'Propósito',
                  'Aprendizado',
                  'Prazer',
                  'Resultados',
                  'Autoridade',
                  'Segurança',
                  'Ordem',
                ],
              },
            },
          });
          setIsVisible(true);
        }
        if (dataBehavioral) {
          setReportBehavioral(dataBehavioral);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [location.search, token]);

  const getHardskill = useCallback(async () => {
    await api
      .get('/hardskill', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        setHardskill(response.data.data);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const deleteHS = async id => {
    await api
      .delete(`user-hardskill/${id}/${user[0].id}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setDel(false);
        getUsers();
      })
      .catch(() => {
        setDel(false);
        setError(true);
      });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/perfil?activeUser=${encodeURIComponent(
        location.search.length > 0
          ? location.search.substring(
              location.search.indexOf('=') + 1,
              +location.search.length,
            )
          : token.email,
      )}`,
    );
    setLink(true);
    setTimeout(() => {
      setLink(false);
    }, 800);
  };

  const onChangeProfilePhoto = useCallback(async file => {
    setFileToCrop(file);
    setShowCropper(true);
  }, []);

  useEffect(() => {
    getTest();
    getUsers();
    getHardskill();
  }, [getTest, getUsers, getHardskill]);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <LayoutContainer>
          <LayoutContent>
            <LayoutContentMain>
              <ContainerBlue>
                <ResponsiveAppBar />
                <ErrorModal isOpen={error} onRequestClose={onCloseError} />
                <CopyModal
                  isOpen={link}
                  width={width}
                  onRequestClose={() => setLink(false)}
                />
                <DeleteModal
                  isOpen={del}
                  onRequestClose={() => setDel(false)}
                  title="Tem certeza que deseja desvincular a hardskill?"
                  onNext={() => deleteHS(selectedHS)}
                />
                {card1 && (
                  <MenuSummary user={user} isOpen={card1} onClose={onClose} />
                )}
                {card2 && (
                  <MenuAbout user={user} isOpen={card2} onClose={onClose} />
                )}
                {card3 && (
                  <MenuSocial user={user} isOpen={card3} onClose={onClose} />
                )}
                {card4 && (
                  <MenuExperience
                    active={active}
                    user={user}
                    isOpen={card4}
                    onClose={onClose}
                  />
                )}
                {card5 && (
                  <MenuFormation
                    active={active}
                    user={user}
                    isOpen={card5}
                    onClose={onClose}
                  />
                )}
                {card6 && (
                  <MenuHardskill user={user} isOpen={card6} onClose={onClose} />
                )}
                <ScrollToTop />
                <Container>
                  <ContainerCard top="20%">
                    <Circle
                      bottom="0%"
                      style={{
                        background: colors.darkBlue,
                        // backgroundImage: 'url(' + User + ')',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <Avatar
                        src={user[0]?.dados_adicionais?.foto}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          alignSelf: 'center',
                          width: '240px',
                          height: '240px',
                          fontSize: '96px',
                        }}
                      >
                        {user[0]?.name.split(' ')[0][0]}
                        {user[0]?.name.split(' ')[1][0]}
                      </Avatar>
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0,
                          color: colors.gray4,
                        }}
                      >
                        <AvatarEditor
                          id="avatar"
                          idFoto={idFoto}
                          urlFoto={urlFoto}
                          setterUrl={setUrlFoto}
                          setterId={setIdFoto}
                          onChangePhoto={onChangeProfilePhoto}
                          fileToUpload={croppedImage}
                          uploadMode="BY_FILE_TO_UPLOAD_CHANGE"
                          userId={user[0]?.id}
                        />
                      </Box>
                    </Circle>
                    <GroupFilter top="0%" bottom="70px">
                      <SubTitle bottom="0%" top="1.2px" align="center">
                        {user.length > 0 ? String(user[0].name) : ''}
                      </SubTitle>
                      <SubTitle
                        bottom="0%"
                        top="1.2px"
                        font="14px"
                        color={colors.lightBlue}
                        align="center"
                      >
                        {data.length > 0 && data[0].posicao_atual
                          ? data[0].posicao_atual
                          : ''}
                      </SubTitle>
                      <SubTitle
                        weight="400"
                        top="1.2px"
                        font="12px"
                        color={colors.gray4}
                        align="center"
                      >
                        {user.length > 0 &&
                        user[0].dados_adicionais?.cidade &&
                        user[0].dados_adicionais?.estado
                          ? `${String(
                              user[0].dados_adicionais?.cidade,
                            )}, ${String(user[0].dados_adicionais?.estado)}`
                          : ''}
                      </SubTitle>
                      <Button
                        type="button"
                        width="200px"
                        hoverColor={colors.lightBlue}
                        text="Compartilhar perfil"
                        fontSize="12px"
                        onClick={copyLink}
                        icon={
                          <Icon
                            width="40px"
                            float="left"
                            left="20px"
                            right="-20px"
                            src={BtnExport}
                            alt="Item"
                          />
                        }
                      />
                    </GroupFilter>
                  </ContainerCard>

                  <ContainerCard top="5%" bottom="0%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Resumo</SubTitle>
                          {location.search.length <= 0 && (
                            <Button
                              type="button"
                              width="120px"
                              hoverColor={colors.lightBlue}
                              text="Editar"
                              fontSize="12px"
                              onClick={onCard1}
                              icon={
                                <Icon
                                  width="30px"
                                  float="left"
                                  left="10px"
                                  right="-20px"
                                  src={BtnEdit}
                                  alt="Item"
                                />
                              }
                            />
                          )}
                        </Row>

                        <DescriptionData font="14px" weight="400">
                          {user.length > 0 && user[0].dados_adicionais?.resumo
                            ? String(user[0].dados_adicionais?.resumo)
                            : ''}
                        </DescriptionData>
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  <ContainerCard top="-5%" bottom="0%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Sobre mim</SubTitle>
                          {location.search.length <= 0 && (
                            <Button
                              type="button"
                              width="120px"
                              hoverColor={colors.lightBlue}
                              text="Editar"
                              fontSize="12px"
                              onClick={onCard2}
                              icon={
                                <Icon
                                  width="30px"
                                  float="left"
                                  left="10px"
                                  right="-20px"
                                  src={BtnEdit}
                                  alt="Item"
                                />
                              }
                            />
                          )}
                        </Row>
                        <Row top="0%">
                          <Group width="25%">
                            <SubTitle font="14px">Cidade onde mora</SubTitle>
                            <SubTitle font="14px" weight="400">
                              {user.length && user[0].dados_adicionais?.cidade
                                ? String(user[0].dados_adicionais?.cidade)
                                : ''}
                            </SubTitle>
                          </Group>
                          <Group width="25%">
                            <SubTitle font="14px">Experiência na área</SubTitle>
                            <SubTitle font="14px" weight="400">
                              {user.length &&
                              user[0].dados_adicionais?.experiencia_area
                                ? String(
                                    user[0].dados_adicionais?.experiencia_area,
                                  )
                                : ''}
                            </SubTitle>
                          </Group>
                          <Group width="25%">
                            <SubTitle font="14px">Modelo de trabalho</SubTitle>
                            <SubTitle font="14px" weight="400">
                              {user.length > 0 &&
                              user[0].dados_adicionais?.modelo_trabalho
                                ? String(
                                    user[0].dados_adicionais?.modelo_trabalho,
                                  )
                                : ''}
                            </SubTitle>
                          </Group>
                        </Row>

                        <Row top="0%">
                          <Group width="25%">
                            <SubTitle font="14px">Nível de inglês</SubTitle>
                            <SubTitle font="14px" weight="400">
                              {user.length > 0 &&
                              user[0].dados_adicionais?.nivel_ingles
                                ? String(user[0].dados_adicionais?.nivel_ingles)
                                : ''}
                            </SubTitle>
                          </Group>
                          <Group width="25%">
                            <SubTitle font="14px">É PCD?</SubTitle>
                            <SubTitle font="14px" weight="400">
                              {user.length > 0 && user[0].dados_adicionais?.pcd
                                ? String(user[0].dados_adicionais?.pcd)
                                : ''}
                            </SubTitle>
                          </Group>
                          <Group width="25%" />
                        </Row>
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  <ContainerCard top="-15%" bottom="0%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Redes sociais</SubTitle>
                          {location.search.length <= 0 && (
                            <Button
                              type="button"
                              width="120px"
                              hoverColor={colors.lightBlue}
                              text="Editar"
                              fontSize="12px"
                              onClick={onCard3}
                              icon={
                                <Icon
                                  width="30px"
                                  float="left"
                                  left="10px"
                                  right="-20px"
                                  src={BtnEdit}
                                  alt="Item"
                                />
                              }
                            />
                          )}
                        </Row>
                        <Row top="0%">
                          {user.length > 0 &&
                          user[0].dados_adicionais?.site_pessoal ? (
                            <Group width="25%">
                              <Row top="0%" style={{ marginRight: '1rem' }}>
                                <SubTitleLink
                                  href={
                                    user.length > 0
                                      ? String(
                                          user[0].dados_adicionais
                                            ?.site_pessoal,
                                        )
                                      : '#'
                                  }
                                  target="_blank"
                                  font="14px"
                                >
                                  <Icon
                                    width="30px"
                                    float="left"
                                    left="0px"
                                    right="10px"
                                    src={Site}
                                    alt="Item"
                                  />
                                  Site pessoal
                                </SubTitleLink>
                              </Row>
                            </Group>
                          ) : (
                            <Group width="25%" />
                          )}

                          {user.length > 0 &&
                          user[0].dados_adicionais?.linkedin ? (
                            <Group width="25%">
                              <Row top="0%" style={{ marginRight: '1rem' }}>
                                <SubTitleLink
                                  font="14px"
                                  href={
                                    user.length > 0
                                      ? String(
                                          user[0].dados_adicionais?.linkedin,
                                        )
                                      : '#'
                                  }
                                  target="_blank"
                                >
                                  <Icon
                                    width="30px"
                                    float="left"
                                    left="0px"
                                    right="10px"
                                    src={LinkedIn}
                                    alt="Item"
                                  />
                                  LinkedIn
                                </SubTitleLink>
                              </Row>
                            </Group>
                          ) : (
                            <Group width="25%" />
                          )}

                          {user.length > 0 &&
                          user[0].dados_adicionais?.facebook ? (
                            <Group width="25%">
                              <Row top="0%" style={{ marginRight: '1rem' }}>
                                <SubTitleLink
                                  font="14px"
                                  href={
                                    user.length > 0
                                      ? String(
                                          user[0].dados_adicionais?.facebook,
                                        )
                                      : '#'
                                  }
                                  target="_blank"
                                >
                                  <Icon
                                    width="30px"
                                    float="left"
                                    left="0px"
                                    right="10px"
                                    src={Facebook}
                                    alt="Item"
                                  />
                                  Facebook
                                </SubTitleLink>
                              </Row>
                            </Group>
                          ) : (
                            <Group width="25%" />
                          )}

                          {user.length > 0 &&
                          user[0].dados_adicionais?.twitter ? (
                            <Group width="25%">
                              <Row top="0%">
                                <SubTitleLink
                                  font="14px"
                                  href={
                                    user.length > 0
                                      ? String(
                                          user[0].dados_adicionais?.twitter,
                                        )
                                      : '#'
                                  }
                                  target="_blank"
                                >
                                  <Icon
                                    width="30px"
                                    float="left"
                                    left="0px"
                                    right="10px"
                                    src={Twitter}
                                    alt="Item"
                                  />
                                  Twitter
                                </SubTitleLink>
                              </Row>
                            </Group>
                          ) : (
                            <Group width="25%" />
                          )}
                        </Row>
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  <ContainerCard top="-25%" bottom="0%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Experiência profissional</SubTitle>
                        </Row>
                        {location.search.length <= 0 && (
                          <Button
                            type="button"
                            width="230px"
                            fontSize="13px"
                            text="Adicionar experiência"
                            color={colors.lightBlue}
                            bgColor={colors.transparent}
                            onClick={newExperience}
                            icon={
                              <Icon
                                width="40px"
                                float="left"
                                left="20px"
                                right="-20px"
                                src={PlusBlue}
                                alt="Item"
                              />
                            }
                          />
                        )}

                        {data[0]?.experiencias.map(item => {
                          return (
                            <>
                              <Row top="0%" bottom="0%" content="space-between">
                                <Group bottom="-20px" width="30%">
                                  <SubTitle bottom="0%" top="3px" font="16px">
                                    {item.empresa}
                                  </SubTitle>
                                </Group>
                                <Group bottom="-20px" width="70%" right="0px">
                                  {location.search.length <= 0 && (
                                    <Button
                                      type="button"
                                      width="120px"
                                      hoverColor={colors.lightBlue}
                                      text="Editar"
                                      fontSize="12px"
                                      onClick={() => editExperience(item)}
                                      icon={
                                        <Icon
                                          width="30px"
                                          float="left"
                                          left="10px"
                                          right="-20px"
                                          src={BtnEdit}
                                          alt="Item"
                                        />
                                      }
                                    />
                                  )}
                                </Group>
                              </Row>

                              <SubTitle
                                bottom="0%"
                                top="3px"
                                font="14px"
                                weight="400"
                              >
                                {item.cargo}
                              </SubTitle>
                              <SubTitle
                                bottom="0%"
                                top="3px"
                                font="12px"
                                weight="400"
                                color={colors.gray4}
                              >
                                {`${moment(item.periodo_de).format(
                                  'DD/MM/YYYY',
                                )} - ${
                                  item.periodo_ate
                                    ? moment(item.periodo_ate).format(
                                        'DD/MM/YYYY',
                                      )
                                    : 'até o momento'
                                }`}
                              </SubTitle>
                              <SubTitle bottom="0%" font="12px" weight="400">
                                {item.descricao}
                              </SubTitle>
                              <Line />
                            </>
                          );
                        })}
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  <ContainerCard top="-35%" bottom="0%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Formação acadêmica</SubTitle>
                        </Row>
                        {location.search.length <= 0 && (
                          <Button
                            type="button"
                            width="230px"
                            fontSize="13px"
                            text="Adicionar formação"
                            color={colors.lightBlue}
                            bgColor={colors.transparent}
                            onClick={newCollege}
                            icon={
                              <Icon
                                width="40px"
                                float="left"
                                left="20px"
                                right="-20px"
                                src={PlusBlue}
                                alt="Item"
                              />
                            }
                          />
                        )}

                        {data.map(item => {
                          if (item.formacoes.length > 0) {
                            return (
                              <>
                                <Row
                                  top="0%"
                                  bottom="0%"
                                  content="space-between"
                                >
                                  <Group bottom="-20px" width="30%">
                                    <SubTitle bottom="0%" top="3px" font="16px">
                                      {item.formacoes[0].instituicao}
                                    </SubTitle>
                                  </Group>
                                  <Group bottom="-20px" width="70%" right="0px">
                                    {location.search.length <= 0 && (
                                      <Button
                                        type="button"
                                        width="120px"
                                        hoverColor={colors.lightBlue}
                                        text="Editar"
                                        fontSize="12px"
                                        onClick={() =>
                                          editCollege(item.formacoes)
                                        }
                                        icon={
                                          <Icon
                                            width="30px"
                                            float="left"
                                            left="10px"
                                            right="-20px"
                                            src={BtnEdit}
                                            alt="Item"
                                          />
                                        }
                                      />
                                    )}
                                  </Group>
                                </Row>
                                <Row top="0%" bottom="0%" content="start">
                                  <SubTitle
                                    bottom="0%"
                                    top="3px"
                                    font="14px"
                                    weight="400"
                                  >
                                    {item.formacoes[0].nome_formacao}
                                  </SubTitle>
                                </Row>
                                <SubTitle
                                  bottom="0%"
                                  top="3px"
                                  font="12px"
                                  weight="400"
                                  color={colors.gray4}
                                >
                                  {`${moment(
                                    item.formacoes[0].periodo_de,
                                  ).format('DD/MM/YYYY')} - ${
                                    item.formacoes[0].periodo_ate
                                      ? moment(
                                          item.formacoes[0].periodo_ate,
                                        ).format('DD/MM/YYYY')
                                      : 'até o momento'
                                  }`}
                                </SubTitle>
                                <Line />
                              </>
                            );
                          }
                          return <span />;
                        })}
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  <ContainerCard top="-45%" bottom="1%">
                    <Card width="100%" heigth="300px">
                      <GroupItems>
                        <Row top="0%" content="space-between">
                          <SubTitle>Hardskills</SubTitle>
                          {location.search.length <= 0 && (
                            <Button
                              type="button"
                              width="230px"
                              fontSize="13px"
                              text="Adicionar hardskill"
                              color={colors.lightBlue}
                              bgColor={colors.transparent}
                              onClick={onCard6}
                              icon={
                                <Icon
                                  width="40px"
                                  float="left"
                                  left="20px"
                                  right="-20px"
                                  src={PlusBlue}
                                  alt="Item"
                                />
                              }
                            />
                          )}
                        </Row>

                        {user.length > 0 && user[0].hard_skills.length > 0 && (
                          <Wrap top="0%">
                            {user[0].hard_skills.map(i => {
                              const json = hardskill.filter(item => {
                                return i.hard_skill_id === item.id;
                              });

                              return (
                                <Group width="35%" bottom="1.5%">
                                  <Row cursor="pointer">
                                    <Tooltip title={userXpLevels[i.valor]}>
                                      <Chip
                                        sx={{ mr: '4px' }}
                                        key={json.hard_skill_id}
                                        label={`${json[0].nome} (${i.valor})`}
                                        color="primary"
                                        onDelete={() =>
                                          onSaveHS(i.hard_skill_id)
                                        }
                                      />
                                    </Tooltip>
                                  </Row>
                                </Group>
                              );
                            })}
                          </Wrap>
                        )}
                      </GroupItems>
                    </Card>
                  </ContainerCard>

                  {String(reportBehavioral).length > 0 &&
                    reportBehavioral !== undefined && (
                      <ContainerCard top="-61%">
                        <Card width="100%" heigth="650px">
                          <GroupItems>
                            <SubTitle>Relatório comportamental</SubTitle>
                            <DescriptionData font="14px" weight="400">
                              O seu relatório comportamental pode ser composto a
                              partir de quatro perfis básicos: Desbravador,
                              Mantenedor, Investigador e Embaixador. Clicando no
                              ícone ao lado dos nomes abaixo, você poderá
                              conhecer as características principais de cada um
                              deles.
                            </DescriptionData>
                            <DescriptionData font="14px">
                              Abaixo, de acordo com suas respostas, veja como
                              ficou a composição do seu perfil nesse momento.
                            </DescriptionData>
                            <SubCard
                              top="2%"
                              width="100%"
                              heigth="auto"
                              border={colors.gray5}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ padding: '16px' }}
                                >
                                  <Stack spacing={2} padding={3}>
                                    <ProgressCard
                                      title="Desbravador"
                                      value={reportBehavioral.DESBRAVADOR}
                                      bgColor={colors.lightYellow}
                                      barColor={colors.darkYellow}
                                    />
                                    <ProgressCard
                                      title="Mantenedor"
                                      value={reportBehavioral.MANTENEDOR}
                                      bgColor={colors.lightBlue}
                                      barColor={colors.darkBlue}
                                    />
                                    <ProgressCard
                                      title="Investigador"
                                      value={reportBehavioral.INVESTIGADOR}
                                      bgColor={colors.lightGreen}
                                      barColor={colors.darkestGreen}
                                    />
                                    <ProgressCard
                                      title="Embaixador"
                                      value={reportBehavioral.EMBAIXADOR}
                                      bgColor={colors.lightRed}
                                      barColor={colors.alertRed}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} padding={3}>
                                  <SubTitle>Seu perfil predominante é</SubTitle>
                                  <SubTitle top="0%" color={colors.lightBlue}>
                                    {reportBehavioral.PERFIL_PREDOMINANTE}
                                  </SubTitle>
                                  <DescriptionData font="14px" weight="400">
                                    {reportBehavioral.MEU_PERFIL_COMPORTAMENTAL}
                                  </DescriptionData>
                                </Grid>
                              </Grid>
                            </SubCard>
                          </GroupItems>

                          <GroupFilter>
                            <Button
                              type="button"
                              width="250px"
                              hoverColor={colors.lightBlue}
                              text="Ver relatório completo"
                              fontSize="12px"
                              onClick={onBehavioral}
                            />
                          </GroupFilter>
                        </Card>
                      </ContainerCard>
                    )}

                  {String(reportCulture).length > 0 &&
                    reportCulture !== undefined && (
                      <ContainerCard top="-70%" bottom="1%">
                        <Card width="100%" heigth="650px">
                          <GroupItems>
                            <SubTitle>Relatório cultural</SubTitle>
                            <DescriptionData font="14px">
                              De acordo com suas respostas, seus estilos
                              dominantes são:
                            </DescriptionData>
                            <GroupItems padding="2%">
                              <Row top="0%">
                                <Group width="100%">
                                  <SubCard
                                    top="2%"
                                    width="100%"
                                    heigth="auto"
                                    background={colors.lightBlue}
                                    border={colors.lightBlue}
                                  >
                                    <GroupItems padding="5%">
                                      <SubTitle
                                        font="16px"
                                        color={colors.white}
                                      >
                                        {reportCulture.CULTURA_PREDOMINANTE}
                                      </SubTitle>
                                      <LabelData
                                        color={colors.white}
                                        font="14px"
                                        weight="400"
                                      >
                                        {reportCulture.A_CULTURA}
                                      </LabelData>
                                    </GroupItems>
                                  </SubCard>
                                </Group>
                              </Row>
                            </GroupItems>
                            <SubTitle>
                              A composição de suas culturas pode ser
                              representada da seguinte forma:
                            </SubTitle>
                            <DescriptionData font="16px" weight="400">
                              Os elementos da cultura de uma organização que,
                              nesse momento, são mais relevantes são
                              apresentadas no gráfico a seguir. Quando mais
                              distante do centro estiver o ponto, maior a
                              importância desse elemento para você.
                            </DescriptionData>
                            {isVisible ? (
                              <Chart
                                options={radar.options}
                                series={radar.series}
                                type="radar"
                                height={800}
                              />
                            ) : (
                              false
                            )}
                          </GroupItems>

                          <GroupFilter>
                            <Button
                              type="button"
                              width="250px"
                              hoverColor={colors.lightBlue}
                              text="Ver relatório completo"
                              fontSize="12px"
                              onClick={onCulture}
                            />
                          </GroupFilter>
                        </Card>
                      </ContainerCard>
                    )}
                </Container>
              </ContainerBlue>
            </LayoutContentMain>
          </LayoutContent>
        </LayoutContainer>
      </ThemeProvider>
      <Dialog fullScreen open={showCropper}>
        <DialogContent>
          <ImageCrop
            aspectRatio={1 / 1}
            setShowCropper={setShowCropper}
            showCropper={showCropper}
            fileToCrop={fileToCrop}
            setFileToCrop={setFileToCrop}
            onSave={file => {
              setShowCropper(false);
              setFileToCrop(null);
              setCroppedImage(file);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Profile;
