import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import moment from 'moment';
import { Button } from '@mui/material';
import Activity from '../../assets/img/Activity.svg';
import Solution from '../../assets/img/Solution.svg';
import Deployment from '../../assets/img/Deployment.svg';
import Cluster from '../../assets/img/Cluster.svg';
import colors from '../../styles/colors';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';

import {
  Container,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  Description,
  ContainerTitle,
  DescriptionData,
  DescriptionCard,
  Row,
  Tag,
  Column,
  GroupProgress,
  Progress,
} from './styles';

function Home() {
  const { token } = useContext(StoreContext);
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const history = useHistory();

  const onBehavioral = () => {
    history.push({
      pathname: '/testeComportamental',
      state: {
        vacancy: false,
      },
    });
  };

  const onCulture = () => {
    history.push({
      pathname: '/testeCultura',
      state: {
        vacancy: false,
      },
    });
  };

  const onCareer = () => {
    history.push({
      pathname: '/testeCarreira',
      state: {
        vacancy: false,
      },
    });
  };

  const onVacancy = () => {
    history.push({
      pathname: '/minhasCarreiras',
      state: {
        vacancy: false,
      },
    });
  };

  const getTests = useCallback(async () => {
    const body = {
      email: token.email,
    };
    await api
      .post('/tests', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data: responseData } = response;
        setData(responseData);
      });
  }, [token]);

  useEffect(() => {
    setWidth(window.screen.width);
    getTests();
  }, [getTests, width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <Title>Inicie sua jornada de carreira</Title>
      <Description>
        Apenas 3 passos para aumentar o seu autoconhecimento e ter um perfil
        campeão
      </Description>
      <ContainerCard width="1300px">
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Solution} alt="Next" />
              <div>
                <DescriptionData>
                  {data.comportamental ? 'Útltima atualização:' : ''}
                </DescriptionData>
                <DescriptionData weight="700">
                  {data.comportamental
                    ? moment(data.comportamental.DATA_DA_ENTRADA).format(
                        'DD/MM/YYYY',
                      )
                    : ''}
                </DescriptionData>
              </div>
            </ContainerTitle>
            {data.comportamental ? (
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 1 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
            ) : (
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 1 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            )}
            <SubTitle bottom="6%">Teste de perfil comportamental</SubTitle>
            <DescriptionCard>
              Conheça seu perfil predominante, suas habilidades comportamentais,
              agilidades de aprendizagem e potencial.
            </DescriptionCard>
            <Row>
              <Icon width="35px" src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado: 8 minutos</DescriptionData>
            </Row>
            {data.comportamental ? (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
            ) : (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>Não iniciado</DescriptionCard>
                  <DescriptionCard>0%</DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            )}

            {data.comportamental ? (
              <>
                <Link
                  target="_blank"
                  to={{
                    pathname: `/relatorioComportamental?activeUser=${encodeURIComponent(
                      token.email,
                    )}`,
                    search: '',
                    hash: '',
                    state: {
                      activeUser: token.email,
                    },
                  }}
                >
                  <Column top="0%">
                    <Button sx={{ my: 1, px: 6 }} variant="contained">
                      Ver Relatório
                    </Button>
                  </Column>
                </Link>
                <Column top="0%">
                  <Button
                    sx={{ my: 1, px: 6 }}
                    variant="outlined"
                    onClick={onBehavioral}
                  >
                    Refazer teste
                  </Button>
                </Column>
              </>
            ) : (
              <Column top="0%">
                <Button
                  sx={{ my: 1, px: 6 }}
                  variant="contained"
                  onClick={onBehavioral}
                >
                  Iniciar teste
                </Button>
              </Column>
            )}
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Deployment} alt="Next" />
              <div>
                <DescriptionData>
                  {data.cultural ? 'Útltima atualização:' : ''}
                </DescriptionData>
                <DescriptionData weight="700">
                  {data.cultural
                    ? moment(data.cultural.DATA_DA_ENTRADA).format('DD/MM/YYYY')
                    : ''}
                </DescriptionData>
              </div>
            </ContainerTitle>
            {data.cultural ? (
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 2 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
            ) : (
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 2 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            )}
            <SubTitle bottom="15%">Teste de cultura</SubTitle>
            <DescriptionCard>
              Descubra o seu estilo cultural, o que é aceito ou não por você no
              ambiente profissional e com qual cultura organizacional você mais
              se identifica.
            </DescriptionCard>
            <Row>
              <Icon width="35px" src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado: 4 minutos</DescriptionData>
            </Row>
            {data.cultural ? (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
            ) : (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>Não iniciado</DescriptionCard>
                  <DescriptionCard>0%</DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            )}

            {data.cultural ? (
              <>
                <Link
                  target="_blank"
                  to={{
                    pathname: `/relatoriodeCultura?activeUser=${encodeURIComponent(
                      token.email,
                    )}`,
                    search: '',
                    hash: '',
                    state: {
                      activeUser: token.email,
                    },
                  }}
                >
                  <Column top="0%">
                    <Button sx={{ my: 1, px: 6 }} variant="contained">
                      Ver relatório
                    </Button>
                  </Column>
                </Link>
                <Column top="0%">
                  <Button
                    sx={{ my: 1, px: 6 }}
                    variant="outlined"
                    onClick={onCulture}
                  >
                    Refazer teste
                  </Button>
                </Column>
              </>
            ) : (
              <Column top="0%">
                <Button
                  sx={{ my: 1, px: 6 }}
                  variant="contained"
                  onClick={onCulture}
                >
                  Iniciar teste
                </Button>
              </Column>
            )}
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle>
              <Icon src={Cluster} alt="Next" />
              <div>
                <DescriptionData>
                  {data.valores ? 'Útltima atualização:' : ''}
                </DescriptionData>
                <DescriptionData weight="700">
                  {data.valores
                    ? moment(data.valores.DATA_DA_ENTRADA).format('DD/MM/YYYY')
                    : ''}
                </DescriptionData>
              </div>
            </ContainerTitle>
            {data.valores ? (
              <Column>
                <Tag>
                  <DescriptionData color={colors.white}>
                    Teste 3 - Concluído
                  </DescriptionData>
                </Tag>
              </Column>
            ) : (
              <Column>
                <Tag background={colors.gray4}>
                  <DescriptionData color={colors.white}>
                    Teste 3 - Pendente
                  </DescriptionData>
                </Tag>
              </Column>
            )}
            <SubTitle>Teste de âncoras de carreira</SubTitle>
            <DescriptionCard>
              Saiba o que você mais valoriza em sua jornada profissional, além
              da comparação entre o que você espera e o que a sua empresa está
              entregando
            </DescriptionCard>
            <Row top="13%">
              <Icon width="35px" src={Activity} alt="Next" />
              <DescriptionData> Tempo estimado: 7 minutos</DescriptionData>
            </Row>
            {data.valores ? (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard color={colors.darkBlue}>
                    Concluído
                  </DescriptionCard>
                  <DescriptionCard color={colors.darkBlue}>
                    100%
                  </DescriptionCard>
                </ContainerTitle>
                <Progress colors={colors.pureGreen} />
              </GroupProgress>
            ) : (
              <GroupProgress>
                <ContainerTitle>
                  <DescriptionCard>Não iniciado</DescriptionCard>
                  <DescriptionCard>0%</DescriptionCard>
                </ContainerTitle>
                <Progress />
              </GroupProgress>
            )}

            {data.valores ? (
              <>
                <Link
                  target="_blank"
                  to={{
                    pathname: `/relatorioDeValoresDeCarreira?activeUser=${encodeURIComponent(
                      token.email,
                    )}`,
                    search: '',
                    hash: '',
                    state: {
                      activeUser: token.email,
                    },
                  }}
                >
                  <Column top="0%">
                    <Button sx={{ my: 1, px: 6 }} variant="contained">
                      Ver relatório
                    </Button>
                  </Column>
                </Link>
                <Column top="0%">
                  <Button
                    sx={{ my: 1, px: 6 }}
                    variant="outlined"
                    onClick={onCareer}
                  >
                    Refazer teste
                  </Button>
                </Column>
              </>
            ) : (
              <Column top="0%">
                <Button
                  sx={{ my: 1, px: 6 }}
                  variant="contained"
                  onClick={onCareer}
                >
                  Iniciar teste
                </Button>
              </Column>
            )}
          </GroupItems>
        </Card>
        <Card width="23%">
          <GroupItems>
            <ContainerTitle bottom="2%">
              <AppRegistrationIcon
                sx={{ color: colors.lightBlue, fontSize: 40 }}
              />
              {/* <Icon src={Finish} alt="Next" /> */}
            </ContainerTitle>
            <Column>
              <Tag>
                <DescriptionData color={colors.white}>Carreira</DescriptionData>
              </Tag>
            </Column>
            <SubTitle bottom="8%">Meu painel de vagas e carreiras</SubTitle>
            <DescriptionCard bottom="14%">
              Acompanhe de uma maneira fácil e eficiente o gerenciamento de suas
              carreiras, faça atualizações no seu perfil e encontre possíveis
              empregadores.
            </DescriptionCard>
            <Column top="22%">
              <Button
                sx={{ my: 1, px: 6 }}
                variant="contained"
                onClick={onVacancy}
              >
                Acessar
              </Button>
            </Column>
          </GroupItems>
        </Card>
      </ContainerCard>
    </Container>
  );
}

export default Home;
