import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';
import TestsModal from '../../components/TestsModal';
import ErrorModal from '../../components/ErrorModal';
import Button from '../../components/Button';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';

import {
  Container,
  Card,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  Step,
  ContainerButton,
} from './styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor:
      theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: colors.lightBlue,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colors.lightBlue,
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function StartCulture() {
  const { token } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [time, setTime] = useState(0);
  const [resp1, setResp1] = useState('');
  const [resp2, setResp2] = useState('');
  const [resp3, setResp3] = useState('');
  const [resp4, setResp4] = useState('');
  const [resp5, setResp5] = useState('');
  const [resp6, setResp6] = useState('');
  const [resp7, setResp7] = useState('');
  const [resp8, setResp8] = useState('');
  const [resp9, setResp9] = useState('');
  const [resp10, setResp10] = useState('');
  const history = useHistory();

  const onBack = () => {
    if (step === 1) {
      history.push({
        pathname: '/',
      });
    } else {
      setStep(step - 1);
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const onFinish = async () => {
    setTime(4);
    setModal(true);
  };

  const sendTest = async () => {
    const body = {
      lang: 'pt-br',
      email: token.email,
      data: new Date(),
      perfil: 'A mim mesmo (autoteste)',
      email_amigo: '',
      empresa: '',
      Cultura1: resp1,
      Cultura2: resp2,
      Cultura3: resp3,
      Cultura4: resp4,
      Cultura5: resp5,
      Cultura6: resp6,
      Cultura7: resp7,
      Cultura8: resp8,
      Cultura9: resp9,
      Cultura10: resp10,
    };

    await api
      .post('/cultural-test', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onFinish();
      })
      .catch(() => {
        setError(true);
      });
  };

  const startTest = async () => {
    const route = '/testeCarreira';

    history.push({
      pathname: route,
      state: {
        vacancy: location.state.vacancy,
        vacancyId: location.state.vacancyId,
      },
    });
  };

  const getTests = useCallback(async () => {
    const body = {
      email: token.email,
    };
    await api.post('/tests', body, {
      headers: {
        authorization: `Bearer ${token.refreshToken}`,
      },
    });
  }, [token]);

  const startReport = async () => {
    history.push({
      pathname: '/',
    });
  };

  const onCloseModal = async () => {
    setModal(false);
  };

  const step1 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 1 de 10
      </SubLabel>
      <Title>Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step active>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp1}
            onChange={e => {
              setResp1(e.target.value);
              setStep(2);
            }}
          >
            <Card>
              <FormControlLabel
                value="Abertura"
                control={<BpRadio />}
                label="Abertura"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Colaboração"
                control={<BpRadio />}
                label="Colaboração"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Decisão"
                control={<BpRadio />}
                label="Decisão"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Comunicação"
                control={<BpRadio />}
                label="Comunicação"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step2 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 2 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step active>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp2}
            onChange={e => {
              setResp2(e.target.value);
              setStep(3);
            }}
          >
            <Card>
              <FormControlLabel
                value="Dominancia"
                control={<BpRadio />}
                label="Dominância"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Agilidade"
                control={<BpRadio />}
                label="Agilidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Confianca"
                control={<BpRadio />}
                label="Confiança"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Obediência"
                control={<BpRadio />}
                label="Obediência"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step3 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 3 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step active>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp3}
            onChange={e => {
              setResp3(e.target.value);
              setStep(4);
            }}
          >
            <Card>
              <FormControlLabel
                value="Ousadia"
                control={<BpRadio />}
                label="Ousadia"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Pertencimento"
                control={<BpRadio />}
                label="Pertencimento"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Regras"
                control={<BpRadio />}
                label="Regras"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Aprendizado"
                control={<BpRadio />}
                label="Aprendizado"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step4 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 4 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step active>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp4}
            onChange={e => {
              setResp4(e.target.value);
              setStep(5);
            }}
          >
            <Card>
              <FormControlLabel
                value="Política"
                control={<BpRadio />}
                label="Política"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Exploração"
                control={<BpRadio />}
                label="Exploração"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Respeito"
                control={<BpRadio />}
                label="Respeito"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Receptividade"
                control={<BpRadio />}
                label="Receptividade"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step5 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 5 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step active>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp5}
            onChange={e => {
              setResp5(e.target.value);
              setStep(6);
            }}
          >
            <Card>
              <FormControlLabel
                value="Trabalho em equipe"
                control={<BpRadio />}
                label="Trabalho em equipe"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Inovação"
                control={<BpRadio />}
                label="Inovação"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Tradição"
                control={<BpRadio />}
                label="Tradição"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Rapidez de reação"
                control={<BpRadio />}
                label="Rapidez de reação"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step6 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 6 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step active>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp6}
            onChange={e => {
              setResp6(e.target.value);
              setStep(7);
            }}
          >
            <Card>
              <FormControlLabel
                value="Continuidade"
                control={<BpRadio />}
                label="Continuidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Diversidade"
                control={<BpRadio />}
                label="Diversidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Atingimento de metas"
                control={<BpRadio />}
                label="Atingimento de metas"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Informalidade"
                control={<BpRadio />}
                label="Informalidade"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step7 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 7 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step active>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp7}
            onChange={e => {
              setResp7(e.target.value);
              setStep(8);
            }}
          >
            <Card>
              <FormControlLabel
                value="Idealismo"
                control={<BpRadio />}
                label="Idealismo"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Construção de capacidade"
                control={<BpRadio />}
                label="Construção de capacidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Criatividade"
                control={<BpRadio />}
                label="Criatividade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Cuidado"
                control={<BpRadio />}
                label="Cuidado"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step8 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 8 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step active>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp8}
            onChange={e => {
              setResp8(e.target.value);
              setStep(9);
            }}
          >
            <Card>
              <FormControlLabel
                value="esponsabilidade social"
                control={<BpRadio />}
                label="Responsabilidade social"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Estabilidade"
                control={<BpRadio />}
                label="Estabilidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Diversão"
                control={<BpRadio />}
                label="Diversão"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Execução"
                control={<BpRadio />}
                label="Execução"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step9 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 9 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step active>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp9}
            onChange={e => {
              setResp9(e.target.value);
              setStep(10);
            }}
          >
            <Card>
              <FormControlLabel
                value="Foco"
                control={<BpRadio />}
                label="Foco"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Engajamento"
                control={<BpRadio />}
                label="Engajamento"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Sustentabilidade"
                control={<BpRadio />}
                label="Sustentabilidade"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Gestão"
                control={<BpRadio />}
                label="Gestão"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step10 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de cultura - Passo 10 de 10
      </SubLabel>
      <Title> Valorizo mais ambientes onde haja...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step active>
          <p>10</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp10}
            onChange={e => setResp10(e.target.value)}
          >
            <Card>
              <FormControlLabel
                value="Sucesso"
                control={<BpRadio />}
                label="Sucesso"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Realismo"
                control={<BpRadio />}
                label="Realismo"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Instinto"
                control={<BpRadio />}
                label="Instinto"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Tolerância"
                control={<BpRadio />}
                label="Tolerância"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <ContainerButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
        <Button
          type="button"
          width="140px"
          fontSize="13px"
          text="Concluir teste"
          hoverColor={colors.lightBlue}
          color={colors.white}
          bgColor={colors.lightBlue}
          onClick={sendTest}
        />
      </ContainerButton>
    </Group>
  );

  const steps = [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
  ];

  useEffect(() => {
    setWidth(window.screen.width);
    getTests();
  }, [getTests, width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <TestsModal
        isOpen={modal}
        onRequestClose={onCloseModal}
        minutes={time}
        test="âncoras de carreira"
        startReport={startReport}
        startTest={startTest}
      />
      {steps[step - 1]}
    </Container>
  );
}

export default StartCulture;
