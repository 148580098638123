import styled from 'styled-components';
import colors from '../../styles/colors';

export const Title = styled.h2`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.116667px;
  color: ${colors.darkBlue};
`;

export const SoftSkillLabel = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`;

export const PersonaValue = styled.span`
  color: #07a5d5;
  font-weight: 500;
`;

export const UserValue = styled.span`
  color: #fb9282;
  font-weight: 500;
`;
