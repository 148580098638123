import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Button from '../Button';
import colors from '../../styles/colors';
import { CardContent, CardTitle, CustomCard } from './Card';
import { Subtitle, Title } from './Title';
import { SliderInput } from './SliderInput';
import { HardskillSelector } from '../HardskillSelector';
import api from '../../api';
import StoreContext from '../Store/Context';
import { MinValueAlert } from './MinValueAlert';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';
import { SoftSkillSliderInput } from '../SoftSkillSliderInput';
import { useLoading } from '../../contexts/LoadingContext';

export function CalibratePersonaDialog({
  isOpen,
  onClose,
  selectedCareer,
  companyId,
}) {
  const { token } = useContext(StoreContext);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [minBehavioral, setMinBehavioral] = useState(100);
  const [minCulture, setMinCulture] = useState(100);
  const [msg, setMsg] = useState('');
  const [msgError, setMsgError] = useState('');
  const [learn1, setLearn1] = useState('');
  const [learn2, setLearn2] = useState('');
  const [learn3, setLearn3] = useState('');
  const [learn4, setLearn4] = useState('');
  const [learn5, setLearn5] = useState('');
  const [disabledUser, setDisabledUser] = useState(true);
  const [behavioral1, setBehavioral1] = useState(0);
  const [behavioral2, setBehavioral2] = useState(0);
  const [behavioral3, setBehavioral3] = useState(0);
  const [behavioral4, setBehavioral4] = useState(0);
  const [culture1, setCulture1] = useState(0);
  const [culture2, setCulture2] = useState(0);
  const [culture3, setCulture3] = useState(0);
  const [culture4, setCulture4] = useState(0);
  const [culture5, setCulture5] = useState(0);
  const [culture6, setCulture6] = useState(0);
  const [culture7, setCulture7] = useState(0);
  const [culture8, setCulture8] = useState(0);
  const [career1, setCareer1] = useState('');
  const [career2, setCareer2] = useState('');
  const [career3, setCareer3] = useState('');
  const [career4, setCareer4] = useState('');
  const [career5, setCareer5] = useState('');
  const [career6, setCareer6] = useState('');
  const [career7, setCareer7] = useState('');
  const [career8, setCareer8] = useState('');
  const [career9, setCareer9] = useState('');
  const [career11, setCareer11] = useState('');
  const [career12, setCareer12] = useState('');
  const [career13, setCareer13] = useState('');
  const [career14, setCareer14] = useState('');
  const [career15, setCareer15] = useState('');
  const [career16, setCareer16] = useState('');
  const [career17, setCareer17] = useState('');
  const [career18, setCareer18] = useState('');
  const [career10, setCareer10] = useState('');
  const [career19, setCareer19] = useState('');
  const [career20, setCareer20] = useState('');
  const [career21, setCareer21] = useState('');
  const [soft1, setSoft1] = useState('');
  const [soft2, setSoft2] = useState('');
  const [soft3, setSoft3] = useState('');
  const [soft4, setSoft4] = useState('');
  const [soft5, setSoft5] = useState('');
  const [soft6, setSoft6] = useState('');
  const [soft7, setSoft7] = useState('');
  const [soft8, setSoft8] = useState('');
  const [soft9, setSoft9] = useState('');
  const [soft10, setSoft10] = useState('');
  const [soft11, setSoft11] = useState('');
  const [soft12, setSoft12] = useState('');
  const [soft13, setSoft13] = useState('');
  const [soft14, setSoft14] = useState('');
  const [soft15, setSoft15] = useState('');
  const [soft16, setSoft16] = useState('');
  const [soft17, setSoft17] = useState('');
  const [soft18, setSoft18] = useState('');
  const [soft19, setSoft19] = useState('');
  const [soft20, setSoft20] = useState('');
  const [soft21, setSoft21] = useState('');
  const [soft22, setSoft22] = useState('');
  const [soft23, setSoft23] = useState('');
  const [soft24, setSoft24] = useState('');
  const [soft25, setSoft25] = useState('');
  const [soft26, setSoft26] = useState('');
  const [soft27, setSoft27] = useState('');
  const [soft28, setSoft28] = useState('');
  const [soft29, setSoft29] = useState('');
  const [soft30, setSoft30] = useState('');
  const [soft31, setSoft31] = useState('');
  const [soft32, setSoft32] = useState('');
  const [soft33, setSoft33] = useState('');
  const [soft34, setSoft34] = useState('');
  const [soft35, setSoft35] = useState('');
  const [soft36, setSoft36] = useState('');
  const [soft37, setSoft37] = useState('');
  const [soft38, setSoft38] = useState('');
  const [soft39, setSoft39] = useState('');
  const [soft40, setSoft40] = useState('');
  const [hards, setHards] = useState([]);
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [initialHardskills, setInitialHardskills] = useState([]);
  const { setLoading } = useLoading();
  const learnLevels = useMemo(() => ['Baixa', 'Moderada', 'Alta'], []);

  const handleUser = e => {
    e.preventDefault();
    const {
      target: { value },
    } = e;
    if (userSelected.includes(0)) {
      userSelected.splice(0, 1);
    }
    setUserSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const getAverage = useCallback(async () => {
    setLoading(true);
    const body = {
      emails: userSelected,
      carreira_id: selectedCareer.id,
    };

    const getLearnLevel = value => {
      if (value <= 25) return 0;
      if (value <= 75) return 1;
      return 2;
    };

    await api
      .post('/persona/fit', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.DATA;
        const average = data.filter(item => {
          return item.TYPE === 'Média';
        });
        if (average.length > 0) {
          // LEARN AGILITIES
          const agilities = average[0].PERFIL;
          setLearn1(getLearnLevel(+agilities.AGILIDADE_MENTAL));
          setLearn2(getLearnLevel(+agilities.AGILIDADE_PESSOAS));
          setLearn3(getLearnLevel(+agilities.AGILIDADE_MUDANCAS));
          setLearn4(getLearnLevel(+agilities.AGILIDADE_RESULTADOS));
          setLearn5(getLearnLevel(+agilities.POTENCIAL));

          // CULTURE
          const culture = average[0].CULTURA;
          setCulture1(parseFloat(culture.ACOLHIMENTO));
          setCulture2(parseFloat(culture.PROPOSITO));
          setCulture3(parseFloat(culture.APRENDIZADO));
          setCulture4(parseFloat(culture.PRAZER));
          setCulture5(parseFloat(culture.RESULTADOS));
          setCulture6(parseFloat(culture.AUTORIDADE));
          setCulture7(parseFloat(culture.SEGURANÇA));
          setCulture8(parseFloat(culture.ORDEM));
          setMinCulture(
            100 -
              (parseFloat(culture.ACOLHIMENTO) +
                parseFloat(culture.PROPOSITO) +
                parseFloat(culture.APRENDIZADO) +
                parseFloat(culture.PRAZER) +
                parseFloat(culture.RESULTADOS) +
                parseFloat(culture.AUTORIDADE) +
                parseFloat(culture.SEGURANÇA) +
                parseFloat(culture.ORDEM)),
          );
          const profile = average[0].PERFIL;
          setBehavioral1(parseFloat(profile.DESBRAVADOR));
          setBehavioral2(parseFloat(profile.EMBAIXADOR));
          setBehavioral3(parseFloat(profile.MANTENEDOR));
          setBehavioral4(parseFloat(profile.INVESTIGADOR));
          setMinBehavioral(
            100 -
              (parseFloat(profile.DESBRAVADOR) +
                parseFloat(profile.EMBAIXADOR) +
                parseFloat(profile.MANTENEDOR) +
                parseFloat(profile.INVESTIGADOR)),
          );
          const career = average[0].VALORES;
          setCareer1(parseFloat(career.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR));
          setCareer2(parseFloat(career.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR));
          setCareer3(parseFloat(career.CELEBRACAO_DE_CONQUISTAS));
          setCareer4(parseFloat(career.CLIMA_INTERNO));
          setCareer5(parseFloat(career.CONFIANCA_NA_LIDERANCA_IMEDIATA));
          setCareer6(parseFloat(career.CONFIANCA_NO_TIME_DE_TRABALHO));
          setCareer7(parseFloat(career.CULTURA_E_VALORES_DA_EMPRESA));
          setCareer8(parseFloat(career.DESAFIOS_CONSTANTES));
          setCareer9(
            parseFloat(career.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS),
          );
          setCareer10(parseFloat(career.HORARIO_DE_TRABALHO));
          setCareer11(parseFloat(career.LOCAL_DE_TRABALHO));
          setCareer12(
            parseFloat(career.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO),
          );
          setCareer13(parseFloat(career.PACOTE_DE_BENEFICIOS));
          setCareer14(parseFloat(career.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO));
          setCareer15(
            parseFloat(career.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO),
          );
          setCareer16(parseFloat(career.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS));
          setCareer17(parseFloat(career.RECONHECIMENTO_E_VALORIZACAO));
          setCareer18(parseFloat(career.SALARIO));
          setCareer19(parseFloat(career.TRATAMENTO_HUMANIZADO));
          setCareer20(
            parseFloat(career.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS),
          );
          setCareer21(parseFloat(career.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO));
          setHards(average[0].HARD_SKILLS);
          const softs = average[0].SOFT_SKILLS;
          setSoft1(parseFloat(softs.APRENDIZADO_CONSTANTE));
          setSoft2(parseFloat(softs.FLEXIBILIDADE));
          setSoft3(parseFloat(softs.GESTAO_DA_MUDANCA));
          setSoft4(parseFloat(softs.INOVACAO));
          setSoft5(parseFloat(softs.INTELIGENCIA_DIGITAL));
          setSoft6(parseFloat(softs.INTRAEMPREENDEDORISMO));
          setSoft7(parseFloat(softs.MENTALIDADE_DE_STARTUP));
          setSoft8(parseFloat(softs.PENSAMENTO_AGIL));
          setSoft9(parseFloat(softs.RESILIENCIA));
          setSoft10(parseFloat(softs.ACABATIVA));
          setSoft11(parseFloat(softs.ENGAJAMENTO_CONTINUO));
          setSoft12(parseFloat(softs.GESTAO_DA_QUALIDADE));
          setSoft13(parseFloat(softs.GESTAO_DE_PROJETOS));
          setSoft14(parseFloat(softs.GESTAO_DO_TEMPO));
          setSoft15(parseFloat(softs.IDENTIFICACAO_DE_PROPOSITO));
          setSoft16(parseFloat(softs.MELHORIA_DE_DESEMPENHO));
          setSoft17(parseFloat(softs.ORIENTACAO_AO_RESULTADO));
          setSoft18(parseFloat(softs.SENSO_DE_DONO));
          setSoft19(parseFloat(softs.VENDA_CONSULTIVA));
          setSoft20(parseFloat(softs.CAPACIDADE_ANALITICA));
          setSoft21(parseFloat(softs.ESCUTA_ATIVA));
          setSoft22(parseFloat(softs.GESTAO_DA_ANSIEDADE));
          setSoft23(parseFloat(softs.GESTAO_DO_CONHECIMENTO));
          setSoft24(parseFloat(softs.INTELIGENCIA_EMOCIONAL));
          setSoft25(parseFloat(softs.LIDERANCA_ESTRATEGICA));
          setSoft26(parseFloat(softs.ORIENTACAO_AO_NEGOCIO));
          setSoft27(parseFloat(softs.PENSAMENTO_ESTRATEGICO));
          setSoft28(parseFloat(softs.PLANEJAMENTO));
          setSoft29(parseFloat(softs.VELOCIDADE_NA_TOMADA_DE_DECISAO));
          setSoft30(parseFloat(softs.COMUNICACAO));
          setSoft31(parseFloat(softs.CONSTRUCAO_DE_ALIANCAS));
          setSoft32(parseFloat(softs.DESENVOLVIMENTO_DE_TALENTOS));
          setSoft33(parseFloat(softs.GESTAO_DE_PESSOAS));
          setSoft34(parseFloat(softs.MENTALIDADE_INCLUSIVA));
          setSoft35(parseFloat(softs.NEGOCIACAO));
          setSoft36(parseFloat(softs.ORIENTACAO_AO_CLIENTE));
          setSoft37(parseFloat(softs.CONCESSAO_DE_FEEDBACK));
          setSoft38(parseFloat(softs.RELACIONAMENTO_INTERPESSOAL));
          setSoft39(parseFloat(softs.SOLUCOES_DE_CONFLITOS));
          setSoft40(parseFloat(softs.TRABALHO_EM_EQUIPE));
          setUserSelected([]);
        }
      })
      .catch(() => {
        setMsgError('Nenhum dado encontrado!');
        setError(true);
      });
    setLoading(false);
  }, [selectedCareer, setLoading, token, userSelected]);

  const clean = async () => {
    setLearn1('');
    setLearn2('');
    setLearn3('');
    setLearn4('');
    setLearn5('');
    setBehavioral1(0);
    setBehavioral2(0);
    setBehavioral3(0);
    setBehavioral4(0);
    setCulture1(0);
    setCulture2(0);
    setCulture3(0);
    setCulture4(0);
    setCulture5(0);
    setCulture6(0);
    setCulture7(0);
    setCulture8(0);
    setCareer1('');
    setCareer2('');
    setCareer3('');
    setCareer4('');
    setCareer5('');
    setCareer6('');
    setCareer7('');
    setCareer8('');
    setCareer9('');
    setCareer11('');
    setCareer12('');
    setCareer13('');
    setCareer14('');
    setCareer15('');
    setCareer16('');
    setCareer17('');
    setCareer18('');
    setCareer10('');
    setCareer19('');
    setCareer20('');
    setCareer21('');
    setSoft1('');
    setSoft2('');
    setSoft3('');
    setSoft4('');
    setSoft5('');
    setSoft6('');
    setSoft7('');
    setSoft8('');
    setSoft9('');
    setSoft10('');
    setSoft11('');
    setSoft12('');
    setSoft13('');
    setSoft14('');
    setSoft15('');
    setSoft16('');
    setSoft17('');
    setSoft18('');
    setSoft19('');
    setSoft20('');
    setSoft21('');
    setSoft22('');
    setSoft23('');
    setSoft24('');
    setSoft25('');
    setSoft26('');
    setSoft27('');
    setSoft28('');
    setSoft29('');
    setSoft30('');
    setSoft31('');
    setSoft32('');
    setSoft33('');
    setSoft34('');
    setSoft35('');
    setSoft36('');
    setSoft37('');
    setSoft38('');
    setSoft39('');
    setSoft40('');
    setHards([]);
    setMinBehavioral(100);
    setMinCulture(100);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      nome: selectedCareer.nome,
      persona: {
        perfil_comportamental: {
          DESBRAVADOR: behavioral1,
          EMBAIXADOR: behavioral2,
          MANTENEDOR: behavioral3,
          INVESTIGADOR: behavioral4,
        },
        learn_agilities: {
          AGILIDADE_MENTAL: learnLevels[learn1],
          AGILIDADE_COM_PESSOAS: learnLevels[learn2],
          AGILIDADE_COM_MUDANCAS: learnLevels[learn3],
          AGILIDADE_COM_RESULTADOS: learnLevels[learn4],
          POTENCIAL: learnLevels[learn5],
        },
        cultural: {
          ACOLHIMENTO: culture1,
          PROPOSITO: culture2,
          APRENDIZADO: culture3,
          PRAZER: culture4,
          RESULTADOS: culture5,
          AUTORIDADE: culture6,
          SEGURANÇA: culture7,
          ORDEM: culture8,
        },
        hard_skills: hards.map(item => ({
          id: item.id,
          nome: item.nome,
          valor: item.value,
          mandatoria: item.required,
        })),
        valores_de_carreira: {
          ABERTURA_PARA_MUDANCAS_E_INOVACAO: parseFloat(career1),
          AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR: parseFloat(career2),
          CELEBRACAO_DE_CONQUISTAS: parseFloat(career3),
          CLIMA_INTERNO: parseFloat(career4),
          CONFIANCA_NA_LIDERANCA_IMEDIATA: parseFloat(career5),
          CONFIANCA_NO_TIME_DE_TRABALHO: parseFloat(career6),
          CULTURA_E_VALORES_DA_EMPRESA: parseFloat(career7),
          DESAFIOS_CONSTANTES: parseFloat(career8),
          GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS: parseFloat(career9),
          HORARIO_DE_TRABALHO: parseFloat(career10),
          LOCAL_DE_TRABALHO: parseFloat(career11),
          OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO: parseFloat(career12),
          PACOTE_DE_BENEFICIOS: parseFloat(career13),
          PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO: parseFloat(career14),
          POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO: parseFloat(career15),
          PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS: parseFloat(career16),
          RECONHECIMENTO_E_VALORIZACAO: parseFloat(career17),
          SALARIO: parseFloat(career18),
          TRATAMENTO_HUMANIZADO: parseFloat(career19),
          TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS: parseFloat(career20),
          VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO: parseFloat(career21),
        },
        soft_skills: {
          APRENDIZADO_CONSTANTE: parseFloat(soft1),
          FLEXIBILIDADE: parseFloat(soft2),
          GESTAO_DA_MUDANCA: parseFloat(soft3),
          INOVACAO: parseFloat(soft4),
          INTELIGENCIA_DIGITAL: parseFloat(soft5),
          INTRAEMPREENDEDORISMO: parseFloat(soft6),
          MENTALIDADE_DE_STARTUP: parseFloat(soft7),
          PENSAMENTO_AGIL: parseFloat(soft8),
          RESILIENCIA: parseFloat(soft9),
          ACABATIVA: parseFloat(soft10),
          ENGAJAMENTO_CONTINUO: parseFloat(soft11),
          GESTAO_DA_QUALIDADE: parseFloat(soft12),
          GESTAO_DE_PROJETOS: parseFloat(soft13),
          GESTAO_DO_TEMPO: parseFloat(soft14),
          IDENTIFICACAO_DE_PROPOSITO: parseFloat(soft15),
          MELHORIA_DE_DESEMPENHO: parseFloat(soft16),
          ORIENTACAO_AO_RESULTADO: parseFloat(soft17),
          SENSO_DE_DONO: parseFloat(soft18),
          VENDA_CONSULTIVA: parseFloat(soft19),
          CAPACIDADE_ANALITICA: parseFloat(soft20),
          ESCUTA_ATIVA: parseFloat(soft21),
          GESTAO_DA_ANSIEDADE: parseFloat(soft22),
          GESTAO_DO_CONHECIMENTO: parseFloat(soft23),
          INTELIGENCIA_EMOCIONAL: parseFloat(soft24),
          LIDERANCA_ESTRATEGICA: parseFloat(soft25),
          ORIENTACAO_AO_NEGOCIO: parseFloat(soft26),
          PENSAMENTO_ESTRATEGICO: parseFloat(soft27),
          PLANEJAMENTO: parseFloat(soft28),
          VELOCIDADE_NA_TOMADA_DE_DECISAO: parseFloat(soft29),
          COMUNICACAO: parseFloat(soft30),
          CONSTRUCAO_DE_ALIANCAS: parseFloat(soft31),
          DESENVOLVIMENTO_DE_TALENTOS: parseFloat(soft32),
          GESTAO_DE_PESSOAS: parseFloat(soft33),
          MENTALIDADE_INCLUSIVA: parseFloat(soft34),
          NEGOCIACAO: parseFloat(soft35),
          ORIENTACAO_AO_CLIENTE: parseFloat(soft36),
          CONCESSAO_DE_FEEDBACK: parseFloat(soft37),
          RELACIONAMENTO_INTERPESSOAL: parseFloat(soft38),
          SOLUCOES_DE_CONFLITOS: parseFloat(soft39),
          TRABALHO_EM_EQUIPE: parseFloat(soft40),
        },
      },
      company_id: companyId,
      carreira_id: selectedCareer.id,
    };

    await api
      .post('/persona/create', data, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const idPersona = response.data.created_id;

        const career = {
          persona_ideal_id: idPersona,
        };

        await api
          .put(`/carreiras/${selectedCareer.id}`, career, {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          })
          .then(async () => {
            await onClose();
            setMsg('Persona calibrada com sucesso!');
            setAlert(true);
          })
          .catch(async () => {
            await onClose();
            setMsgError('Sistema indisponível! Tente novamente mais tarde!');
            setError(true);
          });
      })
      .catch(async () => {
        await onClose();
        setMsgError('Sistema indisponível! Tente novamente mais tarde!');
        setError(true);
      });
    clean();

    setLoading(false);
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const getUsers = useCallback(async () => {
    if (companyId && token?.refreshToken) {
      await api
        .get(`/companies/users/${companyId}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response.data;
          setUsers(data ?? []);
          setDisabledUser(false);
        });
    }
  }, [companyId, token.refreshToken]);

  const handleChangeHardskill = l => {
    setHards(l);
  };

  const handleBehavioral1 = value => {
    setBehavioral1(parseFloat(value));
    setMinBehavioral(
      100 - (parseFloat(value) + behavioral2 + behavioral3 + behavioral4),
    );
  };

  const handleBehavioral2 = value => {
    setBehavioral2(parseFloat(value));
    setMinBehavioral(
      100 -
        (parseFloat(behavioral1) +
          parseFloat(value) +
          behavioral3 +
          behavioral4),
    );
  };

  const handleBehavioral3 = value => {
    setBehavioral3(parseFloat(value));
    setMinBehavioral(
      100 - (behavioral1 + behavioral2 + parseFloat(value) + behavioral4),
    );
  };

  const handleBehavioral4 = value => {
    setBehavioral4(parseFloat(value));
    setMinBehavioral(
      100 - (behavioral1 + behavioral2 + behavioral3 + parseFloat(value)),
    );
  };

  const handleCulture1 = value => {
    setCulture1(parseFloat(value));
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture4 +
          culture5 +
          culture6 +
          culture7 +
          culture8),
    );
  };

  const handleCulture2 = value => {
    setCulture2(parseFloat(value));
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture1 +
          culture3 +
          culture4 +
          culture5 +
          culture6 +
          culture7 +
          culture8),
    );
  };

  const handleCulture3 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture1 +
          culture4 +
          culture5 +
          culture6 +
          culture7 +
          culture8),
    );
    setCulture3(parseFloat(value));
  };

  const handleCulture4 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture1 +
          culture5 +
          culture6 +
          culture7 +
          culture8),
    );
    setCulture4(parseFloat(value));
  };

  const handleCulture5 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture4 +
          culture1 +
          culture6 +
          culture7 +
          culture8),
    );
    setCulture5(parseFloat(value));
  };

  const handleCulture6 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture4 +
          culture5 +
          culture1 +
          culture7 +
          culture8),
    );
    setCulture6(parseFloat(value));
  };

  const handleCulture7 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture4 +
          culture5 +
          culture6 +
          culture1 +
          culture8),
    );
    setCulture7(parseFloat(value));
  };

  const handleCulture8 = value => {
    setMinCulture(
      100 -
        (parseFloat(value) +
          culture2 +
          culture3 +
          culture4 +
          culture5 +
          culture6 +
          culture7 +
          culture1),
    );
    setCulture8(parseFloat(value));
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (selectedCareer.persona) {
      const { persona } = selectedCareer;

      setBehavioral1(persona.perfil_comportamental.DESBRAVADOR);
      setBehavioral2(persona.perfil_comportamental.EMBAIXADOR);
      setBehavioral3(persona.perfil_comportamental.MANTENEDOR);
      setBehavioral4(persona.perfil_comportamental.INVESTIGADOR);

      setLearn1(
        learnLevels.findIndex(
          level => level === persona.learn_agilities.AGILIDADE_MENTAL,
        ),
      );
      setLearn2(
        learnLevels.findIndex(
          level => level === persona.learn_agilities.AGILIDADE_COM_PESSOAS,
        ),
      );
      setLearn3(
        learnLevels.findIndex(
          level => level === persona.learn_agilities.AGILIDADE_COM_MUDANCAS,
        ),
      );
      setLearn4(
        learnLevels.findIndex(
          level => level === persona.learn_agilities.AGILIDADE_COM_RESULTADOS,
        ),
      );
      setLearn5(
        learnLevels.findIndex(
          level => level === persona.learn_agilities.POTENCIAL,
        ),
      );

      setCulture1(persona.cultural.ACOLHIMENTO);
      setCulture2(persona.cultural.PROPOSITO);
      setCulture3(persona.cultural.APRENDIZADO);
      setCulture4(persona.cultural.PRAZER);
      setCulture5(persona.cultural.RESULTADOS);
      setCulture6(persona.cultural.AUTORIDADE);
      setCulture7(persona.cultural.SEGURANÇA);
      setCulture8(persona.cultural.ORDEM);

      setHards(
        persona.hard_skills.map(skill => ({
          id: skill.id,
          nome: skill.nome,
          required: skill.mandatoria,
          value: skill.valor,
        })),
      );
      setInitialHardskills(persona.hard_skills);

      setCareer1(persona.valores_de_carreira.ABERTURA_PARA_MUDANCAS_E_INOVACAO);
      setCareer2(
        persona.valores_de_carreira.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR,
      );
      setCareer3(persona.valores_de_carreira.CELEBRACAO_DE_CONQUISTAS);
      setCareer4(persona.valores_de_carreira.CLIMA_INTERNO);
      setCareer5(persona.valores_de_carreira.CONFIANCA_NA_LIDERANCA_IMEDIATA);
      setCareer6(persona.valores_de_carreira.CONFIANCA_NO_TIME_DE_TRABALHO);
      setCareer7(persona.valores_de_carreira.CULTURA_E_VALORES_DA_EMPRESA);
      setCareer8(persona.valores_de_carreira.DESAFIOS_CONSTANTES);
      setCareer9(
        persona.valores_de_carreira.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS,
      );
      setCareer10(persona.valores_de_carreira.HORARIO_DE_TRABALHO);
      setCareer11(persona.valores_de_carreira.LOCAL_DE_TRABALHO);
      setCareer12(
        persona.valores_de_carreira
          .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO,
      );
      setCareer13(persona.valores_de_carreira.PACOTE_DE_BENEFICIOS);
      setCareer14(
        persona.valores_de_carreira.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO,
      );
      setCareer15(
        persona.valores_de_carreira.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO,
      );
      setCareer16(
        persona.valores_de_carreira.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS,
      );
      setCareer17(persona.valores_de_carreira.RECONHECIMENTO_E_VALORIZACAO);
      setCareer18(persona.valores_de_carreira.SALARIO);
      setCareer19(persona.valores_de_carreira.TRATAMENTO_HUMANIZADO);
      setCareer20(
        persona.valores_de_carreira.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS,
      );
      setCareer21(
        persona.valores_de_carreira.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO,
      );

      setSoft1(persona.soft_skills.APRENDIZADO_CONSTANTE);
      setSoft2(persona.soft_skills.FLEXIBILIDADE);
      setSoft3(persona.soft_skills.GESTAO_DA_MUDANCA);
      setSoft4(persona.soft_skills.INOVACAO);
      setSoft5(persona.soft_skills.INTELIGENCIA_DIGITAL);
      setSoft6(persona.soft_skills.INTRAEMPREENDEDORISMO);
      setSoft7(persona.soft_skills.MENTALIDADE_DE_STARTUP);
      setSoft8(persona.soft_skills.PENSAMENTO_AGIL);
      setSoft9(persona.soft_skills.RESILIENCIA);
      setSoft10(persona.soft_skills.ACABATIVA);
      setSoft11(persona.soft_skills.ENGAJAMENTO_CONTINUO);
      setSoft12(persona.soft_skills.GESTAO_DA_QUALIDADE);
      setSoft13(persona.soft_skills.GESTAO_DE_PROJETOS);
      setSoft14(persona.soft_skills.GESTAO_DO_TEMPO);
      setSoft15(persona.soft_skills.IDENTIFICACAO_DE_PROPOSITO);
      setSoft16(persona.soft_skills.MELHORIA_DE_DESEMPENHO);
      setSoft17(persona.soft_skills.ORIENTACAO_AO_RESULTADO);
      setSoft18(persona.soft_skills.SENSO_DE_DONO);
      setSoft19(persona.soft_skills.VENDA_CONSULTIVA);
      setSoft20(persona.soft_skills.CAPACIDADE_ANALITICA);
      setSoft21(persona.soft_skills.ESCUTA_ATIVA);
      setSoft22(persona.soft_skills.GESTAO_DA_ANSIEDADE);
      setSoft23(persona.soft_skills.GESTAO_DO_CONHECIMENTO);
      setSoft24(persona.soft_skills.INTELIGENCIA_EMOCIONAL);
      setSoft25(persona.soft_skills.LIDERANCA_ESTRATEGICA);
      setSoft26(persona.soft_skills.ORIENTACAO_AO_NEGOCIO);
      setSoft27(persona.soft_skills.PENSAMENTO_ESTRATEGICO);
      setSoft28(persona.soft_skills.PLANEJAMENTO);
      setSoft29(persona.soft_skills.VELOCIDADE_NA_TOMADA_DE_DECISAO);
      setSoft30(persona.soft_skills.COMUNICACAO);
      setSoft31(persona.soft_skills.CONSTRUCAO_DE_ALIANCAS);
      setSoft32(persona.soft_skills.DESENVOLVIMENTO_DE_TALENTOS);
      setSoft33(persona.soft_skills.GESTAO_DE_PESSOAS);
      setSoft34(persona.soft_skills.MENTALIDADE_INCLUSIVA);
      setSoft35(persona.soft_skills.NEGOCIACAO);
      setSoft36(persona.soft_skills.ORIENTACAO_AO_CLIENTE);
      setSoft37(persona.soft_skills.CONCESSAO_DE_FEEDBACK);
      setSoft38(persona.soft_skills.RELACIONAMENTO_INTERPESSOAL);
      setSoft39(persona.soft_skills.SOLUCOES_DE_CONFLITOS);
      setSoft40(persona.soft_skills.TRABALHO_EM_EQUIPE);
    }
  }, [learnLevels, selectedCareer]);

  useEffect(() => {
    setMinBehavioral(
      100 - (behavioral1 + behavioral2 + behavioral3 + behavioral4),
    );
    setMinCulture(
      100 -
        (culture1 +
          culture2 +
          culture3 +
          culture4 +
          culture5 +
          culture6 +
          culture7 +
          culture8),
    );
  }, [
    behavioral1,
    behavioral2,
    behavioral3,
    behavioral4,
    culture1,
    culture2,
    culture3,
    culture4,
    culture5,
    culture6,
    culture7,
    culture8,
  ]);

  useEffect(() => {
    if (isOpen) {
      setUserSelected([]);
    }
  }, [isOpen]);

  return (
    <>
      {error && (
        <ErrorModal
          isOpen={error}
          msg={msgError}
          onRequestClose={onCloseError}
        />
      )}
      {alert && (
        <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      )}
      <Dialog
        open={isOpen ?? false}
        onClose={onClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{ sx: { borderRadius: '1rem' } }}
      >
        <DialogTitle>
          <Title>Calibrar Persona</Title>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <CustomCard>
                <CardTitle icon={false}>
                  Calibrar a partir dos usuários
                </CardTitle>
                <CardContent>
                  <FormControl
                    fullWidth
                    sx={{
                      backgroundColor: colors.gray6,
                      m: 1,
                      display: 'flex',
                    }}
                  >
                    <InputLabel id="users-label">Usuários</InputLabel>
                    <Select
                      value={userSelected}
                      onChange={handleUser}
                      disabled={disabledUser}
                      labelId="users-label"
                      color="info"
                      label="Usuários"
                      multiple
                      sx={{
                        backgroundColor: colors.white,
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        textAlign: 'start',
                      }}
                      InputProps={{
                        sx: {
                          color: 'white',
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: 'white !important',
                          },
                        },
                      }}
                    >
                      <MenuItem value={0}>
                        <em>Procurar por usuários</em>
                      </MenuItem>
                      {users.length > 0
                        ? users.map(item => {
                            return (
                              <MenuItem
                                value={item.email}
                                disabled={
                                  !(
                                    item.tests.comportamental &&
                                    item.tests.cultural &&
                                    item.tests.valores
                                  )
                                }
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : false}
                    </Select>
                  </FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      disabled={userSelected.length === 0}
                      type="button"
                      width="96px"
                      fontSize="13px"
                      text="Aplicar"
                      hoverColor={colors.lightBlue}
                      color={colors.white}
                      bgColor={colors.lightBlue}
                      onClick={getAverage}
                    />
                  </Box>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCard>
                <CardTitle>Comportamento</CardTitle>
                <CardContent sx={{ height: '100%' }}>
                  <SliderInput
                    label="Desbravador"
                    color={colors.lightYellow}
                    value={behavioral1}
                    onChange={handleBehavioral1}
                    hint="Refere-se ao estilo de comportamento das pessoas que tendem a ser diretas, assertivas e orientadas para resultados. Eles são frequentemente vistos como indivíduos competitivos que buscam assumir o controle das situações."
                  />
                  <SliderInput
                    label="Mantenedor"
                    color={colors.lightBlue}
                    value={behavioral3}
                    onChange={handleBehavioral3}
                    hint="Refere-se ao estilo de comportamento das pessoas que são mais calmas, pacientes e colaborativas. Eles valorizam a harmonia e a estabilidade em suas relações e tendem a ser mais tranquilos e previsíveis."
                  />
                  <SliderInput
                    label="Investigador"
                    color={colors.lightGreen}
                    value={behavioral4}
                    onChange={handleBehavioral4}
                    hint="Refere-se ao estilo de comportamento das pessoas que são organizadas, detalhistas e precisas. Eles buscam seguir as regras e normas estabelecidas e valorizam a precisão em suas atividades."
                  />
                  <SliderInput
                    label="Embaixador"
                    color={colors.alertRed}
                    value={behavioral2}
                    onChange={handleBehavioral2}
                    hint="Refere-se ao estilo de comportamento das pessoas que são extrovertidas, sociáveis e comunicativas. Eles têm facilidade em influenciar e persuadir os outros, sendo frequentemente vistos como líderes carismáticos."
                  />
                </CardContent>
                <MinValueAlert minValue={minBehavioral} />
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCard>
                <CardTitle>Agilidades de Aprendizado</CardTitle>
                <CardContent>
                  <SliderInput
                    label="Mudanças"
                    value={learn3}
                    min={0}
                    max={2}
                    marks={[
                      { value: 0, label: 'Baixa' },
                      { value: 1, label: 'Moderada' },
                      { value: 2, label: 'Alta' },
                    ]}
                    onChange={value => setLearn3(value)}
                    hint="Você não só “se adapta bem às mudanças”, mas provoca ou lidera os processos de mudanças, gerenciando resistências e encontrando aliados para ter sucesso na implementação."
                  />
                  <SliderInput
                    label="Resultados"
                    value={learn4}
                    min={0}
                    max={2}
                    marks={[
                      { value: 0, label: 'Baixa' },
                      { value: 1, label: 'Moderada' },
                      { value: 2, label: 'Alta' },
                    ]}
                    onChange={value => setLearn4(value)}
                    hint="Você apresenta um grande impulso para resultados e é energizado por atribuições desafiadoras, sendo conhecido popularmente por “tirar leite de pedra”, entregando os resultados mesmo em situações extremamente críticas ou adversas, energizando-se diante de desafios complexos, construindo times de alta performance."
                  />
                  <SliderInput
                    label="Mental"
                    value={learn1}
                    min={0}
                    max={2}
                    marks={[
                      { value: 0, label: 'Baixa' },
                      { value: 1, label: 'Moderada' },
                      { value: 2, label: 'Alta' },
                    ]}
                    onChange={value => setLearn1(value)}
                    hint="Você encontra soluções para problemas complexos, fazendo conexões “não óbvias” entre múltiplas variáveis, assumindo riscos e tomando decisões difíceis."
                  />
                  <SliderInput
                    label="Pessoas"
                    value={learn2}
                    min={0}
                    max={2}
                    marks={[
                      { value: 0, label: 'Baixa' },
                      { value: 1, label: 'Moderada' },
                      { value: 2, label: 'Alta' },
                    ]}
                    onChange={value => setLearn2(value)}
                    hint="Você navega de forma adequada pelos diversos níveis das organizações, podendo demonstrar certa dificuldade em lidar com situações de conflito ou negociações difíceis."
                  />
                  <SliderInput
                    label="Potencial"
                    value={learn5}
                    min={0}
                    max={2}
                    marks={[
                      { value: 0, label: 'Baixa' },
                      { value: 1, label: 'Moderada' },
                      { value: 2, label: 'Alta' },
                    ]}
                    onChange={value => setLearn5(value)}
                  />
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <CardTitle>Cultura</CardTitle>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SliderInput
                        label="Acolhimento"
                        value={culture1}
                        onChange={handleCulture1}
                        hint="A cultura de acolhimento está relacionada aos relacionamentos e à confiança mútua. Os ambientes são locais calorosos, onde as pessoas ajudam e apoiam umas às outras. As pessoas são unidas por lealdade e os líderes enfatizam a sinceridade, o trabalho em equipe e os relacionamentos positivos."
                      />
                      <SliderInput
                        label="Propósito"
                        value={culture2}
                        onChange={handleCulture2}
                        hint="A cultura de propósito se caracteriza por idealismo e altruísmo. Os ambientes são locais tolerantes, solidários, onde as pessoas tentam fazer o bem pelo futuro de longo prazo da humanidade. São unidos pelo foco na sustentabilidade e nas comunidades globais, e os líderes enfatizam ideais comuns e contribuem para uma causa maior."
                      />
                      <SliderInput
                        label="Aprendizado"
                        value={culture3}
                        onChange={handleCulture3}
                        hint="A cultura de aprendizado está relacionada à exploração, expansividade e criatividade. Os ambientes são locais inovadores e liberais, onde as pessoas lançam ideias novas e exploram alternativas. São unidos pela curiosidade e os líderes enfatizam o conhecimento, a inovação e a aventura."
                      />
                      <SliderInput
                        label="Prazer"
                        value={culture4}
                        onChange={handleCulture4}
                        hint="A cultura de prazer é expressada por meio de divertimento e empolgação. Os ambientes são locais despreocupados, onde as pessoas tendem a fazer o que as tornam felizes. São unidos pela descontração e pelos estímulos, e os líderes enfatizam a espontaneidade e o senso de humor."
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SliderInput
                        label="Resultados"
                        value={culture5}
                        onChange={handleCulture5}
                        hint="A cultura de resultados é caracterizada pelas realizações e pelas conquistas. Os ambientes são orientados para resultados e baseados no mérito, onde as pessoas aspiram ao desempenho de excelência. São unidos pela motivação da competência e do sucesso, e os líderes enfatizam o cumprimento de metas."
                      />
                      <SliderInput
                        label="Autoridade"
                        value={culture6}
                        onChange={handleCulture6}
                        hint="A cultura de autoridade é definida pela força, determinação e ousadia. Os ambientes são locais competitivos, onde as pessoas se esforçam para obter vantagem individual. São unidos por forte controle, e os líderes enfatizam a confiança e a dominação."
                      />
                      <SliderInput
                        label="Segurança"
                        value={culture7}
                        onChange={handleCulture7}
                        hint="A cultura de segurança se refere ao planejamento, precaução e prevenção. Os ambientes são locais previsíveis, onde as pessoas têm consciência do risco e se pautam pela reflexão. São unidos pelo desejo de se sentirem protegidos e antecipam a mudança. Os líderes enfatizam o comportamento realista e o planejamento cuidadoso."
                      />
                      <SliderInput
                        label="Ordem"
                        value={culture8}
                        onChange={handleCulture8}
                        hint="A cultura de ordem se concentra no respeito, na estrutura e nas normas comuns. Os ambientes são locais metódicos, onde as pessoas obedecem as regras do jogo e procuram se encaixar. São unidos pela cooperação, e os líderes enfatizam procedimentos compartilhados e costumes seculares."
                      />
                    </Grid>
                  </Grid>
                  <MinValueAlert minValue={minCulture} />
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <CardTitle>Valores de Carreira</CardTitle>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Abertura para mudanças e inovação"
                        breakLabel
                        value={career1}
                        onChange={value => setCareer1(value)}
                        hint="O quanto uma empresa demonstrar abertura real a mudanças e inovação."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Autonomia e abertura para se expressar"
                        breakLabel
                        value={career2}
                        onChange={value => setCareer2(value)}
                        hint="O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Celebração de conquistas"
                        breakLabel
                        value={career3}
                        onChange={value => setCareer3(value)}
                        hint="O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Clima interno"
                        breakLabel
                        value={career4}
                        onChange={value => setCareer4(value)}
                        hint="O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Confiança na liderança imediata"
                        breakLabel
                        value={career5}
                        onChange={value => setCareer5(value)}
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Confiança no time de trabalho"
                        breakLabel
                        value={career6}
                        onChange={value => setCareer6(value)}
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor) e equipe."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Cultura e valores da empresa"
                        breakLabel
                        value={career7}
                        onChange={value => setCareer7(value)}
                        hint="O quanto a cultura ('jeitão' da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Desafios constantes"
                        breakLabel
                        value={career8}
                        onChange={value => setCareer8(value)}
                        hint="O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Gestão clara e acompanhamento dos objetivos"
                        breakLabel
                        value={career9}
                        onChange={value => setCareer9(value)}
                        hint="O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Horário de trabalho"
                        breakLabel
                        value={career10}
                        onChange={value => setCareer10(value)}
                        hint="O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Local de trabalho"
                        breakLabel
                        value={career11}
                        onChange={value => setCareer11(value)}
                        hint="O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Oportunidades de desenvolvimento e aprendizado"
                        breakLabel
                        value={career12}
                        onChange={value => setCareer12(value)}
                        hint="O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Pacote de benefícios"
                        breakLabel
                        value={career13}
                        onChange={value => setCareer13(value)}
                        hint="O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Plano de carreira claro e estruturado"
                        breakLabel
                        value={career14}
                        onChange={value => setCareer14(value)}
                        hint="O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Possibilidade de crescimento e promoção"
                        breakLabel
                        value={career15}
                        onChange={value => setCareer15(value)}
                        hint="O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Processos simples e desburocratizados"
                        breakLabel
                        value={career16}
                        onChange={value => setCareer16(value)}
                        hint="O quanto a simplificação e organização dos processos da empresa são fatores decisivos."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Reconhecimento e valorização"
                        breakLabel
                        value={career17}
                        onChange={value => setCareer17(value)}
                        hint="O quanto você sentir que recebe reconhecimento e valorização em uma empresa."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Salário"
                        breakLabel
                        value={career18}
                        onChange={value => setCareer18(value)}
                        hint="O quanto o salário pago é fator decisivo."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Tratamento humanizado"
                        breakLabel
                        value={career19}
                        onChange={value => setCareer19(value)}
                        hint="O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional)."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Tratamento justo e igualitário com todos"
                        breakLabel
                        value={career20}
                        onChange={value => setCareer20(value)}
                        hint="O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '0.5rem' }}>
                      <SliderInput
                        label="Visão de futuro da empresa ou negócio"
                        breakLabel
                        value={career21}
                        onChange={value => setCareer21(value)}
                        hint="O quanto ter clareza sobre o futuro da empresa e o que ela busca."
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <CardTitle>Habilidades Comportamentais (Soft Skills)</CardTitle>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={3} sx={{ padding: '0.5rem' }}>
                      <Subtitle>Mudanças</Subtitle>
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <SoftSkillSliderInput
                          label="Aprendizado constante"
                          bgColor={colors.lightYellow}
                          value={soft1}
                          onChange={value => setSoft1(value)}
                          hint="Busca ativa e contínua por novos conhecimentos e aplicações. Aprender e reaprender continuamente"
                        />
                        <SoftSkillSliderInput
                          label="Flexibilidade"
                          bgColor={colors.lightYellow}
                          value={soft2}
                          onChange={value => setSoft2(value)}
                          hint="Abertura para considerar visões diferentes e se adaptar em novos cenários"
                        />
                        <SoftSkillSliderInput
                          label="Gestão de mudança"
                          bgColor={colors.lightYellow}
                          value={soft3}
                          onChange={value => setSoft3(value)}
                          hint="Esforço constante na busca de melhorias e novas práticas"
                        />
                        <SoftSkillSliderInput
                          label="Inovação"
                          bgColor={colors.lightYellow}
                          value={soft4}
                          onChange={value => setSoft4(value)}
                          hint="Processo criativo, busca de novas ideias e inovações, rompendo com o estabelecido"
                        />
                        <SoftSkillSliderInput
                          label="Inteligência digital"
                          bgColor={colors.lightYellow}
                          value={soft5}
                          onChange={value => setSoft5(value)}
                          hint="Capacidade em entender, processar e utilizar a tecnologia de forma eficiente, responsável e produtiva"
                        />
                        <SoftSkillSliderInput
                          label="Intraempreendedorismo"
                          bgColor={colors.lightYellow}
                          value={soft6}
                          onChange={value => setSoft6(value)}
                          hint="Facilidade de analisar cenários e de encontrar oportunidades. Atitude de busca, criação e implementação de ideias"
                        />
                        <SoftSkillSliderInput
                          label="Mentalidade de startup"
                          bgColor={colors.lightYellow}
                          value={soft7}
                          onChange={value => setSoft7(value)}
                          hint="Pensamento discorruptivo e visionário, busca causar grandes impactos, mudar a visão das pessoas e dos próprios processos"
                        />
                        <SoftSkillSliderInput
                          label="Pensamento ágil"
                          bgColor={colors.lightYellow}
                          value={soft8}
                          onChange={value => setSoft8(value)}
                          hint="Pensar na melhoria e ciclos de aprendizagem, foco na entrega de valor de forma contínua"
                        />
                        <SoftSkillSliderInput
                          label="Resiliência"
                          bgColor={colors.lightYellow}
                          value={soft9}
                          onChange={value => setSoft9(value)}
                          hint="Capacidade de lidar com os problemas e pressões, superando os obstáculos e as situações adversas de forma positiva"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ padding: '0.5rem' }}>
                      <Subtitle>Resultados</Subtitle>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <SoftSkillSliderInput
                          label="Acabativa"
                          bgColor={colors.lightBlue}
                          value={soft10}
                          onChange={value => setSoft10(value)}
                          hint="Capacidade de terminar aquilo que iniciou ou concluir o que o outro não finalizou"
                        />
                        <SoftSkillSliderInput
                          label="Engajamento contínuo"
                          bgColor={colors.lightBlue}
                          value={soft11}
                          onChange={value => setSoft11(value)}
                          hint="Conexão emocional com o seu trabalho e compromisso em fazer sempre o melhor"
                        />
                        <SoftSkillSliderInput
                          label="Gestão da qualidade"
                          bgColor={colors.lightBlue}
                          value={soft12}
                          onChange={value => setSoft12(value)}
                          hint="Estabelecer padrões de alta qualidade e buscar a melhoria contínua, assegurando a revisão de práticas e processos"
                        />
                        <SoftSkillSliderInput
                          label="Gestão de projetos"
                          bgColor={colors.lightBlue}
                          value={soft13}
                          onChange={value => setSoft13(value)}
                          hint="Capacidade de aplicar práticas e habilidades para planejamento, execução, monitoramento e controle dos projetos (independente da complexidade)"
                        />
                        <SoftSkillSliderInput
                          label="Gestão do tempo"
                          bgColor={colors.lightBlue}
                          value={soft14}
                          onChange={value => setSoft14(value)}
                          hint="Planejamento e execução do controle sobre o tempo gasto com atividades versus efetividade, eficiência e produtividade"
                        />
                        <SoftSkillSliderInput
                          label="Identificação de propósito"
                          bgColor={colors.lightBlue}
                          value={soft15}
                          onChange={value => setSoft15(value)}
                          hint="Grau de identificação de seus objetivos, preferências e projeto para a vida. O que você ama e lhe faz feliz"
                        />
                        <SoftSkillSliderInput
                          label="Melhoria de desempenho"
                          bgColor={colors.lightBlue}
                          value={soft16}
                          onChange={value => setSoft16(value)}
                          hint="Articulações para a melhoria de competências relacionadas à entregas individuais e organizacionais"
                        />
                        <SoftSkillSliderInput
                          label="Orientação ao resultado"
                          bgColor={colors.lightBlue}
                          value={soft17}
                          onChange={value => setSoft17(value)}
                          hint="Capacidade de tomada de ações para atingir ou superar objetivos"
                        />
                        <SoftSkillSliderInput
                          label="Senso de dono"
                          bgColor={colors.lightBlue}
                          value={soft18}
                          onChange={value => setSoft18(value)}
                          hint="Foco no crescimento do negócio e no próprio desenvolvimento pessoal, garantindo lucro e crescimento mútuos"
                        />
                        <SoftSkillSliderInput
                          label="Venda consultiva"
                          bgColor={colors.lightBlue}
                          value={soft19}
                          onChange={value => setSoft19(value)}
                          hint="Aproximar-se do cliente, alinhando suas expectativas de maneira efetiva e criar um relacionamento"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ padding: '0.5rem' }}>
                      <Subtitle>Mental</Subtitle>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <SoftSkillSliderInput
                          label="Capacidade analítica"
                          bgColor={colors.lightGreen}
                          value={soft20}
                          onChange={value => setSoft20(value)}
                          hint="Capacidade de organizar e avaliar dados e cenários, permitindo emitir pareceres consistentes considerando riscos, oportunidades e impactos"
                        />
                        <SoftSkillSliderInput
                          label="Escuta ativa"
                          bgColor={colors.lightGreen}
                          value={soft21}
                          onChange={value => setSoft21(value)}
                          hint="Ouvir, compreender e interpretar com atenção o que está sendo transmitido, seja de forma verbal ou não verbal"
                        />
                        <SoftSkillSliderInput
                          label="Gestão da ansiedade"
                          bgColor={colors.lightGreen}
                          value={soft22}
                          onChange={value => setSoft22(value)}
                          hint="Capacidade de autopercepção, compreensão de emoções e alternativas para lidar com situações de estresse e pressão"
                        />
                        <SoftSkillSliderInput
                          label="Gestão do conhecimento"
                          bgColor={colors.lightGreen}
                          value={soft23}
                          onChange={value => setSoft23(value)}
                          hint="Facilidade em adquirir, buscar, aplicar e transferir o conhecimento"
                        />
                        <SoftSkillSliderInput
                          label="Inteligência emocional"
                          bgColor={colors.lightGreen}
                          value={soft24}
                          onChange={value => setSoft24(value)}
                          hint="Capacidade de compreender e lidar com os próprios sentimentos e dos outros"
                        />
                        <SoftSkillSliderInput
                          label="Liderança estratégica"
                          bgColor={colors.lightGreen}
                          value={soft25}
                          onChange={value => setSoft25(value)}
                          hint="Alinhar o estilo de liderança às estratégias do negócio. Desenvolver as competências, os processos organizacionais e os comportamentos das pessoas"
                        />
                        <SoftSkillSliderInput
                          label="Orientação ao negócio"
                          bgColor={colors.lightGreen}
                          value={soft26}
                          onChange={value => setSoft26(value)}
                          hint="Capacidade de reconhecer oportunidades para serviços e produtos. Direcionar esforços na melhoria da performance do negócio"
                        />
                        <SoftSkillSliderInput
                          label="Pensamento estratégico"
                          bgColor={colors.lightGreen}
                          value={soft27}
                          onChange={value => setSoft27(value)}
                          hint="Criar meios (fugindo das opções evidentes) que permitam a descoberta de alternativas com alto grau de efetividade para o negócio"
                        />
                        <SoftSkillSliderInput
                          label="Planejamento"
                          bgColor={colors.lightGreen}
                          value={soft28}
                          onChange={value => setSoft28(value)}
                          hint="Prever e controlar etapas e atividades críticas, com o propósito de gerar prontidão de resposta e caminhos consistentes para o alcance dos resultados"
                        />
                        <SoftSkillSliderInput
                          label="Velocidade na tomada de decisão"
                          bgColor={colors.lightGreen}
                          value={soft29}
                          onChange={value => setSoft29(value)}
                          hint="Capacidade de análise do cenário e agilidade de resposta. Comprometimento por falar o que pensa e assumir sua posição"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ padding: '0.5rem' }}>
                      <Subtitle>Pessoas</Subtitle>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <SoftSkillSliderInput
                          label="Comunicação"
                          bgColor={colors.alertRed}
                          value={soft30}
                          onChange={value => setSoft30(value)}
                          hint="Transmitir e explicar ideias de forma clara e objetiva, apresentando argumentos de modo persuasivo e adequado ao estilo do interlocutor"
                        />
                        <SoftSkillSliderInput
                          label="Construção de alianças"
                          bgColor={colors.alertRed}
                          value={soft31}
                          onChange={value => setSoft31(value)}
                          hint="Capacidade em promover a colaboração ampla e articulada de pessoas e negócios, para assegurar iniciativas criativas e integradas"
                        />
                        <SoftSkillSliderInput
                          label="Desenvolvimento de talentos"
                          bgColor={colors.alertRed}
                          value={soft32}
                          onChange={value => setSoft32(value)}
                          hint="Facilidade em identificar, direcionar e desenvolver competências individuais e grupais"
                        />
                        <SoftSkillSliderInput
                          label="Gestão de pessoas"
                          bgColor={colors.alertRed}
                          value={soft33}
                          onChange={value => setSoft33(value)}
                        />
                        <SoftSkillSliderInput
                          label="Mentalidade inclusiva"
                          bgColor={colors.alertRed}
                          value={soft34}
                          onChange={value => setSoft34(value)}
                          hint="Abertura para a valorização das pessoas por seus dons, habilidades e experiências únicas. Valorização da diversidade"
                        />
                        <SoftSkillSliderInput
                          label="Negociação"
                          bgColor={colors.alertRed}
                          value={soft35}
                          onChange={value => setSoft35(value)}
                          hint="Conduzir as partes ao entendimento, por meio da capacidade de leitura das relações informais e poder de influência"
                        />
                        <SoftSkillSliderInput
                          label="Orientação ao cliente"
                          bgColor={colors.alertRed}
                          value={soft36}
                          onChange={value => setSoft36(value)}
                          hint="Capacidade e vontade de compreender as necessidades dos clientes e agir de acordo, considerando custos e benefícios"
                        />
                        <SoftSkillSliderInput
                          label="Concessão de feedbacks"
                          bgColor={colors.alertRed}
                          value={soft37}
                          onChange={value => setSoft37(value)}
                          hint="Permissão para receber retorno sobre suas características, ações e performance. Assim como a facilidade em expressar suas percepções sobre o outro"
                        />
                        <SoftSkillSliderInput
                          label="Relacionamento interpessoal"
                          bgColor={colors.alertRed}
                          value={soft38}
                          onChange={value => setSoft38(value)}
                          hint="Facilidade em estabelecer vínculos, considerando aspectos como empatia e respeito. Contribuir para o trabalho em grupo com maior sinergia e harmonia"
                        />
                        <SoftSkillSliderInput
                          label="Solução de conflitos"
                          bgColor={colors.alertRed}
                          value={soft39}
                          onChange={value => setSoft39(value)}
                          hint="Capacidade de lidar com interesses conflitantes de forma a ajudar a resolvê-los e conciliar ideias divergentes"
                        />
                        <SoftSkillSliderInput
                          label="Trabalho em equipe"
                          bgColor={colors.alertRed}
                          value={soft40}
                          onChange={value => setSoft40(value)}
                          hint="Encorajar a sinergia e a cooperação, contribuindo para o alcance de objetivos por meio de um ambiente colaborativo"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </CustomCard>
            </Grid>
            <Grid item xs={12}>
              <CustomCard>
                <CardTitle>Habilidades Técnicas (Hard Skills)</CardTitle>
                <CardContent>
                  <HardskillSelector
                    onChange={handleChangeHardskill}
                    initialList={initialHardskills}
                  />
                </CardContent>
              </CustomCard>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '1rem' }}>
          <Button
            type="button"
            fontSize="13px"
            text="Cancelar"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onClose}
          />
          <Button
            type="button"
            fontSize="13px"
            text="Salvar"
            disabled={minCulture !== 0 || minBehavioral !== 0}
            hoverColor={colors.lightBlue}
            color={colors.white}
            bgColor={colors.lightBlue}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
