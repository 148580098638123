import React, { useContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from '../../ErrorModal';
import '../../../styles/SidebarConfig.css';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

function MenuFormation({ user, active, width, onClose, isOpen }) {
  const data = active;
  const { token } = useContext(StoreContext);
  const [name, setName] = useState(data.length > 0 ? data[0].instituicao : '');
  const [course, setCourse] = useState(
    data.length > 0 ? data[0].nome_formacao : '',
  );
  const [startDate, setStartDate] = useState(
    data.length > 0 ? data[0].periodo_de : '',
  );
  const [finalDate, setFinalDate] = useState(
    data.length > 0 ? data[0].periodo_ate : '',
  );
  const [checkbox, setCheckbox] = useState(false);
  const [disabledCheckbox, setdisabledCheckbox] = useState(false);
  const [disabledDate, setdisabledDate] = useState(false);
  const [error, setError] = useState(false);

  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  const handleName = e => {
    const { value } = e.target;
    setName(value);
  };

  const handleCourse = e => {
    const { value } = e.target;
    setCourse(value);
  };

  const handleStartDate = e => {
    const { value } = e.target;
    setStartDate(value);
  };

  const handleFinalDate = e => {
    const { value } = e.target;
    setFinalDate(value);
    if (value) {
      setdisabledCheckbox(true);
    } else {
      setdisabledCheckbox(false);
    }
  };

  const putCollege = async () => {
    let last;
    if (data.length > 0) {
      last = {
        instituicao: name,
        nome_formacao: course,
        periodo_de: startDate,
        periodo_ate: moment(Date.now()).format('YYYY-MM-DD'),
      };
    } else {
      last = checkbox
        ? {
            id: user[0].dados_adicionais.id,
            instituicao: name,
            nome_formacao: course,
            // "descricao": description,
            periodo_de: startDate,
            periodo_ate: moment(Date.now()).format('YYYY-MM-DD'),
          }
        : {
            id: user[0].dados_adicionais.id,
            instituicao: name,
            nome_formacao: course,
            // "descricao": description,
            periodo_de: startDate,
            periodo_ate: finalDate,
          };
    }

    const json = {
      user_id: user[0].id,
      formacoes: [last],
    };

    if (data.length > 0) {
      await api
        .put('/dados-candidatos/update', json, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          onClose();
        })
        .catch(() => {
          setError(true);
        });
    } else {
      await api
        .post('/dados-candidatos/save', json, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          onClose();
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const changeDisabledDate = value => {
    if (value) {
      setdisabledDate(true);
    } else {
      setdisabledDate(false);
    }
    setCheckbox(value);
  };

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <GroupLine>
        <Title>Formação acadêmica</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Nome da escola"
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            value={name}
            onChange={handleName}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="Nome do curso"
            onChange={handleCourse}
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            value={course}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="Data inicial"
            type="date"
            onChange={handleStartDate}
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            value={startDate}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="Data final"
            type="date"
            onChange={handleFinalDate}
            InputLabelProps={{ shrink: true, required: false }}
            size="small"
            value={finalDate}
            color="info"
            disabled={disabledDate}
          />
        </Group>
        <Group>
          <FormGroup>
            <FormControlLabel
              control={
                <BpCheckbox
                  checked={checkbox}
                  disabled={disabledCheckbox}
                  onChange={() => changeDisabledDate(!checkbox)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Atualmente"
            />
          </FormGroup>
        </Group>
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Salvar alterações"
          fontSize="12px"
          onClick={putCollege}
        />
      </Container>
    </Slide>
  );
}

export default MenuFormation;
