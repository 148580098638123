import { Typography } from '@mui/material';
import colors from '../../styles/colors';

export function Title({ children }) {
  return (
    <Typography
      component="h2"
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '1.5rem',
        textAlign: 'center',
        color: colors.darkBlue,
      }}
    >
      {children}
    </Typography>
  );
}

export function Subtitle({ children }) {
  return (
    <Typography
      component="h3"
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '1.5rem',
        color: colors.darkBlue,
        marginBottom: '0.75rem',
      }}
    >
      {children}
    </Typography>
  );
}
