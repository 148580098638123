import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { listAllCareers, listCompanyCareers } from '../../api/careers';
import StoreContext from '../Store/Context';
import { NewCareerDialog } from './NewCareerDialog';
import { buildTree } from '../../utils/tree';

const filter = createFilterOptions();

function generateCareerArray({ careerTree, indent = 0, showIndent = true }) {
  const tabSize = showIndent ? 4 : 0;
  if (!careerTree || !careerTree.length) return [];
  const newCareerTree = careerTree.map(career => ({
    ...career,
    nome: String.fromCharCode(160).repeat(indent * tabSize) + career.nome,
  }));
  const firstCareer = newCareerTree.shift();
  if (firstCareer)
    return [
      firstCareer,
      ...generateCareerArray({
        careerTree: firstCareer.children,
        indent: indent + 1,
        showIndent,
      }),
      ...generateCareerArray({ careerTree: newCareerTree, indent, showIndent }),
    ];
  return [];
}

export function CareerSelector({
  onChange,
  fullWidth,
  label = 'Carreira',
  defaultValue,
  companyId,
  showIndent = true,
}) {
  const [selectedCareer, setSelectedCareer] = useState(defaultValue ?? '');
  const [openNewCareerModal, setOpenNewCareerModal] = useState(false);
  const [newCareerValue, setNewCareerValue] = useState('');
  const { token } = useContext(StoreContext);
  const [careerTree, setCareerTree] = useState([]);

  const {
    data: allCareers,
    refetch,
    isLoading,
  } = useQuery('listAllCareers', () =>
    listAllCareers({ token: token.refreshToken }),
  );

  const { data: companyCareers } = useQuery(
    `listCompanyCareers-${companyId}`,
    () => listCompanyCareers({ token: token.refreshToken, companyId }),
  );

  const loadingMessage = useMemo(
    () => ({
      id: 0,
      nome: 'Carregando...',
    }),
    [],
  );

  const handleChangeCareer = useCallback(async (_event, newValue) => {
    if (typeof newValue === 'string') {
      setSelectedCareer({
        nome: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setNewCareerValue(newValue.inputValue);
      setOpenNewCareerModal(true);
    } else {
      setSelectedCareer(newValue);
    }
  }, []);

  const handleNewCareer = useCallback(
    async newCareer => {
      await refetch();
      setSelectedCareer(newCareer);
    },
    [refetch],
  );

  useEffect(() => {
    if (onChange) onChange(selectedCareer);
  }, [onChange, selectedCareer]);

  useEffect(() => {
    if (allCareers) {
      const tmpTree = buildTree({
        items: allCareers.filter(
          career =>
            !companyCareers ||
            !companyCareers.length ||
            companyCareers?.map(cc => cc.id).includes(career.id),
        ),
        key: 'id',
        parentKey: 'carreira_pai_id',
      });
      setCareerTree(generateCareerArray({ careerTree: tmpTree, showIndent }));
    }
  }, [allCareers, companyCareers, showIndent]);

  return (
    <Box sx={{ display: 'block' }}>
      <FormControl
        fullWidth={fullWidth}
        size="small"
        sx={{ minWidth: '240px' }}
      >
        <Autocomplete
          fullWidth={fullWidth}
          value={selectedCareer}
          onChange={handleChangeCareer}
          disablePortal
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          size="small"
          freeSolo
          options={isLoading ? loadingMessage : careerTree}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(
              option => inputValue === option.nome,
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                nome: `Adicionar "${inputValue}"`,
              });
            }
            return filtered;
          }}
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            if (option.inputValue) return option.inputValue;
            if (!option.nome) return '';
            return option.nome.trim();
          }}
          renderOption={(props, option) => <li {...props}>{option.nome}</li>}
          renderInput={params => <TextField {...params} label={label} />}
        />
      </FormControl>
      <NewCareerDialog
        isOpen={openNewCareerModal}
        initialValue={newCareerValue}
        onClose={() => setOpenNewCareerModal(false)}
        onCreate={handleNewCareer}
      />
    </Box>
  );
}
