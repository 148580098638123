import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
  border-bottom-right-radius: 50px;
  z-index: 1;
  background: ${colors.darkBlue};
`;

export const Card = styled.div`
  position: relative;
  text-align: center;
  align-item: center;
  width: ${props => props.width || '1200px'};
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0.5%;
  margin-bottom: 1%;
  top: 20%;
  background: ${colors.white};
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 12px;
  align-items: center;

  @media (max-width: 870px) {
    width: 700px;
    margin-bottom: 5%;
  }

  @media (max-width: 570px) {
    width: 340px;
    margin-bottom: 5%;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;

export const Icon = styled.img`
  position: relative;
  width: 25px;
  float: right;
  padding-right: 5%;

  @media (max-width: 870px) {
    width: 25px;
  }

  @media (max-width: 570px) {
    width: 25px;
  }
`;

export const Label = styled.h2`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: start;
  padding-top: 1.5%;
  padding-bottom: 1.8%;
  padding-left: 2.5%;
  letter-spacing: 0.116667px;
  color: ${colors.darkBlue};
  @media (max-width: 870px) {
    padding-top: 5%;
    padding-bottom: 6%;
  }

  @media (max-width: 570px) {
    padding-top: 5%;
    padding-bottom: 6%;
  }
`;

export const Value = styled.h2`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  text-align: start;
  padding-top: 1.5%;
  padding-bottom: 1.8%;
  padding-left: 5.5%;
  letter-spacing: 0.116667px;
  color: ${colors.darkBlue};
  @media (max-width: 870px) {
    padding-top: 5%;
    padding-bottom: 6%;
  }

  @media (max-width: 570px) {
    padding-top: 5%;
    padding-bottom: 6%;
  }
`;

export const GroupFilter = styled.div`
  padding-bottom: 0.2%;
  padding-left: 5.6%;
  padding-right: 5.6%;
  text-align: ${props => props.align || 'center'};
`;

export const Group = styled.div`
  padding-bottom: 0.2%;
`;

export const ContainerCard = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-item: center;
  width: ${props => props.width || '1200px'};
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5%;
  top: 20%;
  border-radius: 12px;
  align-items: center;

  @media (max-width: 870px) {
    width: 700px;
    display: block;
  }

  @media (max-width: 570px) {
    width: 340px;
    display: block;
  }
`;

export const GroupItems = styled.div`
  padding-top: 1%;
  padding-bottom: 1%;
`;
