import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  /* Shape */
  width: 100%;
  height: 100vh;

  background: ${colors.darkBlue};
`;

export const Header = styled.div`
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  width: 160px;
  align-items: start;
`;

export const TitleGroup = styled.div`
  margin-top: 32px;
`;

export const Icon = styled.img`
  padding-right: ${props => props.right ?? '0px'};
  width: ${props => props.width ?? '40px'};
  text-align: center;
`;

export const Group = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.white};
`;

export const DescriptionGroup = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding: 16px;
`;

export const SubLabel = styled.label`
  text-align: center !important;
  font-size: 16px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;

  line-height: 130%;

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.white};
`;

export const Description = styled.h3`
  text-align: center !important;
  font-size: 16px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;

  line-height: 130%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.white};
`;

export const ContainerTitle = styled.div`
  padding: 8px;
  justify-content: space-between;
  display: flex;
  max-width: 425px;
  margin: 0 auto;
`;

export const Line = styled.div`
  opacity: 0.2;
  border-bottom: 1px solid ${colors.lightBlue};
`;

export const Row = styled.div`
  padding: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescriptionData = styled.p`
  font-size: 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => props.weight || '400'};

  line-height: 130%;

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.white};
`;
