import colors from './colors';

export const lightTheme = {
  background: colors.offWhite,
  stepCircle: colors.lightSmock,
  searchBarBg: colors.offWhite,
  sidenavText: colors.gray3,
  sidenavBgActive: colors.snow,
  cardBg: colors.carmine,
  cardTitle: colors.blue,
  cardSubTitle: colors.gray4,
  cardLinkText: colors.gray4,
  mainTitle: colors.darkBlue,
  mainSubTitle: colors.gray2,
  miniTableBg: colors.smock,
  tableBg: colors.smock,
  tableHead: colors.white,
  SliderSwitchBg: colors.lightBlue,
  calendarPickerColor: 'invert(0)',
};
