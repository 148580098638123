import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  width: ${props => props.width || '180px'};
  background-color: ${props => props.bgColor || colors.lightBlue};
  height: ${props => props.height || '40px'};
  border-radius: 8px;
  outline: none;
  color: ${props => props.color || colors.white};
  font-weight: 600;
  padding-right: ${props => props.right || '0px'};
  padding-left: ${props => props.left || '0px'};
  margin: '10px 0';
  transition: 0.5s;
  font-family: 'Poppins';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: '16px';
  line-height: 24px;
  display: inline-grid;
  zoom: 1;
  margin-bottom: 5%;

  @media (max-width: 870px) {
    width: auto;
  }

  @media (max-width: 570px) {
    width: 280px;
  }
`;

export const Image = styled.div`
  width: 20%;
  float: left;
`;

export const Text = styled.div`
  width: ${props => props.width || '60%'};
  float: ${props => props.float || 'left'};
  padding-top: ${props => props.top || '2%'};
`;

export const Value = styled.div`
  padding-top: 5%;
  width: ${props => props.width || '20%'};
  float: left;
`;

export const Icon = styled.img`
  position: relative;
  cursor: pointer;
  width: ${props => props.width || '15px'};
  padding-left: 2%;
  padding-top: ${props => props.top || '30%'};
  padding-bottom: 1%;
`;

export const Label = styled.p`
  left: 1168px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  text-align: start;
  padding: ${props => props.padding || '3%'};
  font-size: ${props => props.font || '10%'};
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${props => props.color || colors.darkBlue};
`;

export const GpItems = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

export const Progress = styled.div`
  background: ${props => props.color || colors.gray5};
  height: 5px !important;
  margin-top: ${props => props.top || '10px'};
`;

export const Step = styled.div`
  background: ${props => props.color || colors.gray5};
  height: 20px !important;
  width: 33%;
  border-radius: 5px;
  margin: 2px;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  width: 100% important;
  text-align: center;
  align-item: center;
  height: auto;
  top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  justify-content: start;
  border-radius: 12px;
  align-items: center;
`;
