export function buildTree({ items, key = 'id', parentKey = 'parentId' }) {
  const itemMap = new Map(
    items.map(item => [item[key], { ...item, children: [] }]),
  );

  const roots = [];
  items.forEach(item => {
    if (item[parentKey]) {
      itemMap.get(item[parentKey])?.children.push(itemMap.get(item[key]));
    } else {
      roots.push(itemMap.get(item[key]));
    }
  });

  const sortChildren = node => {
    if (node.children.length > 0) {
      node.children.forEach(child => sortChildren(child));
      node.children.sort((a, b) => b.children.length - a.children.length);
    }
  };

  roots.forEach(root => sortChildren(root));
  return roots;
}
