import React, { useContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { TextField } from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from '../../ErrorModal';

function MenuSocial({ user, isOpen, onClose, width }) {
  const { token } = useContext(StoreContext);
  const [facebook, setFacebook] = useState(user[0].dados_adicionais.facebook);
  const [twitter, setTwitter] = useState(user[0].dados_adicionais.twitter);
  const [linkedin, setLinkedin] = useState(user[0].dados_adicionais.linkedin);
  const [sitePessoal, setSitePessoal] = useState(
    user[0].dados_adicionais.site_pessoal,
  );
  const [error, setError] = useState(false);

  const handleSite = async e => {
    const { value } = e.target;
    setSitePessoal(value);
  };

  const handleFacebook = async e => {
    const { value } = e.target;
    setFacebook(value);
  };

  const handleTwitter = async e => {
    const { value } = e.target;
    setTwitter(value);
  };

  const handleLinkedin = async e => {
    const { value } = e.target;
    setLinkedin(value);
  };

  const onCloseError = () => {
    setError(false);
  };

  const putData = async () => {
    const json = {
      user_id: user[0].id,
      site_pessoal: sitePessoal,
      linkedin,
      facebook,
      twitter,
    };

    await api
      .put('/dados-candidatos/update', json, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <GroupLine>
        <Title>Redes Sociais</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Site pessoal"
            onChange={handleSite}
            InputLabelProps={{ shrink: true, required: false }}
            size="small"
            value={sitePessoal}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="LinkedIn"
            onChange={handleLinkedin}
            InputLabelProps={{ shrink: true, required: false }}
            size="small"
            value={linkedin}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="Facebook"
            onChange={handleFacebook}
            InputLabelProps={{ shrink: true, required: false }}
            size="small"
            value={facebook}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <TextField
            label="Twitter"
            onChange={handleTwitter}
            InputLabelProps={{ shrink: true, required: false }}
            size="small"
            value={twitter}
            color="info"
            disabled={false}
          />
        </Group>
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Salvar alterações"
          fontSize="12px"
          onClick={putData}
        />
      </Container>
    </Slide>
  );
}

export default MenuSocial;
