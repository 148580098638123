import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextField, FormControl } from '@mui/material';
import Table from '../../components/Table';
import MenuData from '../../components/Menu/MenuData';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import { removeAccentuation } from '../../utils/removeAccentuation';
import api from '../../api';

import { Container, Title, Card, Label, Group } from './styles';

function NPS() {
  const { token } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [isvisible, setIsVisible] = useState(false);
  const [object, setObject] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const [test, setTest] = useState('');
  const [error, setError] = useState(false);

  const changeDetails = () => {
    setDetails(!details);
  };

  const onCloseError = () => {
    setError(false);
  };

  const handleName = async e => {
    e.preventDefault();
    setTest(e.target.value);
  };

  const onFilter = async e => {
    await handleName(e);
    await setIsVisible(false);
    const type = e.target.value;

    if (type.length > 0) {
      const textData = removeAccentuation(type.toUpperCase().trim());

      const newData = object.filter(item => {
        const itemData = removeAccentuation(
          item.teste_tipo.toUpperCase().trim(),
        );

        return itemData.indexOf(textData) > -1;
      });

      setObject(newData);
    } else {
      setObject(arrayholder);
    }
    await setIsVisible(true);
  };

  const getNPS = useCallback(async () => {
    await api
      .get('/nps', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const resp = response.data;

        setObject(resp);
        setArrayholder(resp);
        setIsVisible(true);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.nps}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.commentary}
      </th>
      {/* <th key={index}>{item.button}</th> */}
    </>
  );

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.teste_tipo}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.nps}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.comentario}</td>
    </tr>
  );

  const nps = {
    head: [
      { name: 'Teste', nps: 'Nota', commentary: 'Comentário', button: ' ' },
    ],
    body: object,
  };

  const fieldRow = width <= 570 ? '80%' : 1100;

  useEffect(() => {
    getNPS();
    setWidth(window.screen.width);
  }, [getNPS, width]);

  return (
    <Container>
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      {details && (
        <MenuData
          isOpen={details}
          onClose={changeDetails}
          title="NPS"
          width={width}
        />
      )}
      <ResponsiveAppBar />
      <Title>NPS</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <FormControl
            sx={{
              backgroundColor: colors.gray6,
              m: 1,
              width: fieldRow,
              fontSize: '12px',
              fontFamily: 'Poppins',
              fontStyle: 'normal',
            }}
          >
            <TextField
              autoWidth
              size="small"
              color="info"
              disabled={false}
              placeholder="Procurar por teste"
              value={test}
              onChange={onFilter}
              InputProps={{
                sx: {
                  input: {
                    padding: 0.7,
                    '&::placeholder': {
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Group>
      </Card>
      <Card>
        <Table
          limit="5"
          headData={nps.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={nps.body}
          renderBody={(item, index) => renderBody(item, index)}
          isLoading={!isvisible}
        />
      </Card>
    </Container>
  );
}

export default NPS;
