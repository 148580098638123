import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import Table from '../../components/Table';
import MenuData from '../../components/Menu/MenuCompany';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from '../../components/ErrorModal';

import { Container, Title } from './styles';
import { EditCompanyDialog } from '../../components/EditCompanyDialog';

function ActiveCompanies() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [error, setError] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openEditCompany, setOpenEditCompany] = useState(false);
  const [term, setTerm] = useState('');

  const changeDetails = async item => {
    setJsonDetails(item);
    setDetails(!details);
  };

  const getData = useCallback(
    async id => {
      if (id === 8) {
        try {
          const response = await api.get('/companies', {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          });
          const responseData = response.data.response;
          const json = [];
          responseData.forEach(item => {
            json.push({
              id: item.id,
              name: item.nome_fantasia,
              plan: item.nome_plano,
              status: item.status === 'S' ? 'Ativo' : 'Inativo',
              details: item,
            });
          });
          setFullList(json.sort((a, b) => (a.name < b.name ? -1 : 1)));
        } catch (err) {
          setError(true);
        }
      } else {
        const json = [];
        userGlobal.forEach(item => {
          json.push({
            id: item.id,
            name: item.nome_fantasia,
            plan: item.nome_plano,
            status: item.status === 'S' ? 'Ativo' : 'Inativo',
            details: item,
          });
        });
        setData(json);
      }
      setIsVisible(true);
    },
    [token, userGlobal],
  );

  const handleCreateCompany = () => {
    setSelectedCompany(null);
    setOpenEditCompany(true);
  };

  const handleEditCompany = useCallback(company => {
    setSelectedCompany(company);
    setOpenEditCompany(true);
  }, []);

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.plan}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.status}
      </th>
      <th key={index}>{item.button}</th>
    </>
  );

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.status}</td>
      <td>
        <Tooltip title="Ver detalhes">
          <IconButton size="small" onClick={() => changeDetails(item.details)}>
            <LaunchIcon fontSize="inherit" sx={{ color: colors.blue }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar">
          <IconButton
            size="small"
            onClick={() => handleEditCompany(item.details)}
          >
            <EditIcon fontSize="inherit" sx={{ color: colors.blue }} />
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  );

  const onCloseDetails = async () => {
    await setDetails(!details);
  };

  const companies = {
    head: [
      { name: 'Nome da empresa', plan: 'Plano', status: 'Status', button: ' ' },
    ],
    body: data,
  };

  const onCloseError = () => {
    setError(false);
  };

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      setWidth(window.screen.width);
      getData(profileGlobal.perfil_id);
    }
  }, [getData, profileGlobal, width]);

  useEffect(() => {
    if (!term) {
      setData(fullList);
    } else {
      setData(
        fullList.filter(item =>
          item.name.toLowerCase().includes(term?.toLowerCase()),
        ),
      );
    }
  }, [fullList, term]);

  return (
    <Container>
      {details && (
        <MenuData
          isOpen={details}
          onClose={onCloseDetails}
          title="Detalhes da Empresa"
          width={width}
          data={jsonDetails}
        />
      )}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <EditCompanyDialog
        isOpen={openEditCompany}
        onClose={() => setOpenEditCompany(false)}
        onChange={async () => {
          setIsVisible(false);
          await getData(profileGlobal.perfil_id);
          setIsVisible(true);
        }}
        company={selectedCompany}
      />
      <ResponsiveAppBar />
      <Title>Empresas ativas</Title>

      <Paper sx={{ maxWidth: '1200px', margin: '80px auto 24px auto', p: 1 }}>
        <Grid
          container
          spacing={2}
          padding="1rem 0"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                size="small"
                label="Pesquise por nome"
                value={term}
                onChange={e => setTerm(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<BusinessIcon />}
              onClick={handleCreateCompany}
            >
              Cadastrar empresa
            </Button>
          </Grid>
        </Grid>
        <Table
          limit="10"
          headData={companies.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={companies.body}
          renderBody={(item, index) => renderBody(item, index)}
          isLoading={!isVisible}
        />
      </Paper>
    </Container>
  );
}

export default ActiveCompanies;
