const DIGIT_1_FACTOR = 10;
const DIGIT_2_FACTOR = 11;

function removeNonDigits(cpf) {
  return cpf.replace(/\D+/g, '');
}

function isValidLength(cpf) {
  return cpf.length === 11;
}

function allDigitsTheSame(cpf) {
  const [firstDigit] = cpf;
  return [...cpf].every(digit => digit === firstDigit);
}

function calculateDigit(cpf, factor) {
  let total = 0;
  let f = factor;
  cpf.split('').forEach(digit => {
    if (f > 1) {
      total += parseInt(digit, 10) * f;
      f -= 1;
    }
  });
  const rest = total % 11;
  return rest < 2 ? 0 : 11 - rest;
}

function extractCheckDigit(cpf) {
  return cpf.slice(-2);
}

export function validateCpf(input) {
  if (!input) return false;
  const cpf = removeNonDigits(input);
  if (!isValidLength(cpf)) return false;
  if (allDigitsTheSame(cpf)) return false;
  const digit1 = calculateDigit(cpf, DIGIT_1_FACTOR);
  const digit2 = calculateDigit(cpf, DIGIT_2_FACTOR);
  const checkDigit = extractCheckDigit(cpf);
  const calculatedCheckDigit = `${digit1}${digit2}`;
  return checkDigit === calculatedCheckDigit;
}
