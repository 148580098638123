import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMessage } from '../../../contexts/MessageContext';
import api from '../../../api';
import StoreContext from '../../Store/Context';

export function NewCareerDialog({ isOpen, onClose, initialValue, onCreate }) {
  const { token } = useContext(StoreContext);
  const { showError, showMessage } = useMessage();

  const { control, handleSubmit, setValue, reset } = useForm();

  const handleClose = () => {
    if (onClose) onClose();
  };

  const onSubmit = async data => {
    try {
      const { data: newCareer } = await api.post(
        '/carreiras',
        {
          nome: data.name,
          descricao: data.description,
        },
        {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        },
      );
      showMessage('Registro criado com sucesso.');
      if (onCreate) onCreate(newCareer);
      handleClose();
    } catch (err) {
      showError('Ocorreu um erro ao salvar o registro. Tente novamente');
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
      setValue('name', initialValue);
    }
  }, [initialValue, isOpen, setValue, reset]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Adicionar carreira</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormControl fullWidth size="small" error={error}>
                <TextField
                  autoFocus
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  label="Nome da carreira"
                  id="name-input"
                  size="small"
                  error={error}
                  helperText={error ? 'Nome é obrigatório' : ''}
                />
              </FormControl>
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormControl fullWidth size="small" error={error} sx={{ mt: 1 }}>
                <TextField
                  multiline
                  rows={4}
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  label="Descrição"
                  id="name-input"
                  size="small"
                  error={error}
                  helperText={error ? 'Descrição é obrigatória' : ''}
                />
              </FormControl>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
