import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

export function CurrencyInput({ id, value, onChange, label }) {
  const [format, setFormat] = useState('9');

  useEffect(() => {
    if (value) {
      const num = value.toString();
      const len = num.length;
      const arr = [];

      for (let i = 0; i < len; i += 1) {
        arr.push('9');
      }
      const newFormat = arr.reverse().join('');
      setFormat(`R$ ${newFormat}`);
    }
  }, [value]);

  return (
    <InputMask mask={format} maskChar=" " value={value} onChange={onChange}>
      {() => <TextField label={label} id={id} size="small" />}
    </InputMask>
  );
}
