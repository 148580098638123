import api from '.';

export async function listAllCareers({ token }) {
  const { data } = await api.get('/carreiras', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data.sort((a, b) =>
    a.nome.toUpperCase() < b.nome.toUpperCase() ? -1 : 1,
  );
}

export async function listCompanyCareers({ token, companyId }) {
  if (!companyId) return [];
  const { data } = await api.get(`/companies/carreiras/${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data.sort((a, b) =>
    a.nome.toUpperCase() < b.nome.toUpperCase() ? -1 : 1,
  );
}
