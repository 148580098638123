import { Autocomplete, FormControl, Skeleton, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { listAllCompanies } from '../../api/companies';
import StoreContext from '../Store/Context';

export function CompanySelector({
  initialState = {
    id: 0,
    razao_social: '',
  },
  label = 'Empresa',
  onChange,
  fullWidth = true,
  options = [],
  width,
}) {
  const [selectedCompany, setSelectedCompany] = useState(initialState);
  const [companies, setCompanies] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { token, userGlobal } = useContext(StoreContext);

  const {
    isLoading,
    error,
    data: allCompanies,
  } = useQuery('listAllCompanies', listAllCompanies);

  useEffect(() => {
    if (initialState.id) {
      setSelectedCompany(initialState);
    }
  }, [initialState]);

  useEffect(() => {
    const perfilId = token.authenticated_user?.user?.perfil?.perfil_id;
    if (perfilId === 8) {
      setCompanies(allCompanies ?? []);
    } else {
      setCompanies(userGlobal ?? []);
    }
  }, [allCompanies, token, userGlobal]);

  if (isLoading)
    return (
      <Skeleton
        sx={{
          m: 1,
        }}
        variant="rounded"
        width={width}
        height={40}
      />
    );

  if (error) {
    return <p>Um erro ocorreu ao carregar o componente</p>;
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <Autocomplete
        fullWidth={fullWidth}
        sx={{ width }}
        disablePortal
        value={selectedCompany}
        defaultValue={initialState}
        onChange={(event, newValue) => {
          setSelectedCompany(newValue);
          if (onChange) onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        size="small"
        id="company-selector"
        options={options?.length ? options : companies}
        getOptionLabel={option => option.razao_social}
        renderInput={params => (
          <TextField fullWidth {...params} label={label} />
        )}
      />
    </FormControl>
  );
}
