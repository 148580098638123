import React from 'react';
import { slide as Slide } from 'react-burger-menu';
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
} from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import Card from '../../Card';
import '../../../styles/SidebarConfig.css';

function MenuData({ width, isOpen, onClose, title }) {
  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <GroupLine>
        <Title>{title}</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Como devemos te chamar?"
            variant="outlined"
            size="small"
            value="Rodrigo Mendes"
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Celular"
            variant="outlined"
            size="small"
            value="(00)00000-0000"
            color="info"
            disabled
          />
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 218 }}>
            <InputLabel id="genre">Gênero</InputLabel>
            <Select
              labelId="genre"
              id="genre"
              variant="outlined"
              value={2}
              size="small"
              color="info"
              disabled
              input={<OutlinedInput label="Gênero" />}
            >
              <MenuItem value={1}>Feminino</MenuItem>
              <MenuItem value={2}>Masculino</MenuItem>
              <MenuItem value={3}>Prefiro não informar</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 218 }}>
            <InputLabel id="occupation">Ocupação principal</InputLabel>
            <Select
              labelId="occupation"
              id="occupation"
              variant="outlined"
              value={1}
              size="small"
              color="info"
              disabled
              input={<OutlinedInput label="Ocupação principal" />}
            >
              <MenuItem value={1}>Trabalho</MenuItem>
              <MenuItem value={2}>Estudo</MenuItem>
              <MenuItem value={3}>Em busca de recolocação</MenuItem>
              <MenuItem value={4}>Aposentado</MenuItem>
              <MenuItem value={5}>Outros</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <TextField
            label="Nome da empresa"
            variant="outlined"
            size="small"
            value="CodeNuts"
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Data de nascimento"
            variant="outlined"
            size="small"
            value="00/00/0000"
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="CPF"
            variant="outlined"
            size="small"
            value="000.000.000-00"
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Plano contratado"
            variant="outlined"
            size="small"
            value="Free"
            color="info"
            disabled
          />
        </Group>
        <Card
          width="230px"
          bgColor={colors.yellow}
          title="NPS"
          description="teste comportamental"
          value="68%"
          right
          left
          paddingLabel="0%"
        />
        <Card
          width="230px"
          bgColor={colors.lightGreen}
          title="NPS"
          description="teste cultura"
          value="20%"
          paddingLabel="0%"
        />
        <Card
          width="230px"
          bgColor={colors.alertRed}
          title="NPS"
          description="teste âncoras de carreira"
          value="88%"
          paddingLabel="0%"
        />
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Ok, entendi"
          fontSize="12px"
          onClick={onClose}
        />
      </Container>
    </Slide>
  );
}

export default MenuData;
