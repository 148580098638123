import { Box, Grid, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import colors from '../../styles/colors';
import { ExclamationIcon } from '../icons/ExclamationIcon';
import { Hint } from '../Hint';

export function SliderInput({
  color = colors.lightBlue,
  hint = '',
  label,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  marks = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
  ],
  breakLabel = false,
}) {
  const CustomSlider = useMemo(
    () =>
      styled(Slider)({
        color,
        height: 8,
        '& .MuiSlider-rail': {
          opacity: 0.5,
          backgroundColor: '#bfbfbf',
        },
        '& .MuiSlider-thumb': {
          height: 24,
          width: 24,
          backgroundColor: color,
          border: '2px solid currentColor',
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
          },
          '&:before': {
            display: 'none',
          },
        },
        '& .MuiSlider-mark': {
          backgroundColor: colors.gray4,
          height: 4,
          width: 4,
          borderRadius: '50%',
          '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'white',
          },
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 12,
          background: 'unset',
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: '50% 50% 50% 0',
          backgroundColor: color,
          transformOrigin: 'bottom left',
          transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
          '&:before': { display: 'none' },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
          },
          '& > *': {
            transform: 'rotate(45deg)',
          },
        },
      }),
    [color],
  );

  return (
    <Grid container sx={{ padding: '0.5rem' }}>
      <Grid item xs={12} md={breakLabel ? 12 : 6}>
        <Box sx={{ display: 'flex', flex: '1', alignItems: 'center' }}>
          {hint && (
            <Hint description={hint}>
              <ExclamationIcon size={28} />
            </Hint>
          )}
          <Typography
            noWrap
            sx={{
              fontWeight: 600,
              fontSize: '1rem',
              paddingLeft: '0.75rem',
            }}
          >
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flex: '1' }}>
          <CustomSlider
            sx={{ marginLeft: '0.75rem' }}
            value={value}
            onChange={(_event, newValue) => {
              if (onChange) onChange(newValue);
            }}
            getAriaValueText={vl => `${vl}%`}
            valueLabelDisplay="auto"
            marks={marks}
            min={min}
            max={max}
            step={step}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
