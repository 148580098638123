import styled from 'styled-components';
import colors from '../../styles/colors';

export const TableContainer = styled.div`
  background-color: #f4f8fe;
  border-radius: 10px;
  overflow: auto;
  table {
    width: 100%;
    min-width: 800px;
    border-spacing: 0;
  }
  thead {
    background-color: ${({ theme }) => theme.tableHead};
  }
  tr {
    text-align: center;
  }
  th,
  td {
    font-size: 0.75rem;
    text-align: start;
    padding: 8px 16px;
    color: ${({ theme }) => theme.mainTitle};
  }
  tbody > tr:nth-of-type(even) {
    background-color: #fff;
  }

  @media (max-width: 570px) {
    table {
      width: 100%;
      min-width: 350px;
    }
    th,
    td {
      min-width: 30px;
      padding: 10px 20px;
    }
  }
`;

export const TablePagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const PaginationItem = styled.div`
  margin-left: 10px;
  padding-top: 3px;
  width: 20px;
  height: 20px;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.mainTitle};
  border-radius: 50%;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background-color: ${colors.blueClean};
    color: ${colors.white};
    font-weight: 600;
  }
  &.active:hover {
    background-color: #3dd969;
    color: ${colors.white};
    font-weight: 600;
  }
  &:hover {
    color: #000;
    background-color: #f4f4f4;
  }
`;
