import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router';
import IosShareIcon from '@mui/icons-material/IosShare';
import { ThemeProvider as MaterialThemeProvider, Button } from '@mui/material';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import { lightTheme } from '../../styles/theme';
import ScrollToTop from '../../utils/ScrollToTop';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from '../../components/ErrorModal';
import AlertModal from '../../components/AlertModal';

import {
  Container,
  ContainerCard,
  SubTitle,
  DescriptionData,
  Row,
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  ContainerTitle,
  GroupFilter,
  Line,
} from './styles';
import { useQuery } from '../../utils/searchQuery';
import { muiTheme } from '../../Layout/styles';

function Vacancy() {
  const [width, setWidth] = useState(window.screen.width);
  const [data, setData] = useState([]);
  const [vacancy, setVacancy] = useState([]);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const { token } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();

  const query = useQuery();
  const vacancyId = query.get('id');

  const onHome = () => {
    history.push({
      pathname: '/',
    });
  };

  const onNext = () => {
    if (!token)
      window.location.href = `${
        process.env.REACT_APP_URL
      }/login?redirect=${encodeURIComponent(window.location.href)}`;
    const id =
      location.search.length > 0
        ? location.search.substring(
            location.search.indexOf('=') + 1,
            +location.search.length,
          )
        : '';
    if (data.comportamental && data.cultural && data.valores) {
      history.push({
        pathname: '/calibrarHardskill',
        state: {
          vacancy: true,
          vacancyId: id,
        },
      });
    } else {
      let route;
      if (!data.comportamental && !data.cultural && !data.valores)
        route = '/testeComportamental';
      if (!data.comportamental && !data.cultural && data.valores)
        route = '/testeComportamental';
      if (data.comportamental && data.cultural && !data.valores)
        route = '/testeCarreira';
      if (!data.comportamental && data.cultural && data.valores) {
        route = '/testeComportamental';
      } else {
        route =
          data.comportamental && !data.cultural && data.valores
            ? '/testeCultural'
            : '/testeComportamental';
      }

      history.push({
        pathname: route,
        state: {
          vacancy: true,
          vacancyId: id,
        },
      });
    }
  };

  const getTests = useCallback(async () => {
    if (token) {
      const body = {
        email: token.email,
      };
      await api
        .post('/tests', body, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          setData(response.data);
        });
    }
  }, [token]);

  const getVacancy = useCallback(async id => {
    await api.get(`/vagas/${id}`).then(async response => {
      setVacancy(response.data[0]);
    });
  }, []);

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    getVacancy(vacancyId);
  }, [getVacancy, vacancyId]);

  useEffect(() => {
    getTests();
  }, [getTests]);

  useEffect(() => {
    setWidth(window.screen.width);
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <MaterialThemeProvider theme={muiTheme}>
        <LayoutContainer>
          <LayoutContent>
            <LayoutContentMain>
              {token && <ResponsiveAppBar />}
              <ErrorModal isOpen={error} onRequestClose={onCloseError} />
              <AlertModal
                isOpen={alert}
                msg={
                  'Identificamos que você realizou todos os testes necessários. \nSua candidatura foi concluída com sucesso!'
                }
                onRequestClose={onCloseAlert}
              />
              <ScrollToTop />
              <Container>
                <ContainerCard top="10%">
                  <GroupFilter top="5%" bottom="70px">
                    {width <= 570 ? (
                      <>
                        <SubTitle
                          color={colors.white}
                          font="30px"
                          bottom="0%"
                          top="1.2px"
                          align="center"
                        >
                          Aplicação para
                        </SubTitle>
                        <Row top="0%" content="center">
                          <SubTitle
                            color={colors.white}
                            font="30px"
                            bottom="0%"
                            top="1.2px"
                            align="center"
                          >
                            {' '}
                            vaga:
                          </SubTitle>
                          <SubTitle
                            color={colors.lightBlue}
                            font="30px"
                            bottom="0%"
                            top="1.2px"
                            align="center"
                          >
                            &nbsp;{vacancy?.nome}
                          </SubTitle>
                        </Row>
                      </>
                    ) : (
                      <Row top="0%" content="center">
                        <SubTitle
                          color={colors.white}
                          font="30px"
                          bottom="0%"
                          top="1.2px"
                          align="center"
                        >
                          Aplicação para vaga:
                        </SubTitle>
                        <SubTitle
                          color={colors.lightBlue}
                          font="30px"
                          bottom="0%"
                          top="1.2px"
                          align="center"
                        >
                          &nbsp;{vacancy?.nome}
                        </SubTitle>
                      </Row>
                    )}

                    <Row top="0%" content="center">
                      <SubTitle
                        color={colors.white}
                        font="30px"
                        bottom="0%"
                        top="1.2px"
                        align="center"
                      >
                        Empresa:
                      </SubTitle>
                      <SubTitle
                        color={colors.lightBlue}
                        font="30px"
                        bottom="0%"
                        top="1.2px"
                        align="center"
                      >
                        &nbsp; {vacancy?.nome_fantasia}
                      </SubTitle>
                    </Row>
                    <SubTitle color={colors.white}>Hey, iGrowner!</SubTitle>
                    <DescriptionData
                      font="14px"
                      weight="400"
                      color={colors.white}
                    >
                      Agora que você já entrou na plataforma o momento é de
                      conhecer melhor você e ver o quanto você está aderente a
                      vaga aberta da carreira que estamos te apresentando.
                    </DescriptionData>
                    <DescriptionData
                      font="14px"
                      weight="400"
                      color={colors.white}
                    >
                      Clique no botão abaixo e faça os nossos testes. Ao final,
                      você retornará para essa página, finalizando com sua
                      autoavaliação das competências e requisitos que pedimos
                      para esta carreira.
                    </DescriptionData>
                    <ContainerCard top="5%" bottom="0%">
                      <SubTitle bottom="0.5%" top="4%" color={colors.lightBlue}>
                        Descrição da vaga
                      </SubTitle>
                      <DescriptionData
                        font="14px"
                        weight="400"
                        color={colors.white}
                      >
                        {vacancy?.descricao}
                      </DescriptionData>
                    </ContainerCard>
                    <Row top="20px" content="center">
                      <Button
                        variant="contained"
                        startIcon={<IosShareIcon />}
                        onClick={onNext}
                      >
                        Responder aos testes de conhecimento
                      </Button>
                    </Row>
                  </GroupFilter>
                </ContainerCard>

                <Line />
                <ContainerTitle>
                  <Button variant="outlined" onClick={onHome}>
                    Sair
                  </Button>
                  <Button variant="negative" onClick={onNext}>
                    Iniciar
                  </Button>
                </ContainerTitle>
              </Container>
            </LayoutContentMain>
          </LayoutContent>
        </LayoutContainer>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
}

export default Vacancy;
