import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import Button from '../../components/Button';
import api from '../../api';
import colors from '../../styles/colors';
import StoreContext from '../../components/Store/Context';

export function RemoveUserFromCompanyDialog({
  isOpen,
  onClose,
  onDelete,
  userId,
  companyId,
}) {
  const { token } = useContext(StoreContext);
  const [isError, setIsError] = useState(false);
  const [isOK, setIsOK] = useState(false);

  const handleRemoveUserFromCompany = useCallback(async () => {
    try {
      const { data: resultData } = await api({
        method: 'DELETE',
        url: '/users/empresa',
        data: {
          user_id: userId,
          company_id: companyId,
        },
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      });
      if (resultData.error) {
        setIsError(true);
      } else {
        setIsOK(true);
        if (onDelete) onDelete();
      }
    } catch (err) {
      setIsError(true);
    }
    if (onClose) onClose();
  }, [companyId, onClose, onDelete, token, userId]);

  return (
    <>
      <Dialog
        open={isOpen ?? false}
        onClose={() => {
          if (onClose) onClose();
        }}
      >
        <DialogTitle>Desvincular colaborador da empresa</DialogTitle>
        <DialogContent>
          Tem certeza que deseja desvincular este colaborador da empresa?
        </DialogContent>
        <DialogActions>
          <Button
            hoverColor={colors.lightBlue}
            text="Sim"
            onClick={handleRemoveUserFromCompany}
          />
          <Button hoverColor={colors.lightBlue} text="Não" onClick={onClose} />
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setIsError(false)}
        autoHideDuration={5000}
        open={isError}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Ocorreu um erro ao desvincular o colaborador da carreira. Tente
          novamente mais tarde.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setIsOK(false)}
        autoHideDuration={3000}
        open={isOK}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Colaborador desvinculado.
        </Alert>
      </Snackbar>
    </>
  );
}
