import React, { useContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
} from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from '../../ErrorModal';
import StatesBrazil from '../../../assets/data/states';

function MenuAbout({ user, onClose, isOpen, width }) {
  const { token } = useContext(StoreContext);
  const [listStates] = useState(
    StatesBrazil.map(item => {
      return item.acronym;
    }),
  );
  const [listCity] = useState([]);
  const [state, setState] = useState(user[0].dados_adicionais.estado);
  const [city, setCity] = useState(user[0].dados_adicionais.cidade);
  const [experience, setExperience] = useState(
    user[0].dados_adicionais.experiencia_area,
  );
  const [job, setJob] = useState(user[0].dados_adicionais.modelo_trabalho);
  const [accepted, setAccepted] = useState(
    user[0].dados_adicionais.aceita_propostas,
  );
  const [pcd, setPcd] = useState(user[0].dados_adicionais.pcd);
  const [english, setEnglish] = useState(user[0].dados_adicionais.nivel_ingles);
  const [salary, setSalary] = useState(
    user[0].dados_adicionais.pretensao_salarial,
  );
  const [error, setError] = useState(false);

  const stateFilter = StatesBrazil.filter(item => {
    return state !== '' ? item.acronym === state : item.acronym === 'AC';
  });

  const cities =
    listCity?.length > 0
      ? listCity
      : stateFilter.flatMap(item => {
          return item.cities;
        });

  const handleState = e => {
    const { value } = e.target;
    setState(value);
  };

  const handleCity = e => {
    const { value } = e.target;
    setCity(value);
  };
  const handleSalary = e => {
    const { value } = e.target;
    setSalary(value);
  };
  const handleExperience = e => {
    const { value } = e.target;
    setExperience(value);
  };
  const handleJob = e => {
    const { value } = e.target;
    setJob(value);
  };
  const handleAccepted = e => {
    const { value } = e.target;
    setAccepted(value);
  };
  const handlePcd = e => {
    const { value } = e.target;
    setPcd(value);
  };
  const handleEnglish = e => {
    const { value } = e.target;
    setEnglish(value);
  };

  const onCloseError = () => {
    setError(false);
  };

  const putData = async () => {
    const json = {
      user_id: user[0].id,
      cidade: city,
      estado: state,
      experiencia_area: experience,
      pretensao_salarial: salary,
      modelo_trabalho: job,
      aceita_propostas: accepted,
      nivel_ingles: english,
      pcd,
    };

    await api
      .put('/dados-candidatos/update', json, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <GroupLine>
        <Title>Sobre</Title>
      </GroupLine>
      <Container>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="state">Estado</InputLabel>
            <Select
              labelId="state"
              id="state"
              InputLabelProps={{ shrink: true, required: true }}
              value={state}
              size="small"
              color="info"
              disabled={false}
              onChange={handleState}
              input={<OutlinedInput label="Estado" />}
            >
              {listStates.map(v => {
                return <MenuItem value={v}>{v}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="city">Cidade</InputLabel>
            <Select
              labelId="city"
              id="city"
              InputLabelProps={{ shrink: true, required: true }}
              value={city}
              size="small"
              color="info"
              disabled={false}
              onChange={handleCity}
              input={<OutlinedInput label="Cidade" />}
            >
              {cities.map(v => {
                return <MenuItem value={v}>{v}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="experience">Experiência na área</InputLabel>
            <Select
              labelId="experience"
              id="experience"
              InputLabelProps={{ shrink: true, required: true }}
              value={experience}
              size="small"
              color="info"
              disabled={false}
              onChange={handleExperience}
              input={<OutlinedInput label="Experiência na área" />}
            >
              <MenuItem value="1-2 anos">1-2 anos</MenuItem>
              <MenuItem value="2-4 anos">2-4 anos</MenuItem>
              <MenuItem value="4-6 anos">4-6 anos</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <TextField
            label="Pretensão salarial"
            onChange={handleSalary}
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            value={salary}
            color="info"
            disabled={false}
          />
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="job">Tipo de Trabalho</InputLabel>
            <Select
              labelId="job"
              id="job"
              InputLabelProps={{ shrink: true, required: true }}
              value={job}
              size="small"
              color="info"
              onChange={handleJob}
              disabled={false}
              input={<OutlinedInput label="Tipo de Curso" />}
            >
              <MenuItem value="Presencial">Presencial</MenuItem>
              <MenuItem value="Remoto">Remoto</MenuItem>
              <MenuItem value="Presencial e remoto">
                Presencial e remoto
              </MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="proposal">Aceita proposta</InputLabel>
            <Select
              labelId="proposal"
              id="proposal"
              InputLabelProps={{ shrink: true, required: true }}
              value={accepted}
              onChange={handleAccepted}
              size="small"
              color="info"
              disabled={false}
              input={<OutlinedInput label="Aceita propostas" />}
            >
              <MenuItem value="PJ">PJ</MenuItem>
              <MenuItem value="CLT">CLT</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="english">Nível de Inglês</InputLabel>
            <Select
              labelId="english"
              id="english"
              InputLabelProps={{ shrink: true, required: true }}
              value={english}
              onChange={handleEnglish}
              size="small"
              color="info"
              disabled={false}
              input={<OutlinedInput label="Nível de Inglês" />}
            >
              <MenuItem value="Básico">Básico</MenuItem>
              <MenuItem value="Intermediário">Intermediário</MenuItem>
              <MenuItem value="Avançado">Avançado</MenuItem>
              <MenuItem value="Fluente">Fluente</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <FormControl sx={{ m: 0, width: 220 }}>
            <InputLabel id="pcd">É PCD?</InputLabel>
            <Select
              labelId="pcd"
              id="pcd"
              InputLabelProps={{ shrink: true, required: true }}
              value={pcd}
              onChange={handlePcd}
              size="small"
              color="info"
              disabled={false}
              input={<OutlinedInput label="É PCD?" />}
            >
              <MenuItem value="S">Sim</MenuItem>
              <MenuItem value="N">Não</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Salvar alterações"
          fontSize="12px"
          onClick={putData}
        />
      </Container>
    </Slide>
  );
}

export default MenuAbout;
