import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../api';
import StoreContext from '../../Store/Context';

export function NewHardskillModal({ onSubmit, open, onClose, initialValue }) {
  const { token } = useContext(StoreContext);
  const [value, setValue] = useState('');
  const [answerType, setAnswerType] = useState('valor');

  const handleCreateHardskill = useCallback(async () => {
    const { data } = await api.post(
      '/hardskill/create',
      {
        nome: value,
        tipo: answerType,
      },
      {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      },
    );
    onSubmit({
      id: data.created_id,
      nome: value,
      tipo: answerType,
    });
    onClose();
  }, [answerType, onClose, onSubmit, token.refreshToken, value]);

  useEffect(() => {
    if (open) {
      setValue(initialValue);
    }
  }, [initialValue, open]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Nova hardskill</DialogTitle>
      <DialogContent>
        <Box sx={{ m: 1, display: 'grid', gap: 2 }}>
          <TextField
            sx={{ mr: 1 }}
            fullWidth
            label="Descrição"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <RadioGroup
            value={answerType}
            onChange={e => setAnswerType(e.target.value)}
          >
            <FormControlLabel
              value="valor"
              control={<Radio />}
              label="Nível de experiência (0 a 10)"
            />
            <FormControlLabel
              value="Idioma"
              control={<Radio />}
              label="Idioma"
            />
            <FormControlLabel
              value="select"
              control={<Radio />}
              label="Sim ou Não"
            />
          </RadioGroup>
          <Button variant="contained" onClick={handleCreateHardskill}>
            Adicionar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
