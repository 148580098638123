import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useCallback, useContext, useEffect } from 'react';
import { Title } from './styles';
import { useMessage } from '../../contexts/MessageContext';
import api from '../../api';
import StoreContext from '../Store/Context';
import { useLoading } from '../../contexts/LoadingContext';

export function EditCompanyDialog({
  isOpen,
  onClose,
  onChange: onEditChange,
  company,
}) {
  const { token } = useContext(StoreContext);
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      cnpj: '',
      razaoSocial: '',
      nomeFantasia: '',
      status: true,
    },
  });

  const { showError, showMessage } = useMessage();

  const { setLoading } = useLoading();

  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        if (company) {
          await api.put(
            `/companies/${company.id}`,
            {
              cnpj: data.cnpj.replace(/[./-]/g, ''),
              razao_social: data.razaoSocial,
              nome_fantasia: data.nomeFantasia,
              status: data.status ? 'S' : 'N',
            },
            { headers: { authorization: `Bearer ${token.refreshToken}` } },
          );
          await showMessage('Cadastro atualizado com sucesso.');
        } else {
          await api.post(
            '/companies/create',
            {
              cnpj: data.cnpj.replace(/[./-]/g, ''),
              razao_social: data.razaoSocial,
              nome_fantasia: data.nomeFantasia,
            },
            { headers: { authorization: `Bearer ${token.refreshToken}` } },
          );
          showMessage('Cadastro criado com sucesso.');
        }
        reset();
        if (onClose) onClose();
        if (onEditChange) onEditChange();
      } catch (err) {
        showError(
          'Ocorreu um erro ao salvar os dados da empresa. Tente novamente.',
        );
      }
      setLoading(false);
    },
    [
      company,
      onClose,
      onEditChange,
      reset,
      setLoading,
      showError,
      showMessage,
      token.refreshToken,
    ],
  );

  useEffect(() => {
    setValue('cnpj', company?.cnpj ?? '');
    setValue('razaoSocial', company?.razao_social ?? '');
    setValue('nomeFantasia', company?.nome_fantasia ?? '');
    setValue('status', company?.status === 'S' ?? false);
  }, [company, setValue]);

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Title>{company ? 'Editar empresa' : 'Cadastrar empresa'}</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={4}>
            <Controller
              name="cnpj"
              control={control}
              rules={{ required: true }}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small" error={error}>
                  <InputMask
                    name={name}
                    mask="99.999.999/9999-99"
                    maskChar=" "
                    value={value}
                    onChange={onChange}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="CNPJ"
                        id="cnpj-input"
                        size="small"
                        error={error}
                      />
                    )}
                  </InputMask>
                  {error && (
                    <FormHelperText>O CNPJ é obrigatório</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name="razaoSocial"
              control={control}
              rules={{ required: true }}
              render={({
                field: { name, onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Razão social"
                  size="small"
                  error={error}
                  helperText={error && 'Razão social é obrigatória'}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Controller
              name="nomeFantasia"
              control={control}
              rules={{ required: true }}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Nome fantasia"
                  size="small"
                  error={error}
                  helperText={error && 'Nome fantasia é obrigatório'}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="status"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <FormControlLabel
                  label="Empresa ativa"
                  control={
                    <Switch
                      name={name}
                      disabled={!company}
                      checked={value}
                      onChange={onChange}
                    />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
