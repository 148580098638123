import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useCallback, useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import api from '../../api';
import StoreContext from '../Store/Context';
import { NewHardskillModal } from './NewHardskillModal';
import { personaXpLevels } from '../../utils/xpLevels';
import colors from '../../styles/colors';

const filter = createFilterOptions();

export function HardskillSelector({
  onChange,
  initialList,
  showRequiredQuestion = true,
  showSkills = true,
  slide = false,
}) {
  const { token } = useContext(StoreContext);
  const [hardskills, setHardskills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState('');
  const [value, setValue] = useState('');
  const [selectedList, setSelectedList] = useState([]);
  const [required, setRequired] = useState(true);
  const [openNewHardskillModal, setOpenNewHardskillModal] = useState(false);
  const [newHardskillValue, setNewHardskillValue] = useState('');

  const handleChangeHardskill = useCallback(async (_event, newValue) => {
    if (typeof newValue === 'string') {
      setSelectedSkill({
        nome: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setNewHardskillValue(newValue.inputValue);
      setOpenNewHardskillModal(true);
    } else {
      setSelectedSkill(newValue);
    }
  }, []);

  const clear = useCallback(() => {
    setSelectedSkill('');
    setValue('');
  }, []);

  const handleAddHardskill = useCallback(() => {
    setSelectedList(oldList => {
      const newList = [
        ...oldList,
        {
          ...selectedSkill,
          value,
          required: !(required === 'false'),
        },
      ];
      onChange(newList);
      return newList;
    });
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, required, selectedSkill, value]);

  const handleRemoveHardskill = id => {
    setSelectedList(oldList => {
      const newList = oldList.filter(item => item.id !== id);
      onChange(newList);
      return newList;
    });
  };

  useEffect(() => {
    setSelectedList(
      initialList.map(item => ({
        id: item.id,
        nome: item.nome,
        value: item.valor,
        required: item.mandatoria,
      })),
    );
  }, [initialList]);

  useEffect(() => {
    api
      .get('/hardskill', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data } = response.data;
        setHardskills(
          data.sort((a, b) => (a.nome.trim() < b.nome.trim() ? -1 : 1)),
        );
      });
  }, [token]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={slide ? 12 : 5}>
          <FormControl fullWidth size="small">
            <Autocomplete
              fullWidth
              disablePortal
              value={selectedSkill}
              onChange={handleChangeHardskill}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  option => inputValue === option.nome,
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    nome: `Adicionar "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              size="small"
              id="free-solo-with-text-demo"
              options={hardskills}
              getOptionLabel={option => {
                if (typeof option === 'string') return option;
                if (option.inputValue) return option.inputValue;
                return option.nome;
              }}
              getOptionDisabled={option =>
                selectedList.findIndex(l => l.id === option.id) !== -1
              }
              renderOption={(props, option) => (
                <li {...props}>{option.nome}</li>
              )}
              freeSolo
              renderInput={params => (
                <TextField {...params} label="Hardskill" />
              )}
            />
          </FormControl>
        </Grid>
        {selectedSkill?.tipo === 'valor' && (
          <Grid item xs={10} md={slide ? 12 : 5}>
            <FormControl size="small" fullWidth>
              <InputLabel id="value-label">Nível de experiência</InputLabel>
              <Select
                fullWidth
                labelId="value-label"
                id="value"
                value={value}
                onChange={e => setValue(e.target.value)}
                size="small"
                label="Nível de experiência"
              >
                {personaXpLevels.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    {index}: {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {selectedSkill?.tipo === 'Idioma' && (
          <Grid item xs={10} md={3}>
            <FormControl size="small" fullWidth>
              <InputLabel id="value-label">Nível</InputLabel>
              <Select
                labelId="value-label"
                id="value"
                value={value}
                onChange={e => setValue(e.target.value)}
                size="small"
                label="Nível"
              >
                <MenuItem value="Básico">Básico</MenuItem>
                <MenuItem value="Intermediário">Intermediário</MenuItem>
                <MenuItem value="Avançado">Avançado</MenuItem>
                <MenuItem value="Fluente">Fluente</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {selectedSkill?.tipo === 'select' && (
          <Grid item xs={10} md={slide ? 12 : 2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="value-label">Resposta</InputLabel>
              <Select
                labelId="value-label"
                id="value"
                value={value}
                size="small"
                onChange={e => setValue(e.target.value)}
                label="Resposta"
              >
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'end',
            marginTop: slide ? 2 : 0,
            justifyContent: slide
              ? 'end'
              : {
                  xs: 'end',
                  md: 'start',
                },
            marginLeft: 2,
          }}
        >
          <IconButton
            sx={{
              backgroundColor: colors.lightBlue,
              color: colors.white,
              ':hover': {
                backgroundColor: colors.blue,
              },
            }}
            onClick={handleAddHardskill}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Grid>
      {showRequiredQuestion && (
        <FormControl>
          <RadioGroup
            row
            value={required}
            onChange={e => setRequired(e.target.value)}
          >
            <FormControlLabel value control={<Radio />} label="Obrigatória" />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Desejável"
            />
          </RadioGroup>
        </FormControl>
      )}
      {showSkills && (
        <Box sx={{ lineHeight: '40px' }}>
          {selectedList.map(item => (
            <Chip
              sx={{ mr: '4px' }}
              color={item.required ? 'primary' : 'default'}
              key={item.id}
              label={`${item.nome} (${item.value})`}
              onDelete={() => handleRemoveHardskill(item.id)}
            />
          ))}
        </Box>
      )}

      <NewHardskillModal
        open={openNewHardskillModal}
        onClose={() => setOpenNewHardskillModal(false)}
        initialValue={newHardskillValue}
        onSubmit={newItem => setSelectedSkill(newItem)}
      />
    </div>
  );
}
