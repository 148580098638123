import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const withAuth = (WrappedComponent, ...profiles) => {
  function Wrapper(props) {
    const { userRole, userData, loading } = useAuth();
    const history = useHistory();
    if (loading) return <div>Carregando...</div>;
    if (userData.email && profiles.includes(userRole))
      return <WrappedComponent {...props} />;
    history.push('/');
    return <span />;
  }

  return Wrapper;
};
