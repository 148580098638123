import React from 'react';
import Modal from 'react-modal';

// ASSETS
import Finish from '../../assets/img/Finish.svg';
import Activity from '../../assets/img/ic_Activity.svg';

// COMPONENTS
import Button from '../Button';

// STYLES
import colors from '../../styles/colors';
import {
  ModalContainer,
  ModalContent,
  Image,
  Title,
  SubLabel,
  Row,
  Icon,
  DescriptionData,
} from './styles';

Modal.setAppElement('#root');

function ModalSubmit({
  isOpen,
  onRequestClose,
  width,
  minutes,
  startReport,
  startTest,
  test,
  isHome,
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}`,
      minWidth: '450px',
      height: '450px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999,
    },
  };

  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Image src={Finish} alt="Finish" />
          <Title>Teste concluído com sucesso!</Title>
          <SubLabel color={colors.darkBlue}>
            Tenha mais profundidade na análise do seu perfil realizando agora o{' '}
            <br />
            nosso teste de {test}!
          </SubLabel>
          {isHome ? (
            false
          ) : minutes ? (
            <Row bottom="8%">
              <Icon width="30px" right="8px" src={Activity} alt="Next" />
              <DescriptionData color={colors.darkBlue}>
                {' '}
                Tempo estimado:{' '}
                {minutes === 1 ? `${minutes} minuto` : `${minutes} minutos`}
              </DescriptionData>
            </Row>
          ) : (
            false
          )}
          {test.length > 0 && (
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text={`Iniciar teste de ${test}`}
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={startTest}
              />
            </Row>
          )}
          <Row top="0%">
            <Button
              type="button"
              width="200px"
              fontSize="13px"
              text="Finalizar"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              onClick={startReport}
            />
          </Row>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default ModalSubmit;
