import React, { useState, useEffect, useContext, useCallback } from 'react';
import Table from '../../components/Table';
import Ellipse1 from '../../assets/img/Ellipse1.svg';
import Ellipse2 from '../../assets/img/Ellipse2.svg';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';

// DATA
import api from '../../api';

import {
  Container,
  Title,
  Label,
  Card,
  ContainerCard,
  Icon,
  Value,
  GroupItems,
  ContainerTitle,
} from './styles';

function Plans() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [jsonPlans, setJsonPlans] = useState([]);
  const [data, setData] = useState([]);
  const [aux, setAux] = useState([]);
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState('Todos os planos');

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        await api
          .get('/companies', {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          })
          .then(async response => {
            const responseData = response.data.response;

            const json = [];
            responseData.forEach(item => {
              json.push({
                name: item.nome_fantasia,
                plan: item.nome_plano,
                status: item.status === 'S' ? 'Ativo' : 'Inativo',
              });
            });
            setData(json);
            setAux(json);
            setIsVisible(true);
          })
          .catch(() => {
            setError(true);
          });
      } else {
        const json = [];
        userGlobal.forEach(item => {
          json.push({
            name: item.nome_fantasia,
            plan: item.nome_plano,
            status: item.status === 'S' ? 'Ativo' : 'Inativo',
          });
        });
        setData(json);
        setAux(json);
        setIsVisible(true);
      }
    },
    [token, userGlobal],
  );

  const getData = useCallback(
    async id => {
      await api
        .get('/planos', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data: responseData } = response;
          setJsonPlans(responseData);
          getCompanies(id);
        })
        .catch(() => {
          setError(true);
        });
    },
    [getCompanies, token.refreshToken],
  );

  const onCloseError = () => {
    setError(false);
  };

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.plan}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.status}
      </th>
    </>
  );

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.status}</td>
    </tr>
  );

  const plans = {
    head: [{ name: 'Nome da empresa', plan: 'Plano', status: 'Status' }],
    body: data,
  };

  const onFilter = async plan => {
    setActive(plan);
    setIsVisible(false);
    if (plan !== 'Todos os planos') {
      await setData(
        aux.filter(item => {
          return item.plan === plan;
        }),
      );
      await setIsVisible(true);
    } else {
      await setData(aux);
      await setIsVisible(true);
    }
  };

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      setWidth(window.screen.width);
      getData(profileGlobal.perfil_id);
    }
  }, [getData, profileGlobal.perfil_id, width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Title>Planos Contratados</Title>
      <ContainerCard>
        <Card
          width="19%"
          cursor="pointer"
          background={active === 'Todos os planos' ? colors.lightBlue : false}
          onClick={() => onFilter('Todos os planos')}
        >
          <GroupItems>
            <ContainerTitle>
              <Label
                color={active === 'Todos os planos' ? colors.white : false}
              >
                Todos os planos
              </Label>
              <Icon
                src={active === 'Todos os planos' ? Ellipse2 : Ellipse1}
                alt="Next"
              />
            </ContainerTitle>
            <Value color={active === 'Todos os planos' ? colors.white : false}>
              {aux.length}
            </Value>
          </GroupItems>
        </Card>
        <Card
          width="19%"
          cursor="pointer"
          background={active === 'Sem plano' ? colors.lightBlue : false}
          onClick={() => onFilter('Sem plano')}
        >
          <GroupItems>
            <ContainerTitle>
              <Label color={active === 'Sem plano' ? colors.white : false}>
                Free
              </Label>
              <Icon
                src={active === 'Sem plano' ? Ellipse2 : Ellipse1}
                alt="Next"
              />
            </ContainerTitle>
            <Value color={active === 'Sem plano' ? colors.white : false}>
              {
                aux.filter(item => {
                  return item.plan === 'Sem plano';
                }).length
              }
            </Value>
          </GroupItems>
        </Card>
        {jsonPlans.map(plan => {
          return (
            <Card
              width="19%"
              cursor="pointer"
              background={active === plan.nome ? colors.lightBlue : false}
              onClick={() => onFilter(plan.nome)}
            >
              <GroupItems>
                <ContainerTitle>
                  <Label color={active === plan.nome ? colors.white : false}>
                    {plan.nome}
                  </Label>
                  <Icon
                    src={active === plan.nome ? Ellipse2 : Ellipse1}
                    alt="Next"
                  />
                </ContainerTitle>
                <Value color={active === plan.nome ? colors.white : false}>
                  {
                    aux.filter(item => {
                      return item.plan === plan.nome;
                    }).length
                  }
                </Value>
              </GroupItems>
            </Card>
          );
        })}
      </ContainerCard>
      <Card>
        <Table
          limit="10"
          headData={plans.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={plans.body}
          renderBody={(item, index) => renderBody(item, index)}
          isLoading={!isVisible}
        />
      </Card>
    </Container>
  );
}

export default Plans;
