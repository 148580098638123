import { Paper, Typography, styled } from '@mui/material';
import colors from '../../styles/colors';

export const Title = styled(Typography)({
  color: colors.darkBlue,
  fontSize: '1.5rem',
  textAlign: 'center',
  fontWeight: 700,
});

export const SubTitle = styled(Typography)({
  color: colors.blue,
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: '0.75rem',
});

export const Section = styled(Paper)({
  border: `solid 1px ${colors.gray5}`,
  padding: '1.5rem',
});
