import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { TextField, FormControl } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { lightTheme } from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import logo from '../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
import { validEmail } from '../../utils/validEmail';
import { phoneMask } from '../../utils/phoneMask';
import { auth } from '../../firebaseConfig';
import api from '../../api';

// UTILS
import ScrollToTop from '../../utils/ScrollToTop';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  Header,
  Logo,
  Group,
  ErrorMessage,
  Row,
} from './styles';

function LegalPerson() {
  const [width, setWidth] = useState(window.screen.width);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState('');

  const history = useHistory();

  useEffect(() => {
    setWidth(window.screen.width);
  }, [width]);

  const onChangePassword = e => {
    setPassword(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangePasswordConfirm = e => {
    setPasswordConfirm(e.target.value);
    setMsg('');
    setError(false);
  };

  const onNext = async e => {
    e.preventDefault();
    setIsLoading(true);
    const inputName = name.trim();
    const word = inputName.split(' ');
    if (
      name.length > 0 &&
      phone.length > 0 &&
      email.length > 0 &&
      message.length > 0 &&
      password.length > 0 &&
      passwordConfirm.length > 0
    ) {
      if (!validEmail(email)) {
        setMsg('E-mail inválido! Digite um e-mail válido!');
        setError(true);
        setIsLoading(false);
      } else if (password.trim() !== passwordConfirm.trim()) {
        setMsg('A senhas informadas devem ser iguais!');
        setError(true);
        setIsLoading(false);
      } else if (word.length === 1) {
        setMsg('É necessário inserir pelo menos um sobrenome!');
        setError(true);
        setIsLoading(false);
      } else {
        createUserWithEmailAndPassword(auth, email.trim(), password)
          // here we get user id if creating is successful.
          .then(async user => {
            const json = {
              email,
              name,
              password,
              password_confirmation: password,
              uid_firebase: user.user.uid,
            };
            await api
              .post('/register', json, {
                headers: {
                  'Content-Type': 'aplication/json',
                },
              })
              .then(async response => {
                const dataUser = response.data;
                const credentials = {
                  email,
                  password: '123456',
                };
                await api
                  .post('/login', credentials, {
                    headers: {
                      'Content-Type': 'aplication/json',
                    },
                  })
                  .then(async loginResponse => {
                    const { data } = loginResponse;

                    const complementary = {
                      user_id: dataUser.user.id,
                      nome: word[0],
                      sobrenome: word[1],
                      mensagem: message,
                    };
                    await api
                      .post('/dados-candidatos/save', complementary, {
                        headers: {
                          authorization: `Bearer ${data.refreshToken}`,
                        },
                      })
                      .then(async () => {
                        const messageEmail = {
                          emails: [email],
                          assunto:
                            'Bem-vindo à iGrown! Comece a moldar sua jornada de carreira.',
                          mensagem: `Caro ${name}, \n\nSeja bem-vindo à iGrown! Estamos muito felizes em recebê-lo(a) em nossa plataforma de desenvolvimento de carreira. Como um colaborador registrado, você está prestes a iniciar uma jornada única e emocionante de autodescoberta, crescimento e oportunidades. \nNa iGrown, acreditamos no seu potencial e estamos aqui para ajudá-lo(a) a acelerar sua carreira. Nossa plataforma foi projetada para fornecer insights valiosos sobre suas habilidades, agilidades de aprendizado, potencial e estilo cultural, permitindo que você descubra seu verdadeiro potencial e alcance suas metas profissionais.\nAqui estão algumas coisas que você poderá fazer na iGrown:\nTestes de Perfil Comportamental: Descubra suas habilidades e características comportamentais, compreenda suas preferências de trabalho e como você pode se desenvolver ainda mais.\nTeste Cultural: Identifique seu estilo cultural predominante e compreenda como você se encaixa na cultura organizacional, facilitando sua integração e alinhamento com a empresa.\nExplorar suas Âncoras de Carreira: Descubra o que é mais importante para você em sua jornada profissional e como suas âncoras de carreira podem orientar suas escolhas e motivações.\nA iGrown está aqui para apoiá-lo(a) em cada etapa de sua carreira. Estamos empenhados em fornecer a você uma experiência excepcional e auxiliá-lo(a) no desenvolvimento de suas habilidades e alcance de seus objetivos profissionais.\nAproveite ao máximo sua experiência na iGrown! Estamos entusiasmados por fazer parte da sua jornada de crescimento e sucesso.\nSeu sucesso é nossa prioridade. Estamos aqui para ajudá-lo(a) a alcançar grandes conquistas!\n\nAtenciosamente,\nEquipe iGrown`,
                        };
                        await api
                          .post('/email/send', messageEmail, {
                            headers: {
                              authorization: `Bearer ${data.refreshToken}`,
                            },
                          })
                          .then(async () => {
                            history.push({
                              pathname: '/enviarEmail',
                              state: {
                                email,
                              },
                            });
                          })
                          .catch(() => {
                            setMsg('Erro ao enviar os seus dados!');
                            setError(true);
                            setIsLoading(false);
                          });
                      })
                      .catch(() => {
                        setMsg('Erro ao enviar os seus dados!');
                        setError(true);
                        setIsLoading(false);
                      });
                  })
                  .catch(() => {
                    setMsg('Erro ao enviar os seus dados!');
                    setError(true);
                    setIsLoading(false);
                  });
              })
              .catch(() => {
                setMsg('Erro ao enviar os seus dados!');
                setError(true);
                setIsLoading(false);
              });
          })
          .catch(() => {
            setMsg('E-mail já cadastrado! Entre em contato conosco');
            setError(true);
            setIsLoading(false);
          });
      }
    } else {
      setMsg('É necessário preeencher todos os campos!');
      setError(true);
      setIsLoading(false);
    }
  };

  const onChangeName = e => {
    setName(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangePhone = e => {
    setPhone(phoneMask(e.target.value));
    setMsg('');
    setError(false);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
    setMsg('');
    setError(false);
  };

  const onChangeMessage = e => {
    setMessage(e.target.value);
    setMsg('');
    setError(false);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <Container>
              <Header>
                <a href="/login">
                  <Logo src={logo} alt="iGrown" />
                </a>
              </Header>
              <Label>Continue o seu cadastro</Label>
              <Card>
                <Group top="30px">
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="Nome completo"
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={onChangeName}
                      color="info"
                      disabled={false}
                    />
                  </FormControl>
                  <Row>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Senha"
                        variant="outlined"
                        size="small"
                        color="info"
                        onChange={onChangePassword}
                        value={password}
                        type="password"
                        disabled={false}
                      />
                    </FormControl>
                    <FormControl
                      sx={{
                        m: 1.2,
                        width: '39%',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                      }}
                    >
                      <TextField
                        label="Confirmar senha"
                        variant="outlined"
                        size="small"
                        color="info"
                        onChange={onChangePasswordConfirm}
                        type="password"
                        value={passwordConfirm}
                        disabled={false}
                      />
                    </FormControl>
                  </Row>
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="Celular"
                      variant="outlined"
                      size="small"
                      color="info"
                      onChange={onChangePhone}
                      value={phone}
                      disabled={false}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="E-mail corporativo"
                      variant="outlined"
                      value={email}
                      onChange={onChangeEmail}
                      size="small"
                      color="info"
                      disabled={false}
                    />
                  </FormControl>
                  <FormControl
                    sx={{
                      m: 1.2,
                      width: '80%',
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    }}
                  >
                    <TextField
                      label="Mensagem"
                      variant="outlined"
                      size="small"
                      onChange={onChangeMessage}
                      value={message}
                      color="info"
                      disabled={false}
                      multiline
                      rows={4}
                      maxRows={4}
                    />
                  </FormControl>
                </Group>
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>
                  {msg}
                </ErrorMessage>
                <Group>
                  <Button
                    type="button"
                    width={width > 530 ? '600px' : '320px'}
                    hoverColor={colors.lightBlue}
                    text="Avançar"
                    isLoading={isLoading}
                    onClick={onNext}
                  />
                </Group>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  );
}

export default LegalPerson;
