import React from 'react';
import Modal from 'react-modal';

// COMPONENTS
import * as Fi from 'react-icons/fi';
import Button from '../Button';

// STYLES
import colors from '../../styles/colors';
import { ModalContainer, ModalContent, Title, Row } from './styles';

Modal.setAppElement('#root');

function LogoutModal({ isOpen, onRequestClose, width, onNext, title }) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}`,
      minWidth: '450px',
      height: '300px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999,
    },
  };

  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Fi.FiAlertCircle color={colors.lightBlue} size={80} />
          <Title>{title}</Title>
          <Row top="0%">
            <Button
              type="button"
              width="150px"
              fontSize="13px"
              text="Não"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              onClick={onRequestClose}
            />
            <Button
              type="button"
              width="150px"
              fontSize="13px"
              text="Sim"
              hoverColor={colors.lightBlue}
              color={colors.white}
              bgColor={colors.lightBlue}
              onClick={onNext}
            />
          </Row>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default LogoutModal;
