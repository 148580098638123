import React from 'react';

import { Tooltip } from '@mui/material';
import {
  Container,
  Image,
  Text,
  Value,
  Icon,
  Label,
  GpItems,
  Progress,
  Step,
  Row,
} from './styles';
import vector from '../../assets/img/Vector.svg';
import exclamation from '../../assets/img/Exclamation.svg';
import colors from '../../styles/colors';

function Card({
  bgColor,
  color,
  title,
  height,
  width,
  description,
  fontColor,
  value,
  font,
  progress,
  colorProgress,
  step,
  paddingLabel,
  topProgress,
  valueColor,
  left,
  right,
  num,
  fontNum,
  iconWhite,
  tooltip,
}) {
  return (
    <Container
      bgColor={bgColor}
      color={color}
      width={width}
      height={height}
      right={right || (description ? '0%' : '30px')}
      left={left || (progress || step ? '30px' : '0px')}
    >
      <GpItems>
        {tooltip && (
          <Image>
            <Tooltip title={tooltip}>
              {iconWhite ? (
                <Icon
                  width={step ? '20px' : '15px'}
                  top={description ? '30%' : '22%'}
                  src={exclamation}
                  alt="Icon"
                />
              ) : (
                <Icon
                  width={step ? '20px' : '15px'}
                  top={description ? '30%' : '22%'}
                  src={vector}
                  alt="Icon"
                />
              )}
            </Tooltip>
          </Image>
        )}

        {description ? (
          <Text>
            <Label
              padding={paddingLabel || '3%'}
              color={fontColor || colors.darkBlue}
              font="12px"
            >
              {title}
            </Label>
            <Label
              padding={paddingLabel || '3%'}
              color={fontColor || colors.darkBlue}
              font="10px"
            >
              {description}
            </Label>
          </Text>
        ) : (
          <Text width={step ? '50%' : '70%'}>
            <Label
              padding={paddingLabel || '3%'}
              color={fontColor || colors.darkBlue}
              font={font}
              top="8%"
            >
              {title}
            </Label>
          </Text>
        )}
        <Value
          width={paddingLabel ? '0%' : description || step ? '25%' : '10%'}
        >
          <Label color={valueColor || colors.darkBlue} font="100%">
            {value}
          </Label>
        </Value>
      </GpItems>
      <GpItems>
        {progress ? (
          colorProgress ? (
            <Progress color={colorProgress} top={topProgress || '10px'} />
          ) : (
            <Progress topProgress={topProgress || '10px'} />
          )
        ) : (
          false
        )}
        {step ? (
          <Row>
            {step === 1 ? (
              <>
                <Step color={colors.lightBlue} />
                <Step />
                <Step />
              </>
            ) : step === 2 ? (
              <>
                <Step color={colors.lightBlue} />
                <Step color={colors.lightBlue} />
                <Step />
              </>
            ) : (
              <>
                <Step color={colors.lightBlue} />
                <Step color={colors.lightBlue} />
                <Step color={colors.lightBlue} />
              </>
            )}
          </Row>
        ) : (
          false
        )}
        {num ? (
          <Text float="right" width="80%">
            <Label font={fontNum} top="8%">
              {num}
            </Label>
          </Text>
        ) : (
          false
        )}
      </GpItems>
    </Container>
  );
}

export default Card;
