import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';
import TestsModal from '../../components/TestsModal';
import ErrorModal from '../../components/ErrorModal';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import Info from '../../assets/img/Info.svg';
import Button from '../../components/Button';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';

import {
  Container,
  Card,
  Icon,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  Step,
  ContainerButton,
} from './styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor:
      theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: colors.lightBlue,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colors.lightBlue,
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function StartBehavioral() {
  const { token } = useContext(StoreContext);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [time, setTime] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [resp1, setResp1] = useState('');
  const [resp2, setResp2] = useState('');
  const [resp3, setResp3] = useState('');
  const [resp4, setResp4] = useState('');
  const [resp5, setResp5] = useState('');
  const [resp6, setResp6] = useState('');
  const [resp7, setResp7] = useState('');
  const [resp8, setResp8] = useState('');
  const [resp9, setResp9] = useState('');
  const [resp10, setResp10] = useState('');
  const [resp11, setResp11] = useState('');
  const [resp12, setResp12] = useState('');
  const [resp13, setResp13] = useState('');
  const [resp14, setResp14] = useState('');
  const [resp15, setResp15] = useState('');
  const [resp16, setResp16] = useState('');
  const [resp17, setResp17] = useState('');
  const [resp18, setResp18] = useState('');
  const [resp19, setResp19] = useState('');
  const [resp20, setResp20] = useState('');
  const [resp21, setResp21] = useState('');
  const [resp22, setResp22] = useState('');
  const [resp23, setResp23] = useState('');
  const [resp24, setResp24] = useState('');
  const [resp25, setResp25] = useState('');
  const [resp26, setResp26] = useState('');
  const [resp27, setResp27] = useState('');
  const [resp28, setResp28] = useState('');
  const [resp29, setResp29] = useState('');
  const [resp30, setResp30] = useState('');
  const [resp31, setResp31] = useState('');
  const [resp32, setResp32] = useState('');
  const [resp33, setResp33] = useState('');
  const [resp34, setResp34] = useState('');
  const [resp35, setResp35] = useState('');
  const [resp36, setResp36] = useState('');
  const [resp37, setResp37] = useState('');
  const [resp38, setResp38] = useState('');
  const [resp39, setResp39] = useState('');
  const [resp40, setResp40] = useState('');

  const onBack = useCallback(() => {
    if (step === 1) {
      history.push({
        pathname: '/',
      });
    } else {
      setStep(step - 1);
    }
  }, [history, step]);

  const getTests = useCallback(async () => {
    const body = {
      email: token.email,
    };
    await api.post('/tests', body, {
      headers: {
        authorization: `Bearer ${token.refreshToken}`,
      },
    });
  }, [token]);

  const onFinish = async () => {
    setTime(4);
    setModal(true);
  };

  const onCloseError = () => {
    setError(false);
  };

  const sendTest = async () => {
    const body = {
      lang: 'pt-br',
      email: token.email,
      name: token.displayName || 'undefined',
      datahora: new Date(),
      perfil: 'A mim mesmo (autoteste)',
      email_amigo: '',
      empresa: '',
      palavra1: resp1,
      palavra2: resp2,
      palavra3: resp3,
      palavra4: resp4,
      palavra5: resp5,
      palavra6: resp6,
      palavra7: resp7,
      palavra8: resp8,
      palavra9: resp9,
      palavra10: resp10,
      palavra11: resp11,
      palavra12: resp12,
      palavra13: resp13,
      palavra14: resp14,
      palavra15: resp15,
      palavra16: resp16,
      palavra17: resp17,
      palavra18: resp18,
      palavra19: resp19,
      palavra20: resp20,
      palavra21: resp21,
      palavra22: resp22,
      palavra23: resp23,
      palavra24: resp24,
      palavra25: resp25,
      palavra26: resp26,
      palavra27: resp27,
      palavra28: resp28,
      palavra29: resp29,
      palavra30: resp30,
      palavra31: resp31,
      palavra32: resp32,
      palavra33: resp33,
      palavra34: resp34,
      palavra35: resp35,
      palavra36: resp36,
      palavra37: resp37,
      palavra38: resp38,
      palavra39: resp39,
      palavra40: resp40,
    };

    await api
      .post('/behiavor-test', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onFinish();
      })
      .catch(() => {
        setError(true);
      });
  };

  const startTest = async () => {
    const route = '/testeCultura';

    history.push({
      pathname: route,
      state: {
        vacancy: location.state.vacancy,
        vacancyId: location.state.vacancyId,
      },
    });
  };

  const startReport = async () => {
    history.push({
      pathname: '/',
    });
  };

  const onCloseModal = async () => {
    await setModal(false);
  };

  const step1 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 1 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step active>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp1}
            onChange={e => {
              setResp1(e.target.value);
              setStep(2);
            }}
          >
            <Card>
              <FormControlLabel
                value="Realizadora"
                control={<BpRadio />}
                label="Realizadora"
              />
              <Tooltip title="(Que tem capacidade de realizar, empreendedora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Comprometida"
                control={<BpRadio />}
                label="Comprometida"
              />
              <Tooltip title="(Envolvida, engajada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Livre"
                control={<BpRadio />}
                label="Livre"
              />
              <Tooltip title="(Independente, que não se submete aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Pontual"
                control={<BpRadio />}
                label="Pontual"
              />
              <Tooltip title="(Que cumpre prazos e horários)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step2 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 2 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step active>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp2}
            onChange={e => {
              setResp2(e.target.value);
              setStep(3);
            }}
          >
            <Card>
              <FormControlLabel
                value="Interessada"
                control={<BpRadio />}
                label="Interessada"
              />
              <Tooltip title="(Curiosa, empenhada, motivada, que expressa interesse)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Curiosa"
                control={<BpRadio />}
                label="Curiosa"
              />
              <Tooltip title="(Que tem grande vontade de saber, entender as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Desconfiada"
                control={<BpRadio />}
                label="Desconfiada"
              />
              <Tooltip title="(Que receia ser enganada, que duvida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Autodidata"
                control={<BpRadio />}
                label="Autodidata"
              />
              <Tooltip title="(Que aprende sozinha)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step3 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 3 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step active>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp3}
            onChange={e => {
              setResp3(e.target.value);
              setStep(4);
            }}
          >
            <Card>
              <FormControlLabel
                value="Generelista"
                control={<BpRadio />}
                label="Generelista"
              />
              <Tooltip title="(Que não possui uma única especialização; que atua em diversas áreas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Informal"
                control={<BpRadio />}
                label="Informal"
              />
              <Tooltip title="(Sem formalidades ou regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Investigadora"
                control={<BpRadio />}
                label="Investigadora"
              />
              <Tooltip title="(Que examina com cuidado, que pesquisa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Morosa"
                control={<BpRadio />}
                label="Morosa"
              />
              <Tooltip title="(Que leva mais tempo nas decisões ou realizações de atividades)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step4 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 4 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step active>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp4}
            onChange={e => {
              setResp4(e.target.value);
              setStep(5);
            }}
          >
            <Card>
              <FormControlLabel
                value="Direta"
                control={<BpRadio />}
                label="Direta"
              />
              <Tooltip title="(Sincera, clara, que vai direto ao ponto)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Comunicativa"
                control={<BpRadio />}
                label="Comunicativa"
              />
              <Tooltip title="(Que se comunica facilmente, franca, expansiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Receptiva"
                control={<BpRadio />}
                label="Receptiva"
              />
              <Tooltip title="(Acolhedora, aberta, compreensiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Quieta"
                control={<BpRadio />}
                label="Quieta"
              />
              <Tooltip title="(Que prefere não falar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step5 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 5 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step active>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp5}
            onChange={e => {
              setResp5(e.target.value);
              setStep(6);
            }}
          >
            <Card>
              <FormControlLabel
                value="Dominante"
                control={<BpRadio />}
                label="Dominante"
              />
              <Tooltip title="(Que domina, que prevalece, influente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Relacional"
                control={<BpRadio />}
                label="Relacional"
              />
              <Tooltip title="(Que gosta de se relacionar com as pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Colaborativa"
                control={<BpRadio />}
                label="Colaborativa"
              />
              <Tooltip title="(Que ajuda, coopera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Individualista"
                control={<BpRadio />}
                label="Individualista"
              />
              <Tooltip title="(Que se coloca em primeiro lugar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step6 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 6 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step active>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp6}
            onChange={e => {
              setResp6(e.target.value);
              setStep(7);
            }}
          >
            <Card>
              <FormControlLabel
                value="Desafiadora"
                control={<BpRadio />}
                label="Desafiadora"
              />
              <Tooltip title="(Que desafia, que contesta)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Desenvolvedora"
                control={<BpRadio />}
                label="Desenvolvedora"
              />
              <Tooltip title="(Que apoia no desenvolvimento de comportamentos, que mentora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Treinadora"
                control={<BpRadio />}
                label="Treinadora"
              />
              <Tooltip title="(Que prepara pessoas para atividades específicas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Demonstradora"
                control={<BpRadio />}
                label="Demonstradora"
              />
              <Tooltip title="(Que ensina de forma prática, demonstrando)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step7 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 7 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step active>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp7}
            onChange={e => {
              setResp7(e.target.value);
              setStep(8);
            }}
          >
            <Card>
              <FormControlLabel
                value="Motivada_por_desafios"
                control={<BpRadio />}
                label="Motivada por desafios"
              />
              <Tooltip title="(Que gera ação quando recebe desafios)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Confiante"
                control={<BpRadio />}
                label="Confiante"
              />
              <Tooltip title="(Que tem confiança em si, segura)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Comedida"
                control={<BpRadio />}
                label="Comedida"
              />
              <Tooltip title="(Pacífica, serena, delicada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Conforme"
                control={<BpRadio />}
                label="Conforme"
              />
              <Tooltip title="(Que realiza conforme as normas e regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step8 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 8 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step active>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp8}
            onChange={e => {
              setResp8(e.target.value);
              setStep(9);
            }}
          >
            <Card>
              <FormControlLabel
                value="Determinada"
                control={<BpRadio />}
                label="Determinada"
              />
              <Tooltip title="(Que expressa decisão, convicção, decidida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Distraida"
                control={<BpRadio />}
                label="Distraída"
              />
              <Tooltip title="(Desatenta, com baixa concentração)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Ouvinte"
                control={<BpRadio />}
                label="Ouvinte"
              />
              <Tooltip title="(Que prefere escutar ao invés de falar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Critica"
                control={<BpRadio />}
                label="Crítica"
              />
              <Tooltip title="(Que avalia, que analisa e dá o seu parecer)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step9 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 9 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step active>
          <p>9</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp9}
            onChange={e => {
              setResp9(e.target.value);
              setStep(10);
            }}
          >
            <Card>
              <FormControlLabel
                value="Agilizada"
                control={<BpRadio />}
                label="Agilizada"
              />

              <Tooltip title="(Estimulada, desembaraçada, que realiza com rapidez)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Aberta"
                control={<BpRadio />}
                label="Aberta"
              />
              <Tooltip title="(Receptiva, sem pré-julgamentos, sem resistência)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Conservadora"
                control={<BpRadio />}
                label="Conservadora"
              />
              <Tooltip title="(Que prefere manter as coisas do mesmo jeito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Sistematica"
                control={<BpRadio />}
                label="Sistemática"
              />
              <Tooltip title="(Metódica, organizada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step10 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 10 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step active>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp10}
            onChange={e => {
              setResp10(e.target.value);
              setStep(11);
            }}
          >
            <Card>
              <FormControlLabel
                value="Persistente"
                control={<BpRadio />}
                label="Persistente"
              />

              <Tooltip title="(Que não desiste facilmente, obstinada, que tem continuidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Animada"
                control={<BpRadio />}
                label="Animada"
              />

              <Tooltip title="(Entusiasmada, calorosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Paciente"
                control={<BpRadio />}
                label="Paciente"
              />

              <Tooltip title="(Que tem paciência, que não se importa em esperar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Criteriosa"
                control={<BpRadio />}
                label="Criteriosa"
              />

              <Tooltip title="(Que possui critérios para a tomada de decisão, cuidadosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step11 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 11 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step active>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp11}
            onChange={e => {
              setResp11(e.target.value);
              setStep(12);
            }}
          >
            <Card>
              <FormControlLabel
                value="Decidida"
                control={<BpRadio />}
                label="Decidida"
              />

              <Tooltip title="(Determinada, deliberada, disposta, resolvida, com objetivos claros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Encorajadora"
                control={<BpRadio />}
                label="Encorajadora"
              />

              <Tooltip title=" (Incentivadora, que dá ânimo aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Receosa"
                control={<BpRadio />}
                label="Receosa"
              />

              <Tooltip title=" (Que tem medo, preocupada, apreensiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Prudente"
                control={<BpRadio />}
                label="Prudente"
              />

              <Tooltip title="(Cautelosa, que age com cuidado)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step12 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 12 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step active>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp12}
            onChange={e => {
              setResp12(e.target.value);
              setStep(13);
            }}
          >
            <Card>
              <FormControlLabel
                value="Exigente"
                control={<BpRadio />}
                label="Exigente"
              />

              <Tooltip title="(Rigorosa, que não se satisfaz facilmente; que sempre quer o melhor)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Avaliadora"
                control={<BpRadio />}
                label="Avaliadora"
              />

              <Tooltip title="(Crítica, analítica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Responsavel"
                control={<BpRadio />}
                label="Responsável"
              />

              <Tooltip title="(Que responde pelos seus próprios ato)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Detalhista"
                control={<BpRadio />}
                label="Detalhista"
              />

              <Tooltip title="(Que se apega aos detalhes, aos pormenores)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step13 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 13 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step active>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp13}
            onChange={e => {
              setResp13(e.target.value);
              setStep(14);
            }}
          >
            <Card>
              <FormControlLabel
                value="Energica"
                control={<BpRadio />}
                label="Enérgica"
              />

              <Tooltip title="(Ativa, energética, segura, disposta)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Bem_humorada"
                control={<BpRadio />}
                label="Bem humorada"
              />

              <Tooltip title="(Com bom humor, alegre)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Calorosa"
                control={<BpRadio />}
                label="Calorosa"
              />

              <Tooltip title="(Afetuosa, simpática)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Introvertida"
                control={<BpRadio />}
                label="Introvertida"
              />

              <Tooltip title="(Concentrada, voltada para si própria)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step14 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 14 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step active>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp14}
            onChange={e => {
              setResp14(e.target.value);
              setStep(15);
            }}
          >
            <Card>
              <FormControlLabel
                value="Comandante"
                control={<BpRadio />}
                label="Comandante"
              />

              <Tooltip title=" (Que comanda, lidera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Motivada"
                control={<BpRadio />}
                label="Motivada"
              />

              <Tooltip title="(Que tem um objetivo, uma razão para agir)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Focada"
                control={<BpRadio />}
                label="Focada"
              />

              <Tooltip title="(Que possui foco, atenta ao seu objetivo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Planejadora"
                control={<BpRadio />}
                label="Planejadora"
              />

              <Tooltip title="(Que é programada, que gosta de planejar as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step15 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 15 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step active>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp15}
            onChange={e => {
              setResp15(e.target.value);
              setStep(16);
            }}
          >
            <Card>
              <FormControlLabel
                value="Originadora"
                control={<BpRadio />}
                label="Originadora"
              />

              <Tooltip title="(Que origina ou é causadora de algo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Mentora"
                control={<BpRadio />}
                label="Mentora"
              />

              <Tooltip title="(Conselheira, que orienta pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Aprovadora"
                control={<BpRadio />}
                label="Aprovadora"
              />

              <Tooltip title="(Que aprova ou autoriza, que dá seu consentimento)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Centralizadora"
                control={<BpRadio />}
                label="Centralizadora"
              />

              <Tooltip title="(Que gosta de ter o controle de tudo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step16 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 16 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step active>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp16}
            onChange={e => {
              setResp16(e.target.value);
              setStep(17);
            }}
          >
            <Card>
              <FormControlLabel
                value="Acelerada"
                control={<BpRadio />}
                label="Acelerada"
              />

              <Tooltip title="(Apressada, rápida, vertiginosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Desorganizada"
                control={<BpRadio />}
                label="Desorganizada"
              />

              <Tooltip title="(Sem organização, sem método)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Metodica"
                control={<BpRadio />}
                label="Metódica"
              />

              <Tooltip title="(Que segue métodos, normas e regras)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Demorada"
                control={<BpRadio />}
                label="Demorada"
              />

              <Tooltip title="(Devagar, morosa, lenta)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step17 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 17 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step active>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp17}
            onChange={e => {
              setResp17(e.target.value);
              setStep(18);
            }}
          >
            <Card>
              <FormControlLabel
                value="Imediatista"
                control={<BpRadio />}
                label="Imediatista"
              />

              <Tooltip title="(Que age de maneira imediata, que deseja as coisas na hora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Apaixonada"
                control={<BpRadio />}
                label="Apaixonada"
              />

              <Tooltip title="(Que faz as coisas com paixão, entusiasmada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Conformada"
                control={<BpRadio />}
                label="Conformada"
              />

              <Tooltip title="(Que aceita a adversidade sem se opor)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Racional"
                control={<BpRadio />}
                label="Racional"
              />

              <Tooltip title="(Que toma decisões baseada no raciocínio, lógica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step18 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 18 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step active>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp18}
            onChange={e => {
              setResp18(e.target.value);
              setStep(19);
            }}
          >
            <Card>
              <FormControlLabel
                value="Criativa"
                control={<BpRadio />}
                label="Criativa"
              />

              <Tooltip title="(Inventiva, inovadora, que cria ou transforma as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Promotora"
                control={<BpRadio />}
                label="Promotora"
              />

              <Tooltip title="(Que promove, que dá impulso a alguma coisa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Rotineira"
                control={<BpRadio />}
                label="Rotineira"
              />

              <Tooltip title="(Que realiza sempre da mesma forma, que prefere a rotina)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Teorica"
                control={<BpRadio />}
                label="Teórica"
              />

              <Tooltip title="(Com conhecimento não prático, vindo de suposição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step19 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 19 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step active>
          <p>19</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp19}
            onChange={e => {
              setResp19(e.target.value);
              setStep(20);
            }}
          >
            <Card>
              <FormControlLabel
                value="Pioneira"
                control={<BpRadio />}
                label="Pioneira"
              />

              <Tooltip title="(Que faz por primeiro, antecessora, descobridora)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Seguidora"
                control={<BpRadio />}
                label="Seguidora"
              />

              <Tooltip title="(Que segue outros, partidária)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Pratica"
                control={<BpRadio />}
                label="Prática"
              />

              <Tooltip title="(Que consegue realizar, não é teórica)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Conhecedora"
                control={<BpRadio />}
                label="Conhecedora"
              />

              <Tooltip title="(Que conhece, que possui vivência)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step20 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 20 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step active>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp20}
            onChange={e => {
              setResp20(e.target.value);
              setStep(21);
            }}
          >
            <Card>
              <FormControlLabel
                value="Ansiosa"
                control={<BpRadio />}
                label="Ansiosa"
              />

              <Tooltip title="(Que deseja excessivamente algo, que expressa inquietação)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Alegre"
                control={<BpRadio />}
                label="Alegre"
              />

              <Tooltip title="(Que causa alegria, contente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Tranquila"
                control={<BpRadio />}
                label="Tranquila"
              />

              <Tooltip title="(De personalidade calma, sossegada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Controladora"
                control={<BpRadio />}
                label="Controladora"
              />

              <Tooltip title="(Que se controla, serena)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step21 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 21 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step active>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp21}
            onChange={e => {
              setResp21(e.target.value);
              setStep(22);
            }}
          >
            <Card>
              <FormControlLabel
                value="Competitiva"
                control={<BpRadio />}
                label="Competitiva"
              />

              <Tooltip title="(Que gosta de competição, disputa pela vitória)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Persuasiva"
                control={<BpRadio />}
                label="Persuasiva"
              />

              <Tooltip title="(Que convence os outros com facilidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Respeitada"
                control={<BpRadio />}
                label="Respeitada"
              />

              <Tooltip title="(Que conseguiu o respeito de outras pessoas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Questionadora"
                control={<BpRadio />}
                label="Questionadora"
              />

              <Tooltip title="(Que questiona, que não aceita facilmente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step22 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 22 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step active>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp22}
            onChange={e => {
              setResp22(e.target.value);
              setStep(23);
            }}
          >
            <Card>
              <FormControlLabel
                value="Desbravadora"
                control={<BpRadio />}
                label="Desbravadora"
              />

              <Tooltip title="(Exploradora, pioneira, que faz por primeiro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Convincente"
                control={<BpRadio />}
                label="Convincente"
              />

              <Tooltip title="(Que tem argumentos convincentes, que faz o outro mudar de opinião)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Logica"
                control={<BpRadio />}
                label="Lógica"
              />

              <Tooltip title="(Que pensa de forma lógica, avaliando causas e consequências)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Poderosa"
                control={<BpRadio />}
                label="Poderosa"
              />

              <Tooltip title="(Que tem força e influência, poder)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step23 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 23 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step active>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp23}
            onChange={e => {
              setResp23(e.target.value);
              setStep(24);
            }}
          >
            <Card>
              <FormControlLabel
                value="Bem sucedida"
                control={<BpRadio />}
                label="Bem sucedida"
              />

              <Tooltip title="(Com sucesso, êxito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Energizante"
                control={<BpRadio />}
                label="Energizante"
              />

              <Tooltip title="(Que fornece energia aos outros, reanima)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Auto-desenvolvedora"
                control={<BpRadio />}
                label="Auto-desenvolvedora"
              />

              <Tooltip title="(Que se desenvolve sozinha, a si mesma)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Estável"
                control={<BpRadio />}
                label="Estável"
              />

              <Tooltip title="(Que não possui variações, constante)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step24 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 24 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step active>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp24}
            onChange={e => {
              setResp24(e.target.value);
              setStep(25);
            }}
          >
            <Card>
              <FormControlLabel
                value="Vitoriosa"
                control={<BpRadio />}
                label="Vitoriosa"
              />

              <Tooltip title="(Vencedora, gloriosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Entusiasmada"
                control={<BpRadio />}
                label="Entusiasmada"
              />

              <Tooltip title="(Animada, empolgada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Cautelosa"
                control={<BpRadio />}
                label="Cautelosa"
              />

              <Tooltip title="(Que age com cuidado, evitando riscos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Previsivel"
                control={<BpRadio />}
                label="Previsível"
              />

              <Tooltip title="(Que age da forma esperada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step25 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 25 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step active>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp25}
            onChange={e => {
              setResp25(e.target.value);
              setStep(26);
            }}
          >
            <Card>
              <FormControlLabel
                value="Insensivel"
                control={<BpRadio />}
                label="Insensível"
              />

              <Tooltip title="(Sem sensibilidade, indiferente ao sentimento alheio)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Amiga"
                control={<BpRadio />}
                label="Amiga"
              />

              <Tooltip title="(Que preza pelas relações de amizade, de afeição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Isolada"
                control={<BpRadio />}
                label="Isolada"
              />

              <Tooltip title="(Que prefere ficar só)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Inclusiva"
                control={<BpRadio />}
                label="Inclusiva"
              />

              <Tooltip title="(Que inclui os diferentes, valoriza a diversidade)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step26 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 26 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step active>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp26}
            onChange={e => {
              setResp26(e.target.value);
              setStep(27);
            }}
          >
            <Card>
              <FormControlLabel
                value="Franca"
                control={<BpRadio />}
                label="Franca"
              />

              <Tooltip title="(Sincera, verdadeira)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Magnética"
                control={<BpRadio />}
                label="Magnética"
              />

              <Tooltip title='(Que atrai a atenção dos outros como um "ímã", que seduz)'>
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Concreta"
                control={<BpRadio />}
                label="Concreta"
              />

              <Tooltip title="(Que prefere o que é real, verdadeiro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Diplomática"
                control={<BpRadio />}
                label="Diplomática"
              />

              <Tooltip title="(Que trata as pessoas com delicadeza e gentileza, que preza pelos bons relacionamentos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step27 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 27 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step active>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp27}
            onChange={e => {
              setResp27(e.target.value);
              setStep(28);
            }}
          >
            <Card>
              <FormControlLabel
                value="Negociadora"
                control={<BpRadio />}
                label="Negociadora"
              />

              <Tooltip title="(Que gosta de negociar, discutir para chegar a um acordo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Cativante"
                control={<BpRadio />}
                label="Cativante"
              />

              <Tooltip title="(Simpática, que inspira)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Atenta"
                control={<BpRadio />}
                label="Atenta"
              />

              <Tooltip title="(Que presta atenção, que nota as coisas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Confiavel"
                control={<BpRadio />}
                label="Confiável"
              />

              <Tooltip title="(Em quem se pode confiar, honesta, sincera)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step28 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 28 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step active>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp28}
            onChange={e => {
              setResp28(e.target.value);
              setStep(29);
            }}
          >
            <Card>
              <FormControlLabel
                value="Visionaria"
                control={<BpRadio />}
                label="Visionária"
              />

              <Tooltip title="(Que enxerga além do óbvio, voltada ao futuro, idealista)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Experiente"
                control={<BpRadio />}
                label="Experiente"
              />

              <Tooltip title="(Que possui vivência, conhecimento prático)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Processual"
                control={<BpRadio />}
                label="Processual"
              />

              <Tooltip title="(Que age de forma ordenada, por processos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Senso_de_dever"
                control={<BpRadio />}
                label="Senso de dever"
              />

              <Tooltip title="(Que possui clareza de suas obrigações, responsável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step29 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 29 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step active>
          <p>29</p>
        </Step>
        <Step>
          <p>...</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp29}
            onChange={e => {
              setResp29(e.target.value);
              setStep(30);
            }}
          >
            <Card>
              <FormControlLabel
                value="Concretizadora"
                control={<BpRadio />}
                label="Concretizadora"
              />

              <Tooltip title=" (Que torna as coisas reais, que atinge os seus objetivos)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Influenciadora"
                control={<BpRadio />}
                label="Influenciadora"
              />

              <Tooltip title="(Que exerce influência, poder sobre o outro)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Executora"
                control={<BpRadio />}
                label="Executora"
              />

              <Tooltip title='(Que gosta de executar as coisas, "mão na massa")'>
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Administradora"
                control={<BpRadio />}
                label="Administradora"
              />

              <Tooltip title="(Que gerencia as situações, que administra)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step30 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 30 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step active>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp30}
            onChange={e => {
              setResp30(e.target.value);
              setStep(31);
            }}
          >
            <Card>
              <FormControlLabel
                value="Agil"
                control={<BpRadio />}
                label="Ágil"
              />

              <Tooltip title="(Que é rápida, veloz)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Impulsiva"
                control={<BpRadio />}
                label="Impulsiva"
              />

              <Tooltip title="(Que age por impulso, de forma impensada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Analitica"
                control={<BpRadio />}
                label="Analítica"
              />

              <Tooltip title="(Examinadora, que analisa detalhadamente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Calma"
                control={<BpRadio />}
                label="Calma"
              />

              <Tooltip title="(Tranquila, sem agitação)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step31 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 31 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step active>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp31}
            onChange={e => {
              setResp31(e.target.value);
              setStep(32);
            }}
          >
            <Card>
              <FormControlLabel
                value="Objetiva"
                control={<BpRadio />}
                label="Objetiva"
              />

              <Tooltip title="(Que vai direto ao ponto, sem rodeios)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Estudiosa"
                control={<BpRadio />}
                label="Estudiosa"
              />

              <Tooltip title="(Que gosta de estudar, aprender)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Perfeccionista"
                control={<BpRadio />}
                label="Perfeccionista"
              />

              <Tooltip title="(Que busca a perfeição)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Especialista"
                control={<BpRadio />}
                label="Especialista"
              />

              <Tooltip title="(Com profundo conhecimento de um determinado tema)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step32 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 32 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step active>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp32}
            onChange={e => {
              setResp32(e.target.value);
              setStep(33);
            }}
          >
            <Card>
              <FormControlLabel
                value="Apressada"
                control={<BpRadio />}
                label="Apressada"
              />

              <Tooltip title="(Que tem pressa, não sabe esperar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Experimentadora"
                control={<BpRadio />}
                label="Experimentadora"
              />

              <Tooltip title="(Que gosta de experimentar coisas novas)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Minuciosa"
                control={<BpRadio />}
                label="Minuciosa"
              />

              <Tooltip title="(Detalhista, ligada aos pormenores)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Segura"
                control={<BpRadio />}
                label="Segura"
              />

              <Tooltip title="(Que possui autoconfiança, que não hesita)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step33 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 33 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step active>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp33}
            onChange={e => {
              setResp33(e.target.value);
              setStep(34);
            }}
          >
            <Card>
              <FormControlLabel
                value="Inspiradora"
                control={<BpRadio />}
                label="Inspiradora"
              />

              <Tooltip title="(Que é capaz de inspirar outras pessoas, influenciar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Falante"
                control={<BpRadio />}
                label="Falante"
              />

              <Tooltip title="(Que fala muito, extrovertida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Orientadora"
                control={<BpRadio />}
                label="Orientadora"
              />

              <Tooltip title="(Que gosta de orientar, ensinar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Reflexiva"
                control={<BpRadio />}
                label="Reflexiva"
              />

              <Tooltip title="(Que reflete, ponderada, prudente)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step34 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 34 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step active>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp34}
            onChange={e => {
              setResp34(e.target.value);
              setStep(35);
            }}
          >
            <Card>
              <FormControlLabel
                value="Independente"
                control={<BpRadio />}
                label="Independente"
              />

              <Tooltip title="(Que não depende de ninguém, livre, com autonomia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Sociavel"
                control={<BpRadio />}
                label="Sociável"
              />

              <Tooltip title="(Que prefere estar entre amigos, que age com cortesia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Solitaria"
                control={<BpRadio />}
                label="Solitária"
              />

              <Tooltip title="(Que normalmente está só, sem companhia)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Apoiadora"
                control={<BpRadio />}
                label="Apoiadora"
              />

              <Tooltip title="(Que dá apoio, dá suporte aos outros)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step35 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 35 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step active>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp35}
            onChange={e => {
              setResp35(e.target.value);
              setStep(36);
            }}
          >
            <Card>
              <FormControlLabel
                value="Resiliente"
                control={<BpRadio />}
                label="Resiliente"
              />

              <Tooltip title="(Que se adapta às alterações, supera as dificuldades)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Otimista"
                control={<BpRadio />}
                label="Otimista"
              />

              <Tooltip title="(Que enxerga as coisas pelo lado bom, positiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Relutante"
                control={<BpRadio />}
                label="Relutante"
              />

              <Tooltip title="(Que resiste, que não aceita, teimosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Adaptavel"
                control={<BpRadio />}
                label="Adaptável"
              />

              <Tooltip title="(Que se adapta com facilidade, flexível)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step36 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 36 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step active>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp36}
            onChange={e => {
              setResp36(e.target.value);
              setStep(37);
            }}
          >
            <Card>
              <FormControlLabel
                value="Obstinada"
                control={<BpRadio />}
                label="Obstinada"
              />

              <Tooltip title="(Persistente, que não desiste)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Motivadora"
                control={<BpRadio />}
                label="Motivadora"
              />

              <Tooltip title="(Animadora, que incentiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Cética"
                control={<BpRadio />}
                label="Cética"
              />

              <Tooltip title="(Que não acredita em nada, que tende a duvidar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Leal"
                control={<BpRadio />}
                label="Leal"
              />

              <Tooltip title="(Que cumpre com as promessas que faz, responsável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step37 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 37 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step active>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp37}
            onChange={e => {
              setResp37(e.target.value);
              setStep(38);
            }}
          >
            <Card>
              <FormControlLabel
                value="Assertiva"
                control={<BpRadio />}
                label="Assertiva"
              />

              <Tooltip title="(Que fala o que pensa sem ser agressiva ou passiva)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Democratica"
                control={<BpRadio />}
                label="Democrática"
              />

              <Tooltip title="(Que preza pela opinião do todo, que decide baseada na maioria)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Hesitante"
                control={<BpRadio />}
                label="Hesitante"
              />

              <Tooltip title="(Indecisa, dividida)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Respeitosa"
                control={<BpRadio />}
                label="Respeitosa"
              />

              <Tooltip title="(Que trata os outros com respeito, com consideração)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step38 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 38 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step active>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp38}
            onChange={e => {
              setResp38(e.target.value);
              setStep(39);
            }}
          >
            <Card>
              <FormControlLabel
                value="Dinamica"
                control={<BpRadio />}
                label="Dinâmica"
              />

              <Tooltip title="(Que está sempre em movimento, em evolução)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Empolgada"
                control={<BpRadio />}
                label="Empolgada"
              />

              <Tooltip title="(Animada, entusiasmada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Precisa"
                control={<BpRadio />}
                label="Precisa"
              />

              <Tooltip title="(Que dificilmente erra, vai direto ao ponto certo)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Cuidadosa"
                control={<BpRadio />}
                label="Cuidadosa"
              />

              <Tooltip title="(Que tem cuidado com o que faz, minuciosa)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step39 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 39 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step active>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp39}
            onChange={e => {
              setResp39(e.target.value);
              setStep(40);
            }}
          >
            <Card>
              <FormControlLabel
                value="Direcionadora"
                control={<BpRadio />}
                label="Direcionadora"
              />

              <Tooltip title="(Que direciona, aponta, conduz)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Participativa"
                control={<BpRadio />}
                label="Participativa"
              />

              <Tooltip title="(Que gosta de interagir, participar)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Reservada"
                control={<BpRadio />}
                label="Reservada"
              />

              <Tooltip title="(Discreta, calada)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Controladora"
                control={<BpRadio />}
                label="Controladora"
              />

              <Tooltip title="(Que exerce controle, que gosta das coisas do seu jeito)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step40 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de perfil comportamental - Passo 40 de 40
      </SubLabel>
      <Title>Sou uma pessoa mais...</Title>
      <DescriptionGroup>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step active>
          <p>40</p>
        </Step>
      </DescriptionGroup>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={resp40}
            onChange={e => setResp40(e.target.value)}
          >
            <Card>
              <FormControlLabel
                value="Impaciente"
                control={<BpRadio />}
                label="Impaciente"
              />

              <Tooltip title="(Que não demonstra paciência, apressada, irritável)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Consultora"
                control={<BpRadio />}
                label="Consultora"
              />

              <Tooltip title="(Que orienta, dá pareceres)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Ponderada"
                control={<BpRadio />}
                label="Ponderada"
              />

              <Tooltip title="(Equilibrada, serena, sensata)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
            <Card>
              <FormControlLabel
                value="Autoridade"
                control={<BpRadio />}
                label="Autoridade"
              />

              <Tooltip title="(Que possui poder para ordenar, que possui conhecimento)">
                <Icon width="25px" src={Info} alt="Informações" />
              </Tooltip>
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <ContainerButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
        <Button
          type="button"
          width="140px"
          fontSize="13px"
          text="Concluir teste"
          hoverColor={colors.lightBlue}
          color={colors.white}
          bgColor={colors.lightBlue}
          onClick={sendTest}
        />
      </ContainerButton>
    </Group>
  );

  const steps = [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
    step25,
    step26,
    step27,
    step28,
    step29,
    step30,
    step31,
    step32,
    step33,
    step34,
    step35,
    step36,
    step37,
    step38,
    step39,
    step40,
  ];
  useEffect(() => {
    getTests();
  }, [getTests]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <TestsModal
        isOpen={modal}
        onRequestClose={onCloseModal}
        minutes={time}
        test="cultura"
        startReport={startReport}
        startTest={startTest}
      />
      {steps[step - 1]}
    </Container>
  );
}

export default StartBehavioral;
