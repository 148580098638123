import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useLocation } from 'react-router';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import Visualization from '../../assets/img/visualization.PNG';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import StoreContext from '../../components/Store/Context';
import Graph from '../../assets/img/Graph.svg';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import ErrorModal from '../../components/ErrorModal';
import { useQuery } from '../../utils/searchQuery';

import {
  Container,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  GroupAccordion,
  GroupText,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group,
} from './styles';

function CultureReport() {
  const { token } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [expanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [report, setReport] = useState([]);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [radar, setRadar] = useState({});
  const [valueNote, setValueNote] = useState(0);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [comentary, setComentary] = useState('');
  const location = useLocation();
  const query = useQuery();

  const onSend = async () => {
    const body = {
      teste_id: report.ID,
      nps: valueNote,
      comentario: comentary,
    };

    await api
      .post('/nps', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setSend(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const handleComentary = async e => {
    e.preventDefault();
    setComentary(e.target.value);
  };

  const getTest = useCallback(async () => {
    const body = {};
    if (location.state) {
      body.email = decodeURIComponent(location.state.activeUser);
    } else {
      body.email = decodeURIComponent(query.get('activeUser') ?? token.email);
    }
    await api
      .post('/tests', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.cultural;
        setReport(data);
        setRadar({
          series: [
            {
              name: 'Cultura',
              data: [
                data.PROPOSITO,
                data.ACOLHIMENTO,
                data.ORDEM,
                data.SEGURANÇA,
                data.AUTORIDADE,
                data.RESULTADOS,
                data.PRAZER,
                data.APRENDIZADO,
              ],
            },
          ],
          tooltip: {
            enabled: true,
          },
          options: {
            colors: ['#95D45A'],
            chart: {
              height: 350,
              type: 'radar',
              fontFamily: 'Poppins',
            },
            title: {
              text: '',
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '14px',
                },
              },
              color: [colors.darkBlue],
              categories: [
                'Propósito',
                'Acolhimento',
                'Ordem',
                'Segurança',
                'Autoridade',
                'Resultados',
                'Prazer',
                'Aprendizado',
              ],
            },
          },
        });
        setIsVisible(true);
      })
      .catch(() => {
        setError(true);
      });
  }, [location.state, query, token]);

  const handleChangeNote = value => {
    setValueNote(value);
    if (value === 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value === 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value === 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setSend(false);
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setWidth(window.screen.width);
    getTest();
  }, [width, location, getTest]);

  return (
    <Container>
      <ResponsiveAppBar />
      {/* <HeaderUser width={width} /> */}
      {/* <Header>
        <Logo src={logo} alt="iGrown" />
      </Header> */}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <AlertModal
        isOpen={send}
        msg="Avaliação enviada com sucesso!"
        onRequestClose={onCloseAlert}
      />
      <Title>Relatório de Cultura iGrown</Title>
      <ContainerCard top="20%">
        <Row>
          <DescriptionData weight="400">Última atualização em</DescriptionData>
          <DescriptionData>
            &nbsp;
            {report ? moment(report.DATA_DA_ENTRADA).format('DD/MM/YYYY') : ''}
          </DescriptionData>
        </Row>
      </ContainerCard>
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Hey, iGrowner!</SubTitle>
            <DescriptionData font="14px" weight="400">
              Aqui está o seu Relatório de Cultura iGrown. Nele, você verá qual
              foi o seu estilo cultural predominante, de acordo com as suas
              respostas, e com qual cultura organizacional você possui maior
              aderência. Por fim, irá conhecer o que é cultura e quais são os
              estilos culturais existentes.
            </DescriptionData>
            <DescriptionData font="14px">
              Por que esse conteúdo é importante?
            </DescriptionData>
            <LabelData font="14px" weight="400">
              A cultura está ligada às atitudes e comportamentos que moldam o
              grupo, define o que é encorajado, desencorajado, aceito ou
              rejeitado. Quando adequadamente alinhada aos valores pessoais,
              tendências e necessidades individuais,
            </LabelData>
            <LabelData font="14px">
              &nbsp; a cultura libera enorme energia para um propósito comum e
              estimula a capacidade da organização e das pessoas envolvidas em
              prosperar.
            </LabelData>
            <DescriptionData font="14px">
              Aproveite e #boradecolar!
            </DescriptionData>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>
              O que é cultura e quais são os estilos culturais existentes?
            </SubTitle>
            <LabelData font="14px" weight="400">
              Para entender ainda melhor esse relatório, é importante que você
              saiba o que queremos dizer quando falamos em cultura e quais são
              os estilos culturais possíveis de serem mapeados atualmente. De
              forma geral, podemos traduzir cultura como os
            </LabelData>
            <LabelData font="14px">
              &nbsp;valores e crenças que norteiam o comportamento social
            </LabelData>
            <LabelData font="14px" weight="400">
              , (as regras não escritas). Ela molda atitudes e comportamentos de
              forma ampla e duradoura.
            </LabelData>
          </GroupItems>
          <GroupAccordion top="2px">
            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Sobre as normas culturais
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      Elas definem o que é encorajado, desencorajado, aceito ou
                      rejeitado dentro do grupo. Quando adequadamente alinhada
                      aos valores pessoais, tendências e necessidades, a cultura
                      libera enorme energia para um propósito comum e estimula a
                      capacidade do indivíduo e da organização de prosperarem.
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>
          </GroupAccordion>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Relatório cultural</SubTitle>
            <DescriptionData font="14px">
              De acordo com as suas respostas, a característica que você mais
              valoriza em um ambiente é:
            </DescriptionData>
            <GroupItems padding="2%">
              {width > 530 ? (
                <Row top="0%">
                  <Group width="100%">
                    <SubCard
                      top="2%"
                      width="100%"
                      heigth="auto"
                      background={colors.lightBlue}
                      border={colors.lightBlue}
                    >
                      <GroupItems padding="5%">
                        <SubTitle font="16px" color={colors.white}>
                          {report.CULTURA_PREDOMINANTE}
                        </SubTitle>
                        <LabelData
                          color={colors.white}
                          font="14px"
                          weight="400"
                        >
                          {report.A_CULTURA}
                        </LabelData>
                      </GroupItems>
                    </SubCard>
                  </Group>
                </Row>
              ) : (
                <Group width="100%">
                  <SubCard
                    top="2%"
                    width="100%"
                    heigth="auto"
                    background={colors.lightBlue}
                    border={colors.lightBlue}
                  >
                    <GroupItems padding="5%">
                      <SubTitle font="16px" color={colors.white}>
                        {report.CULTURA_PREDOMINANTE}
                      </SubTitle>
                      <LabelData color={colors.white} font="14px" weight="400">
                        {report.A_CULTURA}
                      </LabelData>
                    </GroupItems>
                  </SubCard>
                </Group>
              )}
            </GroupItems>
            <SubTitle>
              A composição de suas culturas pode ser representada da seguinte
              forma:
            </SubTitle>
            <DescriptionData font="16px" weight="400">
              Os elementos da cultura de uma organização que, nesse momento, são
              mais relevantes são apresentadas no gráfico a seguir. Quando mais
              distante do centro estiver o ponto, maior a importância desse
              elemento para você.
            </DescriptionData>
            {isVisible ? (
              <Chart
                options={radar.options}
                series={radar.series}
                type="radar"
                height={600}
              />
            ) : (
              false
            )}
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="0%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>
              Quais outras características estão relacionadas a essa cultura?
            </SubTitle>
            <LabelData font="14px" weight="400">
              Veja, abaixo, algumas características gerais que estão diretamente
              ligadas à sua cultura dominante.ode ver, além de seus estilos
              predominantes, outros elementos que, de alguma forma, estiveram
              presentes em suas respostas.
            </LabelData>
          </GroupItems>
          <GroupAccordion top="2px">
            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Interações interpessoais
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report.INTERACOES_INTERPESSOAIS}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Resposta à mudanças
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report.RESPOSTA_A_MUDANÇA}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Prós da cultura
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report.PROS_DA_CULTURA}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Contras da cultura
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report.CONTRAS_DA_CULTURA}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>
            <SubCard
              top="2%"
              width="100%"
              heigth="auto"
              background={colors.darkBlue}
            >
              <GroupItems padding="2%">
                <DescriptionData color={colors.white} font="16px">
                  Frase que retrata o líder dessa cultura
                </DescriptionData>
                <DescriptionData
                  color={colors.white}
                  weight="400"
                  font="14px"
                  styles="italic"
                >
                  {report.FRASE_FAMOSA_QUE_RETRATA_O_LIDER_DESSA_CULTURA}
                </DescriptionData>
              </GroupItems>
            </SubCard>
          </GroupAccordion>
        </Card>
      </ContainerCard>

      <ContainerCard top="-20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Os 8 estilos culturais</SubTitle>
            <DescriptionData font="14px" weight="400">
              Um estudo realizado pela Harvard Business Review, e utilizado como
              modelo teórico para a avaliação de cultura iGrown, revelou duas
              dimensões principais que se aplicam a toda empresa,
              independentemente da área de atuação, porte, setor ou geografia.
              Clique nos ícones abaixo para conhecê-las.
            </DescriptionData>
          </GroupItems>
          <GroupAccordion top="2px">
            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Interações interpessoais
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      A orientação da organização para a coordenação e
                      interações interpessoais está contida num espectro que
                      varia de altamente independente a altamente
                      interdependente. As culturas que se inclinam para a
                      primeira valorizam a autonomia, ação individual e
                      competição. As que se inclinam para a última enfatizam a
                      integração, administrando relacionamentos e coordenando
                      esforços em grupo; e, nestas culturas, as pessoas tendem a
                      colaborar mais e veem o sucesso pelas lentes do grupo.
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Resposta à mudanças
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      A orientação da organização para a coordenação e
                      interações interpessoais está contida num espectro que
                      varia de altamente independente a altamente
                      interdependente. As culturas que se inclinam para a
                      primeira valorizam a autonomia, ação individual e
                      competição. As que se inclinam para a última enfatizam a
                      integração, administrando relacionamentos e coordenando
                      esforços em grupo; e, nestas culturas, as pessoas tendem a
                      colaborar mais e veem o sucesso pelas lentes do grupo.
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <DescriptionData font="16px" weight="400">
              Para entender a cultura, é preciso determinar onde o indivíduo e a
              empresa se situam entre essas duas dimensões.
            </DescriptionData>
            <br />
            <LabelData font="16px" weight="400">
              Aplicando esses fundamentos, foram identificados
            </LabelData>
            <LabelData font="16px">&nbsp;oito estilos</LabelData>
            <LabelData font="16px" weight="400">
              &nbsp;que se aplicam tanto à cultura organizacional, quanto aos
              indivíduos: acolhimento, propósito, aprendizado, prazer,
              resultados, autoridade, segurança e ordem. Na imagem abaixo,
              clique nos ícones para saber mais sobre cada um deles.
            </LabelData>
            <br />

            <img alt="visualizar" src={Visualization} width="100%" />
            <br />
            <LabelData font="16px" weight="400">
              Estilos próximos, como segurança e ordem ou aprendizado e prazer,
              coexistirão mais facilmente do que estilos que se encontram mais
              distantes na matriz acima, como autoridade e propósito ou
              segurança e aprendizado. Por isso, entender qual é o seu estilo
              cultural predominante e qual é a cultura de sua empresa e time de
              trabalho são fundamentais para
            </LabelData>
            <LabelData font="16px">
              &nbsp; aumentar a sinergia e a naturalidade com o qual o trabalho
              deve fluir, reduzindo desgastes e atritos.{' '}
            </LabelData>
            <br />
          </GroupAccordion>
        </Card>
      </ContainerCard>

      <ContainerCard top="-30%" bottom="0.5%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>
              De 1 a 10, quanto o resultado desse teste fez sentido para você?
            </SubTitle>
            <DescriptionGroup>
              <Note onClick={() => handleChangeNote(1)} active={note1}>
                <p>1</p>
              </Note>
              <Note onClick={() => handleChangeNote(2)} active={note2}>
                <p>2</p>
              </Note>
              <Note onClick={() => handleChangeNote(3)} active={note3}>
                <p>3</p>
              </Note>
              <Note onClick={() => handleChangeNote(4)} active={note4}>
                <p>4</p>
              </Note>
              <Note onClick={() => handleChangeNote(5)} active={note5}>
                <p>5</p>
              </Note>
              <Note onClick={() => handleChangeNote(6)} active={note6}>
                <p>6</p>
              </Note>
              <Note onClick={() => handleChangeNote(7)} active={note7}>
                <p>7</p>
              </Note>
              <Note onClick={() => handleChangeNote(8)} active={note8}>
                <p>8</p>
              </Note>
              <Note onClick={() => handleChangeNote(9)} active={note9}>
                <p>9</p>
              </Note>
              <Note onClick={() => handleChangeNote(10)} active={note10}>
                <p>10</p>
              </Note>
            </DescriptionGroup>
            <DescriptionGroup>
              <FormControl
                sx={{
                  backgroundColor: colors.white,
                  m: 1,
                  width: '100%',
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                }}
              >
                <TextField
                  autoWidth
                  size="small"
                  color="info"
                  disabled={false}
                  placeholder="Comente sua nota"
                  value={comentary}
                  onChange={handleComentary}
                  multiline
                  rows={8}
                  maxRows={8}
                  InputProps={{
                    sx: {
                      input: {
                        padding: 0.7,
                        '&::placeholder': {
                          fontSize: '12px',
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </DescriptionGroup>
            <ContainerButton>
              <Button
                type="button"
                width="140px"
                fontSize="13px"
                text="Enviar"
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onSend}
              />
            </ContainerButton>
          </GroupItems>
        </Card>
      </ContainerCard>
    </Container>
  );
}

export default CultureReport;
