import { useState, useEffect, useCallback, useContext } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import api from '../../api';
import StoreContext from '../Store/Context';
import { useMessage } from '../../contexts/MessageContext';

const validTypes = ['image/jpg', 'image/jpeg', 'image/png'];

export function AvatarEditor({
  id,
  setterId,
  setterUrl,
  onChangePhoto = () => {},
  userId,
  fileToUpload = null,
  uploadMode = 'DEFAULT',
}) {
  const [loadingFile, setLoadingFile] = useState(false);
  const { token } = useContext(StoreContext);
  const { showError } = useMessage();

  const isAuthorizedType = useCallback(type => {
    return validTypes.includes(type);
  }, []);

  const isInvalidType = useCallback(
    newFile => {
      const { type } = newFile;
      if (isAuthorizedType(type)) {
        return false;
      }
      return true;
    },
    [isAuthorizedType],
  );

  const handleUploadPhoto = useCallback(
    async newFile => {
      setLoadingFile(true);
      try {
        if (isInvalidType(newFile)) {
          showError('Aceitamos apenas os formatos: jpg, jpeg e png');
          return;
        }

        const data = new FormData();
        data.append('file', newFile);

        const response = await api.post('/upload', data, {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        });

        const createdFile = response.data;
        setterId(createdFile.filename || '');
        setterUrl(createdFile.url || '');
        await api.put(
          '/dados-candidatos/update',
          {
            user_id: userId,
            foto: createdFile.url,
          },
          {
            headers: {
              Authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
        window.location.reload();
      } catch (err) {
        showError('Ocorreu um erro ao atualizar a foto. Tente novamente.');
      } finally {
        setLoadingFile(false);
      }
    },
    [isInvalidType, setterId, setterUrl, showError, token, userId],
  );

  const onChangeFile = useCallback(
    async event => {
      if (event.target.files && event.target.files[0]) {
        const newFile = event.target.files[0];
        if (isInvalidType(newFile)) {
          showError('Aceitamos apenas os formatos: jpg, jpeg e png');
          return;
        }

        if (uploadMode === 'DEFAULT') {
          handleUploadPhoto(newFile);
        }

        if (onChangePhoto) {
          onChangePhoto(newFile);
        }
      }
    },
    [handleUploadPhoto, isInvalidType, onChangePhoto, showError, uploadMode],
  );

  useEffect(() => {
    if (fileToUpload && uploadMode === 'BY_FILE_TO_UPLOAD_CHANGE') {
      handleUploadPhoto(fileToUpload);
    }
  }, [fileToUpload, handleUploadPhoto, uploadMode]);

  if (loadingFile) return <CircularProgress />;

  return (
    <Tooltip title="Editar imagem">
      <IconButton component="label">
        <input
          style={{
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1,
          }}
          id={id}
          name={id}
          type="file"
          onChange={onChangeFile}
        />
        <PhotoCameraIcon />
      </IconButton>
    </Tooltip>
  );
}
