import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextField, FormControl } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import Arrow from '../../assets/img/Arrow.svg';
import Table from '../../components/Table';
import MenuData from '../../components/Menu/MenuCompany';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import { removeAccentuation } from '../../utils/removeAccentuation';
import ErrorModal from '../../components/ErrorModal';

import {
  Container,
  Label,
  Card,
  ContainerCard,
  Icon,
  Value,
  GroupItems,
  Group,
} from './styles';

function Dashboard() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [error, setError] = useState(false);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [data, setData] = useState([]);
  const [active, setActive] = useState('--');
  const [users, setUsers] = useState('--');
  const [plans, setPlans] = useState('--');
  const [object, setObject] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [companyName, setCompanyName] = useState([]);

  const changeDetails = async newData => {
    setJsonDetails(newData);
    setDetails(!details);
  };

  const onClose = async () => {
    setDetails(!details);
  };

  const handleName = async e => {
    e.preventDefault();
    setCompanyName(e.target.value);
  };

  const onFilter = async e => {
    await handleName(e);
    setIsVisible(false);

    if (companyName.length > 0) {
      const textData = removeAccentuation(companyName.toUpperCase().trim());

      const newData = object.filter(item => {
        const itemData = removeAccentuation(
          item.nome_fantasia.toUpperCase().trim(),
        );

        return itemData.indexOf(textData) > -1;
      });
      const json = [];
      newData.forEach(item => {
        json.push({
          id: item.id,
          name: item.nome_fantasia,
          plan: item.nome_plano,
          status: item.status === 'S' ? 'Ativo' : 'Inativo',
          details: item,
        });
      });
      setData(json);
    } else {
      setData(arrayholder);
    }
    setIsVisible(true);
  };

  const getData = useCallback(
    async id => {
      if (id === 8) {
        await api
          .get('/companies', {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          })
          .then(async response => {
            const responseData = response.data.response;
            setObject(responseData);
            const json = [];
            responseData.forEach(item => {
              json.push({
                id: item.id,
                name: item.nome_fantasia,
                plan: item.nome_plano,
                status: item.status === 'S' ? 'Ativo' : 'Inativo',
                details: item,
              });
            });
            const num = responseData.filter(item => {
              return item.status === 'S';
            });
            const newPlans = responseData.map(item => {
              return item.nome_plano;
            });
            const numPlan = [...new Set(newPlans)];
            setPlans(numPlan.length);
            setActive(num.length);
            setData(json);
            setArrayholder(json);
            setIsVisible(true);
          })
          .catch(() => {
            setError(true);
          });
      } else {
        setObject(userGlobal);
        const json = [];
        userGlobal.forEach(item => {
          json.push({
            id: item.id,
            name: item.nome_fantasia,
            plan: item.nome_plano,
            status: item.status === 'S' ? 'Ativo' : 'Inativo',
            details: item,
          });
        });
        const num = userGlobal.filter(item => {
          return item.status === 'S';
        });
        // let numPlan = _.keys(_.countBy(data, function(data) { return data.nome_plano; }));

        const newPlans = userGlobal.map(item => {
          return item.nome_plano;
        });
        const numPlan = [...new Set(newPlans)];
        setPlans(numPlan.length);
        setActive(num.length);
        setData(json);
        setArrayholder(json);
        setIsVisible(true);
      }
    },
    [token, userGlobal],
  );

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const responseData = response.data.users;
        setUsers(responseData.length);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const onCloseError = () => {
    setError(false);
  };

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.plan}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.status}
      </th>
      <th key={index}>{item.button}</th>
    </>
  );

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.plan}</td>
      <td className={width <= 570 ? 'hidden' : ''}>{item.status}</td>
      <td>
        <Button
          type="button"
          width="80px"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          fontSize="12px"
          height="30px"
          margin="0px"
          text="Detalhes"
          onClick={() => changeDetails(item.details)}
        />
      </td>
    </tr>
  );

  const companies = {
    head: [
      { name: 'Nome da empresa', plan: 'Plano', status: 'Status', button: ' ' },
    ],
    body: data,
  };

  const fieldRow = width <= 570 ? '80%' : 1030;

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      getData(profileGlobal.perfil_id);
      getUsers();
      setWidth(window.screen.width);
    }
  }, [width, profileGlobal, getData, getUsers]);

  return (
    <Container>
      {details && (
        <MenuData
          isOpen={details}
          onClose={onClose}
          title="Detalhes da Empresa"
          width={width}
          data={jsonDetails}
        />
      )}
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ResponsiveAppBar />
      <ContainerCard>
        <Card width="32%">
          <GroupItems>
            <Label>Empresas ativas</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{active}</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>Usuários ativos</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{users}</Value>
          </GroupItems>
        </Card>
        <Card width="32%">
          <GroupItems>
            <Label>Planos Contratados</Label>
            <Icon src={Arrow} alt="Next" />
            <Value>{plans}</Value>
          </GroupItems>
        </Card>
      </ContainerCard>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <FormControl
            sx={{
              backgroundColor: colors.gray6,
              m: 1,
              width: fieldRow,
              fontSize: '12px',
              fontFamily: 'Poppins',
              fontStyle: 'normal',
            }}
          >
            <TextField
              autoWidth
              size="small"
              color="info"
              disabled={false}
              placeholder="Procurar por nome de empresa"
              value={companyName}
              onChange={onFilter}
              InputProps={{
                sx: {
                  input: {
                    padding: 0.7,
                    '&::placeholder': {
                      fontSize: '12px',
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Group>
      </Card>
      <Card>
        <Label>Últimas empresas</Label>
        <Table
          limit="5"
          headData={companies.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={companies.body}
          renderBody={(item, index) => renderBody(item, index)}
          isLoading={!isVisible}
        />
      </Card>
    </Container>
  );
}

export default Dashboard;
