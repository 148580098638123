import { Alert, Snackbar } from '@mui/material';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const MessageContext = createContext();

function MessageProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    message: '',
    severity: 'success',
  });

  const showError = useCallback(message => {
    setData({
      severity: 'error',
      message,
    });
    setOpen(true);
  }, []);

  const closeMessage = useCallback(() => {
    setOpen(false);
  }, []);

  const showMessage = useCallback(message => {
    setData({
      severity: 'success',
      message,
    });
    setOpen(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      showError,
      showMessage,
    }),
    [showError, showMessage],
  );

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
      <Snackbar
        onClose={closeMessage}
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert onClose={closeMessage} severity={data.severity}>
          {data.message}
        </Alert>
      </Snackbar>
    </MessageContext.Provider>
  );
}

const useMessage = () => {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('useMessage must be used within an MessageProvider');
  }

  return context;
};

export { MessageProvider, useMessage };
