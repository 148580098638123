import { createTheme } from '@mui/material';
import styled from 'styled-components';
import { blue, green } from '@mui/material/colors';
import colors from '../styles/colors';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.lightBlue,
    },
  },
  typography: {
    fontFamily: 'Poppins',
    useNextVariants: true,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'outlined-green',
          },
          style: {
            backgroundColor: 'transparent',
            border: 'solid 2px',
            borderColor: colors.lightGreen,
            color: colors.lightGreen,
            ':hover': {
              border: 'solid 2px',
              backgroundColor: green[50],
            },
          },
        },
        {
          props: {
            variant: 'negative',
          },
          style: {
            backgroundColor: colors.gray6,
            color: colors.darkBlue,
            ':hover': {
              backgroundColor: colors.gray5,
            },
          },
        },
      ],
      styleOverrides: {
        contained: {
          color: colors.white,
        },
        outlined: {
          backgroundColor: 'transparent',
          border: 'solid 2px',
          ':hover': {
            border: 'solid 2px',
            backgroundColor: blue[50],
          },
        },
        root: {
          fontFamily: 'Poppins',
          fontWeight: 600,
          textTransform: 'none',
          borderRadius: '2rem',
          padding: '0.5rem 2rem 0.5rem 2rem',
          transition: '0.5s',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem',
          backgroundColor: colors.gray2,
        },
      },
    },
  },
});

export const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  /* transition: 0.8s ease-in-out */
`;

export const LayoutContent = styled.div`
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-left: 0;
  }
`;

export const LayoutContentMain = styled.div`
  margin: 0 auto;
  margim-bottom: 10%;
`;
