import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// COMPONENTS
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import StoreContext from '../components/Store/Context';

// PAGES
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';
import ActiveCompanies from '../pages/ActiveCompanies';
import ActiveUsers from '../pages/ActiveUsers';
import NPS from '../pages/NPS';
import Plans from '../pages/Plans';
import Behavioral from '../pages/Behavioral';
import StartBehavioral from '../pages/StartBehavioral';
import Culture from '../pages/Culture';
import StartCulture from '../pages/StartCulture';
import Career from '../pages/Career';
import StartCareer from '../pages/StartCareer';
import BehavioralReport from '../pages/BehavioralReport';
import CareerReport from '../pages/CareerReport';
import CultureReport from '../pages/CultureReport';
import CompanyDashboard from '../pages/CompanyDashboard';
import TeamDashboard from '../pages/TeamDashboard';
import JobPanel from '../pages/JobPanel';
import StartHardskill from '../pages/StartHardskill';
import CalibrateHardSkills from '../pages/CalibrateHardskills';
import JobsApplied from '../pages/JobsApplied';
import Profile from '../pages/Profile';
import CareerPanel from '../pages/CareerPanel';
import FitPersona from '../pages/FitPersona';
import FitCollaborators from '../pages/FitCollaborators';
import CalibrateHardSkillsCareer from '../pages/CalibrateHardskillsCareer';

// STYLES
import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  muiTheme,
} from './styles';

import { lightTheme } from '../styles/theme';

// UTILS
import ScrollToTop from '../utils/ScrollToTop';

function Layout() {
  const { token, setToken } = useContext(StoreContext);

  const history = useHistory();
  if (token === '') {
    setToken(null);
    history.push('/login');
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <MaterialThemeProvider theme={muiTheme}>
        <LayoutContainer>
          <LayoutContent>
            <LayoutContentMain>
              <ScrollToTop />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/painelAdmin" component={Dashboard} />
                <Route
                  exact
                  path="/painelDeCarreiras"
                  component={CareerPanel}
                />
                <Route
                  exact
                  path="/empresasAtivas"
                  component={ActiveCompanies}
                />
                <Route exact path="/usuariosAtivos" component={ActiveUsers} />
                <Route exact path="/planosContratados" component={Plans} />
                <Route exact path="/nps" component={NPS} />
                <Route
                  exact
                  path="/testeComportamental"
                  component={Behavioral}
                />
                <Route
                  exact
                  path="/progressoTesteComportamental"
                  component={StartBehavioral}
                />
                <Route
                  exact
                  path="/relatorioComportamental"
                  component={BehavioralReport}
                />
                <Route exact path="/testeCultura" component={Culture} />
                <Route
                  exact
                  path="/progressoTesteCultura"
                  component={StartCulture}
                />
                <Route
                  exact
                  path="/relatoriodeCultura"
                  component={CultureReport}
                />
                <Route exact path="/fitPersona" component={FitPersona} />
                <Route exact path="/testeCarreira" component={Career} />
                <Route
                  exact
                  path="/progressoTesteCarreira"
                  component={StartCareer}
                />
                <Route
                  exact
                  path="/relatorioDeValoresDeCarreira"
                  component={CareerReport}
                />
                <Route
                  exact
                  path="/painelDaEmpresa"
                  component={CompanyDashboard}
                />
                <Route exact path="/painelDoTime" component={TeamDashboard} />
                <Route exact path="/painelDeVagas" component={JobPanel} />
                <Route exact path="/hardskill" component={StartHardskill} />
                <Route
                  exact
                  path="/calibrarHardskill"
                  component={CalibrateHardSkills}
                />
                <Route
                  exact
                  path="/calibrarHardskillCarreira"
                  component={CalibrateHardSkillsCareer}
                />
                <Route exact path="/minhasCarreiras" component={JobsApplied} />
                <Route exact path="/perfil" component={Profile} />
                <Route
                  exact
                  path="/fitColaboradores"
                  component={FitCollaborators}
                />
              </Switch>
            </LayoutContentMain>
          </LayoutContent>
        </LayoutContainer>
      </MaterialThemeProvider>
    </ThemeProvider>
  );
}

export default Layout;
