import React from 'react';

import { CircularProgress } from '@mui/material';
import { Btn, BtnRow } from './styles';

function Button({
  bgColor,
  color,
  width,
  hoverColor,
  margin,
  hoverBorder,
  onClick,
  border,
  className,
  disabled,
  isLoading,
  text,
  loadingText,
  fontSize,
  height,
  icon,
  id,
  key,
}) {
  return (
    <Btn
      type="submit"
      bgColor={bgColor}
      id={id}
      key={key}
      color={color}
      height={height}
      width={width}
      hoverColor={hoverColor}
      margin={margin}
      fontSize={fontSize}
      hoverBorder={hoverBorder}
      onClick={onClick}
      border={border}
      className={className}
      disabled={disabled}
      text={text}
      loadingText={loadingText}
      isLoading={isLoading}
    >
      {icon || ''}
      {!isLoading && <span>{text}</span>}
      {isLoading && (
        <BtnRow>
          {/* <span>{loadingText}</span> */}
          <CircularProgress size="18px" color="inherit" />
        </BtnRow>
      )}
    </Btn>
  );
}

export default Button;
