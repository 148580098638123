import React, { useState, useContext, useEffect, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from '../../styles/colors';
import logo from '../../assets/img/logo.svg';
import logoPNG from '../../assets/img/logo.png';
import { Logo } from './styles';
import LogoutModal from '../LogoutModal';
import StoreContext from '../Store/Context';
import api from '../../api';
import { UserRole } from '../../utils/enums';
import { useAuth } from '../../contexts/AuthContext';

function ResponsiveAppBar() {
  const history = useHistory();
  const { token, setUserGlobal, setProfileGlobal } = useContext(StoreContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElItem, setAnchorElItem] = useState(null);
  const [data, setData] = useState([]);
  const [logout, setLogout] = useState(false);
  const { userRole, setUserRole } = useAuth();
  const { signOut } = useAuth();
  const [userAvatar, setUserAvatar] = useState('');
  const [userFirstLetters, setUserFirstLetters] = useState('');

  const pagesMobile = [
    {
      name: 'Painel iGrown',
      url: '/',
    },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Painel Admin',
          url: '/painelAdmin',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Painel de Carreiras',
          url: '/painelDeCarreiras',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Painel iGrown-X',
          url: '/painelDaEmpresa',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Detalhes do Time',
          url: '/painelDoTime',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Painel de Vagas',
          url: '/painelDeVagas',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Comparativo Fit Persona',
          url: '/fitPersona',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Comparativo Fit Colaboradores',
          url: '/fitColaboradores',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Empresas ativas',
          url: '/empresasAtivas',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Usuário ativos',
          url: '/usuariosAtivos',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'Planos contratados',
          url: '/planosContratados',
        },
    userRole !== UserRole.MASTER_ADMIN &&
    userRole !== UserRole.COMPANY_ADMIN &&
    userRole !== UserRole.CONSULTANT
      ? false
      : {
          name: 'NPS',
          url: '/nps',
        },
  ].filter(item => item);

  const settings = [
    {
      name: 'Perfil',
      url: '/perfil',
    },
    data.comportamental
      ? {
          name: 'Relatório comportamental',
          url: '/relatorioComportamental',
        }
      : false,
    data.valores
      ? {
          name: 'Relatório de âncoras de carreira',
          url: '/relatorioDeValoresDeCarreira',
        }
      : false,
    data.cultural
      ? {
          name: 'Relatório de cultura',
          url: '/relatorioDeCultura',
        }
      : false,
    {
      name: 'Sair',
      url: 'Logout',
    },
  ];
  const pagesWeb = [];

  if (userRole === UserRole.COMPANY_ADMIN || userRole.CONSULTANT) {
    pagesWeb.push({
      name: 'Painel da Empresa',
      pages: [
        {
          name: 'Painel de Carreiras',
          url: '/painelDeCarreiras',
        },
        {
          name: 'Painel iGrown-X',
          url: '/painelDaEmpresa',
        },
        {
          name: 'Detalhes do Time',
          url: '/painelDoTime',
        },
        {
          name: 'Painel de Vagas',
          url: '/painelDeVagas',
        },
        {
          name: 'Comparativo Fit Persona',
          url: '/fitPersona',
        },
        {
          name: 'Comparativo Fit Colaboradores',
          url: '/fitColaboradores',
        },
      ],
    });
  }

  if (userRole === 8) {
    pagesWeb.push({
      name: 'Painel da Empresa',
      pages: [
        {
          name: 'Painel Admin',
          url: '/painelAdmin',
        },
        {
          name: 'Painel de Carreiras',
          url: '/painelDeCarreiras',
        },
        {
          name: 'Painel iGrown-X',
          url: '/painelDaEmpresa',
        },
        {
          name: 'Detalhes do Time',
          url: '/painelDoTime',
        },
        {
          name: 'Painel de Vagas',
          url: '/painelDeVagas',
        },
        {
          name: 'Comparativo Fit Persona',
          url: '/fitPersona',
        },
        {
          name: 'Comparativo Fit Colaboradores',
          url: '/fitColaboradores',
        },
        {
          name: 'Empresas ativas',
          url: '/empresasAtivas',
        },
        {
          name: 'Usuário ativos',
          url: '/usuariosAtivos',
        },
        {
          name: 'Planos contratados',
          url: '/planosContratados',
        },
        {
          name: 'NPS',
          url: '/nps',
        },
      ],
    });
  }

  pagesWeb.push({
    name: 'Painel iGrown',
    url: '/',
  });

  const handleSignOut = () => {
    signOut();
  };

  const getTests = useCallback(async () => {
    if (token) {
      const body = {
        email: token?.email,
      };
      await api
        .post('/tests', body, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const testData = response.data;
          setData(testData);
        });
    }
  }, [token]);

  const getUser = useCallback(async () => {
    if (token) {
      const setGlobal = new Set();
      await api
        .get(`/users/${token.authenticated_user.user.id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async ({ data: { user } }) => {
          const userData = user.perfil;
          setProfileGlobal(userData);
          localStorage.setItem('userRole', userData?.perfil_id);
          setUserRole(userData?.perfil_id ?? 11);
          await api
            .get(`/users/empresa/${user.id}`, {
              headers: {
                authorization: `Bearer ${token.refreshToken}`,
              },
            })
            .then(async res => {
              const filter = res.data.data?.filter(item => {
                const duplicated = setGlobal.has(item.id);
                setGlobal.add(item.id);
                return !duplicated;
              });
              setUserGlobal(filter);
              setUserAvatar(user?.dados_adicionais?.foto);
              setUserFirstLetters(
                `${user?.name.split(' ')[0][0]}${user?.name.split(' ')[1][0]}`,
              );
            });
        });
    }
  }, [setProfileGlobal, setUserGlobal, setUserRole, token]);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenItemMenu = event => {
    setAnchorElItem(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseItemMenu = () => {
    setAnchorElItem(null);
  };

  const onRedirect = url => {
    if (url === 'Logout') {
      setLogout(true);
    } else {
      history.push({
        pathname: url,
      });
    }
  };

  useEffect(() => {
    getTests();
    getUser();
  }, [getTests, getUser]);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: colors.white,
      }}
    >
      <LogoutModal
        isOpen={logout}
        width={window.screen.width}
        onRequestClose={() => setLogout(false)}
        handleSignOut={handleSignOut}
      />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo
            web="block"
            mobile="none"
            src={logoPNG}
            alt="iGrown"
            onClick={() => onRedirect('/')}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: colors.lightBlue }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {userRole &&
                pagesMobile.map((page, index) => (
                  <MenuItem key={index} onClick={() => onRedirect(page.url)}>
                    <Typography
                      textAlign="center"
                      sx={{
                        my: 0,
                        color: colors.darkBlue,
                        display: 'block',
                        backgroundColor: colors.white,
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        textTransform: 'initial',
                        fontWeight: 400,
                        paddingTop: '5px',
                        paddingRight: '30px',
                      }}
                    >
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
            <Logo
              web="none"
              mobile="block"
              src={logo}
              alt="iGrown"
              onClick={() => onRedirect('/')}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {userRole &&
              pagesWeb.map((page, index) =>
                page.pages ? (
                  <div key={index}>
                    <Button
                      key={page.url}
                      onClick={handleOpenItemMenu}
                      sx={{
                        my: 0,
                        color: colors.darkBlue,
                        display: 'block',
                        backgroundColor: colors.white,
                        fontFamily: 'Poppins',
                        textTransform: 'initial',
                        fontWeight: 600,
                        paddingTop: '15px',
                        paddingRight: '30px',
                      }}
                    >
                      {page.name}
                      <ExpandMoreIcon
                        style={{ verticalAlign: 'top', marginLeft: '3px' }}
                      />
                    </Button>
                    <Menu
                      key={`${page.url}2`}
                      sx={{ mt: '45px' }}
                      id="menu-appbar3"
                      anchorEl={anchorElItem}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElItem)}
                      onClose={handleCloseItemMenu}
                    >
                      {userRole &&
                        page.pages.map((pg, idx) => (
                          <MenuItem
                            key={idx}
                            onClick={() => onRedirect(pg.url)}
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                my: 0,
                                color: colors.darkBlue,
                                display: 'block',
                                backgroundColor: colors.white,
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                textTransform: 'initial',
                                fontWeight: 400,
                                paddingTop: '5px',
                                paddingRight: '30px',
                              }}
                            >
                              {pg.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Menu>
                  </div>
                ) : (
                  <Button
                    key={page.url}
                    onClick={() => onRedirect(page.url)}
                    sx={{
                      my: 0,
                      color: colors.darkBlue,
                      display: 'block',
                      backgroundColor: colors.white,
                      fontFamily: 'Poppins',
                      textTransform: 'initial',
                      fontWeight: 600,
                      paddingTop: '15px',
                      paddingRight: '30px',
                    }}
                  >
                    {page.name}
                  </Button>
                ),
              )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={token.authenticated_user.user.name}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, my: 0 }}>
                <Avatar
                  src={userAvatar}
                  sx={{ backgroundColor: colors.darkBlue }}
                >
                  {userFirstLetters}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(
                (setting, index) =>
                  setting.url && (
                    <MenuItem
                      key={index}
                      onClick={() => onRedirect(setting.url)}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          my: 0,
                          color: colors.darkBlue,
                          display: 'block',
                          backgroundColor: colors.white,
                          fontFamily: 'Poppins',
                          fontSize: '12px',
                          textTransform: 'initial',
                          fontWeight: 400,
                          paddingTop: '5px',
                          paddingRight: '30px',
                        }}
                      >
                        {setting.name}
                      </Typography>
                    </MenuItem>
                  ),
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
