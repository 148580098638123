import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Shape = styled.img`
  position: relative;
  width: 30px !important;
  padding-left: 2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  margin-top: -15px;
  margin-bottom: 15px;
  margin-left: 70%;

  @media (max-width: 870px) {
    width: 25px !important;
    padding-left: 1%;
  }

  @media (max-width: 570px) {
    width: 20px !important;
    padding-left: 1%;
  }
`;

export const Item = styled.img`
  position: relative;
  width: 120px !important;
  padding-left: 2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  margin-top: -15px;
  margin-bottom: 15px;

  @media (max-width: 870px) {
    width: 110px !important;
    padding-left: 1%;
  }

  @media (max-width: 570px) {
    width: 100px !important;
    padding-left: 1%;
  }
`;
