import React from 'react';
import { slide as Slide } from 'react-burger-menu';
import { TextField } from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';

function MenuData({ width, isOpen, onClose, title, data }) {
  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <GroupLine>
        <Title>{title}</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Nome"
            variant="outlined"
            size="small"
            value={data.name}
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="E-mail"
            variant="outlined"
            size="small"
            value={data.email}
            color="info"
            disabled
          />
        </Group>
        {data.dados_adicionais ? (
          <>
            <Group>
              <TextField
                label="Ocupação principal"
                variant="outlined"
                size="small"
                value={
                  data.dados_adicionais.ocupacao_principal || 'Não informada'
                }
                color="info"
                disabled
              />
            </Group>
            <Group>
              <TextField
                label="Gênero"
                variant="outlined"
                size="small"
                value={data.dados_adicionais.genre || 'Não informado'}
                color="info"
                disabled
              />
            </Group>
          </>
        ) : (
          false
        )}
        <Group>
          <TextField
            label="Plano contratado"
            variant="outlined"
            size="small"
            value="Sem Plano"
            color="info"
            disabled
          />
        </Group>
        {/* <Card
                    width="230px"
                    bgColor={colors.yellow}
                    title='NPS'
                    description='teste comportamental'
                    value='68%'
                    right
                    left
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.lightGreen}
                    title='NPS'
                    description='teste cultura'
                    value='20%'
                    paddingLabel={"0%"}
                />
                <Card
                    width="230px"
                    bgColor={colors.alertRed}
                    title='NPS'
                    description='teste âncoras de carreira'
                    value='88%'
                    paddingLabel={"0%"}
                /> */}
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Ok, entendi"
          fontSize="12px"
          onClick={onClose}
        />
      </Container>
    </Slide>
  );
}

export default MenuData;
