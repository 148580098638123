import {
  Box,
  Divider,
  Drawer,
  LinearProgress,
  Paper,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { PersonaValue, SoftSkillLabel, Title, UserValue } from './styles';
import colors from '../../styles/colors';

const softSkillLabels = {
  APRENDIZADO_CONSTANTE: 'Aprendizado constante',
  FLEXIBILIDADE: 'Flexibilidade',
  GESTAO_DA_MUDANCA: 'Gestão da mudança',
  INOVACAO: 'Inovação',
  INTELIGENCIA_DIGITAL: 'Inteligência digital',
  INTRAEMPREENDEDORISMO: 'Intraempreendedorismo',
  MENTALIDADE_DE_STARTUP: 'Mentalidade de startup',
  PENSAMENTO_AGIL: 'Pensamento Ágil',
  RESILIENCIA: 'Resiliência',
  ACABATIVA: 'Acabativa',
  ENGAJAMENTO_CONTINUO: 'Engajamento contínuo',
  GESTAO_DA_QUALIDADE: 'Gestão da qualidade',
  GESTAO_DE_PROJETOS: 'Gestão de projetos',
  GESTAO_DO_TEMPO: 'Gestão do tempo',
  IDENTIFICACAO_DE_PROPOSITO: 'Identificação de propósito',
  MELHORIA_DE_DESEMPENHO: 'Melhoria de desempenho',
  ORIENTACAO_AO_RESULTADO: 'Orientação ao resultado',
  SENSO_DE_DONO: 'Senso de dono',
  VENDA_CONSULTIVA: 'Venda consultiva',
  CAPACIDADE_ANALITICA: 'Capacidade analítica',
  ESCUTA_ATIVA: 'Escuta ativa',
  GESTAO_DA_ANSIEDADE: 'Gestão da ansiedade',
  GESTAO_DO_CONHECIMENTO: 'Gestão do conhecimento',
  INTELIGENCIA_EMOCIONAL: 'Gestão da mudança',
  LIDERANCA_ESTRATEGICA: 'Liderança estratégica',
  ORIENTACAO_AO_NEGOCIO: 'Orientação ao negócio',
  PENSAMENTO_ESTRATEGICO: 'Pensamento estratégico',
  PLANEJAMENTO: 'Planejamento',
  VELOCIDADE_NA_TOMADA_DE_DECISAO: 'Velocidade na tomada de decisão',
  COMUNICACAO: 'Comunicação',
  CONSTRUCAO_DE_ALIANCAS: 'Construção de alianças',
  DESENVOLVIMENTO_DE_TALENTOS: 'Desenvolvimento de talentos',
  GESTAO_DE_PESSOAS: 'Gestão de pessoas',
  MENTALIDADE_INCLUSIVA: 'Mentalidade inclusiva',
  NEGOCIACAO: 'Negociação',
  ORIENTACAO_AO_CLIENTE: 'Orientação ao cliente',
  CONCESSAO_DE_FEEDBACK: 'Concessão de feedback',
  RELACIONAMENTO_INTERPESSOAL: 'Relacionamento interpessoal',
  SOLUCOES_DE_CONFLITOS: 'Soluções de conflitos',
  TRABALHO_EM_EQUIPE: 'Trabalho em equipe',
};

const PersonaLinearProgress = styled(LinearProgress)(() => ({
  marginTop: '8px',
  marginBottom: '8px',
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#a1dbec',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#07a5d5',
  },
}));

const UserLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  marginTop: '8px',
  marginBottom: '8px',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#eed1cc',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#fb9282',
  },
}));

export function SoftSkillDrawer({ isOpen, onClose, softSkills }) {
  return (
    <Drawer anchor="right" open={isOpen ?? false} onClose={onClose}>
      <Box sx={{ padding: '16px', minWidth: '320px' }}>
        <Title>Soft Skills</Title>
        <Divider sx={{ marginBottom: '16px' }} />
        {Object.keys(softSkills.user).map(key => (
          <Paper
            key={key}
            sx={{
              margin: '12px',
              padding: '8px',
              backgroundColor: colors.gray6,
            }}
          >
            <SoftSkillLabel>{softSkillLabels[key]}</SoftSkillLabel>
            <PersonaValue>Persona {softSkills.persona[key]}%</PersonaValue>
            <span>{' | '}</span>
            <UserValue>Teste {softSkills.user[key]}%</UserValue>
            <PersonaLinearProgress
              variant="determinate"
              value={softSkills.persona[key]}
            />
            <UserLinearProgress
              variant="determinate"
              value={softSkills.user[key]}
            />
          </Paper>
        ))}
      </Box>
    </Drawer>
  );
}
