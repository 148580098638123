import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useCallback, useContext, useEffect, useState } from 'react';
import WorkIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import { Section, SubTitle, Title } from './styles';
import api from '../../api';
import StoreContext from '../Store/Context';
import colors from '../../styles/colors';
import { CompanySelector } from '../CompanySelector';
import { useMessage } from '../../contexts/MessageContext';

export function EditUserDialog({ isOpen, onClose, user }) {
  const [careers, setCareers] = useState([]);
  const [careerId, setCareerId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [userCompanies, setUserCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const { token } = useContext(StoreContext);
  const { showMessage, showError } = useMessage();
  const [saving, setSaving] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [profile, setProfile] = useState(0);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      status: 'Ativo',
    },
    values: {
      name: user.name,
      status: user.status,
    },
  });

  const onSubmit = data => {
    setSaving(true);
    api
      .put(
        `/users/${user.id}`,
        {
          name: data.name,
          status: data.status === 'Ativo' ? 'S' : 'N',
        },
        {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        },
      )
      .then(() => {
        showMessage('Cadastro atualizado com sucesso!');
      })
      .catch(() => {
        showError('Ocorreu um erro ao editar o cadastro. Tente novamente.');
      })
      .finally(() => setSaving(false));
  };

  const getCompanies = useCallback(async () => {
    if (token && user && user.id) {
      const { data } = await api.get(`/users/empresa/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token.refreshToken}`,
        },
      });
      setUserCompanies(data.data);
    }
  }, [token, user]);

  const handleAddCompany = useCallback(async () => {
    setLoadingCompanies(true);
    try {
      if (selectedCompanyId) {
        await api.post(
          '/users/empresa',
          {
            user_id: user.id,
            company_id: selectedCompanyId,
          },
          {
            headers: {
              Authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
        if (careerId) {
          await api.post(
            `/user-carreiras`,
            {
              user_id: user.id,
              carreira_id: careerId,
              company_id: selectedCompanyId,
            },
            {
              headers: {
                Authorization: `Bearer ${token.refreshToken}`,
              },
            },
          );
        }
        await getCompanies();
      }
    } catch (err) {
      showError(
        'Ocorreu um erro ao adicionar a empresa. Tente novamente mais tarde.',
      );
    } finally {
      setLoadingCompanies(false);
    }
  }, [
    careerId,
    getCompanies,
    selectedCompanyId,
    showError,
    token.refreshToken,
    user.id,
  ]);

  const handleDeleteCompany = useCallback(
    async companyId => {
      setLoadingCompanies(true);
      await api({
        method: 'DELETE',
        url: '/users/empresa',
        data: {
          user_id: user.id,
          company_id: companyId,
        },
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      });
      await getCompanies();
      setLoadingCompanies(false);
    },
    [getCompanies, token.refreshToken, user.id],
  );

  const handleChangeProfile = e => {
    setProfile(e.target.value);
  };

  const handleSaveProfile = useCallback(async () => {
    setSavingProfile(true);
    await api
      .post(
        '/users/perfil',
        {
          user_id: user.id,
          perfil_id: +profile,
        },
        {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        },
      )
      .then(() => {
        showMessage('Perfil atualizado com sucesso!');
      })
      .catch(() => {
        showError(
          'Ocorreu um erro ao atualizar o perfil. Tente novamente mais tarde.',
        );
      })
      .finally(() => {
        setSavingProfile(false);
      });
  }, [profile, showError, showMessage, token, user.id]);

  useEffect(() => {
    if (isOpen) {
      api
        .get(`/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(({ data }) => {
          setProfile(data?.user?.perfil?.perfil_id ?? 0);
        });
    }
  }, [user, token, isOpen]);

  useEffect(() => {
    if (selectedCompanyId) {
      api
        .get(`/companies/carreiras/${selectedCompanyId}`, {
          headers: {
            Authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(({ data }) => setCareers(data));
    }
  }, [selectedCompanyId, token]);

  useEffect(() => {
    if (isOpen) {
      setLoadingCompanies(true);
      getCompanies().then(() => setLoadingCompanies(false));
    }
  }, [getCompanies, isOpen]);

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Title>Editar usuário</Title>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 1 }}>
          <Section>
            <SubTitle>Dados básicos</SubTitle>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={8}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Nome"
                      size="small"
                      error={error}
                      helperText={error && 'O nome é obrigatório'}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth size="small" error={error}>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        label="Status"
                        size="small"
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem value="Ativo">Ativo</MenuItem>
                        <MenuItem value="Inativo">Inativo</MenuItem>
                      </Select>
                      {error && (
                        <FormHelperText>Selecione um status</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={saving}
                sx={{ width: '120px', height: '2.75rem' }}
              >
                {saving ? <CircularProgress size="1.5rem" /> : 'Salvar'}
              </Button>
            </Box>
          </Section>
          <Section>
            <SubTitle>Perfil na plataforma</SubTitle>
            <FormControl>
              <FormLabel id="profile-label-group">
                Selecione o perfil do usuário na plataforma:
              </FormLabel>
              <RadioGroup
                aria-labelledby="profile-label-group"
                name="profile-group"
                value={profile}
                onChange={handleChangeProfile}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Sem acesso"
                />
                <FormControlLabel
                  value={8}
                  control={<Radio />}
                  label="Master Admin"
                />
                <FormControlLabel
                  value={9}
                  control={<Radio />}
                  label="Consultor"
                />
                <FormControlLabel
                  value={11}
                  control={<Radio />}
                  label="Usuário padrão"
                />
                <FormControlLabel
                  value={12}
                  control={<Radio />}
                  label="Admin cliente"
                />
              </RadioGroup>
            </FormControl>
            <Box
              sx={{ display: 'flex', justifyContent: 'end', marginTop: '1rem' }}
            >
              <Button
                variant="contained"
                onClick={handleSaveProfile}
                disabled={savingProfile}
                sx={{ width: '180px', height: '2.75rem' }}
              >
                {savingProfile ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  'Atualizar perfil'
                )}
              </Button>
            </Box>
          </Section>
          <Section>
            <SubTitle>Empresas vinculadas</SubTitle>
            {!loadingCompanies ? (
              <List>
                {userCompanies?.map((company, index) => (
                  <>
                    <ListItem
                      key={company.id}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon
                            sx={{ color: colors.alertRed }}
                            onClick={() => handleDeleteCompany(company.id)}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: colors.blue }}>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={company.nome_fantasia ?? company.razao_social}
                        secondary={company.carreiras
                          .map(car => car.nome_carreira)
                          .join(', ')}
                      />
                    </ListItem>
                    {index !== userCompanies.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </>
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  p: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <CompanySelector
                  label="Adicionar nova empresa"
                  onChange={company => setSelectedCompanyId(company?.id)}
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <FormControl fullWidth size="small">
                  <Select
                    size="small"
                    onChange={e => setCareerId(e.target.value)}
                  >
                    <MenuItem value={0}>
                      <em>Procurar por carreira</em>
                    </MenuItem>
                    {careers.length > 0
                      ? careers.map(item => {
                          return (
                            <MenuItem value={item.id}>{item.nome}</MenuItem>
                          );
                        })
                      : false}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                md={1}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleAddCompany}
                  disabled={!selectedCompanyId}
                >
                  +
                </Button>
              </Grid>
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, mb: 1 }}>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
