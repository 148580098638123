export const personaXpLevels = [
  'Sem experiência ou conhecimento teórico na área.',
  'Possui leve conhecimento teórico, está começando a estudar o tema.',
  'Entende conceitos fundamentais, mas não os aplicou ainda.',
  'Familiarizado com princípios e teorias, mas sem experiência prática.',
  'Realizou algumas atividades práticas na área sob supervisão.',
  'Confortável com tarefas básicas, tem alguma experiência prática.',
  'Capaz de executar tarefas comuns sem supervisão, ainda aprimorando habilidades.',
  'Pode orientar novatos e resolver problemas mais complexos.',
  'Familiarizado com aspectos avançados, capaz de desenvolver novas ideias ou práticas.',
  'Especialista, apto a contribuir com inovações ou novas metodologias.',
  'Referência na área, com histórico de realização e inovação.',
];

export const userXpLevels = [
  'Nunca atuei na área e não possuo conhecimento teórico.',
  'Leve conhecimento teórico.',
  'Conhecimento teórico básico.',
  'Nunca atuei na área, mas possuo conhecimento teórico.',
  'Alguma experiência limitada.',
  'Tenho alguma experiência, mas estou aprendendo.',
  'Experiência moderada, ainda aprimorando.',
  'Tenho experiência e consigo auxiliar outras pessoas.',
  'Muito experiente, conhecimento avançado.',
  'Especialista na área, apto a inovar.',
  'Tenho muita experiência e sou visto(a) como referência.',
];
