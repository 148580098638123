import React from 'react';
import { slide as Slide } from 'react-burger-menu';
import { TextField } from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';
import { cnpjMask } from '../../../utils/cnpjMask';

function MenuCompany({ width, isOpen, onClose, title, data }) {
  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <GroupLine>
        <Title>{title}</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Nome fantasia"
            variant="outlined"
            size="small"
            value={data.nome_fantasia}
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Razão social"
            variant="outlined"
            size="small"
            value={data.razao_social}
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="CNPJ"
            variant="outlined"
            size="small"
            value={cnpjMask(data.cnpj)}
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Status"
            variant="outlined"
            size="small"
            value={data.status === 'S' ? 'Ativo' : 'Inativo'}
            color="info"
            disabled
          />
        </Group>
        <Group>
          <TextField
            label="Plano contratado"
            variant="outlined"
            size="small"
            value={data.nome_plano}
            color="info"
            disabled
          />
        </Group>
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Ok, entendi"
          fontSize="12px"
          onClick={onClose}
        />
      </Container>
    </Slide>
  );
}

export default MenuCompany;
