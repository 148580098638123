import { Typography } from '@mui/material';

export function DataNotFound() {
  return (
    <Typography
      sx={{
        textAlign: 'center',
        py: 4,
        fontWeight: 700,
        fontSize: 18,
        color: '#777',
      }}
    >
      Não há dados a serem exibidos
    </Typography>
  );
}
