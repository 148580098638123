import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';
import { Typography } from '@mui/material';
import TestsModal from '../../components/TestsModal';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import Button from '../../components/Button';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';
import CheckModal from '../../components/CheckModal';

import {
  Container,
  Card,
  Title,
  GpButton,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  Step,
  Note,
  ContainerButton,
  GpNote,
  Description,
  GpDescription,
} from './styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor:
      theme.palette.mode === 'dark' ? colors.lightBlue : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? colors.lightBlue : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: colors.lightBlue,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colors.lightBlue,
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function StartCareer() {
  const { token } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [msg] = useState('');
  const [alert, setAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [company, setCompany] = useState(false);
  const [resp1, setResp1] = useState(undefined);
  const [resp2, setResp2] = useState(undefined);
  const [resp3, setResp3] = useState(undefined);
  const [resp4, setResp4] = useState(undefined);
  const [resp5, setResp5] = useState(undefined);
  const [resp6, setResp6] = useState(undefined);
  const [resp7, setResp7] = useState(undefined);
  const [resp8, setResp8] = useState(undefined);
  const [resp9, setResp9] = useState(undefined);
  const [resp10, setResp10] = useState(undefined);
  const [resp11, setResp11] = useState(undefined);
  const [resp12, setResp12] = useState(undefined);
  const [resp13, setResp13] = useState(undefined);
  const [resp14, setResp14] = useState(undefined);
  const [resp15, setResp15] = useState(undefined);
  const [resp16, setResp16] = useState(undefined);
  const [resp17, setResp17] = useState(undefined);
  const [resp18, setResp18] = useState(undefined);
  const [resp19, setResp19] = useState(undefined);
  const [resp20, setResp20] = useState(undefined);
  const [resp21, setResp21] = useState(undefined);
  const [resp22, setResp22] = useState(undefined);
  const [resp23, setResp23] = useState(undefined);
  const [resp24, setResp24] = useState(undefined);
  const [resp25, setResp25] = useState(undefined);
  const [resp26, setResp26] = useState(undefined);
  const [resp27, setResp27] = useState(undefined);
  const [resp28, setResp28] = useState(undefined);
  const [resp29, setResp29] = useState(undefined);
  const [resp30, setResp30] = useState(undefined);
  const [resp31, setResp31] = useState(undefined);
  const [resp32, setResp32] = useState(undefined);
  const [resp33, setResp33] = useState(undefined);
  const [resp34, setResp34] = useState(undefined);
  const [resp35, setResp35] = useState(undefined);
  const [resp36, setResp36] = useState(undefined);
  const [resp37, setResp37] = useState(undefined);
  const [resp38, setResp38] = useState(undefined);
  const [resp39, setResp39] = useState(undefined);
  const [resp40, setResp40] = useState(undefined);
  const [resp41, setResp41] = useState(undefined);
  const [resp42, setResp42] = useState(undefined);
  const history = useHistory();
  const location = useLocation();

  const onBack = () => {
    if (step === 1) {
      history.push({
        pathname: '/',
      });
    } else {
      setStep(step - 1);
    }
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const cleanAll = async () => {
    if (step !== 43) {
      setStep(step + 1);
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    }
  };

  const onNext = async () => {
    setTimeout(cleanAll, 500);
  };

  const handleChange = async value => {
    const setters = [
      () => setResp1(value),
      () => setResp2(value),
      () => setResp3(value),
      () => setResp4(value),
      () => setResp5(value),
      () => setResp6(value),
      () => setResp7(value),
      () => setResp8(value),
      () => setResp9(value),
      () => setResp10(value),
      () => setResp11(value),
      () => setResp12(value),
      () => setResp13(value),
      () => setResp14(value),
      () => setResp15(value),
      () => setResp16(value),
      () => setResp17(value),
      () => setResp18(value),
      () => setResp19(value),
      () => setResp20(value),
      () => setResp21(value),
      () => {},
      () => setResp22(value),
      () => setResp23(value),
      () => setResp24(value),
      () => setResp25(value),
      () => setResp26(value),
      () => setResp27(value),
      () => setResp28(value),
      () => setResp29(value),
      () => setResp30(value),
      () => setResp31(value),
      () => setResp32(value),
      () => setResp33(value),
      () => setResp34(value),
      () => setResp35(value),
      () => setResp36(value),
      () => setResp37(value),
      () => setResp38(value),
      () => setResp39(value),
      () => setResp40(value),
      () => setResp41(value),
      () => setResp42(value),
    ];
    setters[step - 1]();
    if (value === 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 2) {
      setNote1(true);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 3) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 4) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 5) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 6) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 7) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 8) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value === 9) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(false);
    } else if (value === 10) {
      setNote1(true);
      setNote2(true);
      setNote3(true);
      setNote4(true);
      setNote5(true);
      setNote6(true);
      setNote7(true);
      setNote8(true);
      setNote9(true);
      setNote10(true);
    }
    onNext();
  };

  const onNextYes = async () => {
    setCompany(true);
    if (step !== 43) {
      setStep(step + 1);
    }
  };

  const onNextNo = async () => {
    setCompany(false);
  };

  const onCloseError = () => {
    setError(false);
  };

  const onFinish = async () => {
    setModal(true);
  };

  const startTest = async () => {
    history.push({
      pathname: '/testeCultura',
      state: {
        vacancy: location.state.vacancy,
        vacancyId: location.state.vacancyId,
      },
    });
  };

  const startReport = async () => {
    const { vacancy } = location.state;
    const id = location.state.vacancyId;
    if (vacancy) {
      return history.push({
        pathname: '/calibrarHardskill',
        state: {
          vacancy: true,
          vacancyId: id,
        },
      });
    }
    if (token.authenticated_user?.user?.carreira_id) {
      return history.push({
        pathname: '/calibrarHardskillCarreira',
      });
    }
    return history.push({
      pathname: '/',
    });
  };

  const sendTest = async () => {
    const body = {
      custom_questions: {
        pergunta01: '',
        pergunta02: '',
      },
      lang: 'pt-br',
      ID_DO_USUARIO: token.displayName || '',
      EMAIL: token.email,
      DATA_DA_ENTRADA: new Date(),
      AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR: resp1,
      CLIMA_INTERNO: resp2,
      CONFIANCA_NA_LIDERANCA_IMEDIATA: resp3,
      CONFIANCA_NO_TIME_DE_TRABALHO: resp4,
      RECONHECIMENTO_E_VALORIZACAO: resp5,
      TRATAMENTO_HUMANIZADO: resp6,
      TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS: resp7,
      VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO: resp8,
      HORARIO_DE_TRABALHO: resp9,
      LOCAL_DE_TRABALHO: resp10,
      PACOTE_DE_BENEFICIOS: resp11,
      SALARIO: resp12,
      ABERTURA_PARA_MUDANCAS_E_INOVACAO: resp13,
      CELEBRACAO_DE_CONQUISTAS: resp14,
      CULTURA_E_VALORES_DA_EMPRESA: resp15,
      DESAFIOS_CONSTANTES: resp16,
      GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS: resp17,
      OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO: resp18,
      PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO: resp19,
      POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO: resp20,
      PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS: resp21,
      VOCE_ESTA_TRABALHANDO_ATUALMENTE: company ? 'Sim' : 'Não',
      AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA: resp22 || '',
      CLIMA_INTERNO_NA_SUA_EMPRESA: resp23 || '',
      CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA: resp24 || '',
      CONFIANCA_NO_SEU_TIME_DE_TRABALHO: resp25 || '',
      RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO: resp26 || '',
      TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA: resp27 || '',
      TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA: resp28 || '',
      VISAO_DO_FUTURO_DA_SUA_EMPRESA: resp29 || '',
      HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA: resp30 || '',
      SEU_LOCAL_DE_TRABALHO: resp31 || '',
      PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA: resp32 || '',
      SEU_SALARIO: resp33 || '',
      ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO: resp34 || '',
      CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA: resp35 || '',
      CULTURA_E_VALORES_DA_SUA_EMPRESA: resp36 || '',
      DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA: resp37 || '',
      GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA:
        resp38 || '',
      OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_NA_SUA_EMPRESA:
        resp39 || '',
      PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA: resp40 || '',
      POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA: resp41 || '',
      PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA: resp42 || '',
    };

    await api
      .post('/values-test', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        startReport();
      });
  };

  const onCloseModal = async () => {
    await setModal(false);
  };

  const step1 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 1 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step active>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Autonomia e abertura para se expressar</Title>
        <Description color={colors.darkBlue}>
          O quanto você ter autonomia no trabalho e abertura da liderança ou
          pares para expressar o que pensa são fatores decisivos para manter
          você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step2 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 2 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step active>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Clima interno</Title>
        <Description color={colors.darkBlue}>
          O quanto o clima interno (bom ambiente e relacionamento, satisfação,
          motivação etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step3 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 3 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step active>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Confiança na liderança imediata</Title>
        <Description color={colors.darkBlue}>
          O quanto sentir que você pode confiar em seu líder imediato (gestor) é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step4 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 4 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step active>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Confiança no time de trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto sentir que você pode confiar em seu líder imediato (gestor) e
          equipe é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step5 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 5 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step active>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Reconhecimento e valorização</Title>
        <Description color={colors.darkBlue}>
          O quanto você sentir que recebe reconhecimento e valorização em uma
          empresa são fatores decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step6 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 6 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step active>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Tratamento humanizado</Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa tratar de forma humanizada seus colaboradores (a
          pessoa por trás do profissional) é fator decisivo para manter você em
          um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step7 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 7 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step active>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Tratamento justo e igualitário com todos</Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa tratar de forma justa seus colaboradores, sem
          preferências e de forma igualitária, é fator decisivo para manter você
          em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step8 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 8 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step active>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Visão de futuro da empresa ou negócio</Title>
        <Description color={colors.darkBlue}>
          O quanto ter clareza sobre o futuro da empresa e o que ela busca é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step9 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 9 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step active>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Horário de trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto o horário de trabalho (turno, flexibilidade de horário,
          previsibilidade de horário, dias de folga, escalas etc) é fator
          decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step10 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 10 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step active>
          <p>10</p>
        </Step>
        <Step>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Local de trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto o local físico de trabalho (distância de sua residência,
          instalações físicas, conforto, possibilidade de trabalhar home office
          etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step11 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 11 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>1</p>
        </Step>
        <Step>
          <p>2</p>
        </Step>
        <Step>
          <p>3</p>
        </Step>
        <Step>
          <p>4</p>
        </Step>
        <Step>
          <p>5</p>
        </Step>
        <Step>
          <p>6</p>
        </Step>
        <Step>
          <p>7</p>
        </Step>
        <Step>
          <p>8</p>
        </Step>
        <Step>
          <p>9</p>
        </Step>
        <Step>
          <p>10</p>
        </Step>
        <Step active>
          <p>11</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Pacote de benefícios</Title>
        <Description color={colors.darkBlue}>
          O quanto o pacote de benefícios oferecidos por uma empresa (vale
          alimentação, academia, plano de saúde etc) é um fator decisivo para
          manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step12 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 12 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step active>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Salário</Title>
        <Description color={colors.darkBlue}>
          O quanto o salário pago é fator decisivo para manter você em um
          trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step13 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 13 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step active>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Abertura para mudanças e inovação</Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa demonstrar abertura real a mudanças e inovação é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step14 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 14 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step active>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Celebração de conquistas</Title>
        <Description color={colors.darkBlue}>
          O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a
          grandes comemorações) é fator decisivo para manter você em um
          trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step15 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 15 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step active>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Cultura e valores da empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto a cultura (&ldquo;jeitão&rdquo; da empresa) e seus valores (o
          que se prega, o que é valorizado naquele ambiente etc) são fatores
          decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step16 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 16 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step active>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Desafios constantes </Title>
        <Description color={colors.darkBlue}>
          O quanto receber desafios constantes (metas desafiadoras, projetos
          difíceis etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step17 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 17 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step active>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title height="200px">
          Gestão clara e acompanhamento dos objetivos
        </Title>
        <Description color={colors.darkBlue}>
          O quanto ter clareza dos objetivos e perceber que a empresa faz gestão
          e acompanhamento constante de seus objetivos são fatores decisivos
          para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step18 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 18 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step active>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title height="200px">
          Oportunidades de desenvolvimento e aprendizado
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa oferecer oportunidades reais de desenvolvimento
          profissional e aprendizado constante são fatores decisivos para manter
          você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step19 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 19 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step active>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Plano de carreira claro e estruturado</Title>
        <Description color={colors.darkBlue}>
          O quanto ter um plano de carreira claro e estruturado pela empresa é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step20 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 20 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step active>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title height="200px">Possibilidades de crescimento e promoção</Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa oferecer possibilidades reais de crescimento e
          promoção é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step21 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 21 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step active>
          <p>21</p>
        </Step>
        <Step>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre você:
        </Typography>
        <Title>Processos simples e desburocratizados</Title>
        <Description color={colors.darkBlue}>
          O quanto a simplificação e organização dos processos da empresa são
          fatores decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step22 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 22 de 22
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>12</p>
        </Step>
        <Step>
          <p>13</p>
        </Step>
        <Step>
          <p>14</p>
        </Step>
        <Step>
          <p>15</p>
        </Step>
        <Step>
          <p>16</p>
        </Step>
        <Step>
          <p>17</p>
        </Step>
        <Step>
          <p>18</p>
        </Step>
        <Step>
          <p>19</p>
        </Step>
        <Step>
          <p>20</p>
        </Step>
        <Step>
          <p>21</p>
        </Step>
        <Step active>
          <p>22</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Title>Você está trabalhando atualmente?</Title>
      </GpDescription>
      <DescriptionGroup>
        <FormControl>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
          >
            <Card>
              <FormControlLabel
                value="Sim"
                control={<BpRadio />}
                onChange={onNextYes}
                label="Sim"
              />
            </Card>
            <Card>
              <FormControlLabel
                value="Nao"
                control={<BpRadio />}
                onChange={onNextNo}
                label="Não"
              />
            </Card>
          </RadioGroup>
        </FormControl>
      </DescriptionGroup>
      <Line />
      <ContainerButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
        <Button
          type="button"
          width="140px"
          fontSize="13px"
          text="Concluir teste"
          hoverColor={colors.lightBlue}
          color={colors.white}
          bgColor={colors.lightBlue}
          onClick={onFinish}
        />
      </ContainerButton>
    </Group>
  );

  const step23 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 23 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step active>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Autonomia e abertura para se expressar na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto você ter autonomia no trabalho e abertura da liderança ou
          pares para expressar o que pensa são fatores decisivos para manter
          você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step24 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 24 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step active>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title>Clima interno na sua empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto o clima interno (bom ambiente e relacionamento, satisfação,
          motivação etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step25 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 25 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step active>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Confiança na liderança imediata na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto sentir que você pode confiar em seu líder imediato (gestor) é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step26 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 26 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step active>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">Confiança no time de trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto sentir que você pode confiar em seu líder imediato (gestor) e
          equipe é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step27 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 27 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step active>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">Reconhecimento e valorização do seu trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto você sentir que recebe reconhecimento e valorização em uma
          empresa são fatores decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step28 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 28 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step active>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="50%">
          Tratamento humanizado dos colaboradores da sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa tratar de forma humanizada seus colaboradores (a
          pessoa por trás do profissional) é fator decisivo para manter você em
          um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step29 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de 6ancora de carreira - Passo 29 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step active>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Tratamento justo e igualitário com todos em sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa tratar de forma justa seus colaboradores, sem
          preferências e de forma igualitária, é fator decisivo para manter você
          em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step30 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 30 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step active>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="50%">
          Visão de futuro da empresa ou negócio na sua empresao futuro da sua
          empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto ter clareza sobre o futuro da empresa e o que ela busca é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step31 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 31 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step active>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title>Horário de trabalho da sua empresa ou área</Title>
        <Description color={colors.darkBlue}>
          O quanto o horário de trabalho (turno, flexibilidade de horário,
          previsibilidade de horário, dias de folga, escalas etc) é fator
          decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step32 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 32 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step active>
          <p>32</p>
        </Step>
        <Step>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title>Seu local de trabalho</Title>
        <Description color={colors.darkBlue}>
          O quanto o local físico de trabalho (distância de sua residência,
          instalações físicas, conforto, possibilidade de trabalhar home office
          etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step33 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 33 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>23</p>
        </Step>
        <Step>
          <p>24</p>
        </Step>
        <Step>
          <p>25</p>
        </Step>
        <Step>
          <p>26</p>
        </Step>
        <Step>
          <p>27</p>
        </Step>
        <Step>
          <p>28</p>
        </Step>
        <Step>
          <p>29</p>
        </Step>
        <Step>
          <p>30</p>
        </Step>
        <Step>
          <p>31</p>
        </Step>
        <Step>
          <p>32</p>
        </Step>
        <Step active>
          <p>33</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title>Pacote de benefícios na sua empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto o pacote de benefícios oferecidos por uma empresa (vale
          alimentação, academia, plano de saúde etc) é um fator decisivo para
          manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step34 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 34 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step active>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title>Seu salário</Title>
        <Description color={colors.darkBlue}>
          O quanto o salário pago é fator decisivo para manter você em um
          trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step35 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 35 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step active>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Abertura da sua empresa para mudanças e inovação
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa demonstrar abertura real a mudanças e inovação é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step36 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 36 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step active>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">Celebração de conquistas na sua empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a
          grandes comemorações) é fator decisivo para manter você em um
          trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step37 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 37 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step active>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="30%">Cultura e valores da sua empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto a cultura (&ldquo;jeitão&rdquo; da empresa) e seus valores (o
          que se prega, o que é valorizado naquele ambiente etc) são fatores
          decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step38 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 38 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step active>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">Desafios constantes dados pela sua empresa</Title>
        <Description color={colors.darkBlue}>
          O quanto receber desafios constantes (metas desafiadoras, projetos
          difíceis etc) é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step39 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 39 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step active>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="50%">
          Gestão clara e acompanhamento dos objetivos na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto ter clareza dos objetivos e perceber que a empresa faz gestão
          e acompanhamento constante de seus objetivos são fatores decisivos
          para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step40 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 40 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step active>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="50%">
          Oportunidades de desenvolvimento e aprendizado na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa oferecer oportunidades reais de desenvolvimento
          profissional e aprendizado constante são fatores decisivos para manter
          você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step41 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 41 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step active>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Plano de carreira claro e estruturado na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto ter um plano de carreira claro e estruturado pela empresa é
          fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step42 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 42 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step active>
          <p>42</p>
        </Step>
        <Step>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="50%">
          Possibilidades de crescimento e promoção na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto uma empresa oferecer possibilidades reais de crescimento e
          promoção é fator decisivo para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <GpButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
      </GpButton>
    </Group>
  );

  const step43 = (
    <Group>
      <SubLabel color={colors.darkBlue}>
        Teste de âncoras de carreira - Passo 43 de 43
      </SubLabel>
      <DescriptionGroup>
        <Step>
          <p>34</p>
        </Step>
        <Step>
          <p>35</p>
        </Step>
        <Step>
          <p>36</p>
        </Step>
        <Step>
          <p>37</p>
        </Step>
        <Step>
          <p>38</p>
        </Step>
        <Step>
          <p>39</p>
        </Step>
        <Step>
          <p>40</p>
        </Step>
        <Step>
          <p>41</p>
        </Step>
        <Step>
          <p>42</p>
        </Step>
        <Step active>
          <p>43</p>
        </Step>
      </DescriptionGroup>
      <GpDescription>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
            color: colors.lightBlue,
          }}
        >
          Sobre sua empresa:
        </Typography>
        <Title bottom="40%">
          Processos simples e desburocratizados na sua empresa
        </Title>
        <Description color={colors.darkBlue}>
          O quanto a simplificação e organização dos processos da empresa são
          fatores decisivos para manter você em um trabalho?
        </Description>
      </GpDescription>
      {width <= 570 ? (
        <DescriptionGroup>
          <GpNote>
            <Note onClick={() => handleChange(1)} active={note1}>
              <p>1</p>
            </Note>
            <Note onClick={() => handleChange(2)} active={note2}>
              <p>2</p>
            </Note>
            <Note onClick={() => handleChange(3)} active={note3}>
              <p>3</p>
            </Note>
            <Note onClick={() => handleChange(4)} active={note4}>
              <p>4</p>
            </Note>
            <Note onClick={() => handleChange(5)} active={note5}>
              <p>5</p>
            </Note>
          </GpNote>
          <GpNote>
            <Note onClick={() => handleChange(6)} active={note6}>
              <p>6</p>
            </Note>
            <Note onClick={() => handleChange(7)} active={note7}>
              <p>7</p>
            </Note>
            <Note onClick={() => handleChange(8)} active={note8}>
              <p>8</p>
            </Note>
            <Note onClick={() => handleChange(9)} active={note9}>
              <p>9</p>
            </Note>
            <Note onClick={() => handleChange(10)} active={note10}>
              <p>10</p>
            </Note>
          </GpNote>
        </DescriptionGroup>
      ) : (
        <DescriptionGroup>
          <Note onClick={() => handleChange(1)} active={note1}>
            <p>1</p>
          </Note>
          <Note onClick={() => handleChange(2)} active={note2}>
            <p>2</p>
          </Note>
          <Note onClick={() => handleChange(3)} active={note3}>
            <p>3</p>
          </Note>
          <Note onClick={() => handleChange(4)} active={note4}>
            <p>4</p>
          </Note>
          <Note onClick={() => handleChange(5)} active={note5}>
            <p>5</p>
          </Note>
          <Note onClick={() => handleChange(6)} active={note6}>
            <p>6</p>
          </Note>
          <Note onClick={() => handleChange(7)} active={note7}>
            <p>7</p>
          </Note>
          <Note onClick={() => handleChange(8)} active={note8}>
            <p>8</p>
          </Note>
          <Note onClick={() => handleChange(9)} active={note9}>
            <p>9</p>
          </Note>
          <Note onClick={() => handleChange(10)} active={note10}>
            <p>10</p>
          </Note>
        </DescriptionGroup>
      )}
      <Line />
      <ContainerButton>
        <Button
          type="button"
          width="80px"
          fontSize="13px"
          text="Voltar"
          color={colors.lightBlue}
          bgColor={colors.transparent}
          onClick={onBack}
        />
        <Button
          type="button"
          width="140px"
          fontSize="13px"
          text="Concluir teste"
          hoverColor={colors.lightBlue}
          color={colors.white}
          bgColor={colors.lightBlue}
          onClick={onFinish}
        />
      </ContainerButton>
    </Group>
  );

  useEffect(() => {
    setWidth(window.screen.width);
  }, [width]);

  useEffect(() => {
    const stepResponses = [
      resp1,
      resp2,
      resp3,
      resp4,
      resp5,
      resp6,
      resp7,
      resp8,
      resp9,
      resp10,
      resp11,
      resp12,
      resp13,
      resp14,
      resp15,
      resp16,
      resp17,
      resp18,
      resp19,
      resp20,
      resp21,
      company ? 'Sim' : 'Não',
      resp22,
      resp23,
      resp24,
      resp25,
      resp26,
      resp27,
      resp28,
      resp29,
      resp30,
      resp31,
      resp32,
      resp33,
      resp34,
      resp35,
      resp36,
      resp37,
      resp38,
      resp39,
      resp40,
      resp41,
      resp42,
    ];
    setNote1(stepResponses[step - 1] >= 1);
    setNote2(stepResponses[step - 1] >= 2);
    setNote3(stepResponses[step - 1] >= 3);
    setNote4(stepResponses[step - 1] >= 4);
    setNote5(stepResponses[step - 1] >= 5);
    setNote6(stepResponses[step - 1] >= 6);
    setNote7(stepResponses[step - 1] >= 7);
    setNote8(stepResponses[step - 1] >= 8);
    setNote9(stepResponses[step - 1] >= 9);
    setNote10(stepResponses[step - 1] >= 10);
  }, [
    company,
    resp1,
    resp10,
    resp11,
    resp12,
    resp13,
    resp14,
    resp15,
    resp16,
    resp17,
    resp18,
    resp19,
    resp2,
    resp20,
    resp21,
    resp22,
    resp23,
    resp24,
    resp25,
    resp26,
    resp27,
    resp28,
    resp29,
    resp3,
    resp30,
    resp31,
    resp32,
    resp33,
    resp34,
    resp35,
    resp36,
    resp37,
    resp38,
    resp39,
    resp4,
    resp40,
    resp41,
    resp42,
    resp5,
    resp6,
    resp7,
    resp8,
    resp9,
    step,
  ]);

  const steps = [
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
    step10,
    step11,
    step12,
    step13,
    step14,
    step15,
    step16,
    step17,
    step18,
    step19,
    step20,
    step21,
    step22,
    step23,
    step24,
    step25,
    step26,
    step27,
    step28,
    step29,
    step30,
    step31,
    step32,
    step33,
    step34,
    step35,
    step36,
    step37,
    step38,
    step39,
    step40,
    step41,
    step42,
    step43,
  ];
  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <CheckModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <TestsModal
        isOpen={modal}
        isHome={location.state.vacancy}
        onRequestClose={onCloseModal}
        test=""
        startReport={sendTest}
        startTest={startTest}
      />
      {steps[step - 1]}
    </Container>
  );
}

export default StartCareer;
