import styled from 'styled-components';
import colors from '../../styles/colors';

export const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  /* transition: 0.8s ease-in-out */
`;

export const LayoutContent = styled.div`
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-left: 0;
  }
`;

export const LayoutContentMain = styled.div`
  margin: 0 auto;
`;

export const Row = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
`;

export const Label = styled.h1`
  position: relative;
  height: 52px;
  top: 110px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 37px;
  }

  @media (max-width: 570px) {
    font-size: 27px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.white};
`;

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
  border-bottom-right-radius: 50px;
  z-index: 1;
  background: ${colors.darkBlue};
`;

export const Group = styled.div`
  padding-top: ${props => props.top || '10px'};
  padding-bottom: ${props => props.bottom || '10px'};
  display: block;
  justify-content: center;
`;

export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  padding-top: 10px
  margin-bottom: ${props => props.maginBottom || '45px'};
  color: ${props => props.color || colors.gray2};
  text-align: center;
  @media (max-width: 870px) {
    margin-bottom: 10px;
  }
`;

export const Card = styled.div`
  position: relative;
  text-align: center;
  width: 800px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  top: 35%;
  background: ${colors.white};
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 12px;
  align-items: center;

  @media (max-width: 870px) {
    width: 400px;
  }

  @media (max-width: 570px) {
    width: 340px;
    height: 340px;
  }
`;

export const InputField = styled.div`
  max-width: 320px;
  width: 100%;
  background-color: ${colors.white};
  margin: 10px 0;
  justify-content: flex-start;
  border: 1px solid ${colors.gray5};
  height: 55px;
  top: 40px;
  border-radius: 10px;
  padding: 0 0.4rem;
  position: relative;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  /* identical to box height, or 24px */

  letter-spacing: 0.1px;

  input {
    background: none;
    font-weight: 600;
    font-size: 1rem;
    color: ${colors.gray1};
  }

  input::placeholder {
    color: ${colors.gray5};
    font-size: 0.8rem;
    font-weight: 500;
  }
  .eye-toggle {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;
