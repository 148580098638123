import React from 'react';
import Modal from 'react-modal';

// ASSETS
import Finish from '../../assets/img/Finish.svg';

// COMPONENTS
import Button from '../Button';

// STYLES
import colors from '../../styles/colors';
import {
  ModalContainer,
  ModalContent,
  Image,
  Title,
  SubLabel,
  Row,
} from './styles';

Modal.setAppElement('#root');

function ModalSubmit({ isOpen, onRequestClose, width, onHome, calibrate }) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}`,
      minWidth: '450px',
      height: '450px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 9999,
    },
  };

  return (
    <ModalContainer>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Image src={Finish} alt="Finish" />
          <Title>
            {calibrate
              ? 'Hardskills preenchidas com sucesso!'
              : 'Hardskills preenchidas!'}
          </Title>
          {calibrate ? (
            false
          ) : (
            <SubLabel color={colors.darkBlue}>
              Candidatura concluída com sucesso!
            </SubLabel>
          )}
          <Row top="15%">
            <Button
              type="button"
              width="300px"
              fontSize="13px"
              text="Finalizar"
              hoverColor={colors.lightBlue}
              color={colors.white}
              bgColor={colors.lightBlue}
              onClick={onHome}
            />
          </Row>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default ModalSubmit;
