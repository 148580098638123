import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  Checkbox,
  Box,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import * as Ai from 'react-icons/ai';
import * as Fi from 'react-icons/fi';
import * as Bi from 'react-icons/bi';
import * as Rx from 'react-icons/rx';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import NewJobModal from '../../components/NewJobModal';
import CopyModal from '../../components/CopyModal';
import ItemDeleteModal from '../../components/ItemDeleteModal';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from '../../components/ErrorModal';
import colors from '../../styles/colors';
import Table from '../../components/Table';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import dataCandidate from '../../assets/data/dataCandidate.json';
import Visualization from '../../components/Visualizations/Time';

import {
  ButtonLink,
  Container,
  Label,
  Card,
  Group,
  GroupFilter,
  Row,
  Title,
} from './styles';
import { CompanySelector } from '../../components/CompanySelector';
import { listAllCompanies } from '../../api/companies';
import { withAuth } from '../../hocs/withAuth';
import { UserRole } from '../../utils/enums';

function JobPanel() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [link, setLink] = useState(false);
  const [del, setDel] = useState(false);
  const [alert, setAlert] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [msgError, setMsgError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [status, setStatus] = useState(-1);
  const [error, setError] = useState(false);
  const [modalCandidate, setModalCandidate] = useState(false);
  const [data, setData] = useState([]);
  const [arrayholder, setArrayholder] = useState([]);
  const [dataFit, setDataFit] = useState(dataCandidate);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [candidate, setCandidate] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [arrayUser, setArrayUser] = useState([]);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });
  const [downloadLink, setDownloadLink] = useState('');
  const [showCandidates, setShowCandidates] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { data: allCompanies } = useQuery('listAllCompanies', listAllCompanies);

  const getCandidate = useCallback(
    async id => {
      const emails = [];
      await api
        .get(`/users/vaga/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data: jobData } = response;
          jobData.forEach(item => {
            emails.push(item.users[0].email);
          });
          let ranking = [];
          const careerId = data.find(
            vaga => vaga.id === selected.id,
          )?.carreira_id;
          if (emails.length && careerId) {
            const res = await api.post(
              '/ranking',
              {
                emails,
                carreira_id: careerId,
              },
              {
                headers: {
                  authorization: `Bearer ${token.refreshToken}`,
                },
              },
            );
            ranking = res.data;
          }

          setCandidate(
            jobData
              .map(j => ({
                ...j.users[0],
                ranking: ranking?.DATA?.find(r => r.EMAIL === j.users[0].email),
              }))
              .sort((a, b) =>
                a.ranking?.FIT_PERSONA_IDEAL > b.ranking?.FIT_PERSONA_IDEAL
                  ? -1
                  : 1,
              ),
          );
          setShowCandidates(true);
        });
    },
    [data, selected, token],
  );

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        setCompanies([]);
      } else {
        setCompanies(userGlobal);
      }
    },
    [userGlobal],
  );

  const copyLink = id => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/vaga?id=${id}`);
    setLink(true);
  };

  const getData = useCallback(async () => {
    await api
      .get('/vagas', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        setArrayholder(response.data);
      })
      .catch(() => {
        setError(true);
        setMsgError('Sistema indisponível! Tente novamente mais tarde!');
      });
  }, [token]);

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.career}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.status}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.vacancy}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.persona}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.company}
      </th>
      <th key={index}>{item.actions}</th>
    </>
  );

  const openDel = async id => {
    setIdSelected(id);
    setDel(true);
  };

  const onChangeDetails = useCallback(async (vl, item) => {
    setSelected(item);
  }, []);

  const openEdit = async item => {
    setSelectedJob(item);
    setIsOpen(true);
  };

  const renderBody = (item, index) => {
    const json = companies.filter(i => {
      return item.company_id === i.id;
    });
    return (
      <tr key={index}>
        <td>{item.nome}</td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.status === 0 ? 'Inativa' : 'Ativa'}
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          <Link
            target="_blank"
            to={{
              pathname: `/vaga?id=${item.id}`,
              search: '',
              hash: '',
              state: {
                id: item.id,
              },
            }}
          >
            Link
          </Link>
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.persona_ideal_id ? 'Sim' : 'Não'}
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {json.length > 0 ? json[0].nome_fantasia : '--'}
        </td>
        <td width="120px">
          <Group>
            <Tooltip title="Copiar link">
              <span>
                <Fi.FiCopy
                  size="1rem"
                  onClick={() => copyLink(item.id)}
                  style={{
                    color: colors.lightBlue,
                    cursor: 'pointer',
                    marginRight: '4px',
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Ver candidatos da vaga">
              <span>
                <Bi.BiSearch
                  size="1rem"
                  onClick={() => onChangeDetails(true, item)}
                  style={{
                    color: colors.lightBlue,
                    cursor: 'pointer',
                    marginRight: '4px',
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Editar vaga">
              <span>
                <Bi.BiEditAlt
                  size="1rem"
                  onClick={() => openEdit(item)}
                  style={{
                    color: colors.lightBlue,
                    cursor: 'pointer',
                    marginRight: '4px',
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Excluir vaga">
              <span>
                <Bi.BiTrash
                  size="1rem"
                  onClick={() => openDel(item.id)}
                  style={{
                    color: colors.lightBlue,
                    cursor: 'pointer',
                    marginRight: '4px',
                  }}
                />
              </span>
            </Tooltip>
          </Group>
        </td>
      </tr>
    );
  };

  const renderHeadCandidate = (item, index) => {
    return (
      <>
        <th style={{ width: '30px' }} key={index}>
          {item.checkbox}
        </th>
        <th key={index}>{item.name}</th>
        <th key={index} style={{ width: '48px' }}>
          {item.ranking}
        </th>
        <th key={index} style={{ width: '180px' }}>
          {item.fit}
        </th>
        <th className={width <= 570 ? 'hidden' : ''} key={index}>
          {item.profile}
        </th>
        <th className={width <= 570 ? 'hidden' : ''} key={index}>
          {item.culture}
        </th>
        <th
          className={width <= 570 ? 'hidden' : ''}
          style={{ width: '140px' }}
          key={index}
        >
          {item.values}
        </th>
        <th key={index}>{item.contacts}</th>
        <th key={index}>{item.actions}</th>
      </>
    );
  };

  const unbind = async item => {
    const json = {
      user_id: item.users[0]?.id,
      vaga_id: selected.id,
    };
    await api
      .delete('/user-vagas', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
        data: json,
      })
      .then(async () => {
        setMsg('Desvinculo concluído com sucesso!');
        await getCandidate(selected.id);
        setAlert(true);
      })
      .catch(() => {
        setMsgError('Sistema indisponível! Tente novamente mais tarde!');
        setError(true);
      });
  };

  const openDetailsCandidate = async item => {
    const body = {
      emails: [item.email],
      vaga_id: selected.id,
    };

    await api
      .post('/persona/fit', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data: userData } = response;
        if (userData.message?.length > 0) {
          setMsgError('Não há informações sobre detalhes desse candidato!');
          setError(true);
        } else {
          setDataFit(userData.DATA);
          setSelectedCandidate(item);
          setModalCandidate(true);
        }
      })
      .catch(() => {
        setError(true);
        setMsgError('Sistema indisponível! Tente novamente mais tarde!');
      });
  };

  const openDetailsPersona = item => {
    const selectedEmails = [item.email];
    history.push('/fitPersona', {
      emails: selectedEmails,
      companyId: selectedCompany,
      jobId: selected.id,
    });
  };

  const handleChangeCheckbox = item => {
    setCandidate(oldState =>
      oldState.map(i => ({
        ...i,
        selected: item.email === i.email ? !i.selected : i.selected,
      })),
    );
  };

  const tooltip = item => (
    <Box sx={{ padding: '8px', fontSize: '14px' }}>
      <Typography sx={{ mb: 1, fontSize: '15px' }}>
        <strong>Detalhes do Fit</strong>
      </Typography>
      <p>
        Global:{' '}
        <strong>{Math.round(item.ranking?.FIT_PERSONA_IDEAL ?? 0)}%</strong>
      </p>
      <p>
        Comportamento:{' '}
        <strong>{Math.round(item.ranking?.FITS.COMPORTAMENTAL ?? 0)}%</strong>
      </p>
      <p>
        Learn Agilities:{' '}
        <strong>{Math.round(item.ranking?.FITS.LEARN_AGILITIES ?? 0)}%</strong>
      </p>
      <p>
        Cultural:{' '}
        <strong>{Math.round(item.ranking?.FITS.CULTURAL ?? 0)}%</strong>
      </p>
      <p>
        Âncoras:{' '}
        <strong>{Math.round(item.ranking?.FITS.VALORES_CARREIRA ?? 0)}%</strong>
      </p>
      <p>
        Hard Skills:{' '}
        <strong>{Math.round(item.ranking?.FITS.HARD_SKILLS ?? 0)}%</strong>
      </p>
    </Box>
  );

  const renderBodyCandidate = (item, index) => {
    return (
      <tr key={index}>
        <td style={{ width: '30px' }}>
          <Checkbox
            checked={item.selected}
            onChange={() => handleChangeCheckbox(item)}
          />
        </td>
        <td>{item.name}</td>
        <td>
          <span style={{ marginRight: '2px', fontWeight: 700 }}>
            {Math.round(item.ranking?.FIT_PERSONA_IDEAL ?? 0)}%
          </span>
          <Tooltip title={tooltip(item)}>
            <InfoIcon sx={{ cursor: 'pointer', fontSize: '15px' }} />
          </Tooltip>
        </td>
        <td>
          <Button
            variant="outlined-green"
            onClick={() => openDetailsPersona(item)}
            size="small"
            sx={{ py: '0.125rem', px: '0.25rem', width: '120px' }}
          >
            Relatório Fit
          </Button>
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.tests.comportamental.PERFIL_PREDOMINANTE}&nbsp;
          <Tooltip title="Exibir relatório comportamental">
            <a
              target="_new"
              href={`/relatorioComportamental?activeUser=${encodeURIComponent(
                item.email,
              )}`}
            >
              <Rx.RxOpenInNewWindow
                style={{ color: colors.lightBlue, fontSize: '14px' }}
              />
            </a>
          </Tooltip>
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.tests.cultural.CULTURA_PREDOMINANTE}&nbsp;
          <Tooltip title="Exibir relatório de cultura">
            <a
              target="_new"
              href={`/relatorioDeCultura?activeUser=${encodeURIComponent(
                item.email,
              )}`}
            >
              <Rx.RxOpenInNewWindow
                style={{ color: colors.lightBlue, fontSize: '14px' }}
              />
            </a>
          </Tooltip>
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {Math.round(
            item.tests?.valores
              .INDICE_DE_PERCEPCAO_DE_VALOR_DE_CARREIRA_NA_EMPRESA ?? 0,
          )}
          %&nbsp;
          <Tooltip title="Exibir relatório de âncoras de carreira">
            <a
              target="_new"
              href={`/relatorioDeValoresDeCarreira?activeUser=${encodeURIComponent(
                item.email,
              )}`}
            >
              <Rx.RxOpenInNewWindow
                style={{ color: colors.lightBlue, fontSize: '14px' }}
              />
            </a>
          </Tooltip>
        </td>
        <td>
          <Stack direction="row" spacing={1}>
            <Tooltip title={item.email}>
              <a href={`mailto:${item.email}`}>
                <Ai.AiOutlineMail
                  size="20"
                  style={{ color: colors.lightBlue }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Telefone">
              <a href="https://wa.me" target="_blank" rel="noreferrer">
                <Ai.AiOutlineWhatsApp size="20" style={{ color: '#25D366' }} />
              </a>
            </Tooltip>
          </Stack>
        </td>
        <td>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Ver detalhes">
              <button
                type="button"
                onClick={() => openDetailsCandidate(item)}
                style={{ color: colors.lightBlue }}
              >
                <Bi.BiSearch size="20" />
              </button>
            </Tooltip>
            <Tooltip title="Desvincular candidato da vaga">
              <button type="button">
                <Bi.BiTrash
                  size="20"
                  style={{ color: '#ff0000' }}
                  onClick={() => unbind(item)}
                />
              </button>
            </Tooltip>
          </Stack>
        </td>
      </tr>
    );
  };

  const handleStatus = useCallback(async e => {
    setStatus(e.target.value);
  }, []);

  const users = {
    head: [
      {
        career: 'Descrição',
        status: 'Status',
        vacancy: 'Link',
        persona: 'Persona',
        company: 'Empresa',
        actions: 'Ações',
      },
    ],
  };

  const jsonCandidates = {
    head: [
      {
        checkbox: '',
        name: 'Candidato',
        ranking: 'Ranking',
        fit: 'Fit',
        profile: 'Perfil Comportamental',
        culture: 'Cultura',
        values: 'Âncoras de carreira',
        contacts: 'Contatos',
        actions: 'Ações',
      },
    ],
    body: candidate || [],
    // body: Details || []
  };

  const deleteVacancy = useCallback(
    async id => {
      await api
        .delete(`/vagas/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          setMsg('Vaga excluída com sucesso!');
          setDel(false);
          setAlert(true);
          await getData();
        });
    },
    [getData, token],
  );

  const onCloseError = () => {
    setError(false);
  };

  const onCloseCandidate = () => {
    setModalCandidate(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const fieldRow1 = width <= 570 ? '80%' : 700;
  const fieldRow2 = width <= 570 ? '80%' : 330;

  const handleCompareSelected = useCallback(async () => {
    const selectedEmails = candidate
      .filter(item => item.selected)
      .map(item => item.email);
    history.push('/fitPersona', {
      emails: selectedEmails,
      companyId: selectedCompany,
      jobId: selected.id,
    });
  }, [candidate, history, selected, selectedCompany]);

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        setArrayUser(response.data.users);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  useEffect(() => {
    if (profileGlobal?.perfil_id) {
      getData();
      getCompanies(profileGlobal?.perfil_id);
      setWidth(window.screen.width);
    }
  }, [width, idSelected, selected, profileGlobal, getData, getCompanies]);

  useEffect(() => {
    if (dataFit.length) getUsers();
  }, [getUsers, dataFit]);

  useEffect(() => {
    if (selected?.id) getCandidate(selected.id);
  }, [getCandidate, selected]);

  useEffect(() => {
    if (location.state?.companyId && location.state?.careerId) {
      setSelectedCompany(location.state.companyId);
      const currentCompany = allCompanies.find(
        c => c.id === location.state.companyId,
      );
      setInitialCompany(currentCompany);
      window.history.replaceState({}, document.title);
    }
  }, [allCompanies, location.state]);

  useEffect(() => {
    if (selectedCompany) {
      const newData = arrayholder.filter(
        item =>
          item.company_id === selectedCompany &&
          (status === -1 || status === Number(item.status)),
      );
      setData(newData);
    }
  }, [arrayholder, selectedCompany, status]);

  useEffect(() => {
    let csv =
      'Nome,Email,Ranking,Perfil Comportamental,Cultura,Âncora de Carreira,Teste de Carreira, Teste Comportamental, Teste Cultural, Teste Hard Skills\n';
    candidate.forEach(item => {
      csv += [
        item.name,
        item.email,
        item.ranking?.FIT_PERSONA_IDEAL,
        item.profile,
        item.culture,
        item.values,
        item.ranking?.TESTE_REALIZADO.CARREIRA,
        item.ranking?.TESTE_REALIZADO.COMPORTAMENTAL,
        item.ranking?.TESTE_REALIZADO.CULTURA,
        item.ranking?.TESTE_REALIZADO.HARD_SKILL,
      ].join(',');
      csv += '\n';
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    setDownloadLink(URL.createObjectURL(blob));
  }, [candidate]);

  return (
    <Container>
      <ResponsiveAppBar />
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <Visualization
        arrayUser={arrayUser}
        width={width}
        data={candidate.filter(item => item.id === selectedCandidate.id)}
        isOpen={modalCandidate}
        onClose={onCloseCandidate}
      />
      <ErrorModal isOpen={error} msg={msgError} onRequestClose={onCloseError} />
      <NewJobModal
        selected={selectedJob}
        initialCompanyId={selectedCompany ?? 0}
        getData={getData}
        isOpen={isOpen}
        width={width}
        onRequestClose={() => setIsOpen(false)}
      />
      <CopyModal
        isOpen={link}
        width={width}
        onRequestClose={() => setLink(false)}
      />
      <ItemDeleteModal
        isOpen={del}
        width={width}
        onRequestClose={() => setDel(false)}
        onDelete={() => deleteVacancy(idSelected)}
      />
      <Title>Painel de Vagas</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Group>
          <CompanySelector
            fullWidth={false}
            initialState={initialCompany}
            width={fieldRow1}
            label={<em>Procurar por empresa</em>}
            onChange={company => setSelectedCompany(company?.id)}
            options={companies}
          />
          <FormControl sx={{ ml: 1, width: fieldRow2 }}>
            <Select
              sx={{ textAlign: 'left' }}
              value={status}
              onChange={handleStatus}
              autoWidth
              size="small"
              color="info"
            >
              <MenuItem value={0}>
                <em>Status</em>
              </MenuItem>
              <MenuItem value={1}>Inativo</MenuItem>
              <MenuItem value={-1}>Ativo</MenuItem>
            </Select>
          </FormControl>
        </Group>
        <Group>
          <GroupFilter align={width > 530 ? 'right' : 'center'}>
            <Button
              startIcon=<AddIcon />
              sx={{ my: 2 }}
              variant="contained"
              onClick={() => {
                setSelected([]);
                setIsOpen(true);
              }}
            >
              Nova vaga
            </Button>
          </GroupFilter>
        </Group>
      </Card>
      <Card>
        {showCandidates ? (
          <>
            <Row>
              <Fi.FiChevronLeft color={colors.lightBlue} />
              <ButtonLink
                onClick={() => setShowCandidates(false)}
                font="12px"
                color={colors.lightBlue}
              >
                Voltar
              </ButtonLink>
            </Row>
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: 'end', marginBottom: '12px' }}
            >
              <Button
                variant="outlined-green"
                onClick={handleCompareSelected}
                sx={{ py: '0.125rem' }}
              >
                Comparar selecionados
              </Button>
              <a href={downloadLink} rel="noreferrer">
                <Button
                  variant="outlined-green"
                  size="small"
                  sx={{ py: '0.125rem' }}
                >
                  Exportar CSV
                </Button>
              </a>
            </Stack>
            <Table
              limit="10"
              headData={jsonCandidates.head}
              renderHead={(item, index) => renderHeadCandidate(item, index)}
              bodyData={jsonCandidates.body}
              renderBody={(item, index) => renderBodyCandidate(item, index)}
            />
          </>
        ) : (
          <Table
            limit="10"
            headData={users.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={data}
            renderBody={(item, index) => renderBody(item, index)}
          />
        )}
      </Card>
    </Container>
  );
}

export default withAuth(
  JobPanel,
  UserRole.MASTER_ADMIN,
  UserRole.COMPANY_ADMIN,
  UserRole.CONSULTANT,
);
