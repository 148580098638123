import React, { useContext } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { Tooltip } from '@mui/material';
import {
  Item,
  Title,
  GroupLine,
  Container,
  Shape,
  SubTitle,
  Row,
  GpItems,
  DescriptionData,
  LabelData,
  Icon,
} from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import vector from '../../../assets/img/Vector.svg';

function MenuAbout({ data, width, arrayUser, isOpen, onClose }) {
  const { token } = useContext(StoreContext);
  const culture =
    data.length > 0 ? data[0].test_cultural ?? data[0].tests.cultural : [];
  const radar =
    culture !== null
      ? {
          series: [
            {
              name: 'Cultura',
              data: [
                culture.ACOLHIMENTO,
                culture.PROPOSITO,
                culture.APRENDIZADO,
                culture.PRAZER,
                culture.RESULTADOS,
                culture.AUTORIDADE,
                culture.SEGURANÇA,
                culture.ORDEM,
              ],
            },
          ],
          options: {
            colors: ['#95D45A'],
            chart: {
              height: 350,
              type: 'radar',
              fontFamily: 'Poppins',
            },
            title: {
              text: '',
            },
            xaxis: {
              color: [colors.darkBlue],
              categories: [
                'Acolhimento',
                'Propósito',
                'Aprendizado',
                'Prazer',
                'Resultados',
                'Autoridade',
                'Segurança',
                'Ordem',
              ],
            },
          },
        }
      : {};
  const behavioral =
    data.length > 0
      ? data[0].test_comportamental ?? data[0].tests.comportamental
      : [];

  let icon;
  if (token) {
    icon = false;
  } else {
    icon =
      width <= 570 ? (
        <Shape src={shape} alt="Menu" />
      ) : (
        <Item src={menu} alt="Menu" />
      );
  }

  const slide = width <= 570 ? '420px' : '700px';

  const career =
    data.length > 0 ? data[0].test_valores ?? data[0].tests.valores : [];

  const line =
    career !== null
      ? {
          series: [
            {
              name: 'Importância para os colaboradores',
              data: [
                career.HORARIO_DE_TRABALHO,
                career.LOCAL_DE_TRABALHO,
                career.PACOTE_DE_BENEFICIOS,
                career.SALARIO,
                career.ABERTURA_PARA_MUDANCAS_E_INOVACAO,
                career.CELEBRACAO_DE_CONQUISTAS,
                career.CULTURA_E_VALORES_DA_EMPRESA,
                career.DESAFIOS_CONSTANTES,
                career.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS,
                career.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO,
                career.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO,
                career.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO,
                career.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS,
                career.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR,
                career.CLIMA_INTERNO,
                career.CONFIANCA_NA_LIDERANCA_IMEDIATA,
                career.CONFIANCA_NO_TIME_DE_TRABALHO,
                career.RECONHECIMENTO_E_VALORIZACAO,
                career.TRATAMENTO_HUMANIZADO,
                career.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS,
                career.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO,
              ],
            },
            {
              name: 'Percepção de valor de empresa',
              data: [
                career.HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA,
                career.SEU_LOCAL_DE_TRABALHO,
                career.PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA,
                career.SEU_SALARIO,
                career.ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO,
                career.CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA,
                career.CULTURA_E_VALORES_DA_SUA_EMPRESA,
                career.DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA,
                career.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA,
                career.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO,
                career.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA,
                career.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA,
                career.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA,
                career.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA,
                career.CLIMA_INTERNO_NA_SUA_EMPRESA,
                career.CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA,
                career.CONFIANCA_NO_SEU_TIME_DE_TRABALHO,
                career.RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO,
                career.TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA,
                career.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA,
                career.VISAO_DO_FUTURO_DA_SUA_EMPRESA,
              ],
            },
          ],
          options: {
            chart: {
              height: 350,
              type: 'area',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              type: 'number',
              categories: [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
              ],
            },
          },
        }
      : {};

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      width={slide}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <GroupLine>
        <Title>Detalhes</Title>
      </GroupLine>
      <Container>
        <Row top="2%" align="start" bottom="2px">
          <GpItems
            padding="10px"
            width="80%"
            radius="12px 0px 0px 12px"
            background={colors.white}
            borderColor={colors.white}
          >
            <SubTitle>
              Perfil predominante:{' '}
              <b>
                {data.length > 0
                  ? data[0].perfil_comportamental ??
                    data[0].tests.comportamental.PERFIL_PREDOMINANTE
                  : '--'}
              </b>
            </SubTitle>
          </GpItems>
          <GpItems
            padding="15px"
            width="20%"
            radius="0px 12px 12px 0px"
            background={colors.white}
            borderColor={colors.white}
          >
            {arrayUser.length > 0 && data.length > 0 ? (
              <Link
                target="_blank"
                to={{
                  pathname: `/relatorioComportamental?activeUser=${encodeURIComponent(
                    arrayUser.filter(i => {
                      return (
                        i.name === data[0].nome ||
                        i.email === data[0].nome ||
                        i.email === data[0].email
                      );
                    })[0].email,
                  )}`,
                  search: '',
                  hash: '',
                  state: {
                    activeUser: arrayUser.filter(i => {
                      return (
                        i.name === data[0].nome ||
                        i.email === data[0].nome ||
                        i.email === data[0].email
                      );
                    })[0].email,
                  },
                }}
              >
                <Button
                  type="button"
                  width="80px"
                  hoverColor={colors.lightBlue}
                  fontSize="12px"
                  height="30px"
                  margin="0px"
                  text="Ver perfil"
                />
              </Link>
            ) : (
              false
            )}
          </GpItems>
        </Row>
        {behavioral !== null ? (
          <>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="85%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Naturalmente orientado para a ação e a conquista, este perfil tem foco nos resultados. Liderança e iniciativa são suas marcas registradas.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Desbravador</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="15%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.DESBRAVADOR || behavioral.DESBRAVADOR >= 0
                    ? `${behavioral.DESBRAVADOR}%`
                    : '-'}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="85%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Este perfil tem um foco na estabilidade e na manutenção da ordem, sendo fundamental para manter a harmonia da equipe.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Mantenedor</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="15%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.MANTENEDOR || behavioral.MANTENEDOR >= 0
                    ? `${behavioral.MANTENEDOR}%`
                    : '-'}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="85%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Este perfil é altamente analítico e orientado para a resolução de problemas complexos.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Investigador</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="15%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.INVESTIGADOR || behavioral.INVESTIGADOR >= 0
                    ? `${behavioral.INVESTIGADOR}%`
                    : '-'}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="85%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Habilidades de relacionamento são o ponto forte desse perfil, que é orientado para criar e manter relacionamentos interpessoais.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Embaixador</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="15%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.EMBAIXADOR || behavioral.EMBAIXADOR >= 0
                    ? `${behavioral.EMBAIXADOR}%`
                    : '-'}
                </DescriptionData>
              </GpItems>
            </Row>
            <br />
            <br />
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="80%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.white}
              >
                <SubTitle>Agilidade de aprendizado</SubTitle>
              </GpItems>
              <GpItems
                padding="15px"
                width="20%"
                radius="0px 12px 12px 0px"
                background={colors.white}
                borderColor={colors.white}
              >
                {arrayUser.length > 0 && data.length > 0 ? (
                  <Link
                    target="_blank"
                    to={{
                      pathname: `/relatorioComportamental?activeUser=${encodeURIComponent(
                        arrayUser.filter(i => {
                          return (
                            i.name === data[0].nome ||
                            i.email === data[0].nome ||
                            i.email === data[0].email
                          );
                        })[0].email,
                      )}`,
                      search: '',
                      hash: '',
                      state: {
                        activeUser: arrayUser.filter(i => {
                          return (
                            i.name === data[0].nome ||
                            i.email === data[0].nome ||
                            i.email === data[0].email
                          );
                        })[0].email,
                      },
                    }}
                  >
                    <Button
                      type="button"
                      width="100px"
                      hoverColor={colors.lightBlue}
                      fontSize="12px"
                      height="30px"
                      margin="0px"
                      text="Saiba mais"
                    />
                  </Link>
                ) : (
                  false
                )}
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="75%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Você não só “se adapta bem às mudanças”, mas provoca ou lidera os processos de mudanças, gerenciando resistências e encontrando aliados para ter sucesso na implementação.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Agilidade com mudanças</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="25%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.AGILIDADE_COM_MUDANCAS}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="75%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Você apresenta um grande impulso para resultados e é energizado por atribuições desafiadoras, sendo conhecido popularmente por “tirar leite de pedra”, entregando os resultados mesmo em situações extremamente críticas ou adversas, energizando-se diante de desafios complexos, construindo times de alta performance.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Agilidade com resultados</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="25%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.AGILIDADE_COM_RESULTADOS}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="75%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Você encontra soluções para problemas complexos, fazendo conexões “não óbvias” entre múltiplas variáveis, assumindo riscos e tomando decisões difíceis.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Agilidade mental</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="25%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.AGILIDADE_MENTAL}
                </DescriptionData>
              </GpItems>
            </Row>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="75%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.gray5}
              >
                <Tooltip title="Você navega de forma adequada pelos diversos níveis das organizações, podendo demonstrar certa dificuldade em lidar com situações de conflito ou negociações difíceis.">
                  <Icon float="left" src={vector} alt="Icon" />
                </Tooltip>
                <LabelData font="12px">Agilidade com pessoas</LabelData>
              </GpItems>
              <GpItems
                padding="15px"
                width="25%"
                radius="0px 12px 12px 0px"
                background={colors.lightBlue}
                borderColor={colors.lightBlue}
              >
                <DescriptionData font="14px" color={colors.white}>
                  {behavioral.AGILIDADE_COM_PESSOAS}
                </DescriptionData>
              </GpItems>
            </Row>
          </>
        ) : (
          false
        )}
        <br />
        <br />
        {culture !== null ? (
          <>
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="80%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.white}
              >
                <SubTitle>
                  Cultura predominante: <b>{culture.CULTURA_PREDOMINANTE}</b>
                </SubTitle>
              </GpItems>
              <GpItems
                padding="15px"
                width="20%"
                radius="0px 12px 12px 0px"
                background={colors.white}
                borderColor={colors.white}
              >
                {arrayUser.length > 0 && data.length > 0 ? (
                  <Link
                    target="_blank"
                    to={{
                      pathname: `/relatoriodeCultura?activeUser=${encodeURIComponent(
                        arrayUser.filter(i => {
                          return (
                            i.name === data[0].nome ||
                            i.email === data[0].nome ||
                            i.email === data[0].email
                          );
                        })[0].email,
                      )}`,
                      search: '',
                      hash: '',
                      state: {
                        activeUser: arrayUser.filter(i => {
                          return (
                            i.name === data[0].nome ||
                            i.email === data[0].nome ||
                            i.email === data[0].email
                          );
                        })[0].email,
                      },
                    }}
                  >
                    <Button
                      type="button"
                      width="100px"
                      hoverColor={colors.lightBlue}
                      fontSize="12px"
                      height="30px"
                      margin="0px"
                      text="Saiba mais"
                    />
                  </Link>
                ) : (
                  false
                )}
              </GpItems>
            </Row>
            {data.length > 0 ? (
              <Chart
                options={radar.options}
                series={radar.series}
                type="radar"
                height={400}
              />
            ) : (
              false
            )}
          </>
        ) : (
          false
        )}
        <br />
        <br />
        {career !== null ? (
          <>
            <SubTitle>Âncoras de carreira</SubTitle>
            {data.length > 0 ? (
              <Chart
                options={line.options}
                series={line.series}
                type="area"
                height={400}
              />
            ) : (
              false
            )}
            <Row top="2%" align="start" bottom="2px">
              <GpItems
                padding="10px"
                width="50%"
                radius="12px 0px 0px 12px"
                background={colors.white}
                borderColor={colors.white}
              >
                <SubTitle font="12px">1 - Horário de trabalho</SubTitle>
                <SubTitle font="12px">2 - Local de trabalho</SubTitle>
                <SubTitle font="12px">3 - Pacote de benefícios</SubTitle>
                <SubTitle font="12px">4 - Salário</SubTitle>
                <SubTitle font="12px">
                  5 - Abertura para mudanças e inovação
                </SubTitle>
                <SubTitle font="12px">6 - Celebração de conquistas</SubTitle>
                <SubTitle font="12px">
                  7 - Cultura e valores da empresa
                </SubTitle>
                <SubTitle font="12px">8 - Desafios constantes</SubTitle>
                <SubTitle font="12px">
                  9 - Gestão clara e acompanhamento dos objetivos
                </SubTitle>
                <SubTitle font="12px">
                  10 - Oportunidades de desenvolvimento e aprendizado
                </SubTitle>
                <SubTitle font="12px">
                  11 - Plano de carreira claro e estruturado
                </SubTitle>
              </GpItems>
              <GpItems
                padding="15px"
                width="50%"
                radius="0px 12px 12px 0px"
                background={colors.white}
                borderColor={colors.white}
              >
                <SubTitle font="12px">
                  12 - Possibilidades de crescimento e promoção
                </SubTitle>
                <SubTitle font="12px">
                  13 - Processos simples e desburocratizados
                </SubTitle>
                <SubTitle font="12px">
                  14 - Autonomia e abertura para se expressar
                </SubTitle>
                <SubTitle font="12px">15 - Clima interno</SubTitle>
                <SubTitle font="12px">
                  16 - Confiança na liderança imediata
                </SubTitle>
                <SubTitle font="12px">
                  17 - Confiança no time de trabalho
                </SubTitle>
                <SubTitle font="12px">
                  18 - Reconhecimento e valorização
                </SubTitle>
                <SubTitle font="12px">19 - Tratamento humanizado</SubTitle>
                <SubTitle font="12px">
                  20 - Tratamento justo e igualitário com todos
                </SubTitle>
                <SubTitle font="12px">
                  21 - Visão de futuro da empresa ou negócio
                </SubTitle>
              </GpItems>
            </Row>
          </>
        ) : (
          false
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </Slide>
  );
}

export default MenuAbout;
