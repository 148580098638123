import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import InputMask from 'react-input-mask';
import colors from '../../styles/colors';
import { validateCpf } from '../../utils/validateCpf';
import api from '../../api';

function unmaskCpf(input) {
  return input.replace(/[._-]/g, '');
}

export function AddCpfDialog({ isOpen, onClose, userId, token }) {
  const [cpf, setCpf] = useState('');
  const [invalid, setInvalid] = useState(false);

  const handleSubmitCpf = useCallback(async () => {
    await api.put(
      '/dados-candidatos/update',
      {
        user_id: userId,
        cpf: unmaskCpf(cpf),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (onClose) onClose();
  }, [cpf, onClose, token, userId]);

  useEffect(() => {
    const unmaskedCpf = unmaskCpf(cpf);
    if (unmaskedCpf.length === 11 && !validateCpf(unmaskedCpf)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [cpf]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: '1rem' } }}
    >
      <DialogTitle>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: colors.darkBlue,
            paddingY: 1,
          }}
        >
          Adicionar CPF
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontFamily: 'Poppins' }}>
          Para continuar, solicitamos que informe o seu CPF:{' '}
        </Typography>
        <InputMask
          mask="999.999.999-99"
          maskChar="_"
          value={cpf}
          onChange={e => setCpf(e.target.value)}
        >
          {() => (
            <TextField
              sx={{ marginTop: 1 }}
              label="CPF"
              id="cpf-input"
              size="small"
            />
          )}
        </InputMask>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '13px',
            paddingTop: 1,
            color: colors.alertRed,
          }}
        >
          {invalid && 'CPF inválido'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="button"
          disabled={invalid || unmaskCpf(cpf).length < 11}
          onClick={handleSubmitCpf}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
