import { Box, Paper, Typography } from '@mui/material';
import { Percent } from '@mui/icons-material';
import colors from '../../styles/colors';

export function CustomCard({ children, bgColor = colors.lightSurface }) {
  return (
    <Paper
      sx={{
        backgroundColor: bgColor,
        border: `solid 1px ${colors.cardBorder}`,
        borderRadius: '0.75rem',
        padding: '1rem',
        height: '100%',
      }}
    >
      {children}
    </Paper>
  );
}

export function CardTitle({ children, icon = true }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.75rem 0.25rem 0.75rem 1rem',
      }}
    >
      {icon && (
        <Percent
          sx={{
            padding: '14px',
            fontSize: '48px',
            backgroundColor: colors.lightBlue,
            color: colors.white,
            borderRadius: '50%',
            marginRight: '12px',
          }}
        />
      )}
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontFamily: 'Poppins',
          fontSize: '1.5rem',
          fontWeight: 700,
          color: colors.darkBlue,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
}

export function CardContent({ children }) {
  return (
    <Box
      sx={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
}
