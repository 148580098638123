import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { AddCircle } from '@mui/icons-material';
import { useQuery } from 'react-query';
import colors from '../../styles/colors';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from '../../components/ErrorModal';
import NoticeModal from '../../components/NoticeModal';
import api from '../../api';
import AlertModal from '../../components/AlertModal';

import {
  ContainerCard,
  Container,
  Label,
  Card,
  Row,
  ErrorMessage,
  GroupItems,
  SubTitle,
  CardFilter,
  GroupSearch,
  GpItems,
  Title,
} from './styles';
import { SoftSkillDrawer } from '../../components/SoftSkillsDrawer';
import { CompanySelector } from '../../components/CompanySelector';
import { DataNotFound } from './DataNotFount';
import { listAllCompanies } from '../../api/companies';

const graphColors = ['#7cc1dc', '#bd71a0', '#f7cb63', '#86da6f', '#ee19e4'];

function FitCollaborators() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [notice, setNotice] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [companieSelected, setCompanieSelected] = useState(0);
  const [msg, setMsg] = useState('');
  const [display, setDisplay] = useState(false);
  const [width, setWidth] = useState(window.screen.width);
  const [userSelected, setUserSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [job, setJob] = useState([]);
  const [error, setError] = useState(false);
  const [disabledCandidate, setDisabledCandidate] = useState(true);
  const [disabledJob, setDisabledJob] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [jobSelected, setJobSelected] = useState(0);
  const [arrayholder, setArrayholder] = useState([]);
  const [line, setLine] = useState([]);
  const [bar, setBar] = useState([]);
  const [agilities, setAgilities] = useState([]);
  const [cultural, setCultural] = useState([]);
  const [hardskillsBar, setHardskillsBar] = useState({});
  const [dataFit, setDataFit] = useState([]);
  const [useLocationState, setUseLocationState] = useState(true);
  const [openSlide, setOpenSlite] = useState(false);
  const [selectedSoftSkill, setSelectedSoftSkill] = useState({});
  const location = useLocation();
  const { data: allCompanies } = useQuery('listAllCompanies', listAllCompanies);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });
  const [allCandidates, setAllCandidates] = useState([]);

  const handleUser = e => {
    e.preventDefault();
    const {
      target: { value },
    } = e;
    if (userSelected.includes(0)) {
      userSelected.splice(0, 1);
    }
    setAlertError(false);
    setUserSelected(value);
  };

  const getNewData = useCallback(
    async newDataSelected => {
      const newData = arrayholder.filter(item => {
        return item.company_id === newDataSelected;
      });
      setJob(newData);
    },
    [arrayholder],
  );

  const handleCompany = useCallback(
    async newValue => {
      if (newValue > 0) {
        await getNewData(newValue);
        setDisabledJob(false);
      }
      setCompanieSelected(newValue);
      setJobSelected(0);
      setUserSelected([]);
      setDisplay(false);
    },
    [getNewData],
  );

  const getCandidate = useCallback(
    async id => {
      try {
        const newEmails = [];
        const response = await api.get(`/companies/users/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        });
        const { data } = response.data;
        await data.forEach(item => {
          newEmails.push(item.email);
        });
        setAllCandidates(
          data.sort((a, b) =>
            a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1,
          ),
        );
      } catch (err) {
        setError(true);
        console.log(err);
      }
    },
    [token],
  );

  const handleJob = useCallback(
    careerId => {
      setJobSelected(careerId);
      setUserSelected([]);
      setSelectedCandidate(
        allCandidates.filter(item => item.carreira_id === careerId),
      );
    },
    [allCandidates],
  );

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        setCompanies([]);
      } else {
        const data = userGlobal;
        setCompanies(data);
      }
    },
    [userGlobal],
  );

  const getName = useCallback(
    email => {
      return selectedCandidate
        .filter(item => email === item.email)
        .map(item => item.name);
    },
    [selectedCandidate],
  );

  const getLevel = txt => {
    if (txt === 'Baixa') return 25;
    if (txt === 'Moderada') return 50;
    return 100;
  };

  const getReport = useCallback(async () => {
    if (userSelected.length > 0 && jobSelected > 0 && companieSelected > 0) {
      const emails = userSelected;
      const body = {
        emails,
        carreira_id: jobSelected,
      };
      await api
        .post('/persona/fit', body, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const data = response.data.DATA;
          const json = response.data;
          if (json.message) {
            setMsg(
              'Não foi encontrado persona para os candidatos selecionados',
            );
            setNotice(true);
          } else {
            setDataFit(data);
            const chatSeries = [];
            const barSeries = [];
            const hardskillsSeries = [];
            const agilitiesSeries = [];
            const culturalSeries = [];
            const behaviorSeries = [];
            const { persona } = job.find(j => j.id === jobSelected);
            await data.map(async (item, index) => {
              if (item.EMAIL) {
                const nameUser = getName(item.EMAIL);
                const values = item.FITS.VALORES ?? [];
                const fits = item.FITS ?? [];
                const hskills = item.HARD_SKILLS ?? [];
                const result = item;
                chatSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  data: [
                    values.ABERTURA_PARA_MUDANCAS_E_INOVACAO ?? 0,
                    values.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR ?? 0,
                    values.CELEBRACAO_DE_CONQUISTAS ?? 0,
                    values.CLIMA_INTERNO ?? 0,
                    values.CONFIANCA_NA_LIDERANCA_IMEDIATA ?? 0,
                    values.CONFIANCA_NO_TIME_DE_TRABALHO ?? 0,
                    values.CULTURA_E_VALORES_DA_EMPRESA ?? 0,
                    values.DESAFIOS_CONSTANTES ?? 0,
                    values.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS ?? 0,
                    values.HORARIO_DE_TRABALHO ?? 0,
                    values.LOCAL_DE_TRABALHO ?? 0,
                    values.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO ?? 0,
                    values.PACOTE_DE_BENEFICIOS ?? 0,
                    values.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO ?? 0,
                    values.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO ?? 0,
                    values.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS ?? 0,
                    values.RECONHECIMENTO_E_VALORIZACAO ?? 0,
                    values.SALARIO ?? 0,
                    values.TRATAMENTO_HUMANIZADO ?? 0,
                    values.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS ?? 0,
                    values.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO ?? 0,
                  ],
                });
                barSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  data: [
                    Math.round(fits.VALORES_CARREIRA ?? 0),
                    Math.round(fits.COMPORTAMENTAL ?? 0),
                    Math.round(fits.LEARN_AGILITIES ?? 0),
                    Math.round(fits.CULTURAL ?? 0),
                    Math.round(fits.HARD_SKILLS ?? 0),
                    Math.round(fits.FIT_PERSONA_IDEAL ?? 0),
                  ],
                });
                hardskillsSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  data: hskills
                    ? persona?.hard_skills?.map(hs => {
                        const hskill = hskills.find(
                          skill => skill.id === hs.id,
                        );
                        return hskill?.valor ?? 0;
                      })
                    : [],
                });
                agilitiesSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  type: 'bar',
                  data: [
                    result.AGILIDADE_MENTAL ?? 0,
                    result.AGILIDADE_COM_PESSOAS ?? 0,
                    result.AGILIDADE_COM_MUDANCAS ?? 0,
                    result.AGILIDADE_COM_RESULTADOS ?? 0,
                    result.POTENCIAL ?? 0,
                  ],
                });
                culturalSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  data: [
                    result.ACOLHIMENTO ?? 0,
                    result.PROPOSITO ?? 0,
                    result.APRENDIZADO ?? 0,
                    result.PRAZER ?? 0,
                    result.RESULTADOS ?? 0,
                    result.AUTORIDADE ?? 0,
                    result.SEGURANÇA ?? 0,
                    result.ORDEM ?? 0,
                  ],
                });
                behaviorSeries.push({
                  name: nameUser,
                  color: graphColors[index],
                  data: [
                    result.DESBRAVADOR ?? 0,
                    result.EMBAIXADOR ?? 0,
                    result.MANTENEDOR ?? 0,
                    result.INVESTIGADOR ?? 0,
                  ],
                });
              }
            });
            chatSeries.push({
              name: 'PERSONA IDEAL',
              color: '#ff0000',
              data: [
                persona?.valores_de_carreira
                  ?.ABERTURA_PARA_MUDANCAS_E_INOVACAO ?? 0,
                persona?.valores_de_carreira
                  ?.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR ?? 0,
                persona?.valores_de_carreira?.CELEBRACAO_DE_CONQUISTAS ?? 0,
                persona?.valores_de_carreira?.CLIMA_INTERNO ?? 0,
                persona?.valores_de_carreira?.CONFIANCA_NA_LIDERANCA_IMEDIATA ??
                  0,
                persona?.valores_de_carreira?.CONFIANCA_NO_TIME_DE_TRABALHO ??
                  0,
                persona?.valores_de_carreira?.CULTURA_E_VALORES_DA_EMPRESA ?? 0,
                persona?.valores_de_carreira?.DESAFIOS_CONSTANTES ?? 0,
                persona?.valores_de_carreira
                  ?.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS ?? 0,
                persona?.valores_de_carreira?.HORARIO_DE_TRABALHO ?? 0,
                persona?.valores_de_carreira?.LOCAL_DE_TRABALHO ?? 0,
                persona?.valores_de_carreira
                  .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO ?? 0,
                persona?.valores_de_carreira?.PACOTE_DE_BENEFICIOS ?? 0,
                persona?.valores_de_carreira
                  ?.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO ?? 0,
                persona?.valores_de_carreira
                  ?.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO ?? 0,
                persona?.valores_de_carreira
                  ?.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS ?? 0,
                persona?.valores_de_carreira?.RECONHECIMENTO_E_VALORIZACAO ?? 0,
                persona?.valores_de_carreira?.SALARIO ?? 0,
                persona?.valores_de_carreira?.TRATAMENTO_HUMANIZADO ?? 0,
                persona?.valores_de_carreira
                  ?.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS ?? 0,
                persona?.valores_de_carreira
                  ?.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO ?? 0,
              ],
            });
            culturalSeries.push({
              name: 'PERSONA IDEAL',
              color: '#ff0000',
              data: [
                persona?.cultural?.ACOLHIMENTO ?? 0,
                persona?.cultural?.PROPOSITO ?? 0,
                persona?.cultural?.APRENDIZADO ?? 0,
                persona?.cultural?.PRAZER ?? 0,
                persona?.cultural?.RESULTADOS ?? 0,
                persona?.cultural?.AUTORIDADE ?? 0,
                persona?.cultural?.SEGURANÇA ?? 0,
                persona?.cultural?.ORDEM ?? 0,
              ],
            });
            agilitiesSeries.push({
              name: 'PERSONA IDEAL',
              type: 'line',
              color: '#ff0000',
              data: [
                getLevel(persona?.learn_agilities?.AGILIDADE_MENTAL ?? 0),
                getLevel(persona?.learn_agilities?.AGILIDADE_COM_PESSOAS ?? 0),
                getLevel(persona?.learn_agilities?.AGILIDADE_COM_MUDANCAS ?? 0),
                getLevel(
                  persona?.learn_agilities?.AGILIDADE_COM_RESULTADOS ?? 0,
                ),
                getLevel(persona?.learn_agilities?.POTENCIAL ?? 0),
              ],
            });
            hardskillsSeries.push({
              name: 'PERSONA IDEAL',
              color: '#ff0000',
              data: persona?.hard_skills?.map(hskill => {
                if (!Number.isNaN(hskill.valor)) return hskill.valor;
                const options = {
                  Sim: 10,
                  Não: 0,
                  Básico: 2,
                  Intermediário: 5,
                  Avançado: 7,
                  Fluente: 10,
                };
                return options[hskill.valor];
              }),
            });
            setLine({
              series: chatSeries,
              options: {
                chart: {
                  height: 350,
                  type: 'area',
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth',
                },
                xaxis: {
                  type: 'number',
                  categories: [
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                  ],
                },
              },
            });
            setAgilities({
              series: agilitiesSeries,
              options: {
                chart: {
                  type: 'bar',
                  height: 350,
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent'],
                },
                xaxis: {
                  categories: [
                    'AGILIDADE MENTAL',
                    'AGILIDADE COM PESSOAS',
                    'AGILIDADE COM MUDANCAS',
                    'AGILIDADE COM RESULTADOS',
                    'POTENCIAL',
                  ],
                },
                yaxis: {
                  title: {
                    text: 'Nível',
                  },
                  min: 0,
                  max: 100,
                },
                annotations: {
                  yaxis: [
                    {
                      y: 0,
                      y2: 33,
                      borderColor: '#000',
                      fillColor: '#ffd0d0',
                      label: {
                        text: 'Baixa',
                        offsetX: -10,
                      },
                    },
                    {
                      y: 33,
                      y2: 66,
                      borderColor: '#000',
                      fillColor: '#fffad0',
                      label: {
                        text: 'Moderada',
                      },
                    },
                    {
                      y: 66,
                      y2: 100,
                      borderColor: '#000',
                      fillColor: '#e5fef0',
                      label: {
                        text: 'Alta',
                      },
                    },
                  ],
                },
                fill: {
                  opacity: 1,
                },
                tooltip: {
                  y: {
                    formatter(val) {
                      return val;
                    },
                  },
                },
              },
            });
            setBar({
              series: barSeries,
              options: {
                chart: {
                  type: 'bar',
                  height: 430,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top',
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: -6,
                  style: {
                    fontSize: '12px',
                    colors: ['#fff'],
                  },
                },
                stroke: {
                  show: true,
                  width: 1,
                  colors: ['#fff'],
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                },
                xaxis: {
                  categories: [
                    'VALORES DE CARREIRA',
                    'COMPORTAMENTAL',
                    'LEARN AGILITIES',
                    'CULTURAL',
                    'HARDSKILLS',
                    'FIT PERSONA IDEAL',
                  ],
                },
              },
            });
            setHardskillsBar({
              series: hardskillsSeries,
              options: {
                chart: {
                  type: 'bar',
                  height: 430,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top',
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: -6,
                  style: {
                    fontSize: '12px',
                    colors: ['#fff'],
                  },
                },
                stroke: {
                  show: true,
                  width: 1,
                  colors: ['#fff'],
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                },
                xaxis: {
                  categories: persona?.hard_skills?.map(hskill => hskill.nome),
                },
              },
            });
            setCultural({
              series: culturalSeries,
              options: {
                chart: {
                  height: 350,
                  type: 'area',
                  dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1,
                  },
                },
                title: {
                  text: '',
                },
                stroke: {
                  width: 2,
                },
                fill: {
                  opacity: 0.1,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  categories: [
                    'ACOLHIMENTO',
                    'PROPOSITO',
                    'APRENDIZADO',
                    'PRAZER',
                    'RESULTADOS',
                    'AUTORIDADE',
                    'SEGURANÇA',
                    'ORDEM',
                  ],
                },
                yaxis: {
                  max: 100,
                },
              },
            });

            setDisplay(true);
          }
        })
        .catch(err => {
          setError(true);
          console.log(err);
        });
    } else {
      setAlertError(true);
    }
  }, [companieSelected, getName, job, jobSelected, token, userSelected]);

  const getJob = useCallback(async () => {
    if (companieSelected) {
      const { data } = await api.get(
        `/companies/carreiras/${companieSelected}`,
        {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        },
      );
      setJob(data);
      setArrayholder(data);
    }
  }, [companieSelected, token]);

  const onCloseError = () => {
    setError(false);
  };

  const onCloseNotice = () => {
    setNotice(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const fieldRow1 = width <= 570 ? '80%' : '54%';
  const fieldRow3 = width <= 570 ? '80%' : '89.5%';

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      getCompanies(profileGlobal.perfil_id);
      getJob();
      setWidth(window.screen.width);
    }
  }, [getCompanies, getJob, profileGlobal]);

  useEffect(() => {
    if (
      useLocationState &&
      location.state?.careerId &&
      location.state?.companyId &&
      location.state?.emails &&
      allCompanies?.length
    ) {
      handleCompany(location.state.companyId).then(() => {
        setInitialCompany(
          allCompanies.find(company => company.id === location.state.companyId),
        );
        handleJob(location.state.careerId);
        getCandidate(location.state.companyId).then(() => {
          setUserSelected(location.state.emails);
          setUseLocationState(false);
          window.history.replaceState({}, document.title);
        });
      });
    }
  }, [
    allCompanies,
    getCandidate,
    handleCompany,
    handleJob,
    location.state?.careerId,
    location.state?.companyId,
    location.state?.emails,
    useLocationState,
  ]);

  useEffect(() => {
    if (userSelected.length > 0 && jobSelected > 0 && companieSelected > 0) {
      getReport();
    }
  }, [companieSelected, getReport, jobSelected, userSelected.length]);

  useEffect(() => {
    if (companieSelected) {
      getCandidate(companieSelected);
      setDisabledCandidate(false);
    }
  }, [companieSelected, getCandidate]);

  return (
    <Container>
      <ResponsiveAppBar />
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <NoticeModal isOpen={notice} msg={msg} onRequestClose={onCloseNotice} />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Title>Comparativo Fit Colaboradores</Title>
      <CardFilter>
        <Label>Filtrar sua busca</Label>
        <GroupSearch>
          <CompanySelector
            fullWidth={false}
            width={320}
            options={companies}
            label={<em>Procurar por empresa</em>}
            onChange={company => handleCompany(company?.id)}
            initialState={initialCompany}
          />

          <FormControl sx={{ ml: 1, width: fieldRow1 }}>
            <Select
              sx={{ textAlign: 'left' }}
              value={jobSelected}
              onChange={e => handleJob(e.target.value)}
              disabled={disabledJob}
              autoWidth
              size="small"
              color="info"
            >
              <MenuItem value={0}>
                <em>Procurar por carreira</em>
              </MenuItem>
              {job.length > 0
                ? job.map(item => {
                    return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                  })
                : false}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: fieldRow3 }}>
            <Select
              value={userSelected}
              onChange={handleUser}
              disabled={disabledCandidate}
              autoWidth
              size="small"
              color="info"
              multiple
              sx={{
                textAlign: 'left',
              }}
            >
              <MenuItem value={0}>
                <em>Procurar por colaborador</em>
              </MenuItem>
              {selectedCandidate.length > 0
                ? selectedCandidate.map(item => {
                    return <MenuItem value={item.email}>{item.name}</MenuItem>;
                  })
                : false}
            </Select>
          </FormControl>
        </GroupSearch>
        <GroupSearch>
          <ErrorMessage color={colors.alertRed}>
            {alertError ? 'É necessário selecionar todos os campos!' : ''}
          </ErrorMessage>
        </GroupSearch>
      </CardFilter>

      {display && userSelected.length > 0 && (
        <>
          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Fit Geral</SubTitle>
                <Typography>
                  Este card oferece uma visão abrangente do alinhamento entre os
                  indivíduos e a persona ideal definida pela empresa. Diversas
                  métricas, que vão desde valores de carreira até hard skills,
                  são apresentadas de forma clara e objetiva.
                </Typography>
                {bar.series.length > 0 ? (
                  <Chart
                    options={bar.options}
                    series={bar.series}
                    type="bar"
                    height={400}
                  />
                ) : (
                  <DataNotFound />
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Fit do Perfil Comportamental</SubTitle>
                <Typography>
                  O Fit Comportamental é crucial para entender como os
                  comportamentos dos indivíduos se encaixam na dinâmica de uma
                  equipe ou empresa. Ele fornece um panorama detalhado sobre
                  diferentes traços comportamentais e competências emocionais.
                </Typography>
                {dataFit.length > 0 ? (
                  <Chart
                    options={{
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: 'top',
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: '14px',
                          colors: ['#fff'],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ['#fff'],
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                      },
                      xaxis: {
                        categories: [
                          'Persona Ideal',
                          ...userSelected.map(item => getName(item)),
                        ],
                      },
                      yaxis: {
                        max: 100,
                      },
                    }}
                    series={[
                      {
                        name: 'Desbravador',
                        color: '#7cc1dc',
                        data: [
                          job.find(j => j.id === jobSelected)?.persona
                            ?.perfil_comportamental.DESBRAVADOR,
                          ...dataFit
                            .filter(user => user.EMAIL)
                            .map(user => user.DESBRAVADOR),
                        ],
                      },
                      {
                        name: 'Embaixador',
                        color: '#eb6e85',
                        data: [
                          job.find(j => j.id === jobSelected)?.persona
                            ?.perfil_comportamental.EMBAIXADOR,
                          ...dataFit
                            .filter(user => user.EMAIL)
                            .map(user => user.EMBAIXADOR),
                        ],
                      },
                      {
                        name: 'Mantenedor',
                        color: '#f7cb63',
                        data: [
                          job.find(j => j.id === jobSelected)?.persona
                            ?.perfil_comportamental.MANTENEDOR,
                          ...dataFit
                            .filter(user => user.EMAIL)
                            .map(user => user.MANTENEDOR),
                        ],
                      },
                      {
                        name: 'Investigador',
                        color: '#86da6f',
                        data: [
                          job.find(j => j.id === jobSelected)?.persona
                            ?.perfil_comportamental.INVESTIGADOR,
                          ...dataFit
                            .filter(user => user.EMAIL)
                            .map(user => user.INVESTIGADOR),
                        ],
                      },
                    ]}
                    type="bar"
                  />
                ) : (
                  <DataNotFound />
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Learn Agilities</SubTitle>
                <Typography>
                  Este card concentra-se na adaptabilidade e capacidade de
                  aprendizado. Ele mostra como os indivíduos estão preparados
                  para enfrentar mudanças e desafios, avaliando diferentes tipos
                  de agilidades no aprendizado.
                </Typography>
                {agilities.series.length > 0 ? (
                  <Chart
                    options={agilities.options}
                    series={agilities.series}
                    height={400}
                  />
                ) : (
                  <DataNotFound />
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Fit Cultural</SubTitle>
                <Typography>
                  A importância do alinhamento cultural é o foco deste card. Um
                  gráfico de teia é utilizado para representar como os
                  indivíduos se alinham com os diferentes aspectos culturais da
                  empresa.
                </Typography>
                {cultural.series.length > 0 ? (
                  <Chart
                    options={cultural.options}
                    series={cultural.series}
                    type="area"
                    height={600}
                  />
                ) : (
                  <DataNotFound />
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Softskills</SubTitle>
                <Typography>
                  Este card detalha a proficiência em uma variedade de soft
                  skills. Ele serve para ilustrar como as habilidades sociais e
                  emocionais dos indivíduos complementam suas habilidades
                  técnicas ou hard skills.
                </Typography>
                <table
                  style={{
                    marginTop: '16px',
                    fontWeight: 400,
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: colors.blue,
                      color: '#fff',
                    }}
                  >
                    <tr>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Candidato
                      </th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Fit persona ideal
                      </th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Fit comportamental
                      </th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Fit learning agilities
                      </th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Fit cultural
                      </th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>
                        Fit Hardskills
                      </th>
                      <th style={{ textAlign: 'center', padding: '8px' }}>
                        Softskills
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFit
                      .filter(item => item.EMAIL)
                      .sort((a, b) =>
                        a.FITS.FIT_PERSONA_IDEAL > b.FITS.FIT_PERSONA_IDEAL
                          ? -1
                          : 1,
                      )
                      .map(item => (
                        <tr>
                          <td style={{ padding: '8px' }}>
                            {getName(item.EMAIL)}
                          </td>
                          <td style={{ padding: '8px' }}>
                            {parseFloat(
                              item.FITS.FIT_PERSONA_IDEAL ?? '0',
                            ).toFixed(2)}
                            %
                          </td>
                          <td style={{ padding: '8px' }}>
                            {parseFloat(
                              item.FITS.COMPORTAMENTAL ?? '0',
                            ).toFixed(2)}
                            %
                          </td>
                          <td style={{ padding: '8px' }}>
                            {parseFloat(
                              item.FITS.LEARN_AGILITIES ?? '0',
                            ).toFixed(2)}
                            %
                          </td>
                          <td style={{ padding: '8px' }}>
                            {parseFloat(item.FITS.CULTURAL ?? '0').toFixed(2)}%
                          </td>
                          <td style={{ padding: '8px' }}>
                            {parseFloat(item.FITS.HARD_SKILLS ?? '0').toFixed(
                              2,
                            )}
                            %
                          </td>
                          <td style={{ textAlign: 'center', padding: '8px' }}>
                            <AddCircle
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedSoftSkill(item.SOFT_SKILLS);
                                setOpenSlite(true);
                              }}
                              style={{ color: colors.blue }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </GroupItems>
            </Card>
          </ContainerCard>
          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Hardskills</SubTitle>
                <Typography>
                  No Fit Hardskills, analisamos a compatibilidade das
                  habilidades técnicas do usuário com as exigidas para a
                  carreira. Através de uma avaliação detalhada, identificamos o
                  nível de alinhamento e identificamos possíveis áreas de
                  aperfeiçoamento. Isso oferece uma visão clara das competências
                  técnicas do usuário.
                </Typography>
                {hardskillsBar.series.length > 0 ? (
                  <Chart
                    options={hardskillsBar.options}
                    series={hardskillsBar.series}
                    type="bar"
                    height={400}
                  />
                ) : (
                  <DataNotFound />
                )}
              </GroupItems>
            </Card>
          </ContainerCard>
          <ContainerCard>
            <Card>
              <GroupItems>
                <SubTitle>Âncoras de carreira</SubTitle>
                <Typography>
                  Este último card explora as “âncoras de carreira”, que são os
                  fatores valorizados pelos indivíduos em suas carreiras.
                  Aspectos como local de trabalho, benefícios e cultura
                  empresarial são avaliados para fornecer uma visão completa do
                  que é mais importante para eles.
                </Typography>
                {line.series.length > 0 ? (
                  <Chart
                    options={line.options}
                    series={line.series}
                    type="area"
                    height={400}
                  />
                ) : (
                  <DataNotFound />
                )}
                <Row top="2%" align="start" bottom="2px">
                  <GpItems
                    padding="10px"
                    width="50%"
                    radius="12px 0px 0px 12px"
                    background={colors.white}
                    borderColor={colors.white}
                  >
                    <SubTitle font="12px">
                      1 - Abertura para mudanças e inovação
                    </SubTitle>
                    <SubTitle font="12px">
                      2 - Autonomia e abertura para se expressar
                    </SubTitle>
                    <SubTitle font="12px">
                      3 - Celebração de conquistas
                    </SubTitle>
                    <SubTitle font="12px">4 - Clima interno</SubTitle>
                    <SubTitle font="12px">
                      5 - Confiança na liderança imediata
                    </SubTitle>
                    <SubTitle font="12px">
                      6 - Confiança no time de trabalho
                    </SubTitle>
                    <SubTitle font="12px">
                      7 - Cultura e valores da empresa
                    </SubTitle>
                    <SubTitle font="12px">8 - Desafios constantes</SubTitle>
                    <SubTitle font="12px">
                      9 - Gestão clara e acompanhamento dos objetivos
                    </SubTitle>
                    <SubTitle font="12px">10 - Horário de Trabalho</SubTitle>
                    <SubTitle font="12px">11 - Local de Trabalho</SubTitle>
                  </GpItems>
                  <GpItems
                    padding="15px"
                    width="50%"
                    radius="0px 12px 12px 0px"
                    background={colors.white}
                    borderColor={colors.white}
                  >
                    <SubTitle font="12px">
                      12 - Oportunidades de desenvolvimento e aprendizado
                    </SubTitle>
                    <SubTitle font="12px">13 - Pacote de benefícios</SubTitle>
                    <SubTitle font="12px">
                      14 - Plano de carreira claro e estruturado
                    </SubTitle>
                    <SubTitle font="12px">
                      15 - Possibilidades de crescimento e promoção
                    </SubTitle>
                    <SubTitle font="12px">
                      16 - Processos simples e desburocratizados
                    </SubTitle>
                    <SubTitle font="12px">17 - Salário</SubTitle>
                    <SubTitle font="12px">
                      18 - Reconhecimento e valorização
                    </SubTitle>
                    <SubTitle font="12px">19 - Tratamento humanizado</SubTitle>
                    <SubTitle font="12px">
                      20 - Tratamento justo e igualitário com todos
                    </SubTitle>
                    <SubTitle font="12px">
                      21 - Visão de futuro da empresa ou negócio
                    </SubTitle>
                  </GpItems>
                </Row>
              </GroupItems>
            </Card>
          </ContainerCard>
        </>
      )}
      <SoftSkillDrawer
        isOpen={openSlide}
        onClose={() => setOpenSlite(false)}
        softSkills={{
          user: selectedSoftSkill,
          persona: job.find(j => j.id === jobSelected)?.persona?.soft_skills,
        }}
      />
    </Container>
  );
}

export default FitCollaborators;
