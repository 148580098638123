import React from 'react';
import Context from './Context';
import useStorage from '../../utils/useStorage';

function StoreProvider({ children }) {
  const [token, setToken] = useStorage('token');
  const [loginHash, setLoginHash] = useStorage('loginHash');
  const [userGlobal, setUserGlobal] = useStorage('userGlobal');
  const [profileGlobal, setProfileGlobal] = useStorage('profileGlobal');
  const [uuidSession, setUuidSession] = useStorage('uuidSession');

  return (
    <Context.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        token,
        setToken,
        userGlobal,
        setUserGlobal,
        profileGlobal,
        setProfileGlobal,
        loginHash,
        setLoginHash,
        uuidSession,
        setUuidSession,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default StoreProvider;
