import React, { useState, useEffect, useContext, useCallback } from 'react';
import moment from 'moment';
import { Box, Chip, CircularProgress, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import ErrorModal from '../../components/ErrorModal';
import PlusBlue from '../../assets/img/PlusBlue.svg';
import MenuHardskill from '../../components/Menu/MenuHardskill';
import DeleteModal from '../../components/DeleteModal';
import MenuExperience from '../../components/Menu/MenuExperience';
import BtnEdit from '../../assets/img/BtnEdit.svg';

import {
  Container,
  Title,
  Card,
  Label,
  ContainerCard,
  GroupItems,
  SubTitle,
  Wrap,
  Row,
  Icon,
  Group,
  Line,
} from './styles';
import { userXpLevels } from '../../utils/xpLevels';

function JobsApplied() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [isVisible, setIsVisible] = useState(false);
  const [hardskill, setHardskill] = useState([]);
  const [object, setObject] = useState([]);
  const [user, setUser] = useState([]);
  const [dataCompanies, setDataCompanies] = useState([]);
  const [dataExperiencies, setDataExperiencies] = useState([]);
  const [error, setError] = useState(false);
  const [cardHardsKill, setCardHardskill] = useState(false);
  const [hasExperiencies, setHasExperiencies] = useState(false);
  const [cardExperiencies, setCardExperiencies] = useState(false);
  const [del, setDel] = useState(false);
  const [selectedHS, setSelectedHS] = useState('');
  const [dataCareer, setDataCareer] = useState('');
  const [active, setActive] = useState({});
  const history = useHistory();

  const onCardHardsKills = () => {
    setCardHardskill(true);
  };

  const editExperience = async arr => {
    setHasExperiencies(false);
    setActive(arr);
    setCardExperiencies(true);
  };

  const newExperience = async () => {
    setHasExperiencies(false);
    setActive([]);
    setCardExperiencies(true);
  };

  const onSaveHS = async id => {
    setSelectedHS(id);
    setDel(true);
  };

  const getExperiencies = useCallback(
    async id => {
      await api
        .get(`/dados-candidatos/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          setDataExperiencies(data);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token],
  );

  const getData = useCallback(
    async id => {
      await api
        .get(`/vagas/user/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;

          setObject(data);
          setIsVisible(true);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token],
  );

  const getCareer = useCallback(
    async id => {
      await api
        .get(`/carreiras/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          setDataCareer(data);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token],
  );

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.users;
        const u = data.filter(item => {
          return item.email === token.email;
        });
        setUser(u);
        getCareer(u[0].carreira_id);
        getExperiencies(u[0].id);
        getData(u[0].id);
      })
      .catch(() => {
        setError(true);
      });
  }, [getCareer, getData, getExperiencies, token]);

  const deleteHS = async id => {
    await api
      .delete(`user-hardskill/${id}/${user[0].id}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setDel(false);
        getUsers();
      })
      .catch(() => {
        setDel(false);
        setError(true);
      });
  };

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.vacancy}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.description}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.salary}
      </th>
      <th key={index}>{item.status}</th>
    </>
  );

  const renderBody = (item, index) => {
    const json = dataCompanies.filter(i => {
      return item.vagas[0].company_id === i.id;
    });
    return (
      <tr key={index}>
        <td>{json.length > 0 ? json[0].nome_fantasia : '--'}</td>
        <td className={width <= 570 ? 'hidden' : ''}>{item.vagas[0].nome}</td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.vagas[0].descricao}
        </td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {`${item.vagas[0].faixa_salarial_inicial} - ${item.vagas[0].faixa_salarial_final}`}
        </td>
        <td>{item.vagas[0].status === 0 ? 'Inativa' : 'Ativa'}</td>
      </tr>
    );
  };

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        await api
          .get('/companies', {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          })
          .then(async response => {
            const data = response.data.response;
            setDataCompanies(data);
          })
          .catch(() => {
            setError(true);
          });
      } else {
        const data = userGlobal;
        setDataCompanies(data);
      }
    },
    [token, userGlobal],
  );

  const companies = {
    head: [
      {
        name: 'Nome da empresa',
        vacancy: 'Vaga',
        description: 'Descrição',
        salary: 'Faixa salarial',
        status: 'Status',
      },
    ],
    body: object,
  };

  const onCloseError = () => {
    setError(false);
  };

  const getHardskill = useCallback(async () => {
    await api
      .get('/hardskill', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data } = response.data;
        setHardskill(data);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const onClose = useCallback(async () => {
    await getHardskill();
    setCardHardskill(false);
    setCardExperiencies(false);
    await getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      setWidth(window.screen.width);
      getCompanies(profileGlobal.perfil_id);
      getHardskill();
      getUsers();
    }
  }, [getCompanies, getHardskill, getUsers, profileGlobal.perfil_id, width]);

  return (
    <Container>
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      {cardExperiencies ? (
        <MenuExperience
          active={active}
          user={user}
          isOpen={cardExperiencies}
          onClose={onClose}
        />
      ) : (
        false
      )}
      {cardHardsKill ? (
        <MenuHardskill user={user} isOpen={cardHardsKill} onClose={onClose} />
      ) : (
        false
      )}
      <DeleteModal
        isOpen={del}
        onRequestClose={() => setDel(false)}
        title="Tem certeza que deseja desvincular a hardskill?"
        onNext={() => deleteHS(selectedHS)}
      />
      <ResponsiveAppBar />
      <Title>Meu painel de vagas e carreiras</Title>
      {isVisible && dataCareer.length > 0 && (
        <ContainerCard top="35%" bottom="2%">
          <Card>
            <Label>Minha carreira</Label>
            <GroupItems padding="2%">
              <SubTitle font="13px" top="0%" bottom="0.5%">
                {dataCareer[0].nome}
              </SubTitle>
              <SubTitle weight="400" font="12px" top="0%" bottom="0.5%">
                {dataCareer[0].descricao}
              </SubTitle>
              <Box sx={{ display: 'flex' }}>
                <i style={{ marginLeft: 'auto' }}>
                  <Button
                    width={300}
                    text="Calibrar habilidades técnicas para esta carreira"
                    hoverColor={colors.lightBlue}
                    onClick={() =>
                      history.push({
                        pathname: '/calibrarHardskillCarreira',
                      })
                    }
                  />
                </i>
              </Box>
            </GroupItems>
          </Card>
        </ContainerCard>
      )}

      {isVisible && (
        <ContainerCard top="30%" bottom="2%">
          <Card width="100%" heigth="300px">
            <Row top="0%" content="space-between">
              <Label>Minhas experiências profissionais</Label>
              <Button
                type="button"
                width="230px"
                fontSize="13px"
                text="Adicionar experiência"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={newExperience}
                icon={
                  <Icon
                    width="40px"
                    float="left"
                    left="20px"
                    right="-20px"
                    src={PlusBlue}
                    alt="Item"
                  />
                }
              />
            </Row>

            {dataExperiencies.map(item => {
              if (item.experiencias.length > 0) {
                setHasExperiencies(true);
                return (
                  <>
                    <Row top="0%" bottom="0%" content="space-between">
                      <Group bottom="-20px" width="30%">
                        <SubTitle bottom="0%" top="3px" font="16px">
                          {item.experiencias[0].empresa}
                        </SubTitle>
                      </Group>
                      <Group bottom="-20px" width="70%" right="0px">
                        <Button
                          type="button"
                          width="100px"
                          hoverColor={colors.lightBlue}
                          text="Editar"
                          fontSize="12px"
                          onClick={() => editExperience(item.experiencias)}
                          icon={
                            <Icon
                              width="30px"
                              float="left"
                              left="10px"
                              right="-20px"
                              src={BtnEdit}
                              alt="Item"
                            />
                          }
                        />
                      </Group>
                    </Row>

                    <SubTitle bottom="0%" top="3px" font="14px" weight="400">
                      {item.experiencias[0].cargo}
                    </SubTitle>
                    <SubTitle
                      bottom="0%"
                      top="3px"
                      font="12px"
                      weight="400"
                      color={colors.gray4}
                    >
                      {`${moment(item.experiencias[0].periodo_de).format(
                        'DD/MM/YYYY',
                      )} - ${
                        item.experiencias[0].periodo_ate
                          ? moment(item.experiencias[0].periodo_ate).format(
                              'DD/MM/YYYY',
                            )
                          : 'até o momento'
                      }`}
                    </SubTitle>
                    <SubTitle bottom="0%" font="12px" weight="400">
                      {item.experiencias[0].descricao}
                    </SubTitle>
                    <Line />
                  </>
                );
              }
              return <span />;
            })}
            {!hasExperiencies && (
              <SubTitle align="center" font="14px">
                Você não possui experiências profissionais cadastradas!
              </SubTitle>
            )}
          </Card>
        </ContainerCard>
      )}

      {isVisible && (
        <ContainerCard top="25%" bottom="2%">
          <Card width="100%" heigth="300px">
            <Row top="0%" content="space-between">
              <Label>Minhas hardskills</Label>
              <Button
                type="button"
                width="230px"
                fontSize="13px"
                text="Adicionar hardskill"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onCardHardsKills}
                icon={
                  <Icon
                    width="40px"
                    float="left"
                    left="20px"
                    right="-20px"
                    src={PlusBlue}
                    alt="Item"
                  />
                }
              />
            </Row>

            {user.length > 0 && user[0].hard_skills.length > 0 ? (
              <Wrap top="0%">
                {user[0].hard_skills.map(i => {
                  const json = hardskill.filter(item => {
                    return i.hard_skill_id === item.id;
                  });
                  return (
                    <Group width="30%" bottom="1.5%">
                      <Row cursor="pointer">
                        <Tooltip title={userXpLevels[i.valor]}>
                          <Chip
                            sx={{ mr: '4px' }}
                            key={json[0]?.hard_skill_id}
                            label={`${json[0]?.nome} (${i.valor})`}
                            color="primary"
                            onDelete={() => onSaveHS(i.hard_skill_id)}
                          />
                        </Tooltip>
                      </Row>
                    </Group>
                  );
                })}
              </Wrap>
            ) : (
              <SubTitle align="center" font="14px">
                Você não possui hardskill cadastradas!
              </SubTitle>
            )}
          </Card>
        </ContainerCard>
      )}

      {isVisible && (
        <ContainerCard top="20%" bottom="10%">
          <Card>
            <Label>Minhas vagas</Label>
            <Table
              limit="10"
              headData={companies.head}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={companies.body}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </Card>
        </ContainerCard>
      )}
      {!isVisible && (
        <Box
          sx={{
            my: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
}

export default JobsApplied;
