import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Card,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai';
import { BiEdit, BiSearch } from 'react-icons/bi';
import Table from '../../components/Table';
import MenuData from '../../components/Menu/MenuUsers';
import colors from '../../styles/colors';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';

import api from '../../api';

import { Container, Title } from './styles';
import { UserRole } from '../../utils/enums';
import { withAuth } from '../../hocs/withAuth';
import { CompanySelector } from '../../components/CompanySelector';
import { EditUserDialog } from '../../components/EditUserDialog';

function ActiveUsers() {
  const { token } = useContext(StoreContext);
  const [details, setDetails] = useState(false);
  const [jsonDetails, setJsonDetails] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [term, setTerm] = useState('');
  const [fullList, setFullList] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [openEditUser, setOpenEditUser] = useState(false);

  const changeDetails = item => {
    setJsonDetails(item);
    setDetails(!details);
  };

  const onCloseDetails = () => {
    setDetails(!details);
  };

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th key={index}>{item.plan}</th>
      <th key={index}>{item.status}</th>
      <th>{item.contacts}</th>
      <th key={index}>{item.button}</th>
    </>
  );

  const getUsersByCompany = useCallback(async () => {
    if (companyId) {
      setIsVisible(false);
      api
        .get(`/companies/users/${companyId}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(response => {
          const { data: responseData } = response.data;
          const json = [];
          responseData.forEach(item => {
            json.push({
              id: item.id,
              name: item.name,
              email: item.email,
              plan: 'Sem Plano',
              status: item.status === 'S' ? 'Ativo' : 'Inativo',
              details: item,
            });
          });
          setFullList(json.sort((a, b) => (a.name < b.name ? -1 : 1)));
          setIsVisible(true);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [companyId, token]);

  const getData = useCallback(async () => {
    if (!companyId) {
      setIsVisible(false);
      await api
        .get(`/users?status=S`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(response => {
          const { users: responseData } = response.data;
          const json = [];
          responseData.forEach(item => {
            json.push({
              id: item.id,
              name: item.name,
              email: item.email,
              plan: 'Sem Plano',
              status: item.status === 'S' ? 'Ativo' : 'Inativo',
              details: item,
            });
          });
          setFullList(json.sort((a, b) => (a.name < b.name ? -1 : 1)));
          setIsVisible(true);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [companyId, token.refreshToken]);

  const renderBody = (item, index) => (
    <tr key={index}>
      <td>{item.name}</td>
      <td>{item.plan}</td>
      <td>{item.status}</td>
      <td>
        <Stack direction="row" spacing={1}>
          <Tooltip title={item.email}>
            <a href={`mailto:${item.email}`}>
              <AiOutlineMail size="20" style={{ color: colors.lightBlue }} />
            </a>
          </Tooltip>
          <Tooltip title="Telefone">
            <a href="https://wa.me" target="_blank" rel="noreferrer">
              <AiOutlineWhatsApp size="20" style={{ color: '#25D366' }} />
            </a>
          </Tooltip>
        </Stack>
      </td>
      <td>
        <Tooltip title="Ver detalhes">
          <button
            type="button"
            onClick={() => changeDetails(item.details)}
            style={{ color: colors.lightBlue }}
          >
            <BiSearch size="20" />
          </button>
        </Tooltip>
        <Tooltip title="Editar usuário">
          <button
            type="button"
            onClick={() => {
              setSelectedUser(item);
              setOpenEditUser(true);
            }}
            style={{ color: colors.lightBlue }}
          >
            <BiEdit size="20" />
          </button>
        </Tooltip>
      </td>
    </tr>
  );

  const users = {
    head: [
      {
        name: 'Nome do usuário',
        plan: 'Plano',
        status: 'Status',
        contacts: 'Contatos',
        button: ' ',
      },
    ],
    body: data,
  };

  const onCloseError = () => {
    setError(false);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getUsersByCompany();
  }, [getUsersByCompany]);

  useEffect(() => {
    if (!term) {
      setData(fullList);
    } else {
      setData(
        fullList.filter(item =>
          item.name.toLowerCase().includes(term?.toLowerCase()),
        ),
      );
    }
  }, [fullList, term]);

  return (
    <Container>
      {details && (
        <MenuData
          isOpen={details}
          onClose={onCloseDetails}
          title="Detalhes do Usuário"
          data={jsonDetails}
        />
      )}
      <EditUserDialog
        isOpen={openEditUser}
        onClose={() => setOpenEditUser(false)}
        user={selectedUser}
      />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ResponsiveAppBar />
      <Title>Usuários ativos</Title>
      <Paper
        sx={{
          maxWidth: '1200px',
          margin: '160px auto 32px auto',
          padding: '3rem',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CompanySelector onChange={company => setCompanyId(company?.id)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                size="small"
                label="Pesquise por nome"
                value={term}
                onChange={e => setTerm(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Card sx={{ maxWidth: '1200px', margin: '40px auto 0 auto' }}>
        <Table
          limit="10"
          headData={users.head}
          renderHead={(item, index) => renderHead(item, index)}
          bodyData={users.body}
          renderBody={(item, index) => renderBody(item, index)}
          isLoading={!isVisible}
        />
      </Card>
    </Container>
  );
}

export default withAuth(ActiveUsers, UserRole.MASTER_ADMIN);
