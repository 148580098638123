import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 0% 100% 0% 100% / 82% 0% 100% 18%;
  border-bottom-right-radius: 50px;
  z-index: 1;
  background: ${colors.darkBlue};
`;

export const Card = styled.div`
  position: relative;
  text-align: center;
  align-item: center;
  width: 1200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  top: 35%;
  background: ${colors.white};
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 12px;
  align-items: center;

  @media (max-width: 870px) {
    width: 700px;
  }

  @media (max-width: 570px) {
    width: 340px;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 120px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;

export const Title = styled.h1`
  position: relative;
  height: 52px;
  top: 110px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 37px;
  }

  @media (max-width: 570px) {
    font-size: 27px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.white};
`;
