import React, { useState, useContext, useEffect, useCallback } from 'react';

import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@mui/material';
import StoreContext from '../Store/Context';

// COMPONENTS
import Button from '../Button';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';

// STYLES
import colors from '../../styles/colors';
import api from '../../api';
import { CompanySelector } from '../CompanySelector';
import { CurrencyInput } from '../CurrencyInput';

function NewJobModal({
  selected,
  isOpen,
  onRequestClose,
  getData,
  initialCompanyId,
}) {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [careers, setCareers] = useState([]);
  const [msg, setMsg] = useState('');
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState('');
  const [complementary, setComplementary] = useState(
    selected.length > 0 ? selected.exige_dados_complementares : '',
  );
  const [name, setName] = useState(selected.length > 0 ? selected.nome : '');
  const [iniValue, setIniValue] = useState(
    selected.length > 0 ? selected.faixa_salarial_inicial : '',
  );
  const [finalValue, setFinalValue] = useState(
    selected.length > 0 ? selected.faixa_salarial_final : '',
  );
  const [description, setDescription] = useState(
    selected.length > 0 ? selected.descricao : '',
  );
  const [career, setCareer] = useState(
    selected.length > 0 ? selected.carreira_id : '',
  );
  const [company, setCompany] = useState(initialCompanyId);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });

  const getCompanies = useCallback(async () => {
    if (profileGlobal.perfil_id === 8) {
      await api
        .get('/companies', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const data = response.data.response;
          setCompanies(data);
        })
        .catch(() => {
          setError(true);
        });
    } else {
      const data = userGlobal;
      setCompanies(data);
    }
  }, [profileGlobal, token, userGlobal]);

  const getCareer = useCallback(async () => {
    if (company) {
      await api
        .get(`/companies/carreiras/${company}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          setCareers(data);
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [company, token]);

  const onCheck = async () => {
    const data = {
      nome: name,
      status,
      exige_dados_complementares: complementary,
      descricao: description,
      company_id: company,
      carreira_id: career,
      codigo: company * career * Math.random(),
    };
    // eslint-disable-next-line prefer-destructuring
    if (iniValue) data.faixa_salarial_inicial = iniValue.split(' ')[1];
    // eslint-disable-next-line prefer-destructuring
    if (finalValue) data.faixa_salarial_final = finalValue.split(' ')[1];
    if (selected.id) {
      await api
        .put(`/vagas/${selected.id}`, data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          setMsg('Vaga atualizada com sucesso!');
          setAlert(true);
          onRequestClose();
          setCareer('');
          setComplementary('');
          setDescription('');
          setFinalValue('');
          setIniValue('');
          setName('');
          setStatus('');
          await getData();
        })
        .catch(async () => {
          await onRequestClose();
          setError(true);
        });
    } else {
      await api
        .post('/vagas', data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          setMsg('Vaga cadastrada com sucesso!');
          setAlert(true);
          onRequestClose();
          setCareer('');
          setComplementary('');
          setDescription('');
          setFinalValue('');
          setIniValue('');
          setName('');
          setStatus('');
          await getData();
        })
        .catch(async () => {
          await onRequestClose();
          setError(true);
        });
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const handleCareer = e => {
    const { value } = e.target;
    setCareer(value);
  };

  const handleStatus = e => {
    const { value } = e.target;
    setStatus(value);
  };

  const handleComplementary = e => {
    const { value } = e.target;
    setComplementary(value);
  };

  const handleName = e => {
    const { value } = e.target;
    setName(value);
  };

  const handleIniValue = e => {
    const { value } = e.target;
    setIniValue(value);
  };

  const handleFinalValue = e => {
    const { value } = e.target;
    setFinalValue(value);
  };

  const handleDescription = e => {
    const { value } = e.target;
    setDescription(value);
  };

  useEffect(() => {
    setStatus(selected.status ?? '');
    setComplementary(selected.exige_dados_complementares ?? '');
    setName(selected.nome ?? '');
    setFinalValue(String(selected.faixa_salarial_final) ?? '');
    setIniValue(String(selected.faixa_salarial_inicial) ?? '');
    setDescription(selected.descricao ?? '');
    setCareer(selected.carreira_id ?? '');
  }, [selected]);

  useEffect(() => {
    getCareer();
  }, [getCareer]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    const initialState = companies.find(item => item.id === initialCompanyId);
    if (initialState) {
      setInitialCompany({
        id: initialState.id,
        razao_social: initialState.razao_social,
      });
    }
  }, [companies, initialCompanyId]);

  useEffect(() => {
    if (initialCompanyId) setCompany(initialCompanyId);
  }, [initialCompanyId]);

  return (
    <>
      {error ? (
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      ) : (
        false
      )}
      {alert ? (
        <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      ) : (
        false
      )}
      <Dialog open={isOpen ?? false} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography
            component="h2"
            variant="h6"
            sx={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: 700 }}
          >
            Nova Vaga
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sx={{ p: 1 }}>
              <CompanySelector
                onChange={item => setCompany(item?.id)}
                initialState={initialCompany}
              />
            </Grid>
            <Grid item xs={12} md={5} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="job-label">Carreira</InputLabel>
                <Select
                  labelId="job-label"
                  id="job-select"
                  label="Carreira"
                  onChange={handleCareer}
                  value={career}
                >
                  {careers.map(item => {
                    return (
                      <MenuItem index={item.id} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-vaga-label">Status vaga</InputLabel>
                <Select
                  labelId="status-vaga-label"
                  id="status-vaga-select"
                  label="Status vaga"
                  value={status}
                  onChange={handleStatus}
                >
                  <MenuItem value={1}>Ativa</MenuItem>
                  <MenuItem value={0}>Inativa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="dados-complementares-label">
                  Dados complementares?
                </InputLabel>
                <Select
                  labelId="dados-complementares-label"
                  id="dados-complementares-select"
                  label="Dados complementares?"
                  value={complementary}
                  onChange={handleComplementary}
                >
                  <MenuItem value={1}>Sim</MenuItem>
                  <MenuItem value={0}>Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <TextField
                  value={name}
                  onChange={handleName}
                  label="Nome da vaga"
                  id="nome-da-vaga-input"
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <CurrencyInput
                  id="faixa-salarial-inicial"
                  label="Faixa salarial inicial"
                  value={iniValue}
                  onChange={handleIniValue}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <CurrencyInput
                  id="faixa-salarial-final"
                  label="Faixa salarial final"
                  value={finalValue}
                  onChange={handleFinalValue}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ p: 1 }}>
              <FormControl fullWidth size="small">
                <TextField
                  value={description}
                  onChange={handleDescription}
                  label="Descrição da vaga"
                  id="descricao-vaga"
                  size="small"
                  multiline
                  rows={5}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Button
            type="button"
            fontSize="13px"
            text="Cancelar"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onRequestClose}
          />
          <Button
            type="button"
            fontSize="13px"
            text="Salvar"
            hoverColor={colors.lightBlue}
            color={colors.white}
            bgColor={colors.lightBlue}
            onClick={onCheck}
            disabled={
              !company || !career || !complementary || !name || !description
            }
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewJobModal;
