import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Tooltip, Grid, Box } from '@mui/material';
import * as Fi from 'react-icons/fi';
import * as Bi from 'react-icons/bi';
import * as Md from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import NewCareerModal from '../../components/NewCareerModal';
import CopyModal from '../../components/CopyModal';
import ItemDeleteModal from '../../components/ItemDeleteModal';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from '../../components/ErrorModal';
import api from '../../api';
import AlertModal from '../../components/AlertModal';

import { Container, Label, Card, Group, Title } from './styles';
import { RemoveCareerDialog } from './RemoveCareerDialog';
import { CompanySelector } from '../../components/CompanySelector';
import { withAuth } from '../../hocs/withAuth';
import { UserRole } from '../../utils/enums';
import { CalibratePersonaDialog } from '../../components/CalibratePersonaDialog';

function CareerPanel() {
  const { token } = useContext(StoreContext);
  const [link, setLink] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [del, setDel] = useState(false);
  const [persona, setPersona] = useState(false);
  const [alert, setAlert] = useState(false);
  const [idSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [width] = useState(window.screen.width);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [openRemoveCareerModal, setOpenRemoveCareerModal] = useState(false);

  const history = useHistory();

  const getData = useCallback(async () => {
    setIsVisible(false);
    await api
      .get(`/companies/carreiras/${selectedCompany}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data: careerData } = response;
        setData(careerData);
        setDisplay(true);
        setIsVisible(true);
      })
      .catch(() => {
        setError(true);
      });
  }, [selectedCompany, token.refreshToken]);

  const renderHead = (item, index) => (
    <>
      <th key={index}>{item.name}</th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.description}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.code}
      </th>
      <th className={width <= 570 ? 'hidden' : ''} key={index}>
        {item.persona}
      </th>
      <th key={index}>{item.actions}</th>
    </>
  );

  const openPersona = async item => {
    setSelected(item);
    setPersona(true);
  };

  const openEdit = async item => {
    setSelected(item);
    setIsOpen(true);
  };

  const openDeleteModal = async item => {
    setOpenRemoveCareerModal(true);
    setSelected(item);
  };

  const renderBody = (item, index) => {
    return (
      <tr key={index}>
        <td>{item.nome}</td>
        <td className={width <= 570 ? 'hidden' : ''}>{item.descricao}</td>
        <td className={width <= 570 ? 'hidden' : ''}>{item.codigo}</td>
        <td className={width <= 570 ? 'hidden' : ''}>
          {item.persona_ideal_id ? 'Sim' : 'Não'}
        </td>
        <td width="150px">
          <Group align="left">
            <Tooltip title="Calibrar Persona">
              <i>
                <Fi.FiUser
                  size="1rem"
                  onClick={() => openPersona(item)}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    marginRight: '4px',
                  }}
                />
              </i>
            </Tooltip>
            <Tooltip title="Detalhes do time">
              <i>
                <Fi.FiUsers
                  size="1rem"
                  onClick={() => {
                    history.push('/painelDoTime', {
                      companyId: selectedCompany,
                      careerId: item.id,
                    });
                  }}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    marginRight: '4px',
                  }}
                />
              </i>
            </Tooltip>
            <Tooltip title="Painel de vagas">
              <i>
                <Md.MdOutlineWork
                  size="1rem"
                  onClick={() => {
                    history.push('/painelDeVagas', {
                      companyId: selectedCompany,
                      careerId: item.id,
                    });
                  }}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    marginRight: '4px',
                  }}
                />
              </i>
            </Tooltip>
            <Tooltip title="Editar carreira">
              <i>
                <Bi.BiEditAlt
                  size="1rem"
                  onClick={() => openEdit(item)}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    marginRight: '4px',
                  }}
                />
              </i>
            </Tooltip>
            <Tooltip title="Excluir carreira">
              <i>
                <Bi.BiTrash
                  size="1rem"
                  onClick={() => openDeleteModal(item)}
                  style={{
                    cursor: 'pointer',
                    color: colors.blue,
                    marginRight: '4px',
                  }}
                />
              </i>
            </Tooltip>
          </Group>
        </td>
      </tr>
    );
  };

  const users = {
    head: [
      {
        name: 'Nome',
        description: 'Descrição',
        code: 'Código',
        persona: 'Persona',
        actions: 'Ações',
      },
    ],
    body: data,
  };

  const deletePersona = async id => {
    await api
      .delete(`/persona/delete/${id}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setIsVisible(false);
        getData();
        setMsg('Persona excluída com sucesso!');
        setDel(false);
        setAlert(true);
      });
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const onRequestClose = async () => {
    setIsOpen(false);
    setIsVisible(false);
    setIsVisible(true);
    if (selectedCompany) getData();
  };

  const onRequestClosePersona = async () => {
    setPersona(false);
    setIsVisible(false);
    setIsVisible(true);
    setSelected({});
    getData();
  };

  useEffect(() => {
    if (selectedCompany) getData();
  }, [selectedCompany, getData]);

  return (
    <Container>
      <ResponsiveAppBar />
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <RemoveCareerDialog
        isOpen={openRemoveCareerModal}
        onClose={() => setOpenRemoveCareerModal(false)}
        onDelete={() => getData()}
        careerId={selected.id}
      />
      <CalibratePersonaDialog
        companyId={selectedCompany}
        selectedCareer={selected}
        isOpen={persona}
        onClose={() => onRequestClosePersona()}
      />
      <NewCareerModal
        selected={selected}
        getData={() => getData()}
        isOpen={isOpen}
        width={width}
        onRequestClose={() => onRequestClose()}
        initialCompanyId={selectedCompany}
      />
      <CopyModal
        isOpen={link}
        width={width}
        onRequestClose={() => setLink(false)}
      />
      <ItemDeleteModal
        isOpen={del}
        width={width}
        onRequestClose={() => setDel(false)}
        onDelete={() => deletePersona(idSelected)}
      />
      <Title>Painel de Carreiras</Title>
      <Card>
        <Label>Filtrar sua busca</Label>
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12} lg={9} sx={{ mb: 1 }}>
            <Box sx={{ py: 1.25, px: 2 }}>
              <CompanySelector
                onChange={company => setSelectedCompany(company?.id)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ textAlign: 'right' }}>
            <Button
              type="button"
              width="120px"
              color={colors.lightBlue}
              bgColor={colors.transparent}
              text="Nova carreira"
              fontSize="12px"
              onClick={() => {
                setSelected([]);
                setIsOpen(true);
              }}
            />
          </Grid>
        </Grid>
      </Card>
      {display && (
        <Card>
          <Table
            limit="10"
            headData={users.head}
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={users.body}
            renderBody={(item, index) => renderBody(item, index)}
            isLoading={!isVisible}
          />
        </Card>
      )}
    </Container>
  );
}

export default withAuth(
  CareerPanel,
  UserRole.MASTER_ADMIN,
  UserRole.COMPANY_ADMIN,
  UserRole.CONSULTANT,
);
