import React, { useContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import '../../../styles/SidebarConfig.css';
import StoreContext from '../../Store/Context';
import api from '../../../api';
import ErrorModal from '../../ErrorModal';
import { HardskillSelector } from '../../HardskillSelector';

function MenuAbout({ user, isOpen, onClose, width }) {
  const { token } = useContext(StoreContext);
  const [error, setError] = useState(false);

  const onCloseError = () => {
    setError(false);
  };

  const icon =
    width <= 570 ? (
      <Shape src={shape} alt="Menu" />
    ) : (
      <Item src={menu} alt="Menu" />
    );

  const handleChangeHardskills = async list => {
    const skill = list[list.length - 1];
    const json = {
      data: [
        {
          user_id: user[0].id,
          hard_skill_id: skill.id,
          valor: skill.value,
        },
      ],
    };
    await api
      .post('/user-hardskill', json, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <GroupLine>
        <Title>Hardskill</Title>
      </GroupLine>
      <Container>
        <Group>
          <HardskillSelector
            onChange={handleChangeHardskills}
            initialList={[]}
            slide
            showRequiredQuestion={false}
            showSkills={false}
          />
        </Group>
      </Container>
    </Slide>
  );
}

export default MenuAbout;
