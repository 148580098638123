import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import Pagination from '../Pagination';

import { TableContainer } from './styles';
import colors from '../../styles/colors';

function Table({
  limit,
  headData,
  renderHead,
  bodyData,
  renderBody,
  children,
  isLoading = false,
}) {
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    if (limit !== undefined) {
      const page = Math.floor(bodyData.length / Number(limit));
      setPages(bodyData.length % Number(limit) === 0 ? page : page + 1);
    }
  }, [bodyData, limit]);

  useEffect(() => {
    setCurrentData(
      bodyData.slice(
        (currentPage - 1) * Number(limit),
        Number(limit * currentPage),
      ),
    );
  }, [bodyData, currentPage, limit]);

  return (
    <div>
      <TableContainer>
        <table style={{ width: '100%' }}>
          {children}
          {headData && renderHead ? (
            <thead>
              <tr>{headData.map((item, index) => renderHead(item, index))}</tr>
            </thead>
          ) : null}
          {!isLoading && bodyData && renderBody && !!currentData.length && (
            <tbody>
              {currentData.map((item, index) => renderBody(item, index))}
            </tbody>
          )}
        </table>
        {!isLoading && !currentData.length && (
          <Typography
            sx={{
              py: 6,
              px: 2,
              textAlign: 'center',
              color: colors.gray3,
              fontWeight: 600,
            }}
          >
            Nenhum registro encontrado
          </Typography>
        )}
        {isLoading && (
          <Box
            sx={{
              py: 4,
              px: 2,
              textAlign: 'center',
              color: colors.gray3,
              fontWeight: 600,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      {pages > 1 ? (
        <Pagination
          limitOfPage={limit}
          totalItems={bodyData.length}
          offset={offset}
          setOffset={setOffset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataShow={setCurrentData}
          bodyData={bodyData}
        />
      ) : null}
    </div>
  );
}

export default Table;
