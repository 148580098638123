import React, { useContext, useState } from 'react';
import { slide as Slide } from 'react-burger-menu';
import { TextField } from '@mui/material';
import { Item, Group, Title, GroupLine, Container, Shape } from './styles';
import StoreContext from '../../Store/Context';
import menu from '../../../assets/img/Menu.svg';
import shape from '../../../assets/img/Shape.svg';
import Button from '../../Button';
import colors from '../../../styles/colors';
import '../../../styles/SidebarConfig.css';
import api from '../../../api';
import ErrorModal from '../../ErrorModal';

function MenuSummary({ user, width, onClose, isOpen }) {
  const { token } = useContext(StoreContext);
  const [data, setData] = useState(user[0]?.dados_adicionais.resumo);
  const [error, setError] = useState(false);

  const handleValue = async e => {
    const { value } = e.target;
    setData(value);
  };

  let icon;
  if (token) {
    icon = false;
  } else {
    icon =
      width <= 570 ? (
        <Shape src={shape} alt="Menu" />
      ) : (
        <Item src={menu} alt="Menu" />
      );
  }

  const putData = async () => {
    const json = { user_id: user[0].id, resumo: data };
    await api
      .put('/dados-candidatos/update', json, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        onClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  const onCloseError = () => {
    setError(false);
  };

  return (
    <Slide
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          top: 0,
        },
      }}
      right
      customBurgerIcon={icon}
      isOpen={isOpen}
      noOverlay
      disableCloseOnEsc
      onClose={onClose}
    >
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <GroupLine>
        <Title>Resumo</Title>
      </GroupLine>
      <Container>
        <Group>
          <TextField
            label="Resumo sobre você"
            InputLabelProps={{ shrink: true, required: true }}
            size="small"
            multiline
            rows={8}
            onChange={handleValue}
            maxRows={10}
            value={data}
            color="info"
            disabled={false}
          />
        </Group>
        <Button
          type="button"
          width="230px"
          hoverColor={colors.lightBlue}
          text="Salvar alterações"
          fontSize="12px"
          onClick={putData}
        />
      </Container>
    </Slide>
  );
}

export default MenuSummary;
