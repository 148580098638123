import React, { useCallback, useEffect, useState } from 'react';
import {
  BsFillCaretRightFill,
  BsFillCaretLeftFill,
  BsFillSkipForwardFill,
  BsSkipBackwardFill,
} from 'react-icons/bs';
import { TablePagination, ArrowsIcon, PaginationItem } from './styles';

const maxItems = 5;
const maxItemsOnLeft = (maxItems - 1) / 2;
function Pagination({
  limitOfPage,
  totalItems,
  offset,
  setOffset,
  currentPage,
  setDataShow,
  setCurrentPage,
  bodyData,
}) {
  const [pages, setPages] = useState(0);
  const [first, setFirst] = useState(0);

  const onPageChange = useCallback(
    page => {
      setOffset((page - 1) * limitOfPage);
      const start = Number(limitOfPage) * (page - 1);
      const end = start + Number(limitOfPage);
      setDataShow(bodyData.slice(start, end));
      setCurrentPage(page);
    },
    [bodyData, limitOfPage, setCurrentPage, setDataShow, setOffset],
  );

  useEffect(() => {
    setCurrentPage(offset ? offset / limitOfPage + 1 : 1);
  }, [limitOfPage, offset, setCurrentPage]);

  useEffect(() => {
    setPages(Math.ceil(totalItems / limitOfPage));
    setFirst(Math.max(currentPage - maxItemsOnLeft, 1));
  }, [currentPage, limitOfPage, totalItems]);

  return (
    <TablePagination>
      <div>
        <ArrowsIcon
          onClick={() => onPageChange(currentPage - currentPage + 1)}
          disabled={currentPage === 1}
          className={currentPage === 1 ? 'btn-disabled' : null}
        >
          <BsSkipBackwardFill />
        </ArrowsIcon>
      </div>
      <div>
        <ArrowsIcon
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={currentPage === 1 ? 'btn-disabled' : null}
        >
          <BsFillCaretLeftFill />
        </ArrowsIcon>
      </div>

      {Array.from({ length: Math.min(maxItems, pages) })
        .map((_, index) => index + first)
        .map((page, index) => (
          <PaginationItem
            key={index}
            className={page === currentPage ? 'active' : null}
          >
            {page <= pages ? (
              <button type="button" onClick={() => onPageChange(page)}>
                {page}
              </button>
            ) : (
              page
            )}
          </PaginationItem>
        ))}

      <div>
        <ArrowsIcon
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pages}
          className={currentPage === pages ? 'btn-disabled' : null}
        >
          <BsFillCaretRightFill />
        </ArrowsIcon>
      </div>
      <div>
        <ArrowsIcon
          onClick={() => onPageChange(pages)}
          disabled={currentPage === pages}
          className={currentPage === pages ? 'btn-disabled' : null}
        >
          <BsFillSkipForwardFill />
        </ArrowsIcon>
      </div>
    </TablePagination>
  );
}

export default Pagination;
