import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import colors from '../../styles/colors';
import { Hint } from '../Hint';
import { ExclamationIcon } from '../icons/ExclamationIcon';

export function ProgressCard({
  title,
  hint,
  value,
  bgColor = colors.lightSurface,
  barColor = colors.gray4,
}) {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: bgColor,
        borderRadius: '0.5rem',
        padding: '0.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          px: '0.5rem',
        }}
      >
        {hint && (
          <Box sx={{ marginTop: '6px' }}>
            <Hint description={hint}>
              <ExclamationIcon color={colors.black} size={16} />
            </Hint>
          </Box>
        )}
        <Typography
          sx={{
            fontSize: '0.875rem',
            flexGrow: 1,
            fontWeight: 600,
            color: colors.black,
            hyphens: 'auto',
            textAlign: 'left',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ color: colors.black, fontWeight: 700 }}>
          {value}%
        </Typography>
      </Box>
      <LinearProgress
        sx={{
          margin: '0.75rem',
          height: '0.5rem',
          borderRadius: '1rem',
          opacity: 1,
          backgroundColor: colors.gray6,
          '.MuiLinearProgress-bar': {
            backgroundColor: barColor,
          },
        }}
        variant="determinate"
        value={value}
      />
    </Paper>
  );
}
