import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const Step = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: inline-grid;
  background: ${props =>
    props.active ? colors.lightBlue : colors.transparent};
  color: ${props => (props.active ? colors.white : colors.lightBlue)};
  margin: 0.16%;
  border: 1px solid ${colors.lightBlue};
`;

export const Note = styled.div`
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  display: inline-grid;
  background: ${props =>
    props.active ? colors.lightBlue : colors.transparent};
  color: ${props => (props.active ? colors.white : colors.lightBlue)};
  margin: 0.2%;
  border: 1px solid ${colors.lightBlue};
`;

export const Card = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 428px;
  height: 56px;
  display: inherit;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  /* FFFFFF */

  background: #ffffff;
  /* E2E2EA */

  border: 1px solid #e2e2ea;
  border-radius: 8px;

  @media (max-width: 870px) {
    width: 400px;
    margin-bottom: 5%;
  }

  @media (max-width: 570px) {
    width: 340px;
    margin-bottom: 5%;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 140px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;

export const Icon = styled.img`
  position: relative;
  text-align: center;
  padding-right: ${props => props.right || '0px'};
  width: ${props => props.width || '40px'};
  @media (max-width: 870px) {
    width: ${props => props.width || '40px'};
  }

  @media (max-width: 570px) {
    width: ${props => props.width || '40px'};
  }
`;

export const SubTitle = styled.h2`
  position: relative;
  height: 52px;
  font-size: 22px;
  padding-top: 0.5%;
  padding-bottom: 4%;
  padding-left: 40%;
  padding-right: 40%;

  /* H1 - Web */

  font-family: 'Poppins';
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 1440px) {
    font-size: 20px;
    padding-left: 35%;
    padding-right: 35%;
  }

  @media (max-width: 870px) {
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 570px) {
    font-size: 18px;
    padding-left: 1%;
    padding-right: 1%;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.darkBlue};
`;

export const Group = styled.div`
  text-align: center;
  padding-top: 80px;
`;

export const Title = styled.h1`
  position: relative;
  height: 52px;
  font-size: 40px;
  padding-bottom: 4%;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 37px;
  }

  @media (max-width: 570px) {
    font-size: 27px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.darkBlue};
`;

export const DescriptionGroup = styled.div`
  padding-bottom: 2%;
  justify-content: space-between;
  @media (max-width: 1500px) {
    padding-bottom: 6%;
    padding-top: 3%;
  }
  @media (max-width: 870px) {
    padding-bottom: 6%;
    padding-top: 5%;
  }

  @media (max-width: 570px) {
    padding-bottom: 6%;
    padding-top: 5%;
  }

  @media (max-width: 425px) {
    padding-bottom: 8%;
    padding-top: 5%;
  }

  @media (max-width: 320px) {
    padding-bottom: 8%;
    padding-top: 5%;
  }
`;

export const SubLabel = styled.label`
  position: relative;
  text-align: center !important;
  height: 52px;
  font-size: 15px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 15px;
  }

  @media (max-width: 570px) {
    font-size: 14px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.white};
`;

export const GpButton = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
`;

export const ContainerButton = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between !important;
  padding-right: 41%;
  padding-left: 41%;

  @media (max-width: 1400px) {
    padding-right: 25%;
    padding-left: 25%;
  }

  @media (max-width: 870px) {
    padding-right: 20%;
    padding-left: 20%;
  }

  @media (max-width: 570px) {
    padding-right: 10%;
    padding-left: 10%;
  }

  @media (max-width: 320px) {
    padding-bottom: 5%;
    padding-top: 5%;
  }
`;

export const Line = styled.div`
  opacity: 0.2;
  border-bottom: 1px solid ${colors.gray4};
  padding-top: ${props => props.top || '5%'};

  @media (max-width: 870px) {
    padding-top: ${props => props.top || '5%'};
  }

  @media (max-width: 570px) {
    padding-top: ${props => props.top || '6%'};
  }

  @media (max-width: 425px) {
    padding-top: ${props => props.top || '6%'};
  }

  @media (max-width: 320px) {
    padding-top: ${props => props.top || '8%'};
  }
`;
