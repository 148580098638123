import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Title } from './Title';
import Button from '../Button';
import colors from '../../styles/colors';
import api from '../../api';
import StoreContext from '../Store/Context';

export function EditCareerDialog({
  isOpen,
  onClose,
  onUpdate,
  selectedUser,
  companyId,
}) {
  const [careers, setCareers] = useState([]);

  // forms
  const [selectedCareerId, setSelectedCareerId] = useState({});
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useContext(StoreContext);

  const handleSubmit = useCallback(async () => {
    try {
      await api.put(
        `/users/${selectedUser.id}`,
        {
          carreira_id: selectedCareerId,
        },
        {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        },
      );

      if (onClose) onClose();
      setMessage('Carreira alterada com sucesso!');
      if (onUpdate) onUpdate();
    } catch (err) {
      setErrorMessage(
        'Ocorreu um erro ao atualizar a carreira. Tente novamente mais tarde',
      );
    }
  }, [onClose, onUpdate, selectedCareerId, selectedUser, token]);

  useEffect(() => {
    if (selectedUser?.id) {
      setSelectedCareerId(selectedUser.career?.id ?? 0);
    }
  }, [selectedUser, token]);

  useEffect(() => {
    if (companyId !== 0 && selectedUser?.id) {
      api
        .get(`/companies/carreiras/${companyId}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(res => {
          setCareers(res.data);
        });
    }
  }, [companyId, selectedUser, token]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setErrorMessage('')}
        autoHideDuration={5000}
        open={!!errorMessage}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMessage('')}
        autoHideDuration={5000}
        open={!!message}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={isOpen ?? false}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '1rem' } }}
      >
        <DialogTitle sx={{ marginBottom: 2 }}>
          <Title>Editar carreira do colaborador</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="career-label">Carreira</InputLabel>
                <Select
                  labelId="career-label"
                  label="Carreira"
                  value={selectedCareerId}
                  onChange={e => setSelectedCareerId(e.target.value)}
                  autoWidth
                  size="small"
                  color="info"
                >
                  <MenuItem value={0}>Carreira</MenuItem>
                  {careers.length > 0
                    ? careers.map(item => {
                        return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                      })
                    : false}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            fontSize="13px"
            text="Cancelar"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onClose}
          />
          <Button
            type="button"
            fontSize="13px"
            text="Salvar"
            disabled={!selectedCareerId}
            hoverColor={colors.lightBlue}
            color={colors.white}
            bgColor={colors.lightBlue}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
