import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { getOrientation, Orientation } from 'get-orientation/browser';
import { Box, Button } from '@mui/material';
import { getCroppedImg, getRotatedImage } from './canvasUtils';

const ORIENTATION_TO_ANGLE = {
  [Orientation.TOP_LEFT]: -180,
  [Orientation.BOTTOM_RIGHT]: 180,
  [Orientation.RIGHT_TOP]: 90,
  [Orientation.LEFT_BOTTOM]: -90,
};

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => resolve(String(reader.result)),
      false,
    );
    reader.readAsDataURL(file);
  });
}

export function ImageCrop({
  aspectRatio,
  saveLabel = 'Salvar',
  fileToCrop,
  showCropper,
  setShowCropper,
  setFileToCrop,
  onSave,
}) {
  const [imageSrc, setImageSrc] = useState('');
  const [filename, setFilename] = useState('');
  const [filetype, setFiletype] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});

  const onCropComplete = useCallback(
    (_croppedArea, newCroppedAreaPixels) => {
      setCroppedAreaPixels(newCroppedAreaPixels);
    },
    [setCroppedAreaPixels],
  );

  const handleSave = useCallback(async () => {
    const { file, url } = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      rotation,
      filename,
      filetype,
    );
    if (onSave) onSave(file, url);
    setImageSrc('');
  }, [imageSrc, croppedAreaPixels, rotation, filename, filetype, onSave]);

  const onFileChange = useCallback(
    async file => {
      if (!fileToCrop) {
        return;
      }

      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const newRotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, newRotation);
      }

      setImageSrc(imageDataUrl);
      setFilename(file.name);
      setFiletype(file.type);
    },
    [fileToCrop, rotation],
  );

  useEffect(() => {
    onFileChange(fileToCrop);
  }, [fileToCrop, onFileChange]);

  const handleCancel = useCallback(() => {
    setImageSrc('');
    if (setShowCropper) setShowCropper(false);
    if (setFileToCrop) setFileToCrop(null);
  }, [setShowCropper, setFileToCrop]);

  if (fileToCrop)
    return (
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: '80px',
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            width: '50%',
            transform: 'translateX(-50%)',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button variant="contained" onClick={handleSave}>
            {saveLabel}
          </Button>
          <Button onClick={handleCancel}>Cancelar</Button>
        </Box>
      </Box>
    );

  return <div />;
}
