import React, { useState, useEffect, useContext, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  Box,
} from '@mui/material';
import Chart from 'react-apexcharts';
import vector from '../../assets/img/Vector.svg';
import plus from '../../assets/img/Plus.svg';
import total from '../../assets/img/Total.svg';
import minus from '../../assets/img/Minus.svg';
import colors from '../../styles/colors';
import CardColor from '../../components/Card';
import Graph from '../../assets/img/Graph.svg';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import api from '../../api';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';

import {
  Container,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  GpItems,
  GroupItems,
  GroupFilter,
  DescriptionData,
  Row,
  Label,
  LabelData,
  SubCard,
  GroupText,
  Group,
} from './styles';
import { CompanySelector } from '../../components/CompanySelector';

const semaforo = aderencia => {
  if (+aderencia < 50) return colors.alertRed;
  if (+aderencia < 80) return colors.yellow;
  return colors.lightGreen;
};

const cultureDescriptions = {
  Acolhimento:
    'A cultura de acolhimento está relacionada aos relacionamentos e à confiança mútua. Os ambientes são locais calorosos, onde as pessoas ajudam e apoiam umas às outras. As pessoas são unidas por lealdade e os líderes enfatizam a sinceridade, o trabalho em equipe e os relacionamentos positivos.',
  Segurança:
    'A cultura de segurança se refere ao planejamento, precaução e prevenção. Os ambientes são locais previsíveis, onde as pessoas têm consciência do risco e se pautam pela reflexão. São unidos pelo desejo de se sentirem protegidos e antecipam a mudança. Os líderes enfatizam o comportamento realista e o planejamento cuidadoso.',
  Resultados:
    'A cultura de resultados é caracterizada pelas realizações e pelas conquistas. Os ambientes são orientados para resultados e baseados no mérito, onde as pessoas aspiram ao desempenho de excelência. São unidos pela motivação da competência e do sucesso, e os líderes enfatizam o cumprimento de metas.',
  Propósito:
    'A cultura de propósito se caracteriza por idealismo e altruísmo. Os ambientes são locais tolerantes, solidários, onde as pessoas tentam fazer o bem pelo futuro de longo prazo da humanidade. São unidos pelo foco na sustentabilidade e nas comunidades globais, e os líderes enfatizam ideais comuns e contribuem para uma causa maior.',
  Aprendizado:
    'A cultura de aprendizado está relacionada à exploração, expansividade e criatividade. Os ambientes são locais inovadores e liberais, onde as pessoas lançam ideias novas e exploram alternativas. São unidos pela curiosidade e os líderes enfatizam o conhecimento, a inovação e a aventura.',
  Prazer:
    'A cultura de prazer é expressada por meio de divertimento e empolgação. Os ambientes são locais despreocupados, onde as pessoas tendem a fazer o que as tornam felizes. São unidos pela descontração e pelos estímulos, e os líderes enfatizam a espontaneidade e o senso de humor.',
  Autoridade:
    'A cultura de autoridade é definida pela força, determinação e ousadia. Os ambientes são locais competitivos, onde as pessoas se esforçam para obter vantagem individual. São unidos por forte controle, e os líderes enfatizam a confiança e a dominação.',
  Ordem:
    'A cultura de ordem se concentra no respeito, na estrutura e nas normas comuns. Os ambientes são locais metódicos, onde as pessoas obedecem as regras do jogo e procuram se encaixar. São unidos pela cooperação, e os líderes enfatizam procedimentos compartilhados e costumes seculares.',
};

function CompanyDashboard() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [expanded, setExpanded] = useState(false);
  const [radar, setRadar] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [msgError, setMsgError] = useState('');
  const [object, setObject] = useState([]);
  const [show, setShow] = useState('');
  const [error, setError] = useState(false);
  const [isVisible] = useState(false);
  const [otherCulturesExpanded, setOtherCulturesExpanded] = useState(false);
  const [jobSelected, setJobSelected] = useState(0);
  const [job, setJob] = useState({});
  const [companies, setCompanies] = useState([]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeExpandOtherCultures = () =>
    setOtherCulturesExpanded(oldState => !oldState);

  const onCloseError = () => {
    setError(false);
  };

  const getData = async () => {
    if (selectedCompany > 0) {
      setShow(false);

      const data = {
        company_id: selectedCompany,
      };
      if (jobSelected !== 0) data.carreira_id = jobSelected;

      await api
        .post('/reports/igrownx', data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data: igrownx } = response.data;
          setRadar({
            series: [
              {
                name: 'Cultura',
                data: [
                  Math.round(igrownx.cultura.dados.ACOLHIMENTO),
                  Math.round(igrownx.cultura.dados.PROPOSITO),
                  Math.round(igrownx.cultura.dados.APRENDIZADO),
                  Math.round(igrownx.cultura.dados.PRAZER),
                  Math.round(igrownx.cultura.dados.RESULTADOS),
                  Math.round(igrownx.cultura.dados.AUTORIDADE),
                  Math.round(igrownx.cultura.dados.SEGURANCA),
                  Math.round(igrownx.cultura.dados.ORDEM),
                ],
              },
            ],
            options: {
              colors: ['#95D45A'],
              chart: {
                height: 350,
                type: 'radar',
                fontFamily: 'Poppins',
              },
              title: {
                text: '',
              },
              yaxis: {
                labels: {
                  formatter: val => `${val}%`,
                },
              },
              xaxis: {
                color: [colors.darkBlue],
                categories: [
                  'Acolhimento',
                  'Propósito',
                  'Aprendizado',
                  'Prazer',
                  'Resultados',
                  'Autoridade',
                  'Segurança',
                  'Ordem',
                ],
              },
            },
          });
          setObject(igrownx);
          setShow(true);
        })
        .catch(() => {
          setError(true);
        });
    } else {
      setMsgError('É obrigatória a seleção da empresa!');
    }
  };

  const fieldRow0 = width <= 570 ? '60%' : 600;
  const fieldRow1 = width <= 570 ? '40%' : 400;

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        setCompanies([]);
      } else {
        setCompanies(userGlobal);
      }
    },
    [userGlobal],
  );

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      getCompanies(profileGlobal.perfil_id);
    }
    setWidth(window.screen.width);
  }, [getCompanies, profileGlobal.perfil_id, width]);

  useEffect(() => {
    if (selectedCompany !== 0) {
      api
        .get(`/companies/carreiras/${selectedCompany}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(res => {
          setJob(res.data);
        });
    }
  }, [selectedCompany, token]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems padding={width > 530 ? '2%' : '4%'}>
            <SubTitle>Olá! Bem-vindo(a) ao Painel iGrown-X!</SubTitle>
            <LabelData font="14px" weight="400">
              Essa área é voltada, exclusivamente, para as empresas que utilizam
              a plataforma iGrown. No <b>Painel iGrown-X</b>, é possível
              visualizar um cruzamento das informações de todos os colaboradores
              da empresa ou de candidatos de um processo seletivo. Ou seja:
              aqui, está <b>a visão consolidada do time da sua organização</b>.
            </LabelData>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="5%" width="800px">
        <SubTitle font="17px" align="center">
          Abaixo, de acordo com os filtros aplicados, está apresentada uma
          análise geral, baseada na resposta de todos os usuários.
        </SubTitle>
      </ContainerCard>

      <ContainerCard top="5%">
        <Card>
          <Label>Filtrar sua busca</Label>
          <GroupFilter align="left">
            <CompanySelector
              fullWidth={false}
              width={fieldRow0}
              label="Procurar por empresa"
              options={companies}
              onChange={company => setSelectedCompany(company?.id)}
            />
            <FormControl sx={{ width: fieldRow1, ml: 4 }}>
              <Select
                value={jobSelected}
                onChange={e => setJobSelected(e.target.value)}
                autoWidth
                size="small"
                color="info"
              >
                <MenuItem value={0}>
                  <em>Procurar por carreira</em>
                </MenuItem>
                {job.length > 0
                  ? job.map(item => {
                      return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                    })
                  : false}
              </Select>
            </FormControl>
          </GroupFilter>
          <GroupFilter>
            <LabelData color={colors.alertRed} weight="500">
              {msgError}
            </LabelData>
          </GroupFilter>
          <GroupFilter align={width > 530 ? 'right' : 'center'}>
            <Button
              type="button"
              width="120px"
              hoverColor={colors.lightBlue}
              text="Aplicar"
              fontSize="12px"
              onClick={() => getData()}
            />
          </GroupFilter>
        </Card>
      </ContainerCard>

      {show && (
        <>
          <ContainerCard top="-5%">
            <Card width="100%" heigth="650px">
              <GroupItems padding="2%">
                <GroupItems padding="1%">
                  <SubTitle>Adesão do time</SubTitle>
                  <LabelData font="14px" weight="400">
                    Nessa parte, o gráfico demonstra como foi a participação dos
                    colaboradores e/ou candidatos, deixando claro quantos
                    colaboradores estão cadastrados na plataforma e, desses,{' '}
                    <strong>
                      quantos realizaram os testes comportamental, de cultura e
                      de valores.
                    </strong>
                    Para ver os números exatos, passe o mouse por cima de cada
                    barra.
                  </LabelData>
                </GroupItems>
                {width > 530 ? (
                  <Row top="0%">
                    <Group width="25%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="100px"
                        background={colors.gray5}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.transparent}
                            title="Usuários cadastrados"
                            font="11px"
                            height="100%"
                            num={parseInt(object.adesao.users, 10) || '-'}
                            fontNum="20px"
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="100px"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Testes de perfil comportamental realizados"
                            value={
                              parseInt(object.adesao.comportamental, 10) || '-'
                            }
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="100px"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Testes de cultura realizados"
                            topProgress="24px"
                            value={parseInt(object.adesao.cultural, 10) || '-'}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="25%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="100px"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Testes de âncoras de carreira realizados"
                            value={parseInt(object.adesao.valores, 10) || '-'}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                  </Row>
                ) : (
                  <>
                    <Group width="100%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="auto"
                        background={colors.gray5}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.transparent}
                            title="Usuários cadastrados"
                            font="11px"
                            height="100%"
                            num={parseInt(object.adesao.users, 10) || '-'}
                            fontNum="20px"
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="auto"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Teste de perfil comportamental"
                            value={
                              parseInt(object.adesao.comportamental, 10) || '-'
                            }
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="auto"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Teste de cultura"
                            value={parseInt(object.adesao.cultural, 10) || '-'}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                    <Group width="100%">
                      <SubCard
                        top="2%"
                        width="100%"
                        heigth="auto"
                        background={colors.white}
                        border={colors.gray5}
                      >
                        <GroupItems padding="5%">
                          <CardColor
                            right
                            left
                            width="100%"
                            bgColor={colors.white}
                            title="Teste de âncoras de carreira"
                            value={parseInt(object.adesao.valores, 10) || '-'}
                            colorProgress={colors.lightBlue}
                            valueColor={colors.lightBlue}
                            font="11px"
                            height="100%"
                            progress
                          />
                        </GroupItems>
                      </SubCard>
                    </Group>
                  </>
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="-10%">
            {width > 530 ? (
              <Row top="-10px">
                <Group width="40%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório comportamental</SubTitle>
                      <LabelData font="14px" weight="400">
                        Abaixo, é possível ver quais são os perfis
                        comportamentais dominantes quando fazemos uma média de
                        todos os candidatos. Caso queira ver a descrição
                        detalhada de algum perfil, basta clicar no ícone
                      </LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      <LabelData font="14px" weight="400">
                        , ao lado dos nomes. E, para ver os números exatos,
                        passe o mouse por cima de cada barra.
                      </LabelData>
                      <br />
                      <br />
                      <DescriptionData font="14px">
                        Como você pode ver, é possível que o perfil predominante
                        seja apenas um ou uma combinação de dois perfis
                        distintos, como “Embaixador – Mantenedor”, por exemplo.
                      </DescriptionData>
                      <br />
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Naturalmente orientado para a ação e a conquista, este perfil tem foco nos resultados. Liderança e iniciativa são suas marcas registradas.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">Desbravador</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR ||
                            object.valores.DESBRAVADOR >= 0
                              ? `${object.valores.DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Uma mistura de liderança com habilidade de relacionamento, esse perfil tem a capacidade de conduzir equipes mantendo um bom clima organizacional.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Desbravador - Embaixador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_EMBAIXADOR ||
                            object.valores.DESBRAVADOR_EMBAIXADOR >= 0
                              ? `${object.valores.DESBRAVADOR_EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Uma combinação de orientação para resultados e estabilidade. Este perfil tem a capacidade de manter a ordem sem perder de vista os objetivos da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Desbravador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_MANTENEDOR ||
                            object.valores.DESBRAVADOR_MANTENEDOR >= 0
                              ? `${object.valores.DESBRAVADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Altamente orientado para desafios e com uma mente analítica. Este perfil tem uma abordagem prática e estratégica para alcançar os objetivos.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Desbravador - Investigador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_INVESTIGADOR ||
                            object.valores.DESBRAVADOR_INVESTIGADOR >= 0
                              ? `${object.valores.DESBRAVADOR_INVESTIGADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Habilidades de relacionamento são o ponto forte desse perfil, que é orientado para criar e manter relacionamentos interpessoais.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">Embaixador</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.EMBAIXADOR ||
                            object.valores.EMBAIXADOR >= 0
                              ? `${object.valores.EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil combina habilidades de relacionamento com foco em resultados, sendo capaz de liderar equipes mantendo um bom ambiente de trabalho.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Embaixador - Desbravador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.EMBAIXADOR_DESBRAVADOR ||
                            object.valores.EMBAIXADOR_DESBRAVADOR >= 0
                              ? `${object.valores.EMBAIXADOR_DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com uma combinação de habilidades de relacionamento e foco na estabilidade, este perfil é ótimo para manter o equilíbrio e a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Embaixador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.EMBAIXADOR_MANTENEDOR ||
                            object.valores.EMBAIXADOR_MANTENEDOR >= 0
                              ? `${object.valores.EMBAIXADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil é altamente analítico e orientado para a resolução de problemas complexos.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">Investigador</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR ||
                            object.valores.INVESTIGADOR >= 0
                              ? `${object.valores.INVESTIGADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com uma abordagem analítica e orientada para a ação, este perfil é capaz de tomar decisões estratégicas e executá-las de forma eficaz.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Investigador - Desbravador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR_DESBRAVADOR ||
                            object.valores.INVESTIGADOR_DESBRAVADOR >= 0
                              ? `${object.valores.INVESTIGADOR_DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil combina uma abordagem analítica com um foco na estabilidade, sendo capaz de tomar decisões estratégicas mantendo a ordem e a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Investigador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR_MANTENEDOR ||
                            object.valores.INVESTIGADOR_MANTENEDOR >= 0
                              ? `${object.valores.INVESTIGADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil tem um foco na estabilidade e na manutenção da ordem, sendo fundamental para manter a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">Mantenedor</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR ||
                            object.valores.MANTENEDOR >= 0
                              ? `${object.valores.MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com um foco na estabilidade e nos resultados, este perfil é capaz de manter a ordem sem perder de vista os objetivos da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Mantenedor - Desbravador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR_DESBRAVADOR ||
                            object.valores.MANTENEDOR_DESBRAVADOR >= 0
                              ? `${object.valores.MANTENEDOR_DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Uma combinação de estabilidade e habilidades de relacionamento, este perfil é ótimo para manter o equilíbrio e a harmonia da equipe mantendo bons relacionamentos.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Mantenedor - Embaixador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR_EMBAIXADOR ||
                            object.valores.MANTENEDOR_EMBAIXADOR >= 0
                              ? `${object.valores.MANTENEDOR_EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                    </GroupItems>
                  </Card>
                </Group>
                <Group width="60%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório cultural</SubTitle>
                      <LabelData font="14px" weight="400">
                        Na parte de cultura, está demonstrada a cultura
                        predominante da empresa, baseada nos relatórios de todos
                        os colaboradores. Para entender melhor sobre os
                        diferentes estilos culturais, passe o mouse sobre os
                        valores do gráfico.
                      </LabelData>
                      <Chart
                        options={radar.options}
                        series={radar.series}
                        type="radar"
                        height={600}
                      />
                      <GroupItems padding="15px">
                        <Accordion
                          expanded={expanded === 'panel1'}
                          onChange={handleChange('panel1')}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography sx={{ color: colors.darkBlue }}>
                              <LabelData
                                font="16px"
                                weight="400"
                                color={colors.lightBlue}
                              >
                                <b>Cultura predominante:</b>{' '}
                              </LabelData>
                              <b>Cultura de {object.cultura.cultura}</b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GroupText>
                              <Typography>{object.cultura.texto}</Typography>
                            </GroupText>
                          </AccordionDetails>
                        </Accordion>
                      </GroupItems>
                      <GroupItems padding="15px">
                        <Accordion
                          expanded={otherCulturesExpanded}
                          onChange={handleChangeExpandOtherCultures}
                          sx={{ overflow: 'hidden' }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box sx={{ display: 'flex' }}>
                              <Typography
                                sx={{ color: colors.darkBlue, fontWeight: 700 }}
                              >
                                Outras culturas
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {Object.keys(cultureDescriptions)
                              .filter(key => key !== object.cultura.cultura)
                              .map(key => (
                                <Box
                                  sx={{
                                    marginBottom: '16px',
                                    paddingX: '16px',
                                  }}
                                >
                                  <Typography sx={{ fontWeight: 700 }}>
                                    {key}
                                  </Typography>
                                  <Typography>
                                    {cultureDescriptions[key]}
                                  </Typography>
                                </Box>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      </GroupItems>
                    </GroupItems>
                  </Card>
                </Group>
              </Row>
            ) : (
              <>
                <Group width="100%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório comportamental</SubTitle>
                      <LabelData font="14px" weight="400">
                        Abaixo, é possível ver quais são os perfis
                        comportamentais dominantes quando fazemos uma média de
                        todos os candidatos. Caso queira ver a descrição
                        detalhada de algum perfil, basta clicar no ícone
                      </LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      <LabelData font="14px" weight="400">
                        , ao lado dos nomes. E, para ver os números exatos,
                        passe o mouse por cima de cada barra.
                      </LabelData>
                      <br />
                      <br />
                      <DescriptionData font="14px">
                        Como você pode ver, é possível que o perfil predominante
                        seja apenas um ou uma combinação de dois perfis
                        distintos, como “Embaixador – Mantenedor”, por exemplo.
                      </DescriptionData>
                      <br />
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Desbravador</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR ||
                            object.valores.DESBRAVADOR >= 0
                              ? `${object.valores.DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">
                            Desbravador - Embaixador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_EMBAIXADOR ||
                            object.valores.DESBRAVADOR_EMBAIXADOR >= 0
                              ? `${object.valores.DESBRAVADOR_EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">
                            Desbravador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_MANTENEDOR ||
                            object.valores.DESBRAVADOR_MANTENEDOR >= 0
                              ? `${object.valores.DESBRAVADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">
                            Desbravador - Investigador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.DESBRAVADOR_INVESTIGADOR ||
                            object.valores.DESBRAVADOR_INVESTIGADOR >= 0
                              ? `${object.valores.DESBRAVADOR_INVESTIGADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>

                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <LabelData font="12px">Embaixador</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.EMBAIXADOR ||
                            object.valores.EMBAIXADOR >= 0
                              ? `${object.valores.EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com uma combinação de habilidades de relacionamento e foco na estabilidade, este perfil é ótimo para manter o equilíbrio e a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Embaixador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.EMBAIXADOR_MANTENEDOR ||
                            object.valores.EMBAIXADOR_MANTENEDOR >= 0
                              ? `${object.valores.EMBAIXADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Icon float="left" src={vector} alt="Icon" />
                          <Tooltip title="Este perfil é altamente analítico e orientado para a resolução de problemas complexos.">
                            <LabelData font="12px">Investigador</LabelData>
                          </Tooltip>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR ||
                            object.valores.INVESTIGADOR >= 0
                              ? `${object.valores.INVESTIGADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com uma abordagem analítica e orientada para a ação, este perfil é capaz de tomar decisões estratégicas e executá-las de forma eficaz.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Investigador - Desbravador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR_DESBRAVADOR ||
                            object.valores.INVESTIGADOR_DESBRAVADOR >= 0
                              ? `${object.valores.INVESTIGADOR_DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil combina uma abordagem analítica com um foco na estabilidade, sendo capaz de tomar decisões estratégicas mantendo a ordem e a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />{' '}
                          </Tooltip>
                          <LabelData font="12px">
                            Investigador - Mantenedor
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.INVESTIGADOR_MANTENEDOR ||
                            object.valores.INVESTIGADOR_MANTENEDOR >= 0
                              ? `${object.valores.INVESTIGADOR_MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Este perfil tem um foco na estabilidade e na manutenção da ordem, sendo fundamental para manter a harmonia da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">Mantenedor</LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR ||
                            object.valores.MANTENEDOR >= 0
                              ? `${object.valores.MANTENEDOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Com um foco na estabilidade e nos resultados, este perfil é capaz de manter a ordem sem perder de vista os objetivos da equipe.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Mantenedor - Desbravador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR_DESBRAVADOR ||
                            object.valores.MANTENEDOR_DESBRAVADOR >= 0
                              ? `${object.valores.MANTENEDOR_DESBRAVADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                      <Row top="2%" align="start" bottom="2px">
                        <GpItems
                          padding="14px"
                          width="85%"
                          radius="12px 0px 0px 12px"
                          background={colors.white}
                          borderColor={colors.gray5}
                        >
                          <Tooltip title="Uma combinação de estabilidade e habilidades de relacionamento, este perfil é ótimo para manter o equilíbrio e a harmonia da equipe mantendo bons relacionamentos.">
                            <Icon float="left" src={vector} alt="Icon" />
                          </Tooltip>
                          <LabelData font="12px">
                            Mantenedor - Embaixador
                          </LabelData>
                        </GpItems>
                        <GpItems
                          padding="16px"
                          width="15%"
                          radius="0px 12px 12px 0px"
                          background={colors.lightBlue}
                          borderColor={colors.lightBlue}
                        >
                          <DescriptionData font="14px" color={colors.white}>
                            {object.valores.MANTENEDOR_EMBAIXADOR ||
                            object.valores.MANTENEDOR_EMBAIXADOR >= 0
                              ? `${object.valores.MANTENEDOR_EMBAIXADOR}%`
                              : '-'}
                          </DescriptionData>
                        </GpItems>
                      </Row>
                    </GroupItems>
                  </Card>
                </Group>
                <Group width="100%">
                  <Card width="100%" height="1300px" bottom="0%">
                    <GroupItems>
                      <SubTitle>Relatório cultural</SubTitle>
                      <LabelData font="14px" weight="400">
                        Na parte de cultura, está demonstrada a cultura
                        predominante da empresa, baseada nos relatórios de todos
                        os colaboradores. Para entender melhor sobre os
                        diferentes estilos culturais, clique no botão.
                      </LabelData>
                      <Icon float="none" src={vector} alt="Icon" />
                      {isVisible ? (
                        <Chart
                          options={radar.options}
                          series={radar.series}
                          type="radar"
                          height={800}
                        />
                      ) : (
                        false
                      )}
                      <GroupItems padding="15px">
                        <Accordion
                          expanded={expanded === 'panel1'}
                          onChange={handleChange('panel1')}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography sx={{ width: '6%', flexShrink: 0 }}>
                              <Icon width="40px" src={Graph} alt="Item" />
                            </Typography>
                            <Typography sx={{ color: colors.darkBlue }}>
                              <b>Acolhimento</b>
                              <LabelData
                                font="16px"
                                weight="400"
                                color={colors.lightBlue}
                              >
                                {' '}
                                &nbsp;<b>Perfil predominante</b>
                              </LabelData>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GroupText>
                              <Typography>
                                A cultura de acolhimento está relacionada aos
                                relacionamentos e à confiança mútua. Os
                                ambientes são locais calorosos, onde as pessoas
                                ajudam e apoiam umas às outras. As pessoas são
                                unidas por lealdade e os líderes enfatizam a
                                sinceridade, o trabalho em equipe e os
                                relacionamentos positivos..
                              </Typography>
                            </GroupText>
                          </AccordionDetails>
                        </Accordion>
                      </GroupItems>
                    </GroupItems>
                  </Card>
                </Group>
              </>
            )}
          </ContainerCard>

          <ContainerCard top="0%" bottom="1%">
            <Card width="100%" heigth="650px">
              <GroupItems padding="2%">
                <GroupItems padding="1%">
                  <SubTitle>Âncoras de carreira</SubTitle>
                  <LabelData font="14px" weight="400">
                    Por fim, é possível ver um consolidado entre os que os
                    colaboradores valorizam e{' '}
                    <strong>o que é valorizado pela empresa.</strong>
                  </LabelData>
                </GroupItems>
                <GroupItems padding="1%">
                  <Typography sx={{ color: colors.darkBlue, fontWeight: 700 }}>
                    Explicação das cores
                  </Typography>
                </GroupItems>
                {width > 530 ? (
                  <>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="10%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={plus}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="90%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Verde
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica satisfação alta dos colaborador(es),
                          sinalizando que a empresa está atendendo ou superando
                          as expectativas nesses atributos.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="10%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={total}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="90%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Amarelo
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica uma satisfação moderada dos colaborador(es),
                          onde a empresa está entregando de forma razoável, mas
                          ainda há espaço para melhorias.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="10%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={minus}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="90%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Vermelho
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica insatisfação dos colaborador(es) em relação à
                          entrega da empresa para determinados atributos.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="35%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={plus}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="65%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Verde
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica satisfação alta dos colaborador(es),
                          sinalizando que a empresa está atendendo ou superando
                          as expectativas nesses atributos.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="35%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={total}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="65%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Amarelo
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica uma satisfação moderada dos colaborador(es),
                          onde a empresa está entregando de forma razoável, mas
                          ainda há espaço para melhorias.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                    <Row top="2%" align="start" bottom="2px">
                      <GpItems
                        padding="14px"
                        width="35%"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <Icon
                          float="left"
                          width="120px"
                          src={minus}
                          alt="Icon"
                        />
                      </GpItems>
                      <GpItems
                        padding="16px"
                        width="65%"
                        radius="0px 12px 12px 0px"
                        background={colors.transparent}
                        borderColor={colors.transparent}
                      >
                        <DescriptionData font="14px" top="0px">
                          Vermelho
                        </DescriptionData>
                        <DescriptionData font="14px" weight="400" top="0px">
                          Indica insatisfação dos colaborador(es) em relação à
                          entrega da empresa para determinados atributos.
                        </DescriptionData>
                      </GpItems>
                    </Row>
                  </>
                )}
                <GroupItems padding="2%">
                  {width > 530 ? (
                    <Row top="0%" items="start">
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.darkBlue}
                            border={colors.darkBlue}
                          >
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title="Estrutura e benefícios"
                                tooltip="Avalia a satisfação dos colaboradores com as condições de trabalho e benefícios. Uma pontuação alta indica satisfação com as condições e benefícios, enquanto uma pontuação baixa indica a necessidade de revisão e melhoria."
                                value=""
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Pacote de Benefícios"
                                tooltip="Reflete o quanto os colaboradores estão satisfeitos com o pacote de benefícios oferecido pela empresa. Um valor alto indica satisfação com os benefícios, enquanto um valor baixo indica a necessidade de rever e melhorar o pacote de benefícios."
                                value={`
                                  ${Math.round(
                                    +object.valores_carreira
                                      .PACOTE_DE_BENEFICIOS_ADERENCIA,
                                  )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .PACOTE_DE_BENEFICIOS_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .PACOTE_DE_BENEFICIOS_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Plano de carreira claro e estruturado"
                                tooltip="Indica o quanto os colaboradores percebem que há um plano de carreira claro e estruturado na empresa. Um valor alto sugere que há clareza e estrutura no plano de carreira, enquanto um valor baixo indica a necessidade de melhorar a clareza e a estrutura do plano de carreira."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Possibilidade de crescimento e promoção"
                                tooltip="Reflete o quanto os colaboradores percebem que há possibilidades de crescimento e promoção na empresa. Um valor alto sugere que há oportunidades de crescimento e promoção, enquanto um valor baixo indica a necessidade de melhorar as oportunidades de crescimento e promoção."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Processos simples e desburocratizados"
                                tooltip="Indica o quanto os colaboradores percebem que os processos da empresa são simples e desburocratizados. Um valor alto sugere que os processos são eficientes e sem burocracia, enquanto um valor baixo indica a necessidade de simplificar e desburocratizar os processos."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Reconhecimento e valorização"
                                tooltip="Indica o quanto os colaboradores se sentem reconhecidos e valorizados pela empresa. Um valor alto sugere que os colaboradores se sentem apreciados, enquanto um valor baixo indica a necessidade de melhorar o reconhecimento e a valorização do trabalho dos colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Salário"
                                tooltip="Indica o quanto os colaboradores estão satisfeitos com seu salário. Um valor alto sugere satisfação com o salário, enquanto um valor baixo indica a necessidade de rever e ajustar os salários."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira.SALARIO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira.SALARIO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira.SALARIO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Visão de futuro da empresa ou negócio"
                                tooltip="Reflete o quanto os colaboradores acreditam na visão de futuro da empresa. Um valor alto sugere que os colaboradores estão alinhados e acreditam na direção que a empresa está tomando, enquanto um valor baixo indica a necessidade de melhorar a comunicação e o alinhamento com a visão de futuro da empresa."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.darkBlue}
                            border={colors.darkBlue}
                          >
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title="Cultura e valores"
                                tooltip="Avalia o alinhamento dos colaboradores com a cultura e valores da empresa, bem como as oportunidades de desenvolvimento. Uma pontuação alta indica alinhamento e apoio ao desenvolvimento, enquanto uma pontuação baixa indica a necessidade de melhorar a comunicação, o alinhamento e as oportunidades de desenvolvimento."
                                value=""
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Cultura e valores da empresa"
                                tooltip="Indica o quanto os colaboradores acreditam e se identificam com a cultura e valores da empresa. Um valor alto sugere que os colaboradores estão alinhados e se identificam com a cultura e valores da empresa, enquanto um valor baixo indica a necessidade de melhorar o alinhamento e a identificação com a cultura e valores da empresa."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Desafios constantes"
                                tooltip="Reflete o quanto os colaboradores percebem que recebem desafios constantes e significativos pela empresa. Um valor alto sugere que os colaboradores se sentem desafiados e engajados, enquanto um valor baixo indica a necessidade de melhorar a oferta de desafios e oportunidades para os colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .DESAFIOS_CONSTANTES_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .DESAFIOS_CONSTANTES_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .DESAFIOS_CONSTANTES_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Gestão clara e acompanhamento dos objetivos"
                                tooltip="Indica o quanto os colaboradores percebem que a gestão e o acompanhamento dos objetivos são claros e eficazes. Um valor alto sugere que há clareza e eficácia na gestão e acompanhamento dos objetivos, enquanto um valor baixo indica a necessidade de melhorar a clareza e o acompanhamento dos objetivos."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Horário de trabalho"
                                tooltip="Reflete o quanto os colaboradores estão satisfeitos com o horário de trabalho. Um valor alto indica satisfação com o horário de trabalho, enquanto um valor baixo indica a necessidade de rever e ajustar os horários de trabalho."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .HORARIO_DE_TRABALHO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .HORARIO_DE_TRABALHO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .HORARIO_DE_TRABALHO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Local de trabalho"
                                tooltip="Indica o quanto os colaboradores estão satisfeitos com seu local de trabalho. Um valor alto sugere satisfação com o local de trabalho, enquanto um valor baixo indica a necessidade de melhorar as condições do local de trabalho."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .LOCAL_DE_TRABALHO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .LOCAL_DE_TRABALHO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .LOCAL_DE_TRABALHO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Oportunidades de desenvolvimento e aprendizado"
                                tooltip="Reflete o quanto os colaboradores percebem que há oportunidades de desenvolvimento e aprendizado na empresa. Um valor alto sugere que há uma cultura de aprendizado e desenvolvimento, enquanto um valor baixo indica a necessidade de melhorar as oportunidades de desenvolvimento e aprendizado."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Tratamento humanizado"
                                tooltip="Reflete o nível de humanização no tratamento dos colaboradores. Um valor alto indica que os colaboradores se sentem tratados com respeito e dignidade, enquanto um valor baixo sugere a necessidade de melhorar o tratamento humanizado dos colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .TRATAMENTO_HUMANIZADO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .TRATAMENTO_HUMANIZADO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .TRATAMENTO_HUMANIZADO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Tratamento justo e igualitário com todos"
                                tooltip="Indica o quanto os colaboradores percebem que são tratados de maneira justa e igualitária pela empresa. Um valor alto sugere que há justiça e igualdade no tratamento dos colaboradores, enquanto um valor baixo indica a necessidade de melhorar a equidade no tratamento dos colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                      <Group width="35%">
                        <Grid xs="3">
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.darkBlue}
                            border={colors.darkBlue}
                          >
                            <GroupItems padding="1%">
                              <CardColor
                                right
                                left
                                iconWhite
                                width="100%"
                                bgColor={colors.darkBlue}
                                title="Clima e confiança"
                                tooltip="Avalia a qualidade das relações e o ambiente de trabalho. Uma pontuação alta indica um ambiente positivo e relações saudáveis, enquanto uma pontuação baixa sugere a necessidade de melhorias na cultura e nas relações interpessoais."
                                value=""
                                font="11px"
                                height="100%"
                                fontColor={colors.white}
                                valueColor={colors.white}
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Abertura para mudanças e inovação"
                                tooltip="Indica o quanto os colaboradores percebem que a empresa está aberta a mudanças e inovações. Um valor alto sugere que a empresa é vista como inovadora e adaptável, enquanto um valor baixo indica a necessidade de promover uma cultura de inovação e adaptabilidade."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Autonomia e abertura para se expressar"
                                tooltip="Este valor indica o quanto os colaboradores se sentem livres e encorajados a expressar suas ideias e opiniões na empresa. Um valor alto sugere que os colaboradores se sentem valorizados e ouvidos, enquanto um valor baixo pode indicar que há espaço para melhorias na promoção da abertura e autonomia."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Celebração de conquistas"
                                tooltip="Reflete o quanto os colaboradores percebem que suas conquistas são celebradas pela empresa. Um valor alto sugere que há uma cultura de celebração e reconhecimento das conquistas, enquanto um valor baixo indica a necessidade de melhorar o reconhecimento e a celebração das conquistas dos colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Clima interno"
                                tooltip="Reflete a percepção dos colaboradores sobre o ambiente de trabalho. Um valor alto indica um ambiente de trabalho positivo e saudável, enquanto um valor baixo sugere a necessidade de melhorias na atmosfera de trabalho."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .CLIMA_INTERNO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .CLIMA_INTERNO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .CLIMA_INTERNO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Confiança na liderança imediata"
                                tooltip="Indica o nível de confiança que os colaboradores têm em seus líderes imediatos. Um valor alto sugere que os líderes são vistos como confiáveis e capazes, enquanto um valor baixo indica a necessidade de melhorar a confiança e a relação entre líderes e colaboradores."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                          <SubCard
                            top="2%"
                            width="100%"
                            heigth="auto"
                            background={colors.white}
                            border={colors.gray5}
                          >
                            <GroupItems padding="5%">
                              <CardColor
                                right
                                left
                                width="100%"
                                bgColor={colors.white}
                                title="Confiança no time de trabalho"
                                tooltip="Reflete o nível de confiança que os colaboradores têm em seus colegas de equipe. Um valor alto indica uma equipe coesa e colaborativa, enquanto um valor baixo sugere a necessidade de fortalecer a confiança e a colaboração dentro da equipe."
                                value={`
                                ${Math.round(
                                  +object.valores_carreira
                                    .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                                )}%`}
                                colorProgress={semaforo(
                                  object.valores_carreira
                                    .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                                )}
                                valueColor={semaforo(
                                  object.valores_carreira
                                    .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                                )}
                                font="11px"
                                height="100%"
                                progress
                              />
                            </GroupItems>
                          </SubCard>
                        </Grid>
                      </Group>
                    </Row>
                  ) : (
                    <>
                      <Grid xs="3">
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.darkBlue}
                          border={colors.darkBlue}
                        >
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title="Estrutura e benefícios"
                              tooltip="Avalia a satisfação dos colaboradores com as condições de trabalho e benefícios. Uma pontuação alta indica satisfação com as condições e benefícios, enquanto uma pontuação baixa indica a necessidade de revisão e melhoria."
                              value=""
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Pacote de Benefícios"
                              tooltip="Reflete o quanto os colaboradores estão satisfeitos com o pacote de benefícios oferecido pela empresa. Um valor alto indica satisfação com os benefícios, enquanto um valor baixo indica a necessidade de rever e melhorar o pacote de benefícios."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .PACOTE_DE_BENEFICIOS_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .PACOTE_DE_BENEFICIOS_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .PACOTE_DE_BENEFICIOS_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Plano de carreira claro e estruturado"
                              tooltip="Indica o quanto os colaboradores percebem que há um plano de carreira claro e estruturado na empresa. Um valor alto sugere que há clareza e estrutura no plano de carreira, enquanto um valor baixo indica a necessidade de melhorar a clareza e a estrutura do plano de carreira."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira_semaforo
                                  .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira_semaforo
                                  .PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Possibilidade de crescimento e promoção"
                              tooltip="Reflete o quanto os colaboradores percebem que há possibilidades de crescimento e promoção na empresa. Um valor alto sugere que há oportunidades de crescimento e promoção, enquanto um valor baixo indica a necessidade de melhorar as oportunidades de crescimento e promoção."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira_semaforo
                                  .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira_semaforo
                                  .POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Processos simples e desburocratizados"
                              tooltip="Indica o quanto os colaboradores percebem que os processos da empresa são simples e desburocratizados. Um valor alto sugere que os processos são eficientes e sem burocracia, enquanto um valor baixo indica a necessidade de simplificar e desburocratizar os processos."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira_semaforo
                                  .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira_semaforo
                                  .PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Reconhecimento e valorização"
                              tooltip="Indica o quanto os colaboradores se sentem reconhecidos e valorizados pela empresa. Um valor alto sugere que os colaboradores se sentem apreciados, enquanto um valor baixo indica a necessidade de melhorar o reconhecimento e a valorização do trabalho dos colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira_semaforo
                                  .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira_semaforo
                                  .RECONHECIMENTO_E_VALORIZACAO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Salário"
                              tooltip="Indica o quanto os colaboradores estão satisfeitos com seu salário. Um valor alto sugere satisfação com o salário, enquanto um valor baixo indica a necessidade de rever e ajustar os salários."
                              value={`
                              ${Math.round(
                                +object.valores_carreira.SALARIO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira.SALARIO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira.SALARIO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Visão de futuro da empresa ou negócio"
                              tooltip="Reflete o quanto os colaboradores acreditam na visão de futuro da empresa. Um valor alto sugere que os colaboradores estão alinhados e acreditam na direção que a empresa está tomando, enquanto um valor baixo indica a necessidade de melhorar a comunicação e o alinhamento com a visão de futuro da empresa."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>

                      <Grid xs="3">
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.darkBlue}
                          border={colors.darkBlue}
                        >
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title="Cultura e valores"
                              tooltip="Indica o quanto os colaboradores acreditam e se identificam com a cultura e valores da empresa. Um valor alto sugere que os colaboradores estão alinhados e se identificam com a cultura e valores da empresa, enquanto um valor baixo indica a necessidade de melhorar o alinhamento e a identificação com a cultura e valores da empresa."
                              value=""
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Cultura e valores da empresa"
                              tooltip="Indica o quanto os colaboradores acreditam e se identificam com a cultura e valores da empresa. Um valor alto sugere que os colaboradores estão alinhados e se identificam com a cultura e valores da empresa, enquanto um valor baixo indica a necessidade de melhorar o alinhamento e a identificação com a cultura e valores da empresa."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Desafios constantes"
                              tooltip="Reflete o quanto os colaboradores percebem que recebem desafios constantes e significativos pela empresa. Um valor alto sugere que os colaboradores se sentem desafiados e engajados, enquanto um valor baixo indica a necessidade de melhorar a oferta de desafios e oportunidades para os colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .DESAFIOS_CONSTANTES_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .DESAFIOS_CONSTANTES_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .DESAFIOS_CONSTANTES_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Gestão clara e acompanhamento dos objetivos"
                              tooltip="Indica o quanto os colaboradores percebem que a gestão e o acompanhamento dos objetivos são claros e eficazes. Um valor alto sugere que há clareza e eficácia na gestão e acompanhamento dos objetivos, enquanto um valor baixo indica a necessidade de melhorar a clareza e o acompanhamento dos objetivos."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Horário de trabalho"
                              tooltip="Reflete o quanto os colaboradores estão satisfeitos com o horário de trabalho. Um valor alto indica satisfação com o horário de trabalho, enquanto um valor baixo indica a necessidade de rever e ajustar os horários de trabalho."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .HORARIO_DE_TRABALHO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .HORARIO_DE_TRABALHO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .HORARIO_DE_TRABALHO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Local de trabalho"
                              tooltip="Indica o quanto os colaboradores estão satisfeitos com seu local de trabalho. Um valor alto sugere satisfação com o local de trabalho, enquanto um valor baixo indica a necessidade de melhorar as condições do local de trabalho."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .LOCAL_DE_TRABALHO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .LOCAL_DE_TRABALHO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .LOCAL_DE_TRABALHO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Oportunidades de desenvolvimento e aprendizado"
                              tooltip="Reflete o quanto os colaboradores percebem que há oportunidades de desenvolvimento e aprendizado na empresa. Um valor alto sugere que há uma cultura de aprendizado e desenvolvimento, enquanto um valor baixo indica a necessidade de melhorar as oportunidades de desenvolvimento e aprendizado."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Tratamento humanizado"
                              tooltip="Reflete o nível de humanização no tratamento dos colaboradores. Um valor alto indica que os colaboradores se sentem tratados com respeito e dignidade, enquanto um valor baixo sugere a necessidade de melhorar o tratamento humanizado dos colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .TRATAMENTO_HUMANIZADO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .TRATAMENTO_HUMANIZADO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .TRATAMENTO_HUMANIZADO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Tratamento justo e igualitário com todos"
                              tooltip="Indica o quanto os colaboradores percebem que são tratados de maneira justa e igualitária pela empresa. Um valor alto sugere que há justiça e igualdade no tratamento dos colaboradores, enquanto um valor baixo indica a necessidade de melhorar a equidade no tratamento dos colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>

                      <Grid xs="3">
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.darkBlue}
                          border={colors.darkBlue}
                        >
                          <GroupItems padding="1%">
                            <CardColor
                              right
                              left
                              iconWhite
                              width="100%"
                              bgColor={colors.darkBlue}
                              title="Clima e confiança"
                              tooltip="Avalia a qualidade das relações e o ambiente de trabalho. Uma pontuação alta indica um ambiente positivo e relações saudáveis, enquanto uma pontuação baixa sugere a necessidade de melhorias na cultura e nas relações interpessoais."
                              value=""
                              font="11px"
                              height="100%"
                              fontColor={colors.white}
                              valueColor={colors.white}
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Abertura para mudanças e inovação"
                              tooltip="Indica o quanto os colaboradores percebem que a empresa está aberta a mudanças e inovações. Um valor alto sugere que a empresa é vista como inovadora e adaptável, enquanto um valor baixo indica a necessidade de promover uma cultura de inovação e adaptabilidade."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Autonomia e abertura para se expressar"
                              tooltip="Este valor indica o quanto os colaboradores se sentem livres e encorajados a expressar suas ideias e opiniões na empresa. Um valor alto sugere que os colaboradores se sentem valorizados e ouvidos, enquanto um valor baixo pode indicar que há espaço para melhorias na promoção da abertura e autonomia."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Celebração de conquistas"
                              tooltip="Reflete o quanto os colaboradores percebem que suas conquistas são celebradas pela empresa. Um valor alto sugere que há uma cultura de celebração e reconhecimento das conquistas, enquanto um valor baixo indica a necessidade de melhorar o reconhecimento e a celebração das conquistas dos colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .CELEBRACAO_DE_CONQUISTAS_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Clima interno"
                              tooltip="Reflete a percepção dos colaboradores sobre o ambiente de trabalho. Um valor alto indica um ambiente de trabalho positivo e saudável, enquanto um valor baixo sugere a necessidade de melhorias na atmosfera de trabalho."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .CLIMA_INTERNO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira.CLIMA_INTERNO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira.CLIMA_INTERNO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Confiança na liderança imediata"
                              tooltip="Indica o nível de confiança que os colaboradores têm em seus líderes imediatos. Um valor alto sugere que os líderes são vistos como confiáveis e capazes, enquanto um valor baixo indica a necessidade de melhorar a confiança e a relação entre líderes e colaboradores."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                        <SubCard
                          top="2%"
                          width="100%"
                          heigth="auto"
                          background={colors.white}
                          border={colors.gray5}
                        >
                          <GroupItems padding="5%">
                            <CardColor
                              right
                              left
                              width="100%"
                              bgColor={colors.white}
                              title="Confiança no time de trabalho"
                              tooltip="Reflete o nível de confiança que os colaboradores têm em seus colegas de equipe. Um valor alto indica uma equipe coesa e colaborativa, enquanto um valor baixo sugere a necessidade de fortalecer a confiança e a colaboração dentro da equipe."
                              value={`
                              ${Math.round(
                                +object.valores_carreira
                                  .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                              )}%`}
                              colorProgress={semaforo(
                                object.valores_carreira
                                  .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                              )}
                              valueColor={semaforo(
                                object.valores_carreira
                                  .CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA,
                              )}
                              font="11px"
                              height="100%"
                              progress
                            />
                          </GroupItems>
                        </SubCard>
                      </Grid>
                    </>
                  )}
                </GroupItems>
              </GroupItems>
            </Card>
          </ContainerCard>
        </>
      )}
    </Container>
  );
}

export default CompanyDashboard;
