export function ExclamationIcon({ size = 20, color = '#062847' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM9.375 5.78125C9.375 5.69531 9.44531 5.625 9.53125 5.625H10.4688C10.5547 5.625 10.625 5.69531 10.625 5.78125V11.0938C10.625 11.1797 10.5547 11.25 10.4688 11.25H9.53125C9.44531 11.25 9.375 11.1797 9.375 11.0938V5.78125ZM10 14.375C9.75467 14.37 9.52108 14.269 9.34935 14.0938C9.17763 13.9185 9.08144 13.6829 9.08144 13.4375C9.08144 13.1921 9.17763 12.9565 9.34935 12.7812C9.52108 12.606 9.75467 12.505 10 12.5C10.2453 12.505 10.4789 12.606 10.6506 12.7812C10.8224 12.9565 10.9186 13.1921 10.9186 13.4375C10.9186 13.6829 10.8224 13.9185 10.6506 14.0938C10.4789 14.269 10.2453 14.37 10 14.375Z"
        fill={color}
      />
    </svg>
  );
}
