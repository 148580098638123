import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import Correct from '../../assets/img/Correct.svg';
import X from '../../assets/img/X.svg';
import Carbon from '../../assets/img/Carbon.svg';
import Result from '../../assets/img/Result.svg';
import Like from '../../assets/img/Like.svg';
import NotLike from '../../assets/img/NotLike.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import StoreContext from '../../components/Store/Context';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import ErrorModal from '../../components/ErrorModal';
import { useQuery } from '../../utils/searchQuery';

import {
  Container,
  Image,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  Note,
  DescriptionGroup,
  ContainerButton,
  GpImage,
  GpItems,
} from './styles';
import { ProgressCard } from '../../components/ProgressCard';

function CareerReport() {
  const { token } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [report, setReport] = useState([]);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [valueNote, setValueNote] = useState(0);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [comentary, setComentary] = useState('');

  const query = useQuery();
  const activeUser = query.get('activeUser')
    ? decodeURIComponent(query.get('activeUser'))
    : null;

  const onSend = async () => {
    const body = {
      teste_id: report.ID,
      nps: valueNote,
      comentario: comentary,
    };

    await api
      .post('/nps', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setSend(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const handleComentary = async e => {
    e.preventDefault();
    setComentary(e.target.value);
  };

  const getTest = useCallback(async () => {
    const body = {
      email: activeUser ?? token.email,
    };
    await api
      .post('/tests', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.valores;
        setReport(data);
      })
      .catch(() => {
        setError(true);
      });
  }, [activeUser, token]);

  const handleChangeNote = value => {
    setValueNote(value);
    if (value === 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value === 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value === 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setSend(false);
  };

  useEffect(() => {
    getTest();
    setWidth(window.screen.width);
  }, [getTest, width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <AlertModal
        isOpen={send}
        msg="Avaliação enviada com sucesso!"
        onRequestClose={onCloseAlert}
      />
      <Title>Relatório de Âncora de Carreira iGrown</Title>
      <ContainerCard top="20%">
        <Row>
          <DescriptionData weight="400">Última atualização em</DescriptionData>
          <DescriptionData>
            &nbsp;
            {report ? moment(report.DATA_DA_ENTRADA).format('DD/MM/YYYY') : ''}
          </DescriptionData>
        </Row>
      </ContainerCard>
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Hey, iGrowner!</SubTitle>
            <LabelData font="14px" weight="400">
              Aqui está o seu{' '}
            </LabelData>
            <LabelData font="14px">
              Relatório de Âncora de Carreira iGrown
            </LabelData>
            <LabelData font="14px" weight="400">
              . O valor de carreira é a diferença entre o conjunto de benefícios
              (ganhos percebidos) e os custos envolvidos em sua jornada
              profissional (perdas nesse processo).
            </LabelData>
            <Row>
              <GpImage width="auto">
                <Image width="30px" src={Like} alt="Item" />
                <DescriptionData align="center">Benefícios</DescriptionData>
              </GpImage>
              <LabelData bottom="5%" font="14px">
                +
              </LabelData>
              <GpImage width="auto">
                <Image width="30px" src={NotLike} alt="Item" />
                <DescriptionData align="center">Custos</DescriptionData>
              </GpImage>
              <LabelData bottom="5%" font="14px">
                =
              </LabelData>
              <GpImage width="auto" top="12px">
                <Image width="30px" src={Result} alt="Item" />
                <DescriptionData align="center">
                  Valores de <br /> carreira
                </DescriptionData>
              </GpImage>
            </Row>
            <SubTitle font="16px">
              Nesse relatório, você irá conferir:{' '}
            </SubTitle>
            <DescriptionData font="14px">
              • Quais são os pontos mais e menos relevantes para você quando
              falamos de carreira;
            </DescriptionData>
            <DescriptionData font="14px">
              • Se estiver trabalhando, você também verá um balanço de como
              esses valores são percebidos na sua empresa;
            </DescriptionData>
            <DescriptionData font="14px">
              • Por fim, será mostrada a comparação entre o que você espera e o
              que a sua empresa está entregando;
            </DescriptionData>
            <DescriptionData bottom="1%" font="14px">
              Aproveite e #boradecolar!
            </DescriptionData>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Sobre você</SubTitle>
            <LabelData font="14px" weight="400">
              De acordo com suas respostas, analisamos o grau de importância de
              cada valor para a sua carreira profissional. Os números variam de
              0% a 100% e,
            </LabelData>
            <LabelData font="14px">
              &nbsp;quanto maior, mais importante aquele quesito para você.
            </LabelData>
            <DescriptionData font="14px" weight="400">
              Conforme mostrado abaixo, os atributos estão divididos em três
              grandes grupos: estrutura e benefícios, cultura e valores e clima
              e confiança.
            </DescriptionData>
            <Grid container spacing={4} padding={3}>
              <Grid item xs={12} md={4}>
                <SubTitle>Estrutura e benefícios</SubTitle>
                <SubTitle font="40px">
                  {report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR
                    ? `${report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_RELEVANCIA_COLABORADOR}%`
                    : ''}
                </SubTitle>
                <Stack spacing={2}>
                  <ProgressCard
                    title="Horário de trabalho"
                    hint="O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc)."
                    value={report.HORARIO_DE_TRABALHO}
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Local de trabalho"
                    hint="O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc)."
                    value={report.LOCAL_DE_TRABALHO}
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Pacote de benefícios"
                    hint="O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc)."
                    value={report.PACOTE_DE_BENEFICIOS}
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Salário"
                    hint="O quanto o salário pago é fator decisivo."
                    value={report.SALARIO}
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <SubTitle>Cultura e valores</SubTitle>
                <SubTitle font="40px">
                  {report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR
                    ? `${report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR}%`
                    : ''}
                </SubTitle>
                <Stack spacing={2}>
                  <ProgressCard
                    title="Abertura para mudanças"
                    hint="O quanto uma empresa demonstrar abertura real a mudanças e inovação."
                    value={report.ABERTURA_PARA_MUDANCAS_E_INOVACAO}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Celebração de conquista"
                    hint="O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo."
                    value={report.CELEBRACAO_DE_CONQUISTAS}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Cultura e valores da empresa"
                    hint={`O quanto a cultura ("jeitão" da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc).`}
                    value={report.CULTURA_E_VALORES_DA_EMPRESA}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Desafios constantes"
                    hint="O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo."
                    value={report.DESAFIOS_CONSTANTES}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Gestão clara e acompanhamento dos objetivos"
                    hint="O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos."
                    value={report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Oportunidade de desenvolvimento"
                    hint="O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos."
                    value={
                      report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO
                    }
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Plano de carreira estruturado"
                    hint="O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo."
                    value={report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Possibilidade de crescimento"
                    hint="O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo."
                    value={report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Processos simples e desburocratizados"
                    hint="O quanto a simplificação e organização dos processos da empresa são fatores decisivos."
                    value={report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS}
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <SubTitle>Clima e confiança</SubTitle>
                <SubTitle font="40px">
                  {report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR
                    ? `${report.CLIMA_E_CONFIANCA_PORCENTAGEM_RELEVANCIA_COLABORADOR}%`
                    : ''}
                </SubTitle>
                <Stack spacing={2}>
                  <ProgressCard
                    title="Autonomia e abertura para se expressar"
                    hint="O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa"
                    value={report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Clima interno"
                    hint="O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho"
                    value={report.CLIMA_INTERNO}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Confiança na liderança"
                    hint="O quanto sentir que você pode confiar em seu líder imediato (gestor)."
                    value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Confiança no time"
                    hint="O quanto sentir que você pode confiar em seu líder imediato (gestor) e equipe."
                    value={report.CONFIANCA_NO_TIME_DE_TRABALHO}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Reconhecimento e valorização"
                    hint="O quanto você sentir que recebe reconhecimento e valorização em uma empresa."
                    value={report.RECONHECIMENTO_E_VALORIZACAO}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Tratamento justo e igualitário"
                    hint="O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária."
                    value={report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Visão do futuro da sua empresa"
                    hint="O quanto ter clareza sobre o futuro da empresa e o que ela busca."
                    value={report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Tratamento humanizado"
                    hint="O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional)."
                    value={report.TRATAMENTO_HUMANIZADO}
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                </Stack>
              </Grid>
            </Grid>
          </GroupItems>
        </Card>
      </ContainerCard>

      {String(report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA)
        .length > 0 &&
        report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA !==
          undefined && (
          <ContainerCard top="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Sobre a empresa que você trabalha</SubTitle>
                <DescriptionData font="14px" weight="400">
                  Nessa segunda etapa do relatório, analisamos como você percebe
                  esse mesmos âncoras de carreira na sua empresa. Assim como foi
                  feito anteriormente, cada atributo recebeu uma nota (variando
                  entre 0% e 100%) e, quanto maior esse número, mais você
                  percebe aquele valor presente dentro da organização.
                </DescriptionData>
                <Grid container spacing={4} padding={3}>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Estrutura e benefícios</SubTitle>
                    <SubTitle font="40px">
                      {report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA
                        ? `${report.ESTRUTURA_E_BENEFICIOS_PORCENTAGEM_ATENDIMENTO_EMPRESA}%`
                        : ''}
                    </SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Horário de trabalho"
                        hint="O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc)."
                        value={
                          report.HORARIO_DE_TRABALHO_DA_SUA_EMPRESA_OU_AREA
                        }
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Local de trabalho"
                        hint="O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc)."
                        value={report.SEU_LOCAL_DE_TRABALHO}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Pacote de benefícios"
                        hint="O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc)."
                        value={report.PACOTE_DE_BENEFICIOS_DA_SUA_EMPRESA}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Salário"
                        hint="O quanto o salário pago é fator decisivo."
                        value={report.SEU_SALARIO}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Cultura e valores</SubTitle>
                    <SubTitle font="40px">
                      {report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA
                        ? `${report.CULTURA_E_VALORES_PORCENTAGEM_ATENDIMENTO_EMPRESA}%`
                        : ''}
                    </SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Abertura para mudanças"
                        hint="O quanto uma empresa demonstrar abertura real a mudanças e inovação."
                        value={
                          report.ABERTURA_DA_SUA_EMPRESA_PARA_MUDANCAS_E_INOVACAO
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Celebração de conquista"
                        hint="O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo."
                        value={report.CELEBRACAO_DE_CONQUISTAS_PELA_SUA_EMPRESA}
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Cultura e valores da empresa"
                        hint={`O quanto a cultura ("jeitão" da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc).`}
                        value={report.CULTURA_E_VALORES_DA_SUA_EMPRESA}
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Desafios constantes"
                        hint="O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo."
                        value={
                          report.DESAFIOS_CONSTANTES_DADOS_PELA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Gestão clara e acompanhamento dos objetivos"
                        hint="O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos."
                        value={
                          report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_PELA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Oportunidade de desenvolvimento"
                        hint="O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos."
                        value={
                          report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_NA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Plano de carreira estruturado"
                        hint="O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo."
                        value={
                          report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_NA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Possibilidade de crescimento"
                        hint="O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo."
                        value={
                          report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_NA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Processos simples e desburocratizados"
                        hint="O quanto a simplificação e organização dos processos da empresa são fatores decisivos."
                        value={
                          report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_DA_SUA_EMPRESA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Clima e confiança</SubTitle>
                    <SubTitle font="40px">
                      {report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA
                        ? `${report.CLIMA_E_CONFIANCA_PORCENTAGEM_ATENDIMENTO_EMPRESA}%`
                        : ''}
                    </SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Autonomia e abertura para se expressar"
                        hint="O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa"
                        value={
                          report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_NA_SUA_EMPRESA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Clima interno"
                        hint="O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho"
                        value={report.CLIMA_INTERNO_NA_SUA_EMPRESA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Confiança na liderança"
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor)."
                        value={report.CONFIANCA_NA_SUA_LIDERANCA_IMEDIATA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Confiança no time"
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor) e equipe."
                        value={report.CONFIANCA_NO_SEU_TIME_DE_TRABALHO}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Reconhecimento e valorização"
                        hint="O quanto você sentir que recebe reconhecimento e valorização em uma empresa."
                        value={
                          report.RECONHECIMENTO_E_VALORIZACAO_DO_SEU_TRABALHO
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Tratamento justo e igualitário"
                        hint="O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária."
                        value={
                          report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_EM_SUA_EMPRESA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Visão do futuro da sua empresa"
                        hint="O quanto ter clareza sobre o futuro da empresa e o que ela busca."
                        value={report.VISAO_DO_FUTURO_DA_SUA_EMPRESA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Tratamento humanizado"
                        hint="O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional)."
                        value={
                          report.TRATAMENTO_HUMANIZADO_DOS_COLABORADORES_DA_SUA_EMPRESA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </GroupItems>
            </Card>
          </ContainerCard>
        )}

      {String(report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA).length > 0 &&
        report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA !== undefined && (
          <ContainerCard top="-10%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <LabelData font="20px">Valores importantes para você</LabelData>
                <LabelData font="20px" color={colors.lightBlue}>
                  &nbsp;x
                </LabelData>
                <LabelData font="20px">
                  &nbsp;valores percebidos na sua empresa
                </LabelData>
                <DescriptionData font="16px">
                  Abaixo, está o índice de valor percebido em sua carreira na
                  empresa em que você trabalha atualmente:{' '}
                </DescriptionData>
                <br />
                <SubCard
                  top="0%"
                  width="100%"
                  heigth="auto"
                  mt="0"
                  pb="0"
                  bottom="0"
                  border={colors.white}
                >
                  <GroupItems padding="0%">
                    {width > 530 ? (
                      <Row top="5%" align="initial">
                        <GpItems
                          width="15%"
                          radius="12px 0px 0px 12px"
                          background={colors.lightBlue}
                        >
                          <Icon
                            float="center"
                            width="50px"
                            src={Carbon}
                            alt="Item"
                          />
                          <SubTitle
                            align="center"
                            color={colors.white}
                            font="40px"
                          >
                            {`${report.INDICE_DE_PERCEPCAO_DE_VALOR_DE_CARREIRA_NA_EMPRESA}%`}
                          </SubTitle>
                          <DescriptionData
                            align="center"
                            color={colors.white}
                            font="14px"
                            weight="400"
                          >
                            de valor percebido em sua carreira
                          </DescriptionData>
                        </GpItems>
                        <GpItems
                          width="85%"
                          radius="0px 12px 12px 0px"
                          background={colors.gray5}
                        >
                          <DescriptionData font="14px" weight="400">
                            Para chegarmos até esse número, foi feita uma
                            comparação entre os atributos que são importantes
                            para você e como você enxerga a sua empresa atual.{' '}
                          </DescriptionData>
                          <DescriptionData font="14px">
                            Portanto, esse resultado representa o quanto a
                            organização atende os atributos de carreira
                            pesquisados.{' '}
                          </DescriptionData>
                          <DescriptionData font="14px" weight="400">
                            Assim como aconteceu anteriormente, a variação é de
                            0% a 100% e, quanto maior a porcentagem, mais você
                            percebe valor na empresa atual.
                          </DescriptionData>
                        </GpItems>
                      </Row>
                    ) : (
                      <>
                        <GpItems
                          width="100%"
                          radius="12px 12px 0px 0px"
                          background={colors.lightBlue}
                        >
                          <ContainerButton>
                            <Icon
                              float="center"
                              width="50px"
                              src={Carbon}
                              alt="Item"
                            />
                          </ContainerButton>
                          <SubTitle
                            align="center"
                            color={colors.white}
                            font="40px"
                          >
                            {`${report.INDICE_DE_PERCEPCAO_DE_VALOR_DE_CARREIRA_NA_EMPRESA}%`}
                          </SubTitle>
                          <DescriptionData
                            align="center"
                            color={colors.white}
                            font="14px"
                            weight="400"
                          >
                            de valor percebido em sua carreira
                          </DescriptionData>
                        </GpItems>
                        <GpItems
                          width="100%"
                          radius="0px 0px 12px 12px"
                          background={colors.gray5}
                        >
                          <DescriptionData font="14px" weight="400">
                            Para chegarmos até esse número, foi feita uma
                            comparação entre os atributos que são importantes
                            para você e como você enxerga a sua empresa atual.{' '}
                          </DescriptionData>
                          <DescriptionData font="14px">
                            Portanto, esse resultado representa o quanto a
                            organização atende os atributos de carreira
                            pesquisados.{' '}
                          </DescriptionData>
                          <DescriptionData font="14px" weight="400">
                            Assim como aconteceu anteriormente, a variação é de
                            0% a 100% e, quanto maior a porcentagem, mais você
                            percebe valor na empresa atual.
                          </DescriptionData>
                        </GpItems>
                      </>
                    )}
                  </GroupItems>
                </SubCard>
                <DescriptionData font="14px">
                  O que é importante para mim também é relevante para minha
                  empresa?
                </DescriptionData>
                <br />
                <LabelData font="14px" weight="400">
                  Por último, analisamos a aderência dos atributos da empresa às
                  suas necessidades de carreira, ou seja,
                </LabelData>
                <LabelData font="14px">
                  mostramos o quanto a sua empresa atende aos atributos que são
                  importante para você.
                </LabelData>
                <Row top="0%" align="start">
                  <Icon
                    top="15px"
                    left="0px"
                    right="10px"
                    width="30px"
                    src={X}
                    alt="Item"
                  />
                  <DescriptionData font="14px">
                    Resultados abaixo de 100% demonstram que a empresa não está
                    atendendo as suas expectativas.{' '}
                  </DescriptionData>
                </Row>
                <Row top="0%" align="start">
                  <Icon
                    top="15px"
                    left="0px"
                    right="10px"
                    width="30px"
                    src={Correct}
                    alt="Item"
                  />
                  <DescriptionData font="14px">
                    Resultados próximos ou iguais a 100% demonstram que a
                    expectativa está sendo atendida
                  </DescriptionData>
                </Row>
                <Row top="0%" align="start">
                  <Icon
                    top="15px"
                    left="0px"
                    right="10px"
                    width="30px"
                    src={Correct}
                    alt="Item"
                  />
                  <DescriptionData font="14px">
                    Resultados superiores a 100% demonstram que sua expectativa
                    está sendo superada.
                  </DescriptionData>
                </Row>
                <SubCard
                  top="2%"
                  width="100%"
                  heigth="auto"
                  background={colors.darkBlue}
                >
                  <GroupItems padding="2%">
                    <LabelData color={colors.white} weight="400" font="14px">
                      Resultados
                    </LabelData>
                    <LabelData color={colors.white} font="14px">
                      &nbsp;superiores a 100%
                    </LabelData>
                    <LabelData color={colors.white} weight="400" font="14px">
                      &nbsp;nem sempre são bons, uma vez que a empresa pode
                      estar investindo muito para atender pontos que{' '}
                    </LabelData>
                    <LabelData color={colors.white} font="14px">
                      &nbsp;são pouco relevantes para você{' '}
                    </LabelData>
                    <LabelData color={colors.white} weight="400" font="14px">
                      &nbsp;e deixando de atender pontos de maior importância.
                    </LabelData>
                  </GroupItems>
                </SubCard>
                <Grid container spacing={4} padding={3}>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Estrutura e benefícios</SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Horário de trabalho"
                        hint="O quanto o horário de trabalho (turno, flexibilidade de horário, previsibilidade de horário, dias de folga, escalas etc)."
                        value={report.HORARIO_DE_TRABALHO_ADERENCIA}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Local de trabalho"
                        hint="O quanto o local físico de trabalho (distância de sua residência, instalações físicas, conforto, possibilidade de trabalhar home office etc)."
                        value={report.LOCAL_DE_TRABALHO_ADERENCIA}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Pacote de benefícios"
                        hint="O quanto o pacote de benefícios oferecidos por uma empresa (vale alimentação, academia, plano de saúde etc)."
                        value={report.PACOTE_DE_BENEFICIOS_ADERENCIA}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                      <ProgressCard
                        title="Salário"
                        hint="O quanto o salário pago é fator decisivo."
                        value={report.SALARIO_ADERENCIA}
                        bgColor={colors.lightYellow}
                        barColor={colors.darkYellow}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Cultura e valores</SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Abertura para mudanças"
                        hint="O quanto uma empresa demonstrar abertura real a mudanças e inovação."
                        value={
                          report.ABERTURA_PARA_MUDANCAS_E_INOVACAO_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Celebração de conquista"
                        hint="O quanto o hábito de celebrar conquistas em uma empresa (de pequenas a grandes comemorações) é fator decisivo."
                        value={report.CELEBRACAO_DE_CONQUISTAS_ADERENCIA}
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Cultura e valores da empresa"
                        hint={
                          'O quanto a cultura ("jeitão" da empresa) e seus valores (o que se prega, o que é valorizado naquele ambiente etc).'
                        }
                        value={report.CULTURA_E_VALORES_DA_EMPRESA_ADERENCIA}
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Desafios constantes"
                        hint="O quanto receber desafios constantes (metas desafiadoras, projetos difíceis etc) é fator decisivo."
                        value={report.DESAFIOS_CONSTANTES_ADERENCIA}
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Gestão clara e acompanhamento dos objetivos"
                        hint="O quanto ter clareza dos objetivos e perceber que a empresa faz gestão e acompanhamento constante de seus objetivos são fatores decisivos."
                        value={
                          report.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Oportunidade de desenvolvimento"
                        hint="O quanto uma empresa oferecer oportunidades reais de desenvolvimento profissional e aprendizado constante são fatores decisivos."
                        value={
                          report.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Plano de carreira estruturado"
                        hint="O quanto ter um plano de carreira claro e estruturado pela empresa é fator decisivo."
                        value={
                          report.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Possibilidade de crescimento"
                        hint="O quanto uma empresa oferecer possibilidades reais de crescimento e promoção é fator decisivo."
                        value={
                          report.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                      <ProgressCard
                        title="Processos simples e desburocratizados"
                        hint="O quanto a simplificação e organização dos processos da empresa são fatores decisivos."
                        value={
                          report.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS_ADERENCIA
                        }
                        bgColor={colors.lightBlue}
                        barColor={colors.darkBlue}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SubTitle>Clima e confiança</SubTitle>
                    <Stack spacing={2}>
                      <ProgressCard
                        title="Autonomia e abertura para se expressar"
                        hint="O quanto você ter autonomia no trabalho e abertura da liderança ou pares para expressar o que pensa"
                        value={
                          report.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR_ADERENCIA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Clima interno"
                        hint="O quanto o clima interno (bom ambiente e relacionamento, satisfação, motivação etc) é fator decisivo para manter você em um trabalho"
                        value={report.CLIMA_INTERNO_ADERENCIA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Confiança na liderança"
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor)."
                        value={report.CONFIANCA_NA_LIDERANCA_IMEDIATA_ADERENCIA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Confiança no time"
                        hint="O quanto sentir que você pode confiar em seu líder imediato (gestor) e equipe."
                        value={report.CONFIANCA_NO_TIME_DE_TRABALHO_ADERENCIA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Reconhecimento e valorização"
                        hint="O quanto você sentir que recebe reconhecimento e valorização em uma empresa."
                        value={report.RECONHECIMENTO_E_VALORIZACAO_ADERENCIA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Tratamento justo e igualitário"
                        hint="O quanto uma empresa tratar de forma justa seus colaboradores, sem preferências e de forma igualitária."
                        value={
                          report.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS_ADERENCIA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Visão do futuro da sua empresa"
                        hint="O quanto ter clareza sobre o futuro da empresa e o que ela busca."
                        value={
                          report.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO_ADERENCIA
                        }
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                      <ProgressCard
                        title="Tratamento humanizado"
                        hint="O quanto uma empresa tratar de forma humanizada seus colaboradores (a pessoa por trás do profissional)."
                        value={report.TRATAMENTO_HUMANIZADO_ADERENCIA}
                        bgColor={colors.lightRed}
                        barColor={colors.alertRed}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </GroupItems>
            </Card>
          </ContainerCard>
        )}

      <ContainerCard top="-20%" bottom="0.5%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>
              De 1 a 10, quanto o resultado desse teste fez sentido para você?
            </SubTitle>
            <DescriptionGroup>
              <Note onClick={() => handleChangeNote(1)} active={note1}>
                <p>1</p>
              </Note>
              <Note onClick={() => handleChangeNote(2)} active={note2}>
                <p>2</p>
              </Note>
              <Note onClick={() => handleChangeNote(3)} active={note3}>
                <p>3</p>
              </Note>
              <Note onClick={() => handleChangeNote(4)} active={note4}>
                <p>4</p>
              </Note>
              <Note onClick={() => handleChangeNote(5)} active={note5}>
                <p>5</p>
              </Note>
              <Note onClick={() => handleChangeNote(6)} active={note6}>
                <p>6</p>
              </Note>
              <Note onClick={() => handleChangeNote(7)} active={note7}>
                <p>7</p>
              </Note>
              <Note onClick={() => handleChangeNote(8)} active={note8}>
                <p>8</p>
              </Note>
              <Note onClick={() => handleChangeNote(9)} active={note9}>
                <p>9</p>
              </Note>
              <Note onClick={() => handleChangeNote(10)} active={note10}>
                <p>10</p>
              </Note>
            </DescriptionGroup>
            <DescriptionGroup>
              <FormControl
                sx={{
                  backgroundColor: colors.white,
                  m: 1,
                  width: '100%',
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                }}
              >
                <TextField
                  autoWidth
                  size="small"
                  color="info"
                  disabled={false}
                  placeholder="Comente sua nota"
                  value={comentary}
                  onChange={handleComentary}
                  multiline
                  rows={8}
                  maxRows={8}
                  InputProps={{
                    sx: {
                      input: {
                        padding: 0.7,
                        '&::placeholder': {
                          fontSize: '12px',
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </DescriptionGroup>
            <ContainerButton>
              <Button
                type="button"
                width="140px"
                fontSize="13px"
                text="Enviar"
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onSend}
              />
            </ContainerButton>
          </GroupItems>
        </Card>
      </ContainerCard>
    </Container>
  );
}

export default CareerReport;
