import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import { UserRole } from '../utils/enums';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userRole, setUserRole] = useState(UserRole.NO_ACCESS);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!userData.email;
  const history = useHistory();

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    const { data } = await api.post('/login', { email, password });
    const role = data.authenticated_user?.user?.perfil?.perfil_id;
    setUserData(data);
    setUserRole(+role);
    localStorage.setItem('userData', JSON.stringify(data));
    localStorage.setItem('userRole', +role);
    setLoading(false);
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('userData');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userGlobal');
    localStorage.removeItem('profileGlobal');
    localStorage.removeItem('loginHash');
    localStorage.removeItem('token');
    localStorage.removeItem('uuidSession');
    history.push('/login');
  }, [history]);

  const contextValue = useMemo(
    () => ({
      userData,
      isAuthenticated,
      loading,
      signIn,
      signOut,
      userRole,
      setUserRole,
    }),
    [isAuthenticated, signIn, signOut, userData, userRole, loading],
  );

  useEffect(() => {
    const savedUserData = JSON.parse(localStorage.getItem('userData') ?? '{}');
    const savedUserRole = localStorage.getItem('userRole');
    setUserData(savedUserData);
    setUserRole(+savedUserRole);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
