import { QueryClient, QueryClientProvider } from 'react-query';
import StoreProvider from '../components/Store/Provider';
import { AuthProvider } from './AuthContext';
import { MessageProvider } from './MessageContext';
import { LoadingProvider } from './LoadingContext';

export function ApplicationProvider({ children }) {
  const queryClient = new QueryClient();

  return (
    <LoadingProvider>
      <AuthProvider>
        <MessageProvider>
          <StoreProvider>
            <QueryClientProvider client={queryClient}>
              {children}
            </QueryClientProvider>
          </StoreProvider>
        </MessageProvider>
      </AuthProvider>
    </LoadingProvider>
  );
}
