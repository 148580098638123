import React, { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { ThemeProvider } from 'styled-components';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { lightTheme } from '../../styles/theme';
import HeaderLogo from '../../components/Headers/HeaderLogo';
import { analytics } from '../../firebaseConfig';
import Button from '../../components/Button';

// UTILS
import ScrollToTop from '../../utils/ScrollToTop';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Title,
  Container,
  Card,
  Label,
} from './styles';
import colors from '../../styles/colors';
import { useQuery } from '../../utils/searchQuery';

function Login() {
  const location = useLocation();
  const locationFilter = location.state;
  const { email } = locationFilter;

  const history = useHistory();
  const query = useQuery();
  const redirectUrl = query.get('redirect');

  useEffect(() => {
    logEvent(analytics, 'SendEmail');
  }, []);

  const onLogin = async e => {
    e.preventDefault();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      history.push({
        pathname: '/',
        state: {
          email,
        },
      });
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogo />
            <Container>
              <Title>Bem-vindo à iGrown!</Title>
              <Card>
                <Label>Cadastro realizado com sucesso!</Label>
                <Button
                  type="button"
                  width="300px"
                  fontSize="13px"
                  text="Acessar plataforma"
                  color={colors.lightBlue}
                  bgColor={colors.transparent}
                  onClick={onLogin}
                />
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  );
}

export default Login;
