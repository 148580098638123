import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import Modal from 'react-modal';

import { TextField, FormControl, Box } from '@mui/material';
import StoreContext from '../Store/Context';

// COMPONENTS
import Button from '../Button';
import ErrorModal from '../ErrorModal';
import AlertModal from '../AlertModal';

// STYLES
import colors from '../../styles/colors';
import { ModalContainer, ModalContent, Title, Row, Group } from './styles';
import api from '../../api';
import { CompanySelector } from '../CompanySelector';
import { CareerSelector } from '../CareerSelector';

function NewCareerModal({
  selected: propSelected,
  isOpen,
  onRequestClose,
  width,
  initialCompanyId,
}) {
  const selected = useMemo(() => [propSelected], [propSelected]);

  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState('');
  const [code, setCode] = useState([]);
  const [name, setName] = useState(selected.length > 0 ? selected[0].nome : '');
  const [description, setDescription] = useState(
    selected.length > 0 ? selected[0].descricao : '',
  );
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(initialCompanyId);
  const [parentCareerId, setParentCareerId] = useState(0);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 570 ? '800px' : '380px',
      minWidth: '450px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1,
    },
  };

  const fieldRow2 = width <= 570 ? '80%' : 718;

  const getCompanies = useCallback(async () => {
    if (profileGlobal.perfil_id === 8) {
      await api
        .get('/companies', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const data = response.data.response;
          setCompanies(data);
        })
        .catch(() => {
          setError(true);
        });
    } else {
      const data = userGlobal;
      setCompanies(data);
    }
  }, [profileGlobal, token, userGlobal]);

  const linkCompanie = async career => {
    const body = {
      company_id: selectedCompanyId,
      carreira_id: career,
    };

    await api
      .post('/companies/carreiras', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        await onRequestClose();
        setMsg('Carreira cadastrada com sucesso!');
        setAlert(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const onCheck = async () => {
    const data = {
      codigo: code,
      nome: name,
      descricao: description,
      carreira_pai_id: parentCareerId !== 0 ? parentCareerId : undefined,
    };

    if (selected[0].id) {
      await api
        .put(`/carreiras/${selected[0].id}`, data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async () => {
          await onRequestClose();
          setMsg('Carreira atualizada com sucesso!');
          setAlert(true);
        })
        .catch(async () => {
          await onRequestClose();
          setError(true);
        });
    } else {
      await api
        .post('/carreiras', data, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          linkCompanie(response.data.id);
        })
        .catch(async () => {
          await onRequestClose();
          setError(true);
        });
    }
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const handleCode = e => {
    const { value } = e.target;
    setCode(value);
  };

  const handleName = e => {
    const { value } = e.target;
    setName(value);
  };

  const handleDescription = e => {
    const { value } = e.target;
    setDescription(value);
  };

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    if (initialCompanyId) setSelectedCompanyId(initialCompanyId);
  }, [initialCompanyId]);

  useEffect(() => {
    const initialState = companies.find(item => item.id === initialCompanyId);
    if (initialState) {
      setInitialCompany({
        id: initialState.id,
        razao_social: initialState.razao_social,
      });
    }
  }, [companies, initialCompanyId]);

  useEffect(() => {
    setName(selected.length > 0 ? selected[0].nome : '');
    setDescription(selected.length > 0 ? selected[0].descricao : '');
    setCode(selected.length > 0 ? selected[0].codigo : '');
  }, [alert, selected]);

  useEffect(() => {
    if (isOpen && !selected[0]?.id) {
      setName('');
      setCode('');
      setDescription('');
    }
  }, [isOpen, selected]);

  return (
    <ModalContainer>
      {error ? (
        <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      ) : (
        false
      )}
      {alert ? (
        <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      ) : (
        false
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        <ModalContent>
          <Title>Nova carreira</Title>
          <Group>
            <Box sx={{ m: 1, width: fieldRow2 }}>
              <CompanySelector
                onChange={item => {
                  setSelectedCompanyId(item?.id);
                }}
                initialState={initialCompany}
              />
            </Box>
            <FormControl
              sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}
            >
              <TextField
                value={name}
                onChange={handleName}
                label="Nome da carreira"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl
              sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}
            >
              <CareerSelector
                companyId={selectedCompanyId}
                fullWidth
                label="Carreira Pai (opcional)"
                onChange={career => setParentCareerId(career?.id ?? 0)}
              />
            </FormControl>
            <FormControl
              sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}
            >
              <TextField
                value={code}
                onChange={handleCode}
                label="Código"
                id="outlined-size-small"
                size="small"
              />
            </FormControl>
            <FormControl
              sx={{ backgroundColor: colors.white, m: 1, width: fieldRow2 }}
            >
              <TextField
                value={description}
                onChange={handleDescription}
                label="Descrição"
                id="outlined-size-small"
                size="small"
                multiline
                rows={4}
                maxRows={4}
              />
            </FormControl>
          </Group>

          {width > 570 ? (
            <Row top="0%">
              <Button
                type="button"
                width="300px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="400px"
                fontSize="13px"
                text="Salvar"
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
                disabled={!selectedCompanyId || !name || !description}
              />
            </Row>
          ) : (
            <>
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Cancelar"
                color={colors.lightBlue}
                bgColor={colors.transparent}
                onClick={onRequestClose}
              />
              <Button
                type="button"
                width="360px"
                fontSize="13px"
                text="Salvar"
                hoverColor={colors.lightBlue}
                color={colors.white}
                bgColor={colors.lightBlue}
                onClick={onCheck}
              />
            </>
          )}
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
}

export default NewCareerModal;
