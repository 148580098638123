import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Grid, Stack, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import { useLocation } from 'react-router';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import CardColor from '../../components/Card';
import StoreContext from '../../components/Store/Context';
import Graph from '../../assets/img/Graph.svg';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import ErrorModal from '../../components/ErrorModal';

import {
  Container,
  Card,
  ContainerCard,
  Icon,
  SubTitle,
  Title,
  GroupItems,
  DescriptionData,
  Row,
  LabelData,
  SubCard,
  GroupAccordion,
  GroupText,
  Note,
  DescriptionGroup,
  ContainerButton,
  Group,
} from './styles';
import { useQuery } from '../../utils/searchQuery';
import { ProgressCard } from '../../components/ProgressCard';

const potencials = {
  Baixo: `Se você identificou um potencial baixo, reconheça que a adaptabilidade
    é essencial para liderança eficaz. Trabalhar na sua capacidade de se adaptar
    a novas situações e desafios o tornará um líder mais resiliente e capaz de
    enfrentar mudanças com confiança.`,
  Médio: `Ter um potencial médio é um ponto de partida sólido para liderança.
    Líderes bem-sucedidos precisam se adaptar a cenários em constante evolução.
    Continue buscando oportunidades de aprendizado e adaptabilidade, pois isso o
    ajudará a liderar com eficácia em um mundo em constante mudança.`,
  Alto: `Com um potencial alto, você está em uma posição vantajosa para a
    liderança. Sua notável adaptabilidade é uma qualidade valiosa para liderar
    equipes e organizações. Continue a aprimorar suas habilidades de adaptação e
    esteja disposto a liderar pelo exemplo, demonstrando como enfrentar desafios
    com confiança e resiliência.`,
};
function BehavioralReport() {
  const { token } = useContext(StoreContext);
  const [width, setWidth] = useState(window.screen.width);
  const [expanded, setExpanded] = useState(false);
  const [report, setReport] = useState([]);
  const [note1, setNote1] = useState(false);
  const [note2, setNote2] = useState(false);
  const [note3, setNote3] = useState(false);
  const [note4, setNote4] = useState(false);
  const [note5, setNote5] = useState(false);
  const [note6, setNote6] = useState(false);
  const [note7, setNote7] = useState(false);
  const [note8, setNote8] = useState(false);
  const [note9, setNote9] = useState(false);
  const [note10, setNote10] = useState(false);
  const [valueNote, setValueNote] = useState(0);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [comentary, setComentary] = useState('');
  const location = useLocation();
  const query = useQuery();

  const onSend = async () => {
    const body = {
      teste_id: report?.ID,
      nps: valueNote,
      comentario: comentary,
    };

    await api
      .post('/nps', body, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setSend(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const getTest = useCallback(async () => {
    let email;
    if (location.state) {
      email = decodeURIComponent(location.state.activeUser);
    } else {
      email = decodeURIComponent(query.get('activeUser') ?? token.email);
    }
    await api
      .post(
        '/tests',
        {
          email,
        },
        {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        },
      )
      .then(async response => {
        const data = response.data.comportamental;
        setReport(data);
      })
      .catch(() => {
        setError(true);
      });
  }, [location.state, query, token]);

  const handleComentary = async e => {
    e.preventDefault();
    setComentary(e.target.value);
  };

  const handleChangeNote = value => {
    setValueNote(value);
    if (value === 1) {
      setNote1(true);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 2) {
      setNote1(false);
      setNote2(true);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 3) {
      setNote1(false);
      setNote2(false);
      setNote3(true);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 4) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(true);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 5) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(true);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 6) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(true);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 7) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(true);
      setNote8(false);
      setNote9(false);
      setNote10(false);
    } else if (value === 8) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(true);
      setNote9(false);
      setNote10(false);
    } else if (value === 9) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(true);
      setNote10(false);
    } else if (value === 10) {
      setNote1(false);
      setNote2(false);
      setNote3(false);
      setNote4(false);
      setNote5(false);
      setNote6(false);
      setNote7(false);
      setNote8(false);
      setNote9(false);
      setNote10(true);
    }
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setSend(false);
  };

  useEffect(() => {
    getTest();
    setWidth(window.screen.width);
  }, [getTest]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <AlertModal
        isOpen={send}
        msg="Avaliação enviada com sucesso!"
        onRequestClose={onCloseAlert}
      />
      <Title>Relatório comportamental iGrown</Title>
      <ContainerCard top="20%">
        <Row>
          <DescriptionData weight="400">Última atualização em</DescriptionData>
          <DescriptionData>
            &nbsp;
            {report ? moment(report?.DATA_DA_ENTRADA).format('DD/MM/YYYY') : ''}
          </DescriptionData>
        </Row>
      </ContainerCard>
      <ContainerCard top="20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Hey, iGrowner!</SubTitle>
            <DescriptionData font="14px">
              Aqui está o seu relatório comportamental iGrown.
            </DescriptionData>
            <LabelData font="14px" weight="400">
              Lembramos que as informações a seguir retratam suas tendências
              comportamentais, podendo variar conforme o contexto, pessoa ou
              momento de vida. Assim, não há certo ou errado, melhor ou pior.
              Apenas informações orientadoras para aumentar o seu
              autoconhecimento e acelerar a sua carreira, ok?
            </LabelData>
            <LabelData font="14px">&nbsp;Aproveite e #boradecolar</LabelData>
            <SubTitle font="16px">Nesse relatório, foram analisadas:</SubTitle>
            <DescriptionData font="14px">
              • As suas tendências comportamentais;
            </DescriptionData>
            <DescriptionData font="14px">
              • As suas agilidades de aprendizagem;
            </DescriptionData>
            <DescriptionData bottom="2.5%" font="14px">
              • As suas soft skills, ou seja, as habilidades comportamentais de
              destaque para desenvolvimento.
            </DescriptionData>
            <LabelData font="14px">O grande objetivo</LabelData>
            <LabelData font="14px">
              &nbsp; é aumentar o seu autoconhecimento e ajudá-lo a conhecer
              suas forças e fraquezas para que você possa decolar em sua
              carreira! =D
            </LabelData>
            <SubCard
              top="2%"
              width="100%"
              heigth="auto"
              background={colors.darkBlue}
            >
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">
                  Pessoas com autoconhecimento elevado têm uma boa dose de
                  autocrítica saudável, passam por uma busca contínua por
                  feedback e auto feedback, resultando em um alto nível de
                  autoconfiança. Têm consciência do seu potencial e conseguem
                  traduzi-lo nas demais dimensões por meio de ações práticas e
                  efetivas.
                </LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Tendências comportamentais</SubTitle>
            <DescriptionData font="14px" weight="400">
              O seu relatório comportamental pode ser composto a partir de
              quatro perfis básicos: Desbravador, Mantenedor, Investigador e
              Embaixador. Clicando no ícone ao lado dos nomes abaixo, você
              poderá conhecer as características principais de cada um deles.
            </DescriptionData>
            <DescriptionData font="14px">
              Abaixo, de acordo com suas respostas, veja como ficou a composição
              do seu perfil nesse momento.
            </DescriptionData>

            <SubCard top="2%" width="100%" heigth="auto" border={colors.gray5}>
              <GroupItems padding="2%">
                <Row top="0%">
                  <Group width="50%">
                    <Grid xs="6">
                      <Stack spacing={2}>
                        <ProgressCard
                          title="Desbravador"
                          hint="Refere-se ao estilo de comportamento das pessoas que tendem a ser diretas, assertivas e orientadas para resultados. Eles são frequentemente vistos como indivíduos competitivos que buscam assumir o controle das situações."
                          value={report?.DESBRAVADOR}
                          bgColor={colors.lightYellow}
                          barColor={colors.darkYellow}
                        />
                        <ProgressCard
                          title="Mantenedor"
                          hint="Refere-se ao estilo de comportamento das pessoas que são mais calmas, pacientes e colaborativas. Eles valorizam a harmonia e a estabilidade em suas relações e tendem a ser mais tranquilos e previsíveis."
                          value={report?.MANTENEDOR}
                          bgColor={colors.lightBlue}
                          barColor={colors.darkBlue}
                        />
                        <ProgressCard
                          title="Investigador"
                          hint="Refere-se ao estilo de comportamento das pessoas que são organizadas, detalhistas e precisas. Eles buscam seguir as regras e normas estabelecidas e valorizam a precisão em suas atividades."
                          value={report?.INVESTIGADOR}
                          bgColor={colors.lightGreen}
                          barColor={colors.darkestGreen}
                        />
                        <ProgressCard
                          title="Embaixador"
                          hint="Refere-se ao estilo de comportamento das pessoas que são extrovertidas, sociáveis e comunicativas. Eles têm facilidade em influenciar e persuadir os outros, sendo frequentemente vistos como líderes carismáticos."
                          value={report?.EMBAIXADOR}
                          bgColor={colors.lightRed}
                          barColor={colors.alertRed}
                        />
                      </Stack>
                    </Grid>
                  </Group>
                  <Group width="50%">
                    <Grid xs="6">
                      <SubTitle>Seu perfil predominante é</SubTitle>
                      <SubTitle top="0%" color={colors.lightBlue}>
                        {report?.PERFIL_PREDOMINANTE ?? 0}
                      </SubTitle>
                      <DescriptionData font="14px" weight="400">
                        {report?.MEU_PERFIL_COMPORTAMENTAL ?? 0}
                      </DescriptionData>
                    </Grid>
                  </Group>
                </Row>
              </GroupItems>
            </SubCard>
            <SubCard
              top="2%"
              width="100%"
              heigth="auto"
              background={colors.darkBlue}
            >
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">
                  Todas essas tendências descritas aqui são situacionais, ou
                  seja, elas podem variar de acordo com o contexto e seu momento
                  de vida.
                </LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="0%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>
              Quais outras características estão relacionadas a esse perfil?{' '}
            </SubTitle>
            <LabelData font="14px" weight="400">
              Veja, abaixo, algumas características gerais que estão diretamente
              ligadas ao seu perfil dominante.
            </LabelData>
          </GroupItems>
          <GroupAccordion top="2px">
            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Palavras-chave
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report?.PALAVRAS_CHAVE}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Objetivos frequentes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report?.SEUS_OBJETIVOS_FREQUENTES}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Estilos de liderança
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report?.SEUS_ESTILOS_DE_LIDERANCA}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Onde colocar ênfase
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report?.ONDE_VOCE_PODE_COLOCAR_ENFASE}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Onde perde energia
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report?.ONDE_VOCE_PODE_PERDER_ENERGIA}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleChange('panel6')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Comportamentos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report?.COMPORTAMENTOS_CARACTERISTICOS}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Como se comunicar com você
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report?.COMO_SE_COMUNICAR_COM_VOCE}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Como reconhecer pessoas com o seu perfil
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>
                      {report?.COMO_RECONHECER_PESSOAS_COM_O_SEU_PERFIL}
                    </Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>

            <GroupItems padding="15px">
              <Accordion
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '6%', flexShrink: 0 }}>
                    <Icon width="40px" src={Graph} alt="Item" />
                  </Typography>
                  <Typography sx={{ color: colors.darkBlue }}>
                    Cultura do time
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GroupText>
                    <Typography>{report?.CULTURA_DO_TIME}</Typography>
                  </GroupText>
                </AccordionDetails>
              </Accordion>
            </GroupItems>
          </GroupAccordion>
        </Card>
      </ContainerCard>

      <ContainerCard top="-10%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Agilidades de aprendizagem</SubTitle>
            <DescriptionData font="14px" weight="400">
              A partir das palavras que você selecionou anteriormente,
              identificamos quais são as suas agilidades de aprendizagem
              (Learning Agilities). Veja seu resultado abaixo.
            </DescriptionData>
            <DescriptionData font="14px">
              Com base em suas respostas, podemos considerar seu potencial em
              agilidades de aprendizado e adaptabilidade como:{' '}
              <strong>{report?.POTENCIAL?.toLocaleUpperCase()}</strong>
            </DescriptionData>
            <DescriptionData font="14px">
              {report?.POTENCIAL ? potencials[report?.POTENCIAL] : ''}
            </DescriptionData>
            <Grid container spacing={4} sx={{ p: 4 }}>
              <Grid item xs={12} md={6}>
                <SubCard
                  top="2%"
                  width="100%"
                  heigth="auto"
                  background={colors.white}
                  border={colors.gray5}
                >
                  <CardColor
                    width="100%"
                    tooltip={report?.AGILIDADE_COM_MUDANCAS_TEXT}
                    left
                    bgColor={colors.white}
                    title="Agilidade com mudanças"
                    value={report?.AGILIDADE_COM_MUDANCAS}
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={
                      report?.AGILIDADE_COM_MUDANCAS === 'Alta'
                        ? 3
                        : report?.AGILIDADE_COM_MUDANCAS === 'Moderada'
                        ? 2
                        : 1
                    }
                    height="100%"
                  />
                </SubCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubCard
                  top="2%"
                  width="100%"
                  heigth="auto"
                  background={colors.white}
                  border={colors.gray5}
                >
                  <CardColor
                    width="100%"
                    left
                    bgColor={colors.white}
                    tooltip={report?.AGILIDADE_COM_RESULTADOS_TEXT}
                    title="Agilidade com resultados"
                    value={report?.AGILIDADE_COM_RESULTADOS}
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={
                      report?.AGILIDADE_COM_RESULTADOS === 'Alta'
                        ? 3
                        : report?.AGILIDADE_COM_RESULTADOS === 'Moderada'
                        ? 2
                        : 1
                    }
                    height="100%"
                  />
                </SubCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubCard
                  top="2%"
                  width="100%"
                  heigth="auto"
                  background={colors.white}
                  border={colors.gray5}
                >
                  <CardColor
                    width="100%"
                    left
                    bgColor={colors.white}
                    title="Agilidade mental"
                    tooltip={report?.AGILIDADE_MENTAL_TEXT}
                    value={report?.AGILIDADE_MENTAL}
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={
                      report?.AGILIDADE_MENTAL === 'Alta'
                        ? 3
                        : report?.AGILIDADE_MENTAL === 'Moderada'
                        ? 2
                        : 1
                    }
                    height="100%"
                  />
                </SubCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <SubCard
                  top="2%"
                  width="100%"
                  heigth="auto"
                  background={colors.white}
                  border={colors.gray5}
                >
                  <CardColor
                    width="100%"
                    left
                    bgColor={colors.white}
                    title="Agilidade com pessoas"
                    value={report?.AGILIDADE_COM_PESSOAS}
                    tooltip={report?.AGILIDADE_COM_PESSOAS_TEXT}
                    valueColor={colors.lightBlue}
                    font="14px"
                    step={
                      report?.AGILIDADE_COM_PESSOAS === 'Alta'
                        ? 3
                        : report?.AGILIDADE_COM_PESSOAS === 'Moderada'
                        ? 2
                        : 1
                    }
                    height="100%"
                  />
                </SubCard>
              </Grid>
            </Grid>

            <SubCard
              top="2%"
              width="100%"
              heigth="auto"
              background={colors.darkBlue}
            >
              <GroupItems padding="2%">
                <LabelData color={colors.white} font="14px">
                  Pessoas que buscam desenvolver suas agilidades de aprendizado,
                  normalmente, são consideradas para posições estratégicas e
                  como sucessoras de líderes, dado sua capacidade de adaptação e
                  entregas acima da média, tendo condições de levar o negócio a
                  novos patamares e assegurar o crescimento e a perpetuidade da
                  organização.
                </LabelData>
              </GroupItems>
            </SubCard>
          </GroupItems>
        </Card>
      </ContainerCard>

      <ContainerCard top="-20%">
        <Card width="100%" heigth="650px">
          <GroupItems>
            <SubTitle>Soft Skills: Habilidades comportamentais </SubTitle>
            <DescriptionData font="14px" weight="400">
              Como você pode ver abaixo, assim como foi feito nas learning
              agilities, as soft skills foram organizadas em diferentes grupos.
              No entanto, o foco aqui são habilidades comportamentais.
            </DescriptionData>
            <DescriptionData font="14px">
              Para você entender melhor seu resultado, os parâmetros a serem
              considerados são:
            </DescriptionData>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                  <SubTitle>Mudanças</SubTitle>
                  <ProgressCard
                    title="Aprendizado constante"
                    value={report?.APRENDIZADO_CONSTANTE}
                    hint="Busca ativa e contínua por novos conhecimentos e aplicações. Aprender e reaprender continuamente"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Flexibilidade"
                    value={report?.FLEXIBILIDADE}
                    hint="Abertura para considerar visões diferentes e se adaptar em novos cenários"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Gestão de mudanças"
                    value={report?.GESTAO_DA_MUDANCA}
                    hint="Esforço constante na busca de melhorias e novas práticas"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Inovação"
                    value={report?.INOVACAO}
                    hint="Processo criativo, busca de novas ideias e inovações, rompendo com o estabelecido"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Inteligência digital"
                    value={report?.INTELIGENCIA_DIGITAL}
                    hint="Capacidade em entender, processar e utilizar a tecnologia de forma eficiente, responsável e produtiva"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="In­tra­em­pre­en­de­do­ris­mo"
                    value={report?.INTRAEMPREENDEDORISMO}
                    hint="Facilidade de analisar cenários e de encontrar oportunidades. Atitude de busca, criação e implementação de ideias"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Mentalidade de startup"
                    value={report?.MENTALIDADE_DE_STARTUP}
                    hint="Pensamento discorruptivo e visionário, busca causar grandes impactos, mudar a visão das pessoas e dos próprios processos"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Pensamento ágil"
                    value={report?.PENSAMENTO_AGIL}
                    hint="Pensar na melhoria e ciclos de aprendizagem, foco na entrega de valor de forma contínua"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                  <ProgressCard
                    title="Resiliência"
                    value={report?.RESILIENCIA}
                    hint="Capacidade de lidar com os problemas e pressões, superando os obstáculos e as situações adversas de forma positiva"
                    bgColor={colors.lightYellow}
                    barColor={colors.darkYellow}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                  <SubTitle>Resultados</SubTitle>
                  <ProgressCard
                    title="Acabativa"
                    value={report?.ACABATIVA}
                    hint="Capacidade de terminar aquilo que iniciou ou concluir o que o outro não finalizou"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Engajamento contínuo"
                    value={report?.ENGAJAMENTO_CONTINUO}
                    hint="Conexão emocional com o seu trabalho e compromisso em fazer sempre o melhor"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Gestão da qualidade"
                    value={report?.GESTAO_DA_QUALIDADE}
                    hint="Estabelecer padrões de alta qualidade e buscar a melhoria contínua, assegurando a revisão de práticas e processos"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Gestão de projetos"
                    value={report?.GESTAO_DE_PROJETOS}
                    hint="Capacidade de aplicar práticas e habilidades para planejamento, execução, monitoramento e controle dos projetos (independente da complexidade)"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Gestão do tempo"
                    value={report?.GESTAO_DO_TEMPO}
                    hint="Planejamento e execução do controle sobre o tempo gasto com atividades versus efetividade, eficiência e produtividade"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Identificação de propósito"
                    value={report?.IDENTIFICACAO_DE_PROPOSITO}
                    hint="Grau de identificação de seus objetivos, preferências e projeto para a vida. O que você ama e lhe faz feliz"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Melhoria de desempenho"
                    value={report?.MELHORIA_DE_DESEMPENHO}
                    hint="Articulações para a melhoria de competências relacionadas à entregas individuais e organizacionais"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Orientação ao resultado"
                    value={report?.ORIENTACAO_AO_RESULTADO}
                    hint="Capacidade de tomada de ações para atingir ou superar objetivos"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Senso de dono"
                    value={report?.SENSO_DE_DONO}
                    hint="Foco no crescimento do negócio e no próprio desenvolvimento pessoal, garantindo lucro e crescimento mútuos"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                  <ProgressCard
                    title="Venda consultiva"
                    value={report?.VENDA_CONSULTIVA}
                    hint="Aproximar-se do cliente, alinhando suas expectativas de maneira efetiva e criar um relacionamento"
                    bgColor={colors.lightBlue}
                    barColor={colors.darkBlue}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                  <SubTitle>Mental</SubTitle>
                  <ProgressCard
                    title="Capacidade analítica"
                    value={report?.CAPACIDADE_ANALITICA}
                    hint="Capacidade de organizar e avaliar dados e cenários, permitindo emitir pareceres consistentes considerando riscos, oportunidades e impactos"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Escuta ativa"
                    value={report?.ESCUTA_ATIVA}
                    hint="Ouvir, compreender e interpretar com atenção o que está sendo transmitido, seja de forma verbal ou não verbal"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Gestão da ansiedade"
                    value={report?.GESTAO_DA_ANSIEDADE}
                    hint="Capacidade de autopercepção, compreensão de emoções e alternativas para lidar com situações de estresse e pressão"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Gestão do conhecimento"
                    value={report?.GESTAO_DO_CONHECIMENTO}
                    hint="Facilidade em adquirir, buscar, aplicar e transferir o conhecimento"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Inteligência emocional"
                    value={report?.INTELIGENCIA_EMOCIONAL}
                    hint="Capacidade de compreender e lidar com os próprios sentimentos e dos outros"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Liderança estratégica"
                    value={report?.LIDERANCA_ESTRATEGICA}
                    hint="Alinhar o estilo de liderança às estratégias do negócio. Desenvolver as competências, os processos organizacionais e os comportamentos das pessoas"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Orientação ao negócio"
                    value={report?.ORIENTACAO_AO_NEGOCIO}
                    hint="Capacidade de reconhecer oportunidades para serviços e produtos. Direcionar esforços na melhoria da performance do negócio"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Pensamento estratégico"
                    value={report?.PENSAMENTO_ESTRATEGICO}
                    hint="Criar meios (fugindo das opções evidentes) que permitam a descoberta de alternativas com alto grau de efetividade para o negócio"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Planejamento"
                    value={report?.PLANEJAMENTO}
                    hint="Prever e controlar etapas e atividades críticas, com o propósito de gerar prontidão de resposta e caminhos consistentes para o alcance dos resultados"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                  <ProgressCard
                    title="Velocidade na tomada de decisão"
                    value={report?.VELOCIDADE_NA_TOMADA_DE_DECISAO}
                    hint="Capacidade de análise do cenário e agilidade de resposta. Comprometimento por falar o que pensa e assumir sua posição"
                    bgColor={colors.lightGreen}
                    barColor={colors.darkestGreen}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1}>
                  <SubTitle>Pessoas</SubTitle>
                  <ProgressCard
                    title="Comunicação"
                    value={report?.COMUNICACAO}
                    hint="Transmitir e explicar ideias de forma clara e objetiva,  apresentando argumentos de modo persuasivo e adequado ao estilo do interlocutor"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Construção de alianças"
                    value={report?.CONSTRUCAO_DE_ALIANCAS}
                    hint="Capacidade em promover a colaboração ampla e articulada de pessoas e negócios, para assegurar iniciativas criativas e integradas"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Desenvolvimento de talentos"
                    value={report?.DESENVOLVIMENTO_DE_TALENTOS}
                    hint="Facilidade em identificar, direcionar e desenvolver competências individuais e grupais"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Mentalidade inclusiva"
                    value={report?.MENTALIDADE_INCLUSIVA}
                    hint="Abertura para a valorização das pessoas por seus dons, habilidades e experiências únicas. Valorização da diversidade"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Negociação"
                    value={report?.NEGOCIACAO}
                    hint="Conduzir as partes ao entendimento, por meio da capacidade de leitura das relações informais e poder de influência"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Orientação ao cliente"
                    value={report?.ORIENTACAO_AO_CLIENTE}
                    hint="Capacidade e vontade de compreender as necessidades dos clientes e agir de acordo, considerando custos e benefícios"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Concessão de feedbacks"
                    value={report?.CONCESSAO_DE_FEEDBACK}
                    hint="Permissão para receber retorno sobre suas características, ações e performance. Assim como a facilidade em expressar suas percepções sobre o outro"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Relacionamento interpessoal"
                    value={report?.RELACIONAMENTO_INTERPESSOAL}
                    hint="Facilidade em estabelecer vínculos, considerando aspectos como empatia e respeito. Contribuir para o trabalho em grupo com maior sinergia e harmonia"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Solução de conflitos"
                    value={report?.SOLUCAO_DE_PROBLEMAS}
                    hint="Capacidade de lidar com interesses conflitantes de forma a ajudar a resolvê-los e conciliar ideias divergentes"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                  <ProgressCard
                    title="Trabalho em equipe"
                    value={report?.TRABALHO_EM_EQUIPE}
                    hint="Encorajar a sinergia e a cooperação, contribuindo para o alcance de objetivos  por meio de um ambiente colaborativo"
                    bgColor={colors.lightRed}
                    barColor={colors.alertRed}
                  />
                </Stack>
              </Grid>
            </Grid>
          </GroupItems>
        </Card>
        <ContainerCard top="-40px" bottom="0.1%">
          <Card width="100%" heigth="650px">
            <GroupItems>
              <SubTitle>
                De 1 a 10, quanto o resultado desse teste fez sentido para você?
              </SubTitle>
              <DescriptionGroup>
                <Note onClick={() => handleChangeNote(1)} active={note1}>
                  <p>1</p>
                </Note>
                <Note onClick={() => handleChangeNote(2)} active={note2}>
                  <p>2</p>
                </Note>
                <Note onClick={() => handleChangeNote(3)} active={note3}>
                  <p>3</p>
                </Note>
                <Note onClick={() => handleChangeNote(4)} active={note4}>
                  <p>4</p>
                </Note>
                <Note onClick={() => handleChangeNote(5)} active={note5}>
                  <p>5</p>
                </Note>
                <Note onClick={() => handleChangeNote(6)} active={note6}>
                  <p>6</p>
                </Note>
                <Note onClick={() => handleChangeNote(7)} active={note7}>
                  <p>7</p>
                </Note>
                <Note onClick={() => handleChangeNote(8)} active={note8}>
                  <p>8</p>
                </Note>
                <Note onClick={() => handleChangeNote(9)} active={note9}>
                  <p>9</p>
                </Note>
                <Note onClick={() => handleChangeNote(10)} active={note10}>
                  <p>10</p>
                </Note>
              </DescriptionGroup>
              <DescriptionGroup>
                <FormControl
                  sx={{
                    backgroundColor: colors.white,
                    m: 1,
                    width: '100%',
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                  }}
                >
                  <TextField
                    autoWidth
                    size="small"
                    color="info"
                    disabled={false}
                    placeholder="Comente sua nota"
                    value={comentary}
                    onChange={handleComentary}
                    multiline
                    rows={8}
                    maxRows={8}
                    InputProps={{
                      sx: {
                        input: {
                          padding: 0.7,
                          '&::placeholder': {
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </DescriptionGroup>
              <ContainerButton>
                <Button
                  type="button"
                  width="140px"
                  fontSize="13px"
                  text="Enviar"
                  hoverColor={colors.lightBlue}
                  color={colors.white}
                  bgColor={colors.lightBlue}
                  onClick={onSend}
                />
              </ContainerButton>
            </GroupItems>
          </Card>
        </ContainerCard>
      </ContainerCard>
    </Container>
  );
}

export default BehavioralReport;
