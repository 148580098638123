import { Box, Slider, Typography, styled } from '@mui/material';
import colors from '../../styles/colors';
import { Hint } from '../Hint';
import { ExclamationIcon } from '../icons/ExclamationIcon';

const CustomSlider = styled(Slider)({
  color: colors.white,
  height: 8,
  '& .MuiSlider-rail': {
    opacity: 0.4,
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
});

export function SoftSkillSliderInput({
  bgColor = colors.lightBlue,
  hint = '',
  label,
  value,
  onChange,
}) {
  return (
    <Box
      sx={{ borderRadius: '1rem', backgroundColor: bgColor, padding: '1rem' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            paddingY: '0.75rem',
          }}
        >
          {hint && (
            <Box sx={{ marginTop: '6px' }}>
              <Hint description={hint}>
                <ExclamationIcon size={20} />
              </Hint>
            </Box>
          )}
          <Typography sx={{ color: colors.darkBlue, fontWeight: 700 }}>
            {label}
          </Typography>
        </Box>
        <Typography sx={{ color: colors.darkBlue, fontWeight: 700 }}>
          {value}%
        </Typography>
      </Box>
      <Box sx={{ paddingX: '1.25rem' }}>
        <CustomSlider
          step={1}
          min={0}
          max={100}
          value={value}
          onChange={(_event, newValue) => {
            if (onChange) onChange(newValue);
          }}
          getAriaValueText={vl => `${vl}%`}
          valueLabelDisplay="off"
        />
      </Box>
    </Box>
  );
}
