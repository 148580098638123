import React from 'react';

import { Header, Logo } from './styles';
import logo from '../../../assets/img/logo.svg';

function HeaderLogo() {
  return (
    <Header>
      <a href="/">
        <Logo src={logo} alt="iGrown" />
      </a>
    </Header>
  );
}

export default HeaderLogo;
