import styled from 'styled-components';

import colors from '../../styles/colors';

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const Step = styled.div`
  position: relative;
  width: 26px;
  border-radius: 13px;
  display: inline-grid;
  background: ${props =>
    props.active ? colors.lightBlue : colors.transparent};
  color: ${props => (props.active ? colors.white : colors.lightBlue)};
  margin: 0.16%;
  border: 1px solid ${colors.lightBlue};
`;

export const Row = styled.div`
  text-align: ${props => props.align || 'center'};
  position: relative;
  display: flex;
  justify-content: center;
  margin: 24px;
  padding-bottom: 24px;
`;

export const Tag = styled.div`
  position: relative;
  background-color: ${props => props.background || colors.darkBlue};
  width: 80px;
  border-radius: 20px;
  margin: auto;
`;

export const Column = styled.div`
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;

  @media (max-width: 570px) {
    width: 300px;
  }

  @media (max-width: 425px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 300px;
  }
`;

export const DescriptionData = styled.p`
  font-size: ${props => props.font || '12px'};

  padding-top: 12px;
  padding-bottom: 32px;

  font-family: 'Poppins';
  font-style: ${props => props.styles || 'normal'};
  font-weight: ${props => props.weight || '600'};

  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 12px;
  }

  @media (max-width: 570px) {
    font-size: 12px;
  }

  text-align: ${props => props.align || 'start'};
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.darkBlue};
`;

export const Card = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 428px;
  display: inherit;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  /* FFFFFF */

  background: #ffffff;
  /* E2E2EA */

  border: 1px solid #e2e2ea;
  border-radius: 8px;

  @media (max-width: 870px) {
    width: 400px;
    margin-bottom: 5%;
  }

  @media (max-width: 570px) {
    width: 340px;
    margin-bottom: 5%;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;

  @media (max-width: 870px) {
    width: 140px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;

export const Icon = styled.img`
  position: relative;
  text-align: center;
  padding-right: ${props => props.right || '0px'};
  width: ${props => props.width || '40px'};
  @media (max-width: 870px) {
    width: ${props => props.width || '40px'};
  }

  @media (max-width: 570px) {
    width: ${props => props.width || '40px'};
  }
`;

export const SubTitle = styled.h2`
  position: relative;
  font-size: ${props => (props.font ? props.font : '22px')};
  margin-bottom: 16px;
  padding-left: 4%;
  padding-right: 4%;

  /* H1 - Web */

  font-family: 'Poppins';
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 1440px) {
    font-size: 20px;
  }

  @media (max-width: 870px) {
    font-size: 20px;
  }

  @media (max-width: 570px) {
    font-size: 18px;
    padding-left: 1%;
    padding-right: 1%;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => (props.color ? props.color : colors.darkBlue)};
`;

export const Group = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  position: relative;
  font-size: 24px;
  padding-top: 40px;
  padding-bottom: 16px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 22px;
  }

  @media (max-width: 570px) {
    font-size: 20px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.darkBlue};
`;

export const Note = styled.div`
  position: relative;
  cursor: pointer;
  width: 50px;
  border-radius: 25px;
  padding: 10px;
  display: inline-grid;
  background: ${props => (props.active ? colors.lightBlue : colors.white)};
  color: ${props => (props.active ? colors.white : colors.lightBlue)};
  margin: 0.2%;
  border: 1px solid ${colors.lightBlue};
`;

export const GpNote = styled.div`
  justify-content: space-between;
`;

export const ItemDescription = styled.h3`
  position: relative;
  font-size: 16px;
  padding-top: 24px;
  padding-bottom: 16px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  line-height: 130%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.darkBlue};
`;

export const DescriptionGroup = styled.div`
  width: 650px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 16px;
  justify-content: space-between;

  @media (max-width: 570px) {
    width: 300px;
  }

  @media (max-width: 425px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 300px;
  }
`;

export const GpForm = styled.div`
  width: 470px;
  justify-content: center;
  display: inline-grid;
`;

export const ErrorMessage = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: ${props => props.maginBottom || '45px'};
  color: ${props => props.color || colors.gray2};
  text-align: center;
  @media (max-width: 870px) {
    margin-bottom: 10px;
  }
`;

export const InputField = styled.div`
  max-width: 320px;
  width: 100%;
  background-color: ${colors.white};
  // margin: 10px 0;
  justify-content: center;
  border: 1px solid ${colors.gray5};
  border-radius: 10px;
  padding: 0 0.4rem;
  position: relative;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  /* identical to box height, or 24px */

  letter-spacing: 0.1px;

  input {
    background: none;
    font-weight: 600;
    font-size: 1rem;
    color: ${colors.gray1};
  }

  input::placeholder {
    color: ${colors.gray5};
    font-size: 0.8rem;
    font-weight: 500;
  }
  .eye-toggle {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 22px;
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const InputSmaller = styled(InputField)`
  max-width: 320px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Description = styled.text`
  position: relative;
  text-align: center !important;
  font-size: 13px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;

  line-height: 130%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.white};
`;

export const SubLabel = styled.label`
  position: relative;
  text-align: center !important;
  font-size: 15px;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 15px;
  }

  @media (max-width: 570px) {
    font-size: 14px;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.white};
`;

export const GpButton = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
`;

export const ContainerButton = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between !important;
  padding-right: 41%;
  padding-left: 41%;

  @media (max-width: 1400px) {
    padding-right: 25%;
    padding-left: 25%;
  }

  @media (max-width: 870px) {
    padding-right: 20%;
    padding-left: 20%;
  }

  @media (max-width: 570px) {
    padding-right: 10%;
    padding-left: 10%;
  }
`;

export const Line = styled.div`
  opacity: 0.2;
  border-bottom: 1px solid ${colors.gray4};
`;
