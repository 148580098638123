export const validDate = date => {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/.exec(date);
  if (regex) {
    const day = parseInt(regex[1], 10);
    const month = parseInt(regex[2], 10);
    const year = parseInt(regex[3], 10);

    const d = new Date(year, month - 1, day);
    const valid =
      d.getFullYear() === year &&
      d.getMonth() + 1 === month &&
      d.getDate() === day;
    return valid;
  }
  return false;
};
