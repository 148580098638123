import React, { useState, useEffect } from 'react';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { ThemeProvider } from 'styled-components';
import { auth, analytics } from '../../firebaseConfig';
import { lightTheme } from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import logo from '../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
// ASSETS
import Eye from '../../assets/img/Eye_Icon.png';
import EyeClose from '../../assets/img/Eye_Close_Icon.png';
import PasswordResetModal from '../../components/PasswordResetModal';

// UTILS
import ScrollToTop from '../../utils/ScrollToTop';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Container,
  Card,
  InputSmaller,
  ErrorMessage,
  Header,
  Logo,
  Group,
} from './styles';

function PasswordRecover() {
  const [error, setError] = useState(true);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [msg, setMsg] = useState('');
  const [width, setWidth] = useState(window.screen.width);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    logEvent(analytics, 'Recuperacao_senha');
    setWidth(window.screen.width);
  }, [password1, password2, width]);

  const getParameterByName = (name, url) => {
    let newUrl = '';
    if (!url) newUrl = window.location.href;
    const newName = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(newUrl);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const onRegister = () => {
    if (password1 === password2) {
      const oobCode = getParameterByName('oobCode');

      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          const newPassword = password1;

          // Save the new password.
          confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => {
              setPassword1('');
              setPassword2('');
              setMsg('Senha alterada com sucesso!');
            })
            .catch(() => {
              setMsg('Erro ao enviar a senha!');
              setError(true);
            });
        })
        .catch(() => {
          setMsg('Erro ao enviar a senha!');
          setError(true);
        });
    } else {
      setMsg('As senhas precisam ser iguais!');
      setError(true);
    }
  };
  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const handlePassword1 = async e => {
    e.preventDefault();
    setPassword1(e.target.value);
    setError(false);
    setMsg('');
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const handlePassword2 = async e => {
    e.preventDefault();
    setPassword2(e.target.value);
    setError(false);
    setMsg('');
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <PasswordResetModal
              isOpen={open}
              width={width}
              onRequestClose={() => setOpen(false)}
            />
            <Container>
              <Header>
                <a href="/login">
                  <Logo src={logo} alt="iGrown" />
                </a>
              </Header>
              <Label>Recuperação de senha</Label>
              <Card>
                <Group>
                  <InputSmaller autoFocus>
                    <input
                      type={passwordShown1 ? 'text' : 'password'}
                      value={password1}
                      name="password"
                      placeholder="Insira sua nova senha"
                      onChange={handlePassword1}
                    />
                    {/* eslint-disable-next-line */}
                    <img
                      className="eye-toggle"
                      src={passwordShown1 ? Eye : EyeClose}
                      alt="password toggle"
                      onClick={togglePassword1}
                    />
                  </InputSmaller>
                  <InputSmaller autoFocus>
                    <input
                      type={passwordShown2 ? 'text' : 'password'}
                      value={password2}
                      name="password"
                      placeholder="Confirme sua nova senha"
                      onChange={handlePassword2}
                    />
                    {/* eslint-disable-next-line */}
                    <img
                      className="eye-toggle"
                      src={passwordShown2 ? Eye : EyeClose}
                      alt="password toggle"
                      onClick={togglePassword2}
                    />
                  </InputSmaller>
                </Group>
                <ErrorMessage
                  color={error ? colors.alertRed : colors.darkestGreen}
                >
                  {msg}
                </ErrorMessage>
                <Button
                  type="button"
                  width="320px"
                  hoverColor={colors.lightBlue}
                  text="Enviar"
                  isLoading={false}
                  onClick={onRegister}
                />
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
    </ThemeProvider>
  );
}

export default PasswordRecover;
