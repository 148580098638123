import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Select, MenuItem, FormControl, Grid } from '@mui/material';
import Chart from 'react-apexcharts';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import colors from '../../styles/colors';
import StoreContext from '../../components/Store/Context';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import ErrorModal from '../../components/ErrorModal';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import NoticeModal from '../../components/NoticeModal';

import {
  ContainerCard,
  Container,
  Label,
  Card,
  Row,
  GroupItems,
  SubTitle,
  CardFilter,
  GpItems,
  Title,
} from './styles';
import { CompanySelector } from '../../components/CompanySelector';
import { listAllCompanies } from '../../api/companies';

function FitPersona() {
  const { token, userGlobal, profileGlobal } = useContext(StoreContext);
  const [alert, setAlert] = useState(false);
  const [notice, setNotice] = useState(false);
  const [companieSelected, setCompanieSelected] = useState(0);
  const [msg, setMsg] = useState('');
  const [display, setDisplay] = useState(false);
  const [userSelected, setUserSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [job, setJob] = useState([]);
  const [error, setError] = useState(false);
  const [disabledCandidate, setDisabledCandidate] = useState(true);
  const [disabledJob, setDisabledJob] = useState(true);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [jobSelected, setJobSelected] = useState(0);
  const [arrayholder, setArrayholder] = useState([]);
  const [line, setLine] = useState([]);
  const [bar, setBar] = useState([]);
  const [column, setColumn] = useState([]);
  const [radar, setRadar] = useState([]);
  const [dataFit, setDataFit] = useState([]);
  const location = useLocation();
  const [useLocationState, setUseLocationState] = useState(true);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });

  const { data: allCompanies } = useQuery('listAllCompanies', listAllCompanies);

  const handleUser = useCallback(e => {
    e.preventDefault();
    const { value } = e.target;
    setUserSelected(value);
  }, []);

  const getNewData = useCallback(
    async selectedCompany => {
      const newData = arrayholder.filter(item => {
        return item.company_id === selectedCompany;
      });
      setJob(newData);
    },
    [arrayholder],
  );

  const handleCompanie = useCallback(
    async value => {
      if (value > 0) {
        await getNewData(value);
        setDisabledJob(false);
      }
      setCompanieSelected(value);
    },
    [getNewData],
  );

  const getCandidate = useCallback(
    async id => {
      const emailList = [];
      await api
        .get(`/users/vaga/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          await data.forEach(item => {
            emailList.push(item.users[0].email);
          });
          setSelectedCandidate(data);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token],
  );

  const handleJob = useCallback(
    e => {
      const newValue = e.target.value;
      if (newValue > 0) {
        getCandidate(newValue);
        setDisabledCandidate(false);
      }
      setJobSelected(newValue);
    },
    [getCandidate],
  );

  const getCompanies = useCallback(
    async id => {
      if (id === 8) {
        setCompanies([]);
      } else {
        const data = userGlobal;
        setCompanies(data);
      }
    },
    [userGlobal],
  );

  const getName = useCallback(
    email => {
      return selectedCandidate
        .filter(item => email === item.users[0].email)
        .map(item => item.users[0].name);
    },
    [selectedCandidate],
  );

  const getReport = useCallback(async () => {
    if (userSelected.length > 0 && jobSelected > 0 && companieSelected > 0) {
      const emails = userSelected;
      const body = {
        emails,
        carreira_id: job.find(j => j.id === jobSelected).carreira_id,
      };
      await api
        .post('/persona/fit', body, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const data = response.data.DATA;
          // const data = dataCandidate.DATA;
          const json = response.data;
          if (json.message) {
            setMsg(
              'Não foi encontrado persona para os candidatos selecionados',
            );
            setNotice(true);
          } else {
            setDataFit(data);
            const chatSeries = [];
            const barSeries = [];
            const columnSeries = [];
            const radarSeries = [];
            await data.map(async item => {
              if (item.EMAIL) {
                const nameUser = getName(item.EMAIL);
                const values = item.FITS.VALORES;
                const fits = item.FITS;
                const result = item;
                chatSeries.push({
                  name: nameUser,
                  data: [
                    values.ABERTURA_PARA_MUDANCAS_E_INOVACAO,
                    values.AUTONOMIA_E_ABERTURA_PARA_SE_EXPRESSAR,
                    values.CELEBRACAO_DE_CONQUISTAS,
                    values.CLIMA_INTERNO,
                    values.CONFIANCA_NA_LIDERANCA_IMEDIATA,
                    values.CONFIANCA_NO_TIME_DE_TRABALHO,
                    values.CULTURA_E_VALORES_DA_EMPRESA,
                    values.DESAFIOS_CONSTANTES,
                    values.GESTAO_CLARA_E_ACOMPANHAMENTO_DOS_OBJETIVOS,
                    values.HORARIO_DE_TRABALHO,
                    values.LOCAL_DE_TRABALHO,
                    values.OPORTUNIDADES_DE_DESENVOLVIMENTO_E_APRENDIZADO,
                    values.PACOTE_DE_BENEFICIOS,
                    values.PLANO_DE_CARREIRA_CLARO_E_ESTRUTURADO,
                    values.POSSIBILIDADES_DE_CRESCIMENTO_E_PROMOCAO,
                    values.PROCESSOS_SIMPLES_E_DESBUROCRATIZADOS,
                    values.RECONHECIMENTO_E_VALORIZACAO,
                    values.SALARIO,
                    values.TRATAMENTO_HUMANIZADO,
                    values.TRATAMENTO_JUSTO_E_IGUALITARIO_COM_TODOS,
                    values.VISAO_DE_FUTURO_DA_EMPRESA_OU_NEGOCIO,
                  ],
                });
                barSeries.push({
                  name: nameUser,
                  data: [
                    fits.VALORES_CARREIRA,
                    fits.COMPORTAMENTAL,
                    fits.LEARN_AGILITIES,
                    fits.CULTURAL,
                    fits.HARD_SKILLS,
                    fits.FIT_PERSONA_IDEAL,
                  ],
                });
                columnSeries.push({
                  name: nameUser,
                  data: [
                    result.AGILIDADE_MENTAL,
                    result.AGILIDADE_COM_PESSOAS,
                    result.AGILIDADE_COM_MUDANCAS,
                    result.AGILIDADE_COM_RESULTADOS,
                    result.POTENCIAL,
                  ],
                });
                radarSeries.push({
                  name: nameUser,
                  data: [
                    result.ACOLHIMENTO,
                    result.PROPOSITO,
                    result.APRENDIZADO,
                    result.PRAZER,
                    result.RESULTADOS,
                    result.AUTORIDADE,
                    result.SEGURANÇA,
                    result.ORDEM,
                  ],
                });
              }
            });
            setLine({
              series: chatSeries,
              options: {
                chart: {
                  height: 350,
                  type: 'area',
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth',
                },
                xaxis: {
                  type: 'number',
                  categories: [
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                  ],
                },
              },
            });
            setColumn({
              series: columnSeries,
              options: {
                chart: {
                  type: 'bar',
                  height: 350,
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent'],
                },
                xaxis: {
                  categories: [
                    'AGILIDADE MENTAL',
                    'AGILIDADE COM PESSOAS',
                    'AGILIDADE COM MUDANCAS',
                    'AGILIDADE COM RESULTADOS',
                    'POTENCIAL',
                  ],
                },
                yaxis: {
                  title: {
                    text: '$ (thousands)',
                  },
                },
                fill: {
                  opacity: 1,
                },
                tooltip: {
                  y: {
                    formatter(val) {
                      return `$ ${val} thousands`;
                    },
                  },
                },
              },
            });
            setBar({
              series: barSeries,
              options: {
                chart: {
                  type: 'bar',
                  height: 430,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top',
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetX: -6,
                  style: {
                    fontSize: '12px',
                    colors: ['#fff'],
                  },
                },
                stroke: {
                  show: true,
                  width: 1,
                  colors: ['#fff'],
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                },
                xaxis: {
                  categories: [
                    'VALORES DE CARREIRA',
                    'COMPORTAMENTAL',
                    'LEARN AGILITIES',
                    'CULTURAL',
                    'HARDSKILLS',
                    'FIT PERSONA IDEAL',
                  ],
                },
              },
            });
            setRadar({
              series: radarSeries,
              options: {
                chart: {
                  height: 350,
                  type: 'radar',
                  dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1,
                  },
                },
                title: {
                  text: '',
                },
                stroke: {
                  width: 2,
                },
                fill: {
                  opacity: 0.1,
                },
                markers: {
                  size: 0,
                },
                xaxis: {
                  categories: [
                    'ACOLHIMENTO',
                    'PROPOSITO',
                    'APRENDIZADO',
                    'PRAZER',
                    'RESULTADOS',
                    'AUTORIDADE',
                    'SEGURANÇA',
                    'ORDEM',
                  ],
                },
              },
            });
            setDisplay(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [userSelected, jobSelected, companieSelected, job, token, getName]);

  const getJob = useCallback(async () => {
    await api
      .get('/vagas', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data } = response;
        setJob(data);
        setArrayholder(data);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);

  const onCloseError = () => {
    setError(false);
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const onCloseNotice = () => {
    setNotice(false);
  };

  useEffect(() => {
    getJob();
  }, [getJob]);

  useEffect(() => {
    if (profileGlobal.perfil_id) {
      getCompanies(profileGlobal.perfil_id);
    }
  }, [getCompanies, profileGlobal]);

  useEffect(() => {
    if (userSelected) {
      getReport();
    }
  }, [getReport, userSelected]);

  useEffect(() => {
    if (companieSelected) {
      getCandidate(companieSelected);
      setDisabledCandidate(false);
    }
  }, [companieSelected, getCandidate]);

  useEffect(() => {
    if (
      useLocationState &&
      location.state?.companyId &&
      location.state?.jobId &&
      location.state?.emails &&
      arrayholder.length
    ) {
      const currentCompany = allCompanies.find(
        c => c.id === location.state.companyId,
      );
      setInitialCompany(currentCompany);
      setCompanieSelected(location.state.companyId);
      getJob().then(() => {
        handleCompanie(location.state.companyId).then(() => {
          setDisabledCandidate(false);
          setJobSelected(location.state.jobId);
          getCandidate(location.state.jobId).then(() => {
            setUserSelected(location.state.emails);
            setUseLocationState(false);
            window.history.replaceState({}, document.title);
          });
        });
      });
    }
  }, [
    allCompanies,
    arrayholder,
    getCandidate,
    getJob,
    handleCompanie,
    location,
    useLocationState,
  ]);

  return (
    <Container>
      <ResponsiveAppBar />
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <NoticeModal isOpen={notice} msg={msg} onRequestClose={onCloseNotice} />
      <ErrorModal isOpen={error} onRequestClose={onCloseError} />
      <Title>Comparativo Fit Persona</Title>
      <CardFilter>
        <Label>Filtrar sua busca</Label>
        <Grid container spacing={2} sx={{ paddingX: '1.5rem' }}>
          <Grid item xs={12} md={5}>
            <CompanySelector
              fullWidth
              options={companies}
              label={<em>Procurar por empresa</em>}
              onChange={company => handleCompanie(company?.id)}
              initialState={initialCompany}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl fullWidth>
              <Select
                fullWidth
                value={jobSelected}
                onChange={handleJob}
                disabled={disabledJob}
                autoWidth
                size="small"
                color="info"
                sx={{
                  textAlign: 'start',
                }}
              >
                <MenuItem value={0}>
                  <em>Procurar por vaga</em>
                </MenuItem>
                {job.length > 0
                  ? job.map(item => {
                      return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                    })
                  : false}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                fullWidth
                value={userSelected}
                onChange={handleUser}
                disabled={disabledCandidate}
                autoWidth
                size="small"
                color="info"
                multiple
                sx={{
                  textAlign: 'start',
                }}
              >
                <MenuItem value={0}>
                  <em>Procurar por candidato</em>
                </MenuItem>
                {selectedCandidate.length > 0
                  ? selectedCandidate.map(item => {
                      return (
                        <MenuItem value={item.users[0].email}>
                          {item.users[0].name}
                        </MenuItem>
                      );
                    })
                  : false}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardFilter>
      {display && dataFit.length > 0 && (
        <>
          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Fit Persona ideal</SubTitle>
                {dataFit.length > 0 ? (
                  <Chart
                    options={bar.options}
                    series={bar.series}
                    type="bar"
                    height={400}
                  />
                ) : (
                  false
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Cultura</SubTitle>
                {dataFit.length > 0 ? (
                  <Chart
                    options={radar.options}
                    series={radar.series}
                    type="radar"
                    height={600}
                  />
                ) : (
                  false
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="0%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Learn Agilities</SubTitle>
                {dataFit.length > 0 ? (
                  <Chart
                    options={column.options}
                    series={column.series}
                    type="bar"
                    height={400}
                  />
                ) : (
                  false
                )}
              </GroupItems>
            </Card>
          </ContainerCard>

          <ContainerCard top="45%" bottom="1%">
            <Card width="100%" heigth="650px">
              <GroupItems>
                <SubTitle>Âncoras de carreira</SubTitle>
                {dataFit.length > 0 ? (
                  <Chart
                    options={line.options}
                    series={line.series}
                    type="area"
                    height={400}
                  />
                ) : (
                  false
                )}
                <Row top="2%" align="start" bottom="2px">
                  <GpItems
                    padding="10px"
                    width="50%"
                    radius="12px 0px 0px 12px"
                    background={colors.white}
                    borderColor={colors.white}
                  >
                    <SubTitle font="12px">
                      1 - Abertura para mudanças e inovação
                    </SubTitle>
                    <SubTitle font="12px">
                      2 - Autonomia e abertura para se expressar
                    </SubTitle>
                    <SubTitle font="12px">
                      3 - Celebração de conquistas
                    </SubTitle>
                    <SubTitle font="12px">4 - Clima interno</SubTitle>
                    <SubTitle font="12px">
                      5 - Confiança na liderança imediata
                    </SubTitle>
                    <SubTitle font="12px">
                      6 - Confiança no time de trabalho
                    </SubTitle>
                    <SubTitle font="12px">
                      7 - Cultura e valores da empresa
                    </SubTitle>
                    <SubTitle font="12px">8 - Desafios constantes</SubTitle>
                    <SubTitle font="12px">
                      9 - Gestão clara e acompanhamento dos objetivos
                    </SubTitle>
                    <SubTitle font="12px">10 - Horário de Trabalho</SubTitle>
                    <SubTitle font="12px">11 - Local de Trabalho</SubTitle>
                  </GpItems>
                  <GpItems
                    padding="15px"
                    width="50%"
                    radius="0px 12px 12px 0px"
                    background={colors.white}
                    borderColor={colors.white}
                  >
                    <SubTitle font="12px">
                      12 - Oportunidades de desenvolvimento e aprendizado
                    </SubTitle>
                    <SubTitle font="12px">13 - Pacote de benefícios</SubTitle>
                    <SubTitle font="12px">
                      14 - Plano de carreira claro e estruturado
                    </SubTitle>
                    <SubTitle font="12px">
                      15 - Possibilidades de crescimento e promoção
                    </SubTitle>
                    <SubTitle font="12px">
                      16 - Processos simples e desburocratizados
                    </SubTitle>
                    <SubTitle font="12px">17 - Salário</SubTitle>
                    <SubTitle font="12px">
                      18 - Reconhecimento e valorização
                    </SubTitle>
                    <SubTitle font="12px">19 - Tratamento humanizado</SubTitle>
                    <SubTitle font="12px">
                      20 - Tratamento justo e igualitário com todos
                    </SubTitle>
                    <SubTitle font="12px">
                      21 - Visão de futuro da empresa ou negócio
                    </SubTitle>
                  </GpItems>
                </Row>
              </GroupItems>
            </Card>
          </ContainerCard>
        </>
      )}
    </Container>
  );
}

export default FitPersona;
