import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { ThemeProvider } from 'styled-components';
import { auth, analytics } from '../../firebaseConfig';
import { lightTheme } from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import { validEmail } from '../../utils/validEmail';
import logo from '../../assets/img/logo.svg';
import HeaderLogin from '../../components/Headers/HeaderLogin';
// ASSETS
import Eye from '../../assets/img/Eye_Icon.png';
import EyeClose from '../../assets/img/Eye_Close_Icon.png';
import PasswordResetModal from '../../components/PasswordResetModal';
import api from '../../api';

// UTILS
import ScrollToTop from '../../utils/ScrollToTop';

// COMPONENTS
import StoreContext from '../../components/Store/Context';

import {
  LayoutContainer,
  LayoutContent,
  LayoutContentMain,
  Label,
  Description,
  Container,
  Card,
  InputSmaller,
  ErrorMessage,
  Header,
  Logo,
  Group,
} from './styles';
import { useQuery } from '../../utils/searchQuery';
import { useAuth } from '../../contexts/AuthContext';
import { AddCpfDialog } from '../../components/AddCpfDialog';
import { COMPANIES_WITH_REQUIRED_CPF } from '../../utils/requiredCPF';

function Login() {
  const { setToken } = useContext(StoreContext);
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState('');
  const [msgLogin, setMsgLogin] = useState('');
  const [width, setWidth] = useState(window.screen.width);
  const [open, setOpen] = useState(false);
  const [openAddCpf, setOpenAddCpf] = useState(false);
  const [tempToken, setTempToken] = useState(null);

  const { signIn } = useAuth();

  const history = useHistory();

  const query = useQuery();
  const redirectUrl = query.get('redirect') ?? process.env.REACT_APP_URL;

  const firebaseAutentication = async () => {
    logEvent(analytics, 'Login_TAP_SignIn');

    const data = {
      email,
      password,
    };

    if (email && password) {
      try {
        await signInWithEmailAndPassword(auth, email.trim(), password.trim());
        await signIn({ email, password });
        await api
          .post('/login', data, {
            headers: {
              'Content-Type': 'aplication/json',
            },
          })
          .then(async response => {
            const { data: responseData } = response;
            const { data: companies } = await api.get(
              `/users/empresa/${responseData.authenticated_user.user.id}`,
              {
                headers: {
                  Authorization: `Bearer ${responseData.refreshToken}`,
                },
              },
            );
            if (
              companies.data?.some(company =>
                COMPANIES_WITH_REQUIRED_CPF.includes(company.id),
              )
            ) {
              const { data: dadosCandidato } = await api.get(
                `/dados-candidatos/${responseData.authenticated_user.user.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${responseData.refreshToken}`,
                  },
                },
              );
              if (!dadosCandidato[0]?.cpf) {
                setOpenAddCpf(true);
                setTempToken(responseData);
                return;
              }
            }
            setToken(responseData);
            window.location.href = decodeURIComponent(redirectUrl);
          })
          .catch(() => {
            setError(true);
          });
      } catch (err) {
        setIsLoading(false);
        setMsgLogin('Usuário ou senha inválidos!');
        setError(true);
      }
    } else {
      setMsgLogin('Usuário ou senha inválidos!');
      setError(true);
      setIsLoading(false);
    }
  };

  const handleSignIn = async e => {
    e.preventDefault();
    logEvent(analytics, 'Login_TAP_SignIn');
    setIsLoading(true);
    if (!validEmail(email)) {
      setError(true);
      setIsLoading(false);
      setMsgLogin('E-mail inválido! Digite um e-mail válido!');
    } else {
      firebaseAutentication();
    }
  };

  const onRegister = async e => {
    e.preventDefault();
    history.push(`/novoCadastro?redirect=${encodeURIComponent(redirectUrl)}`);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleEmail = async e => {
    e.preventDefault();
    setEmail(e.target.value);
    setError(false);
    setMsgLogin('');
  };

  const handlePassword = async e => {
    e.preventDefault();
    setPassword(e.target.value);
    setError(false);
    setMsgLogin('');
  };

  useEffect(() => {
    logEvent(analytics, 'Login');
    setWidth(window.screen.width);
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <LayoutContainer>
        <LayoutContent>
          <LayoutContentMain>
            <HeaderLogin width={width} />
            <PasswordResetModal
              isOpen={open}
              width={width}
              onRequestClose={() => setOpen(false)}
            />
            <Container>
              <Header>
                <a href="/login">
                  <Logo src={logo} alt="iGrown" />
                </a>
              </Header>
              <Label>Login</Label>
              <Card>
                <Group>
                  <InputSmaller autoFocus>
                    <input
                      type="text"
                      value={email}
                      name="email"
                      placeholder="Digite seu e-mail"
                      onChange={handleEmail}
                    />
                  </InputSmaller>
                  <InputSmaller autoFocus>
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      value={password}
                      name="password"
                      placeholder="Digite sua senha"
                      onChange={handlePassword}
                    />
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                      className="eye-toggle"
                      src={passwordShown ? Eye : EyeClose}
                      alt="password toggle"
                      onClick={togglePassword}
                    />
                  </InputSmaller>
                </Group>
                <ErrorMessage color={error ? colors.alertRed : colors.darkBlue}>
                  {msgLogin}
                </ErrorMessage>
                <Button
                  type="button"
                  width="320px"
                  hoverColor={colors.lightBlue}
                  text="Avançar"
                  isLoading={isLoading}
                  onClick={handleSignIn}
                />
                <Button
                  type="button"
                  width="320px"
                  color={colors.lightBlue}
                  bgColor={colors.transparent}
                  text="Faça seu cadastro"
                  onClick={onRegister}
                />
                <Description
                  onClick={() => setOpen(true)}
                  color={colors.lightBlue}
                  weight="400"
                  font="12px"
                >
                  Esqueceu a senha?
                </Description>
              </Card>
              <ScrollToTop />
            </Container>
          </LayoutContentMain>
        </LayoutContent>
      </LayoutContainer>
      <AddCpfDialog
        isOpen={openAddCpf}
        onClose={() => {
          setOpenAddCpf(false);
          setToken(tempToken);
          window.location.href = decodeURIComponent(redirectUrl);
        }}
        userId={tempToken?.authenticated_user?.user?.id}
        token={tempToken?.refreshToken}
      />
    </ThemeProvider>
  );
}

export default Login;
