import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { CssBaseline } from '@mui/material';
import Login from './pages/Login';
import PasswordRecover from './pages/PasswordRecover';
import LoginAdmin from './pages/LoginAdmin';
import SendEmail from './pages/SendEmail';
import Register from './pages/Register';
import PrivateRoutes from './components/Routes/PrivateRoutes';
import Vacancy from './pages/Vacancy';
import LegalPerson from './pages/LegalPerson';
import NaturalPerson from './pages/NaturalPerson';
import Layout from './Layout';
import colors from './styles/colors';
import './styles/global.css';
import { CalibratePersonaDialog } from './components/CalibratePersonaDialog';
import { ApplicationProvider } from './contexts';

ReactDOM.render(
  <BrowserRouter>
    <CssBaseline />
    <ApplicationProvider>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/recuperacaoSenha" component={PasswordRecover} />
        <Route path="/loginAdmin" component={LoginAdmin} />
        <Route path="/enviarEmail" component={SendEmail} />
        <Route path="/novoCadastro" component={Register} />
        <Route path="/pessoaFisica" component={NaturalPerson} />
        <Route path="/pessoaJuridica" component={LegalPerson} />
        <Route path="/vaga" component={Vacancy} />
        <Route path="/test-page" component={CalibratePersonaDialog} />
        <PrivateRoutes path="/" component={Layout} />
      </Switch>
      <CookieConsent
        location="bottom"
        buttonText="Entendi"
        cookieName="cookie_consent"
        onAccept={() => {
          Cookies.set('cookie_consent', '1');
        }}
        style={{
          background: colors.white,
          paddingLeft: '96px',
          paddingRight: '96px',
        }}
        buttonStyle={{
          background: colors.lightBlue,
          color: colors.white,
          fontSize: '13px',
          borderRadius: '0.75rem',
        }}
        expires={0}
      >
        <span style={{ fontSize: '12px', color: colors.black }}>
          Este site usa cookies e dados pessoais de acordo com os nossos Termos
          de Uso e Política de Privacidade e, ao continuar navegando neste site,
          você declara estar ciente dessas condições.
        </span>
      </CookieConsent>
    </ApplicationProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
