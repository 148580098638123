import styled from 'styled-components';

import colors from '../../styles/colors';

export const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.background};
  /* transition: 0.8s ease-in-out */
`;

export const LayoutContent = styled.div`
  min-height: 100vh;
  @media (max-width: 870px) {
    padding-left: 0;
  }
`;

export const LayoutContentMain = styled.div`
  margin: 0 auto;
`;

export const Container = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 400px;
  z-index: 1;
`;

export const ContainerBlue = styled.div`
  /* Shape */
  position: relative;
  width: 100%;
  height: 350px;
  z-index: 1;
  background: ${colors.darkBlue};
`;

export const Card = styled.div`
  position: relative;
  text-align: start;
  align-item: center;
  width: ${props => props.width || '1200px'};
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0.5%;
  margin-bottom: ${props => props.bottom || '8%'};
  top: ${props => props.top || '10%'};
  background: ${props => props.background || colors.white};
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 12px;
  align-items: center;

  @media (max-width: 870px) {
    width: 700px;
    height: auto;
    margin-bottom: 25%;
  }

  @media (max-width: 570px) {
    width: 340px;
    height: auto;
    margin-bottom: 25%;
  }
`;

export const InputCircle = styled.input`
  text-align: center;
  cursor: pointer;
  display: none;
`;

export const Circle = styled.div`
    position: relative;
    text-align: center;
    width: ${props => props.width || '250px'};
    height: ${props => props.height || '250px'};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.bottom || '1%'};
    top: ${props => props.top || '10%'};
    background: ${props => props.background || colors.gray5};
    box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
    border-radius:  ${props => props.width / 2 || '125px'};;
    align-items: center;
    border: 5px solid ${props => props.border || colors.white};
    ::before {
      color: ${colors.white}
      content: ${props => props.content || ''};;
    }

    input {
      display: none;
      cursor: pointer;
    }
    `;

export const SubCard = styled.div`
  position: relative;
  text-align: start;
  align-item: center;
  width: ${props => props.width || '1200px'};
  height: ${props => props.heigth || '500px'};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0.5%;
  margin-bottom: 1%;
  margin-top: 2%;
  top: ${props => props.top || '10%'};
  background: ${props => props.background || colors.white};
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: ${props => props.radius || '12px'};
  border: 1px solid ${props => props.border || colors.darkBlue};
  align-items: center;

  @media (max-width: 870px) {
    width: ${props => props.width || '600px'};
    height: ${props => props.heigth || 'auto'};
    margin-bottom: 5%;
  }

  @media (max-width: 570px) {
    width: ${props => props.width || '300px'};
    height: ${props => props.heigth || 'auto'};
    margin-bottom: 5%;
  }
`;

export const Header = styled.div`
  position: relative;
  background-color: ${colors.white};
  left: 0%;
  heigth: 100px;
  right: 0%;
  top: 0%;
  bottom: 0%;
  box-shadow: inset 0px -1px 0px #e2e2ea;
`;

export const Logo = styled.img`
  position: relative;
  width: 160px;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;

  @media (max-width: 870px) {
    width: 140px;
  }

  @media (max-width: 570px) {
    width: 100px;
  }
`;

export const ImageHorizontal = styled.img`
  position: relative;
  width: 100%;
  align-items: start;
  padding-left: 2%;
  padding-right: 10px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  @media (max-width: 570px) {
    width: 140px;
  }
`;

export const ImageVertical = styled.img`
  position: relative;
  width: 50px;
  height: 550px;
  align-items: start;
  padding-left: 10px;
  padding-right: 0px;
  padding-top: 0.4%;
  padding-bottom: 0.4%;
  @media (max-width: 570px) {
    width: 30px;
    height: 350px;
    padding-left: 0px;
  }
`;

export const Icon = styled.img`
  position: relative;
  cursor: pointer;
  width: ${props => props.width || '50px'};
  float: ${props => props.float || 'right'};
  padding-right: ${props => props.right || '15px'};
  padding-left: ${props => props.left || '10px'};

  @media (max-width: 870px) {
    width: ${props => props.width || '50px'};
  }

  @media (max-width: 570px) {
    width: ${props => props.width || '50px'};
  }
`;

export const Group = styled.div`
  position: relative;
  flex-direction: column;
  text-align: start !important;
`;

export const SubTitle = styled.h2`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => props.weight || '700'};
  font-size: ${props => props.font || '20px'};
  line-height: 150%;
  text-align: ${props => props.align || 'start'};
  padding-top: ${props => props.top || '1.5%'};
  margin-left: 8px;
  padding-bottom: ${props => props.bottom || '1.8%'};
  letter-spacing: 0.116667px;
  color: ${props => props.color || colors.darkBlue};
  @media (max-width: 870px) {
    padding-top: ${props => props.top || '5%'};
    padding-bottom: ${props => props.bottom || '6%'};
  }

  @media (max-width: 570px) {
    padding-top: ${props => props.top || '5%'};
    padding-bottom: ${props => props.bottom || '6%'};
  }
`;

export const Avatar = styled.h2`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => props.weight || '700'};
  font-size: ${props => props.font || '100px'};
  line-height: 150%;
  justify-content: center;
  text-align: ${props => props.align || 'center'};
  padding-top: ${props => props.top || '15%'};
  padding-bottom: ${props => props.bottom || '15%'};
  letter-spacing: 0.116667px;
  color: ${props => props.color || colors.white};
  @media (max-width: 870px) {
    padding-top: ${props => props.top || '6%'};
    padding-bottom: ${props => props.bottom || '6%'};
  }
`;

export const SubTitleLink = styled.a`
  /* H2 */

  font-family: 'Poppins';
  font-style: normal;
  cursor: pointer;
  font-weight: ${props => props.weight || '700'};
  font-size: ${props => props.font || '20px'};
  line-height: 150%;
  text-align: ${props => props.align || 'start'};
  padding-top: ${props => props.top || '1.5%'};
  padding-bottom: ${props => props.bottom || '1.8%'};
  letter-spacing: 0.116667px;
  color: ${props => props.color || colors.darkBlue};
  @media (max-width: 870px) {
    padding-top: ${props => props.top || '5%'};
    padding-bottom: ${props => props.bottom || '6%'};
  }

  @media (max-width: 570px) {
    padding-top: ${props => props.top || '5%'};
    padding-bottom: ${props => props.bottom || '6%'};
  }
`;

export const ContainerCard = styled.div`
  position: relative;
  text-align: start;
  align-item: start;
  width: ${props => props.width || '1200px'};
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.bottom || '0.5%'};
  padding-bottom: ${props => props.bottom || '0%'};
  justify-content: start;
  top: ${props => props.top || '30%'};
  border-radius: 12px;
  align-items: start;

  @media (max-width: 870px) {
    width: 700px;
    display: block;
  }

  @media (max-width: 570px) {
    width: 340px;
    display: block;
  }
`;

export const GroupItems = styled.div`
  padding: ${props => props.padding || '5%'};
`;

export const GroupGraph = styled.div`
  padding-top: ${props => props.top || '5%'};
  padding-bottom: ${props => props.bottom || '5%'};
`;

export const GroupText = styled.div`
  padding-right: ${props => props.right || '5%'};
  padding-left: ${props => props.left || '5%'};
`;

export const GroupAccordion = styled.div`
  padding-top: ${props => props.top || '5%'};
  padding-left: ${props => props.left || '5%'};
  padding-right: ${props => props.right || '5%'};
  padding-bottom: ${props => props.bottom || '5%'};
`;

export const GroupFilter = styled.div`
  padding-bottom: ${props => props.bottom || '1%'};
  padding-top: ${props => props.top || '0%'};
  padding-left: 5.6%;
  padding-right: 5.6%;
  text-align: ${props => props.align || 'center'};
`;

export const Title = styled.h1`
  position: relative;

  top: 80px;
  font-size: 40px;
  padding-bottom: 1%;

  /* H1 - Web */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;

  @media (max-width: 870px) {
    font-size: 37px;
    padding-bottom: 3%;
  }

  @media (max-width: 570px) {
    font-size: 27px;
    padding-bottom: 8%;
  }

  text-align: center;
  letter-spacing: 0.116667px;

  color: ${colors.white};
`;

export const Row = styled.div`
  cursor: ${props => props.cursor || 'auto'};
  position: relative;
  width: 100% important;
  text-align: center;
  align-item: center;
  display: flex;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.bottom || '20px'};
  justify-content: ${props => props.content || 'start'};
  border-radius: 12px;
  align-items: center;
`;

export const Wrap = styled.div`
  position: relative;
  width: 100% important;
  text-align: center;
  align-item: center;
  display: flex;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props => props.bottom || '20px'};
  justify-content: ${props => props.content || 'start'};
  border-radius: 12px;
  align-items: center;
  flex-wrap: wrap;
`;

export const DescriptionData = styled.p`
  font-size: ${props => props.font || '12px'};
  padding-bottom: ${props => props.bottom || '0%'};
  padding-top: 1.5%;

  font-family: 'Poppins';
  font-style: ${props => props.styles || 'normal'};
  font-weight: ${props => props.weight || '600'};

  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 12px;
    padding-top: 0.5%;
  }

  @media (max-width: 570px) {
    font-size: 12px;
    padding-top: 1%;
  }

  text-align: start;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.darkBlue};
`;

export const Line = styled.div`
  opacity: 0.2;
  border-bottom: 1px solid ${colors.gray4};
  padding-top: ${props => props.top || '5%'};

  @media (max-width: 870px) {
    padding-top: ${props => props.top || '5%'};
  }

  @media (max-width: 570px) {
    padding-top: ${props => props.top || '6%'};
  }

  @media (max-width: 425px) {
    padding-top: ${props => props.top || '6%'};
  }

  @media (max-width: 320px) {
    padding-top: ${props => props.top || '8%'};
  }
`;

export const LabelData = styled.label`
  font-size: ${props => props.font || '12px'};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => props.weight || '600'};

  line-height: 130%;
  /* identical to box height, or 52px */

  @media (max-width: 870px) {
    font-size: 12px;
  }

  @media (max-width: 570px) {
    font-size: 12px;
  }

  text-align: start;
  letter-spacing: 0.116667px;

  color: ${props => props.color || colors.darkBlue};
`;

export const DescriptionGroup = styled.div`
  padding-bottom: 2%;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 1500px) {
    padding-bottom: 6%;
    padding-top: 3%;
  }
  @media (max-width: 870px) {
    padding-bottom: 6%;
    padding-top: 5%;
  }

  @media (max-width: 570px) {
    padding-bottom: 6%;
    padding-top: 5%;
  }

  @media (max-width: 425px) {
    padding-bottom: 8%;
    padding-top: 5%;
  }

  @media (max-width: 320px) {
    padding-bottom: 8%;
    padding-top: 5%;
  }
`;

export const Note = styled.div`
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  display: inline-grid;
  background: ${props =>
    props.active ? colors.lightBlue : colors.transparent};
  color: ${props => (props.active ? colors.white : colors.lightBlue)};
  margin: 0.2%;
  border: 1px solid ${colors.lightBlue};
`;

export const ContainerButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center !important;
  padding-right: 41%;
  padding-left: 41%;

  @media (max-width: 1400px) {
    padding-right: 25%;
    padding-left: 25%;
  }

  @media (max-width: 870px) {
    padding-right: 20%;
    padding-left: 20%;
  }

  @media (max-width: 570px) {
    padding-right: 10%;
    padding-left: 10%;
  }

  @media (max-width: 320px) {
    padding-bottom: 5%;
    padding-top: 5%;
  }
`;

export const ContainerCenter = styled.div`
  position: absolute;
  justify-content: center !important;
  padding-right: 41%;
  padding-left: 41%;
  top: 35%;
  @media (max-width: 570px) {
    top: 46%;
  }
`;
