import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import Button from '../../components/Button';
import api from '../../api';
import colors from '../../styles/colors';
import StoreContext from '../../components/Store/Context';

export function RemoveCareerDialog({ isOpen, onClose, onDelete, careerId }) {
  const { token } = useContext(StoreContext);
  const [isError, setIsError] = useState(false);
  const [isOK, setIsOK] = useState(false);
  const handleRemoveCareer = useCallback(async () => {
    try {
      await api.delete(`/carreiras/${careerId}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      });
      setIsOK(true);
      if (onClose) onClose();
      if (onDelete) onDelete();
    } catch (err) {
      setIsError(true);
      if (onClose) onClose();
    }
  }, [careerId, onClose, onDelete, token]);

  return (
    <>
      <Dialog
        open={isOpen ?? false}
        onClose={() => {
          if (onClose) onClose();
        }}
      >
        <DialogTitle>Excluir carreira</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir esta carreira?
        </DialogContent>
        <DialogActions>
          <Button
            hoverColor={colors.lightBlue}
            text="Sim"
            onClick={handleRemoveCareer}
          />
          <Button hoverColor={colors.lightBlue} text="Não" onClick={onClose} />
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setIsError(false)}
        autoHideDuration={5000}
        open={isError}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Ocorreu um erro ao excluir a carreira. Verifique que não há
          colaboradores ou vagas e candidatos vinculados com esta carreira.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setIsOK(false)}
        autoHideDuration={3000}
        open={isOK}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Carreira excluída com sucesso.
        </Alert>
      </Snackbar>
    </>
  );
}
