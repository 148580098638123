export const removeAccentuation = str => {
  let newstr = '';
  const withAccent =
    'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
  const withoutAccent =
    'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';

  for (let i = 0; i < str.length; i += 1) {
    let swap = false;
    for (let a = 0; a < withAccent.length; a += 1) {
      if (str.substr(i, 1) === withAccent.substr(a, 1)) {
        newstr += withoutAccent.substr(a, 1);
        swap = true;
        break;
      }
    }
    if (swap === false) {
      newstr += str.substr(i, 1);
    }
  }
  return newstr;
};
