import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Title } from './Title';
import Button from '../Button';
import colors from '../../styles/colors';
import { CompanySelector } from '../CompanySelector';
import api from '../../api';
import StoreContext from '../Store/Context';

export function EditCollaboratorDialog({
  isOpen,
  onClose,
  onUpdate,
  selectedUser,
  initialCompanyId,
}) {
  const [careers, setCareers] = useState([]);
  const [initialCompany, setInitialCompany] = useState({
    id: 0,
    razao_social: '',
  });
  const [companies, setCompanies] = useState([]);

  // forms
  const [name, setName] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(initialCompanyId);
  const [selectedCareerId, setSelectedCareerId] = useState({});
  const [profileId, setProfileId] = useState(11);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { token, profileGlobal, userGlobal } = useContext(StoreContext);

  const handleSubmit = useCallback(async () => {
    try {
      if (profileId) {
        await api.post(
          '/users/perfil',
          {
            user_id: selectedUser.id,
            perfil_id: profileId,
          },
          {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
      }
      if (selectedCareerId) {
        await api.put(
          `/users/${selectedUser.id}`,
          {
            name,
            carreira_id: selectedCareerId,
          },
          {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
      }
      if (initialCompanyId !== selectedCompanyId) {
        await api.post(
          `/users/empresa`,
          {
            user_id: selectedUser.id,
            company_id: selectedCompanyId,
          },
          {
            headers: {
              authorization: `Bearer ${token.refreshToken}`,
            },
          },
        );
        await api({
          method: 'DELETE',
          url: '/users/empresa',
          data: {
            user_id: selectedUser.id,
            company_id: initialCompanyId,
          },
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        });
      }

      if (onClose) onClose();
      setMessage('Usuário alterado com sucesso!');
      if (onUpdate) onUpdate();
    } catch (err) {
      setErrorMessage(
        'Ocorreu um erro ao atualizar o usuário. Tente novamente mais tarde',
      );
    }
  }, [
    initialCompanyId,
    name,
    onClose,
    onUpdate,
    profileId,
    selectedCareerId,
    selectedCompanyId,
    selectedUser,
    token,
  ]);

  const getCompanies = useCallback(async () => {
    if (profileGlobal.perfil_id === 8) {
      await api
        .get('/companies', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const data = response.data.response;
          setCompanies(data);
        });
    } else {
      const data = userGlobal;
      setCompanies(data);
    }
  }, [profileGlobal, token, userGlobal]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    if (selectedUser?.id) {
      setName(selectedUser.name);
      setSelectedCareerId(selectedUser.career?.id ?? 0);
      api
        .get(`/users/${selectedUser.id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(({ data }) => {
          setProfileId(data.user.perfil?.perfil_id ?? 11);
        });
    }
  }, [selectedUser, token]);

  useEffect(() => {
    const initialState = companies.find(item => item.id === initialCompanyId);
    if (initialState) {
      setInitialCompany({
        id: initialState.id,
        razao_social: initialState.razao_social,
      });
      setSelectedCompanyId(initialState.id);
    }
  }, [companies, initialCompanyId]);

  useEffect(() => {
    if (selectedCompanyId !== 0 && selectedUser?.id) {
      api
        .get(`/companies/carreiras/${selectedCompanyId}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(res => {
          setCareers(res.data);
        });
    }
  }, [selectedCompanyId, selectedUser, token]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setErrorMessage('')}
        autoHideDuration={5000}
        open={!!errorMessage}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMessage('')}
        autoHideDuration={5000}
        open={!!message}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={isOpen ?? false}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { borderRadius: '1rem' } }}
      >
        <DialogTitle sx={{ marginBottom: 2 }}>
          <Title>Editar colaborador</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <TextField
                  label="Nome"
                  size="small"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CompanySelector
                onChange={item => {
                  setSelectedCompanyId(item?.id);
                  setSelectedCareerId(null);
                }}
                initialState={initialCompany}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl fullWidth size="small">
                <InputLabel id="career-label">Carreira</InputLabel>
                <Select
                  labelId="career-label"
                  label="Carreira"
                  value={selectedCareerId}
                  onChange={e => setSelectedCareerId(e.target.value)}
                  autoWidth
                  size="small"
                  color="info"
                >
                  <MenuItem value={0}>Carreira</MenuItem>
                  {careers.length > 0
                    ? careers.map(item => {
                        return <MenuItem value={item.id}>{item.nome}</MenuItem>;
                      })
                    : false}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="profile-label">Perfil do usuário</InputLabel>
                <Select
                  value={profileId}
                  onChange={e => setProfileId(e.target.value)}
                  autoWidth
                  labelId="profile-label"
                  label="Perfil do usuário"
                  size="small"
                  color="info"
                >
                  <MenuItem value={8}>Administrador Master</MenuItem>
                  <MenuItem value={11}>Profissional</MenuItem>
                  <MenuItem value={12}>Administrador Cliente</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            fontSize="13px"
            text="Cancelar"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onClose}
          />
          <Button
            type="button"
            fontSize="13px"
            text="Salvar"
            disabled={!selectedCompanyId || !name || !profileId}
            hoverColor={colors.lightBlue}
            color={colors.white}
            bgColor={colors.lightBlue}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
