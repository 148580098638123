import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import Activity from '../../assets/img/ic_Activity.svg';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import Solution from '../../assets/img/Solution.svg';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';

import {
  Container,
  Icon,
  Title,
  Description,
  ContainerTitle,
  DescriptionData,
  Row,
  Group,
  DescriptionGroup,
  SubLabel,
  Line,
  TitleGroup,
} from './styles';

function Behavioral() {
  const [width, setWidth] = useState(window.screen.width);
  const location = useLocation();
  const history = useHistory();

  const onHome = () => {
    history.push({
      pathname: '/',
    });
  };

  const onTest = () => {
    history.push({
      pathname: '/progressoTesteComportamental',
      state: {
        vacancy: location.state.vacancy,
        vacancyId: location.state.vacancyId,
      },
    });
  };

  useEffect(() => {
    setWidth(window.screen.width);
  }, [width]);

  return (
    <Container>
      <ResponsiveAppBar />
      <Group>
        <TitleGroup>
          <Icon src={Solution} alt="Next" />
          <Title>Teste de perfil comportamental</Title>
        </TitleGroup>
        <DescriptionGroup>
          <Description>
            <SubLabel color={colors.lightBlue}>Objetivo:</SubLabel> Aumentar o
            seu autoconhecimento apresentando suas tendências comportamentais,
            suas habilidades de destaque e para desenvolvimento, suas agilidades
            de aprendizado e o seu potencial.
          </Description>
        </DescriptionGroup>
        <DescriptionGroup>
          <SubLabel color={colors.lightBlue}>Instruções:</SubLabel>
          <Description>
            Serão apresentadas 4 páginas com 10 blocos de palavras (cada bloco
            com 4 opções). Selecione a palavra que mais representa você como
            pessoa (1 opção por bloco). Se ficar em dúvida em mais de uma
            palavra, selecione a opção mais frequente.
          </Description>
        </DescriptionGroup>
        <Row>
          <Icon width="30px" right="8px" src={Activity} alt="Next" />
          <DescriptionData> Tempo estimado: 8 minutos</DescriptionData>
        </Row>
        <Line />
        <ContainerTitle>
          <Button
            type="button"
            width="80px"
            fontSize="13px"
            text="Sair"
            color={colors.lightBlue}
            bgColor={colors.transparent}
            onClick={onHome}
          />
          <Button
            type="button"
            width="120px"
            fontSize="13px"
            hoverColor={colors.white}
            bgColor={colors.white}
            color={colors.darkBlue}
            border
            text="Iniciar teste"
            onClick={onTest}
          />
        </ContainerTitle>
      </Group>
    </Container>
  );
}

export default Behavioral;
