import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  CircularProgress,
} from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import colors from '../../styles/colors';
import Button from '../../components/Button';
import HardskillModal from '../../components/HardskillModal';
import StoreContext from '../../components/Store/Context';
import ErrorModal from '../../components/ErrorModal';
import api from '../../api';
import AlertModal from '../../components/AlertModal';
import CheckModal from '../../components/CheckModal';

import {
  Container,
  SubTitle,
  Title,
  Group,
  Line,
  DescriptionData,
  DescriptionGroup,
  Row,
} from './styles';
import { userXpLevels } from '../../utils/xpLevels';

function CalibrateHardSkills() {
  const { token } = useContext(StoreContext);
  const [dataCareer, setDataCareer] = useState('');
  const [list, setList] = useState([]);
  const [json, setJson] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState('');
  const vacancyId = location.state?.vacancyId;

  const getHardskill = useCallback(
    async hards => {
      await api
        .get('/hardskill', {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response.data;
          const l = hards.map(item => {
            const arr = data.filter(i => {
              return i.nome === item.name || i.nome === item.nome;
            })[0];
            return arr;
          });
          setList(l);
          setIsVisible(true);
        })
        .catch(() => {
          setError(true);
        });
    },
    [token.refreshToken],
  );

  const getCareer = useCallback(
    async id => {
      await api
        .get(`/carreiras/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          setDataCareer(data);
          getHardskill(
            data[0].persona ? data[0].persona.persona.hard_skills : [],
          );
        })
        .catch(() => {
          setError(true);
        });
    },
    [getHardskill, token],
  );

  const getVacancy = useCallback(
    async id => {
      await api
        .get(`/vagas/${id}`, {
          headers: {
            authorization: `Bearer ${token.refreshToken}`,
          },
        })
        .then(async response => {
          const { data } = response;
          getCareer(data[0].carreira_id);
        });
    },
    [getCareer, token.refreshToken],
  );

  const linkVacancy = async () => {
    const userVagasRequest = {
      user_id: user[0].id,
      vaga_id: vacancyId,
    };
    await api
      .post('/user-vagas', userVagasRequest, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async () => {
        setMsg('Candidatura concluída com sucesso!');
        setAlert(true);
        history.push({
          pathname: '/',
        });
      })
      .catch(err => {
        setMsg(err.response.data.message);
        setCheck(true);
      });
  };

  const bondVacancy = async () => {
    await api
      .get(`/dados/users/${user[0].id}/${vacancyId}`, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const { data } = response.data;
        if (data.length === 0) {
          linkVacancy();
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const onFinish = async () => {
    if (json.length !== list.length) {
      setMsg('Selecione todas as opções!');
      setAlert(true);
      return;
    }
    const data = {
      data: json,
    };

    if (list.length)
      await api.post('/user-hardskill', data, {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      });
    bondVacancy();
  };

  const onHome = async () => {
    history.push({
      pathname: '/',
    });
  };

  const onCloseAlert = () => {
    setAlert(false);
  };

  const getUsers = useCallback(async () => {
    await api
      .get('/users?status=S', {
        headers: {
          authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .then(async response => {
        const data = response.data.users;
        const u = data.filter(item => {
          return item.email === token.email;
        });
        setUser(u);
      })
      .catch(() => {
        setError(true);
      });
  }, [token.email, token.refreshToken]);

  const onCloseModal = async () => {
    setModal(false);
  };

  const mountJson = async (e, hardskillId) => {
    const { value } = e.target;

    const arr = json.filter(item => {
      return item.hard_skill_id !== hardskillId;
    });

    arr.push({
      user_id: user[0].id,
      hard_skill_id: hardskillId,
      valor: value,
    });

    setJson(arr);
  };

  const onCloseError = () => {
    setError(false);
  };

  const onCloseCheck = () => {
    setCheck(false);
  };

  useEffect(() => {
    getUsers();
    getVacancy(vacancyId);
    setWidth(window.screen.width);
  }, [width, getUsers, getVacancy, vacancyId]);

  return (
    <Container>
      <ResponsiveAppBar />
      <ErrorModal isOpen={error} msg={msg} onRequestClose={onCloseError} />
      <AlertModal isOpen={alert} msg={msg} onRequestClose={onCloseAlert} />
      <CheckModal isOpen={check} msg={msg} onRequestClose={onCloseCheck} />
      <HardskillModal
        isOpen={modal}
        calibrate
        onRequestClose={onCloseModal}
        onHome={onHome}
      />
      {isVisible ? (
        <>
          <Group>
            <Title>
              {list.length > 0
                ? 'Auto-avaliação de competências da vaga'
                : 'Finalização do cadastro'}
            </Title>
            <SubTitle font="19px" color={colors.lightBlue}>
              {dataCareer.length > 0 ? dataCareer[0].nome : '--'}
            </SubTitle>
            {list.length > 0 ? (
              <DescriptionGroup>
                <DescriptionData font="14px" weight="400">
                  {dataCareer.length > 0
                    ? `Utilize o formulário abaixo para registrar a sua auto avaliação sobre as suas competências técnicas relacionadas à posição ${dataCareer[0].nome}.`
                    : ''}
                </DescriptionData>
              </DescriptionGroup>
            ) : (
              <>
                <DescriptionGroup>
                  <DescriptionData align="center" font="14px" weight="400">
                    Clique em finalizar para concluir o processo de candidatura.
                  </DescriptionData>
                </DescriptionGroup>
                <Row top="0%">
                  <Button
                    type="button"
                    width="400px"
                    fontSize="13px"
                    text="Finalizar"
                    hoverColor={colors.lightBlue}
                    color={colors.white}
                    bgColor={colors.lightBlue}
                    onClick={onFinish}
                  />
                </Row>
              </>
            )}
            <Line />
          </Group>
          {list.length > 0 &&
            list.map(i => {
              if (i.tipo === 'valor') {
                return (
                  <Group>
                    <Title>{i.nome}</Title>
                    <DescriptionGroup>
                      <FormControl
                        sx={{ m: 1, width: '95%', background: colors.white }}
                      >
                        <InputLabel id="value">Nível de experiência</InputLabel>
                        <Select
                          labelId="value"
                          id="value"
                          InputLabelProps={{ shrink: true, required: true }}
                          size="small"
                          color="info"
                          onChange={value => mountJson(value, i.id)}
                          disabled={false}
                          input={<OutlinedInput label="Nível de experiência" />}
                        >
                          {userXpLevels.map((level, index) => (
                            <MenuItem value={index}>
                              {index}: {level}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DescriptionGroup>
                    <Line />
                  </Group>
                );
              }
              if (i.tipo === 'select') {
                return (
                  <Group>
                    <Title>{i.nome}</Title>
                    <DescriptionGroup>
                      <FormControl
                        sx={{ m: 1, width: '95%', background: colors.white }}
                      >
                        <InputLabel id="value">Resposta</InputLabel>
                        <Select
                          labelId="value"
                          id="value"
                          InputLabelProps={{ shrink: true, required: true }}
                          size="small"
                          color="info"
                          onChange={value => mountJson(value, i.id)}
                          disabled={false}
                          input={<OutlinedInput label="Resposta" />}
                        >
                          <MenuItem value="S">Sim</MenuItem>
                          <MenuItem value="N">Não</MenuItem>
                        </Select>
                      </FormControl>
                    </DescriptionGroup>
                    <Line />
                  </Group>
                );
              }
              return (
                <Group>
                  <Title>{i.nome}</Title>
                  <DescriptionGroup>
                    <FormControl
                      sx={{ m: 1, width: '95%', background: colors.white }}
                    >
                      <InputLabel id="job">Nível</InputLabel>
                      <Select
                        labelId="job"
                        id="job"
                        InputLabelProps={{ shrink: true, required: true }}
                        size="small"
                        color="info"
                        onChange={value => mountJson(value, i.id)}
                        disabled={false}
                        input={<OutlinedInput label="Nível" />}
                      >
                        <MenuItem value="Básico">Básico</MenuItem>
                        <MenuItem value="Intermediário">Intermediário</MenuItem>
                        <MenuItem value="Avançado">Avançado</MenuItem>
                        <MenuItem value="Fluente">Fluente</MenuItem>
                      </Select>
                    </FormControl>
                  </DescriptionGroup>
                  <Line />
                </Group>
              );
            })}
          {list.length > 0 && (
            <Group>
              <Row top="0%">
                <Button
                  type="button"
                  width="400px"
                  fontSize="13px"
                  text="Salvar"
                  hoverColor={colors.lightBlue}
                  color={colors.white}
                  bgColor={colors.lightBlue}
                  onClick={onFinish}
                />
              </Row>
            </Group>
          )}
        </>
      ) : (
        <Group>
          <CircularProgress />
        </Group>
      )}
    </Container>
  );
}

export default CalibrateHardSkills;
