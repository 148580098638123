import { Backdrop, CircularProgress } from '@mui/material';
import { createContext, useContext, useMemo, useState } from 'react';
import colors from '../styles/colors';

const LoadingContext = createContext({});

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const contextValue = useMemo(
    () => ({ setLoading, isLoading: loading }),
    [loading],
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
      <Backdrop
        open={loading}
        sx={{ zIndex: theme => theme.zIndex.modal + 50 }}
      >
        <CircularProgress sx={{ color: colors.white }} color="inherit" />
      </Backdrop>
    </LoadingContext.Provider>
  );
}

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within an LoadingProvider');
  }

  return context;
};

export { LoadingProvider, useLoading };
