import { Typography } from '@mui/material';
import colors from '../../styles/colors';

export function MinValueAlert({ minValue }) {
  return (
    <Typography
      sx={{
        marginY: '0.75rem',
        textAlign: 'center',
        color: minValue === 0 ? colors.lowGreen : colors.alertRed,
        fontWeight: 700,
      }}
    >
      {minValue === 0
        ? 'Parabéns! Valores preenchidos corretamente!'
        : `A soma dos valores deve ser igual a 100. Valor ${
            minValue > 0 ? 'faltante' : 'excedente'
          }: ${Math.abs(minValue)}`}
    </Typography>
  );
}
